<template>

    <div class="bg-white mt-8 mx-3 sm:mx-0 px-3 py-3 border border-red text-red text-center"
         v-if="isShowMessage">
        <p>{{ message }}</p>
        <p v-for="error in errors" :key="error.id">{{ error[0] }}</p>
    </div>
    <!-- MEMO: 個人用/法人用切替タブ -->
    <div class="pt-8">
        <ul class="flex justify-center text-center">
            <li class="w-full block font-bold">
                <button type="button" class="c-button__changeColor py-1.5 px-6 rounded-2xl mr-5"
                        :class="[isActiveTab === refEnum.GuestType.PEOPLE.val ? 'is-active' : '']"
                        @click="tabSelect(refEnum.GuestType.PEOPLE.val)">
                    個人用
                </button>
                <span class="c-button__separate"></span>
                <button type="button" class="c-button__changeColor py-1.5 px-6 rounded-2xl ml-5"
                        :class="[isActiveTab === refEnum.GuestType.CORPORATION.val ? 'is-active' : '']"
                        @click="tabSelect(refEnum.GuestType.CORPORATION.val)">
                    法人用
                </button>
            </li>
        </ul>
    </div>

    <Form :validation-schema="schema">
        <!-- MEMO:登録フォーム -->
        <div class="sm:pb-6"
             :class="userAgentBrowser"
        >
            <div class="px-4"
                 v-if="isShowRegisterForm">
                <!-- MEMO: 更新時に使用する宛名のID -->
                <Field name="id" type="hidden" v-model="sender_item.id"/>
                <Field name="customer_id" type="hidden" v-model="sender_item.customer_id"/>

                <!-- MEMO: 個人用/法人用の種別 -->
                <Field name="guest_type" type="hidden" v-model="isActiveTab"/>

                <ul>
                    <li>
                        <div class="flex items-baseline text-sm pt-8 pb-1">
                            <span>お名前</span>
                            <span v-if="!isShowCorporationForm && this.windowWidth < 640"
                                  class="p-sender__honour ml-auto">旧姓・年齢</span>
                            <button v-if="!isShowCorporationForm && this.windowWidth < 640" type="button"
                                    @click="honourGuide()" class="p-sender__honourGuide">?
                            </button>
                        </div>
                        <div v-if="showGuide && !isShowCorporationForm && this.windowWidth < 640">
                            <div class="p-sender__honourGuide--arrow">
                                <p>旧姓や年齢にご使用下さい。</p>
                                <p>例）(旧姓：佐藤)</p>
                            </div>
                        </div>
                        <div class="c-grid__input__col3 is-sideMenu">
                            <VMenu :distance="-3" placement="top" :triggers="['hover']">
                                <Field name="sei" type="text" placeholder="姓"
                                       class="block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                       :validateOnBlur="true"
                                       :validateOnChange="true"
                                       :validateOnInput="true"
                                       autocomplete="off"
                                       @copy="disableEvent"
                                       @paste="disableEvent"
                                       @select="setSelectionRange"
                                       @cut="disableEvent"
                                       @click="inputProcess"
                                       @blur="inputProcess"
                                       @keyup.left="inputProcess"
                                       @keyup.right="inputProcess"
                                       @compositionstart="compositionStart"
                                       @compositionend="compositionEnd"
                                       @input="inputProcess"
                                       v-model="sender_item.sei"/>
                                <ErrorMessage name="sei" as="li" class="c-validate__message"/>

                                <template #popper>
                                    <button
                                        type="button"
                                        @click="showExCharacterToolTip('sei');
                                            externalOpenModal();
                                            changeOverflow()"
                                    >
                                        <img alt="ic_gaiji-box.png" src="/images/icon/ic_gaiji-box.png"
                                             class="w-28 sm:w-32 mx-auto">
                                    </button>
                                </template>

                                <!-- MEMO: 外字選択済 プレビュー表示 -->
                                <div class="c-grid__external is-sideMenu mt-2" v-if="inputValueHaveSomeElements">
                                    <NamePreview
                                        :preview-data="evenInputValuesForNameType(refEnum.NameType.SEI.val)"
                                    />
                                </div>
                                <!-- MEMO: 外字選択済 プレビュー表示 End-->
                            </VMenu>

                            <VMenu :distance="-3" placement="top" :triggers="['hover']">
                                <Field name="mei"
                                       type="text" placeholder="名"
                                       class="block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                       :validateOnBlur="true"
                                       :validateOnChange="true"
                                       :validateOnInput="true"
                                       autocomplete="off"
                                       @copy="disableEvent"
                                       @paste="disableEvent"
                                       @select="setSelectionRange"
                                       @cut="disableEvent"
                                       @click="inputProcess"
                                       @blur="inputProcess"
                                       @keyup.left="inputProcess"
                                       @keyup.right="inputProcess"
                                       @compositionstart="compositionStart"
                                       @compositionend="compositionEnd"
                                       @input="inputProcess"
                                       v-model="sender_item.mei"/>
                                <ErrorMessage name="mei" as="li" class="c-validate__message"/>

                                <template #popper>
                                    <button
                                        type="button"
                                        @click="showExCharacterToolTip('mei');
                                            externalOpenModal();
                                            changeOverflow()"
                                    >
                                        <img alt="ic_gaiji-box.png" src="/images/icon/ic_gaiji-box.png"
                                             class="w-28 sm:w-32 mx-auto">
                                    </button>
                                </template>

                                <!-- MEMO: 外字選択済 プレビュー表示 -->
                                <div v-if="inputValueHaveSomeElements" class="c-grid__external is-sideMenu mt-2">
                                    <NamePreview
                                        :preview-data="evenInputValuesForNameType(refEnum.NameType.MEI.val)"
                                    />
                                </div>
                                <!-- MEMO: 外字選択済 プレビュー表示 End-->
                            </VMenu>
                            <div class="sm:pt-6" v-if="!isShowCorporationForm">
                                <div class="relative">
                                    <span v-if="this.windowWidth > 640"
                                          class="block text-sm sm:pb-1">旧姓・年齢</span>
                                    <button v-if="this.windowWidth > 640" type="button"
                                            @click="honourGuide()" class="p-sender__honourGuide is-design">?
                                    </button>
                                    <div v-if="showGuide && this.windowWidth > 640">
                                        <div class="p-sender__honourGuide--arrow is-design">
                                            <p>旧姓や年齢にご使用下さい。</p>
                                            <p>例）(旧姓：佐藤)</p>
                                        </div>
                                    </div>
                                </div>
                                <VMenu :distance="-3" placement="top" :triggers="['hover']">
                                    <Field name="old_name_or_age"
                                           type="text"
                                           class="block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                           :validateOnBlur="true"
                                           :validateOnChange="true"
                                           :validateOnInput="true"
                                           autocomplete="off"
                                           @copy="disableEvent"
                                           @paste="disableEvent"
                                           @select="setSelectionRange"
                                           @cut="disableEvent"
                                           @click="inputProcess"
                                           @blur="inputProcess"
                                           @keyup.left="inputProcess"
                                           @keyup.right="inputProcess"
                                           @compositionstart="compositionStart"
                                           @compositionend="compositionEnd"
                                           @input="inputProcess"
                                           v-model="sender_item.old_name_or_age"/>
                                    <ErrorMessage name="old_name_or_age" as="li" class="c-validate__message"/>

                                    <template #popper>
                                        <button
                                            type="button"
                                            @click="showExCharacterToolTip('old_name_or_age');
                                                externalOpenModal();
                                                changeOverflow()"
                                        >
                                            <img alt="ic_gaiji-box.png" src="/images/icon/ic_gaiji-box.png"
                                                 class="w-28 sm:w-32 mx-auto">
                                        </button>
                                    </template>

                                    <!-- MEMO: 外字選択済 プレビュー表示 -->
                                    <div class="c-grid__external--age mt-2">
                                        <NamePreview
                                            :preview-data="evenInputValuesForNameType(refEnum.NameType.OLD_NAME.val)"
                                        />
                                    </div>
                                    <!-- MEMO: 外字選択済 プレビュー表示 End-->
                                </VMenu>
                            </div>
                        </div>
                    </li>
                    <!-- MEMO: 法人用の項目 -->
                    <li v-if="isShowCorporationForm">
                        <p class="text-sm pb-1">役職</p>
                        <Field name="position_name"
                               type="text" placeholder=""
                               class="w-5/12 border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                               :validateOnBlur="true"
                               :validateOnChange="true"
                               :validateOnInput="true"
                               v-model="sender_item.position_name"/>
                    </li>
                    <ErrorMessage name="position_name" as="li"
                                  class="c-validate__message"/>

                    <!-- MEMO: 外字検索モーダル -->
                    <SearchExternalCharacterModal
                        v-if="isShowExternalCharacterModal"
                        :get-scroll-y="scrollY"
                        :class="{'is-active': isExternalAndSearchPostModal}"
                        :display-font-id="displayFontId"
                        @closeSearchExternalCharacterModal="selectExternalCharacter"
                    />

                    <!-- MEMO: 法人用の項目 -->
                    <div v-if="isShowCorporationForm">
                        <li>
                            <p class="pt-8 pb-1 text-sm">法人名1</p>
                            <Field name="corporate_name1"
                                   type="text" placeholder="株式会社 ○○"
                                   class="block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                   :validateOnBlur="true"
                                   :validateOnChange="true"
                                   :validateOnInput="true"
                                   v-model="sender_item.corporate_name1"/>
                        </li>
                        <ErrorMessage name="corporate_name1" as="li" class="c-validate__message"/>

                        <!--法人名(仮)-->
                        <li>
                            <p class="text-sm pt-4 pb-1">法人名2</p>
                            <Field name="corporate_name2"
                                   type="text" placeholder="会社名が長い場合に使用"
                                   class="block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                   :validateOnBlur="true"
                                   :validateOnChange="true"
                                   :validateOnInput="true"
                                   v-model="sender_item.corporate_name2"/>
                        </li>
                        <ErrorMessage name="corporate_name2" as="li" class="c-validate__message"/>

                        <li>
                            <p class="pt-8 pb-1 text-sm">部署1</p>
                            <Field name="department_name1"
                                   type="text" placeholder="○○部"
                                   class="block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                   :validateOnBlur="true"
                                   :validateOnChange="true"
                                   :validateOnInput="true"
                                   v-model="sender_item.department_name1"/>
                        </li>
                        <ErrorMessage name="department_name1" as="li" class="c-validate__message"/>

                        <!--部署(仮)-->
                        <li>
                            <p class="text-sm pt-4 pb-1">部署2</p>
                            <Field name="department_name2"
                                   type="text" placeholder="部署が長い場合に使用"
                                   class="block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                   :validateOnBlur="true"
                                   :validateOnChange="true"
                                   :validateOnInput="true"
                                   v-model="sender_item.department_name2"/>
                        </li>
                        <ErrorMessage name="department_name2" as="li" class="c-validate__message"/>
                    </div>
                    <!-- MEMO: 法人用の項目 End -->

                    <!-- MEMO: 連名
                        姓、名の連名のケースは初期に3つのテキストエリアを表示 / それ以降は追加ボタンを設置する -->
                    <template v-if="!isShowCorporationForm">
                        <li class="pt-8" v-for="(joint_name, index) in sender_item.joint_names" :key="index">
                            <div class="pb-1 text-sm">
                                <span>連名</span>
                                <button type="button"
                                        class="p-button__delete"
                                        @click="deleteSenderJoinName(index)">
                                    <span class="text-red pl-2">削除</span>
                                </button>
                            </div>
                            <div class="c-grid__input__col3 is-sideMenu sm:gap-y-4">
                                <!-- MEMO: 連名（姓）-->
                                <div>
                                    <Field :name="`joint_names[${index}].sei`"
                                           type="text" placeholder="姓"
                                           class="block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                           v-model="sender_item.joint_names[index].sei"
                                    />
                                    <ErrorMessage :name="`joint_names[${index}].sei`" as="li"
                                                  class="c-validate__message"/>
                                </div>

                                <!-- MEMO: 連名（名）-->
                                <div>
                                    <Field :name="`joint_names[${index}].mei`"
                                           type="text" placeholder="名"
                                           class="block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                           v-model="sender_item.joint_names[index].mei"
                                    />
                                    <ErrorMessage :name="`joint_names[${index}].mei`" as="li"
                                                  class="c-validate__message"/>
                                </div>

                                <!-- MEMO: 連名（旧姓・年齢）-->
                                <div v-if="!isShowCorporationForm">
                                    <Field :name="`joint_names[${index}].old_name_or_age`"
                                           type="text" placeholder="例）(旧姓：佐藤)"
                                           class="block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                           v-model="sender_item.joint_names[index].old_name_or_age"
                                    />
                                    <ErrorMessage :name="`joint_names[${index}].old_name_or_age`" as="li"
                                                  class="c-validate__message"/>
                                </div>
                                <!--                                    </VMenu>-->
                            </div>
                            <!-- MEMO: 法人用の項目 -->
                            <div v-if="isShowCorporationForm" class="pt-6">
                                <li>
                                    <p class="text-sm pb-1">役職</p>
                                    <Field :name="`joint_names[${index}].position_name`"
                                           type="text" placeholder=""
                                           class="w-5/12 border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                           :validateOnBlur="true"
                                           :validateOnChange="true"
                                           :validateOnInput="true"
                                           v-model="sender_item.joint_names[index].position_name"/>
                                </li>
                            </div>
                            <ErrorMessage :name="`joint_names[${index}].position_name`" as="li"
                                          class="c-validate__message"/>

                        </li>

                        <!--MEMO: 連名追加ボタン -->
                        <li>
                            <div class="text-center pt-12">
                                <button type="button" class="bg-sky-400 text-white text-base py-2 px-6 rounded-5px"
                                        @click="addJointNameForm"><span class="text-lg">＋</span>&nbsp;連名を追加
                                </button>
                            </div>
                        </li>
                    </template>
                    <!-- MEMO: 郵便番号 -->
                    <li>
                        <p class="text-sm pt-8 pb-1">郵便番号</p>
                        <div class="flex justify-between">
                            <Field name="post_no"
                                   type="text" placeholder="例）1012345"
                                   class="w-2/3 placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                                   :validateOnBlur="true"
                                   :validateOnChange="true"
                                   :validateOnInput="true"
                                   @blur="getPostNo"
                                   v-model="sender_item.post_no"
                                   maxlength="7"/>
                            <button type="button"
                                    @click="getPostNo;
                                                changeOverflow()"
                                    class="w-1/3 text-sm bg-blue02 text-white font-bold ml-2 rounded-5px">住所検索
                            </button>
                        </div>
                        <ErrorMessage name="post_no" as="li" class="c-validate__message"/>
                    </li>

                    <!-- MEMO: 都道府県 -->
                    <li>
                        <div class="flex items-center gap-2 pt-6 pb-1">
                            <p class="text-sm">都道府県</p>
                        </div>
                        <Field name="pref_name"
                               class="c-input__selectArea w-4/12 rounded-md py-1.5 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                               as="select"
                               @change="setPrefName"
                               v-model="selected_pref">
                            <option v-for="pref_data in pref" :key="pref_data.id" :value="pref_data.name">
                                {{ pref_data.name }}
                            </option>
                        </Field>
                        <ErrorMessage name="pref_name" as="li" class="c-validate__message"/>
                    </li>

                    <!-- MEMO: 市区町村・地名 -->
                    <li>
                        <div class="flex items-center gap-2 pt-6 pb-1">
                            <p class="text-sm">市区町村・地名</p>
                        </div>
                        <Field name="address"
                               type="text" placeholder="市区町村"
                               class="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                               :validateOnBlur="true"
                               :validateOnChange="true"
                               :validateOnInput="true"
                               v-model="sender_item.address"/>
                        <ErrorMessage name="address" as="li" class="c-validate__message"/>
                    </li>

                    <!-- MEMO: 建物・部屋番号 building_name-->
                    <li>
                        <p class="text-sm pt-6 pb-1">建物・部屋番号</p>
                        <Field name="building_name"
                               type="text"
                               class="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                               :validateOnBlur="true"
                               :validateOnChange="true"
                               :validateOnInput="true"
                               v-model="sender_item.building_name"/>
                        <ErrorMessage name="building_name" as="li" class="c-validate__message"/>
                    </li>

                    <!-- MEMO 電話番号 -->
                    <li>
                        <p class="text-sm pt-6 pb-1">電話番号</p>
                        <Field name="tel"
                               type="text" placeholder="090-1234-5678"
                               class="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                               :validateOnBlur="true"
                               :validateOnChange="true"
                               :validateOnInput="true"
                               v-model="sender_item.tel"/>
                        <ErrorMessage name="tel" as="li" class="c-validate__message"/>
                    </li>
                </ul>
            </div>
        </div>
        <!-- MEMO:個人用入力フォーム End -->

        <!-- MEMO: 住所検索をした時のモーダル -->
        <DuplicatePostNoModal
            v-if="isShowSelectPostNoModal"
            :post-no="sender_item.post_no"
            :post-no-list="post_no_list"
            :get-scroll-y="scrollY"
            @closeDuplicatePostNoModal="selectDuplicatePostNo"
            :class="{'is-active': isExternalAndSearchPostModal}"/>
    </Form>
</template>

<script>
import {DesignSenderValidationRule} from "../validation_rules/design_sender_rule";
import * as Enum from "../const/const";
import config from "../const/const";
import {ErrorMessage, Field, Form} from 'vee-validate';
import NamePreview from "./NamePreview";
import SearchExternalCharacterModal from "./SearchExternalCharacterModal";
import axios from "axios";
import RegisterFunctions from "../functions/register";
import DuplicatePostNoModal from "./DuplicatePostNoModal";
import CommonModule from "../functions/common_module";
import InputFunctions from "../functions/input";
import _ from "lodash";
import {inject} from "vue";
import AvailableResources from "./Edit/AvailableResources.vue";

const {disableEvent} = InputFunctions();
const {
    newChar,
    initJointName,
} = RegisterFunctions();

/**
 * 名前タイプを文字列から数値に変換します
 * */
const convertNameTypeStringToNumber = (target_name) => {
    let name_type = Enum.NameType.SEI.val;
    switch (target_name) {
        case 'mei':
            name_type = Enum.NameType.MEI.val;
            break;
        case 'old_name_or_age':
            name_type = Enum.NameType.OLD_NAME.val;
            break;
    }
    return name_type;
}

export default {
    name: "SenderEditForm",
    components: {SearchExternalCharacterModal, NamePreview, Form, Field, ErrorMessage, DuplicatePostNoModal},
    setup() {
        const availableResources = inject('availableResources')
        const makingItem = inject('makingItemInfo')
        return {
            availableResources,
            makingItem
        }
    },
    props: {
        senderItem: {
            type: Object,
            required: false,
        },
        displayFontId: {
            type: Number,
            default: Enum.FONT.GOTHIC.id,
        },
    },
    data() {
        // MEMO:
        const schema = DesignSenderValidationRule;

        return {
            customer: null,
            style_display: '',
            sender_item: null,        // propsで渡されたデータを変更するための変数
            pref: [],                 // 都道府県のセレクトボックに表示する敬称のデータ
            selected_pref: '',        // 都道府県の選択済みにするための変数(デフォルト値)。都道府県の場合、郵便番号検索があるためnameの値を設定する
            check_post_no_data: '',             // 郵便番号選択モーダルのチェックボックス
            isActiveTab: Enum.GuestType.PEOPLE.val,  // デフォルトの有効タブ
            isShowRegisterForm: false,              // 登録フォームの表示・非表示
            isShowCorporationForm: false,           // 法人用登録項目の表示・非表示
            isShowMessage: false,                   // 登録時のエラーメッセージ表示切り替え
            isShowSelectPostNoModal: false,         // 郵便番号が重複している場合に表示する選択モーダルの表示切り替え
            post_no_list: [],   // 郵便番号が重複した場合の選択肢用配列
            get_post_no_message: null,  // 郵便番号検索のメッセージ
            message: null,      // 登録時のメッセージ用
            errors: {},         // 登録処理のエラーメッセージ用
            isShowExternalCharacterModal: false,
            ex_target_input_name: '',
            ex_cursor_point: 0,         // 外字を挿入するカーソルの位置
            selection_range: [],        // テキストの選択範囲
            copy_selection_range: [],   // コピーした際のテキストの選択範囲
            input_values: [],           // 入力データ用
            joint_name: null,
            delete_by_cut_texts: [],
            composing: false,           // IME入力中の場合True
            schema,
            content: '',
            isExternalAndSearchPostModal: false,
            modalNotScroll: false,
            scrollY: 0,
            windowWidth: '',
            showGuide: false,
        }
    },
    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述
        userAgentBrowser() {
            const browser = window.navigator.userAgent.toLowerCase();
            return browser.indexOf('safari') != -1 ? ['pb-32'] : ['pb-20'];
        },
        inputValueHaveSomeElements() {
            if (!this.input_values) {
                return false
            }
            if (!this.input_values.length) {
                return false
            }
            return this.input_values.length > 0
        },
    },
    methods: {
        disableEvent(e) {
            disableEvent(e);
        },
        /**
         * MEMO: IME変換開始時
         * */
        compositionStart() {
            // 変換中にする
            this.composing = true;
        },
        /**
         * MEMO: IME変換終了時
         * */
        compositionEnd(e) {
            // 変換終了にして、入力処理を実行
            this.composing = false;
            this.inputProcess(e);
        },
        /**
         * MEMO: 外字挿入箇所のカーソルの位置を記憶する
         * */
        inputProcess(e) {
            // MEMO: IME変換中はReturn
            if (this.composing) {
                return;
            }

            // 現在のカーソルの位置を設定
            this.ex_cursor_point = e.target.selectionStart;

            switch (e.type) {
                case 'click':
                case 'blur':
                    this.clickProcess(e);
                    break;
            }

            switch (e.inputType) {
                // MEMO: Ctrl+z
                case 'historyUndo':
                    this.clickProcess(e);
                    break;
                case 'deleteByCut':
                    this.deleteByCutProcess(e);
                    break;
                // 文字削除
                case 'deleteContentForward':
                case 'deleteContentBackward':
                    this.deleteContentProcess(e);
                    break;
                // ペースト
                case 'insertFromPaste':
                    this.insertFromPasteProcess(e);
                    break;
            }
            this.refreshInputValues(this.sender_item.sei, Enum.NameType.SEI.val)
            this.refreshInputValues(this.sender_item.mei, Enum.NameType.MEI.val)
            this.refreshInputValues(this.sender_item.old_name_or_age, Enum.NameType.OLD_NAME.val)

            // MEMO: 保存する際に使用するため、プレビューのデータをセットする
            this.sender_item.sender_name_parts = this.input_values;
        },
        /**
         * MEMO: input text のクリック時の処理
         * */
        clickProcess() {
            //MEMO: 範囲選択→何も操作なしの場合もあるため、範囲選択をリセット
            this.selection_range.splice(0);
        },
        /**
         * MEMO: input text の文字入力時の処理
         * */
        insertFromPasteProcess(e) {
            // TODO: 切り取り → ペーストの場合（未実装）
            // if(this.delete_by_cut_texts.length) {
            //     console.log('paste start:' + this.ex_cursor_point + '/' + e.target.selectionStart)
            //     for(let i =0; i < this.delete_by_cut_texts.length; i++) {
            //         let clone = JSON.parse(JSON.stringify(this.delete_by_cut_texts[i]));
            //         // MEMO: ペースト時にはカーソルは進んでしまっているため、-1した位置を指定する
            //         clone.position = this.ex_cursor_point + i;
            //         this.input_values.splice(clone.position, 0,clone);
            //     }
            // } else {

            const name_type = convertNameTypeStringToNumber(e.target.name);
            const start_index = this.getStartIndex(name_type);

            // MEMO: ペースト
            //  コピー範囲の文字が外字検索の文字の場合、ペーストする際も外字として扱う
            const copy_selection_range_Length = this.copy_selection_range.length
            for (let i = 0; i < copy_selection_range_Length; i++) {
                let position = this.copy_selection_range[i]

                // コピー元の文字情報を取得する
                let result = this.findInputValues(name_type, position);

                if (!result.length) {
                    continue;
                }

                let clone = JSON.parse(JSON.stringify(result[0]));

                // MEMO: ペースト時にはカーソルは進んでしまっているため、
                //  現在のカーソルの位置 - コピーした際の文字数 + 範囲コピーの位置からコピー元の文字情報を取得する
                clone.position = this.ex_cursor_point - copy_selection_range_Length + i;
                clone.name_type = name_type;
                this.input_values.splice(start_index + clone.position, 0, clone);
            }
            // MEMO: コピペにより文字が追加され位置が変わったのでコピペしたテキストボックス内の文字の位置(position)を再設定する
            this.refreshPosition(name_type);
            // }
        },
        /**
         * MEMO: input type=textのdelete or backspaceの処理
         * */
        deleteContentProcess(e) {
            let deleted_positions = [];

            // 範囲削除の場合
            if (this.selection_range.length) {
                deleted_positions = this.selection_range;
            } else {
                // 一文字削除の場合、カーソルの位置を使用する
                deleted_positions.push(this.ex_cursor_point);
            }

            const name_type = convertNameTypeStringToNumber(e.target.name);
            for (let i = 0; i < deleted_positions.length; i++) {
                // 削除された文字列を元に、選択済み外字から指定されたPositionに一致しない要素を取得し選択済み外字を上書きする
                [this.input_values, this.sender_item.display_sender_name_parts] =
                    this.notEvenInputValuesForPosition(name_type, deleted_positions[i]);
            }

            //MEMO：文字を削除した場合、テキストボックス内の文字位置が変わるため、data内のpositionも前詰めする
            this.refreshPosition(name_type);
            this.selection_range.splice(0); // 選択範囲をリセット
        },
        /**
         * MEMO: input type=textのCtrl+x(切り取り)の処理
         * */
        deleteByCutProcess(e) {
            // TODO: 未実装
            let cut_positions = [];
            // 範囲削除の場合
            if (this.selection_range.length) {
                cut_positions = this.selection_range;
            } else if (this.copy_selection_range.length) {
                // コピー 後に切り取りの場合
                cut_positions = this.copy_selection_range;
            } else {
                // 一文字削除の場合、カーソルの位置を使用する
                cut_positions.push(this.ex_cursor_point);
            }

            const name_type = convertNameTypeStringToNumber(e.target.name);
            for (let index in cut_positions) {
                // 削除された文字列を元に、選択済み外字から指定されたPositionに一致しない要素を取得し選択済み外字を上書きする
                [this.input_values, this.sender_item.display_sender_name_parts] =
                    this.notEvenInputValuesForPosition(name_type, cut_positions[index]);
            }

            //
            // // 削除前に切り取った文字をdelete_by_cut_textsに保存する
            // for(let i =0; i < cut_positions.length; i++) {
            //     let result = this.input_values.filter(function (item) {
            //         return item.position === cut_positions[i];
            //     })
            //
            //     if(!result.length) {
            //         continue;
            //     }
            //
            //     let clone = JSON.parse(JSON.stringify(result[0]));
            //     this.delete_by_cut_texts.push(clone)
            // }
            //
            // for(let i =0; i < cut_positions.length; i++) {
            //     // 削除された文字列を元に、選択済み外字から指定されたPositionに一致しない要素を取得し選択済み外字を上書きする
            //     this.input_values = this.input_values.filter(function (item) {
            //         return item.position !== cut_positions[i];
            //     })
            // }
            //
            // // 文字の位置(position)の前詰め
            // this.input_values.map((value, index) => {
            //     value.position = index;
            // });
            //
            // console.log('deleteByCut cursor:' + this.ex_cursor_point + '/' + e.target.selectionStart)
            this.selection_range.splice(0); // 選択範囲をリセット
            // 編集時に外字プレビューを表示するため、登録されている外字情報をdataにセット
            this.sender_item.sender_name_parts = _.cloneDeep(this.input_values);
        },
        /**
         * MEMO: テキストボックスの値を元に、入力用配列を再構築する
         * */
        refreshInputValues(value, name_type) {

            if (!value) {
                return [];
            }

            // 姓名ごとに、input_values内の開始位置を取得する
            let start_index = this.getStartIndex(name_type);
            const input_chars = Array.from(value);

            for (let i = 0; i < input_chars.length; i++) {
                // 半角スペース、全角スペースを置換する
                let input_char = input_chars[i];

                // テキストボックスの値と入力されている文字と一致した場合は配列内のデータを使う
                let result = this.input_values.filter(function (item) {
                    return item.name_type === name_type && item.input_text === input_char && item.position === i;
                });

                // すでにdataの中にある場合はスキップ
                if (result.length > 0) {
                    continue;
                }

                // テキストボックス内に
                // IME入力中→クリックで確定した時などはここに入る
                let data = newChar();
                data.position = i;
                data.type = Enum.DataType.FROM.val
                data.name_type = name_type;
                data.input_text = input_char;
                const splice_index = start_index + i;

                this.input_values.splice(splice_index, 0, data);
                this.sender_item.display_sender_name_parts.splice(splice_index, 0, _.cloneDeep(data));
                this.refreshPosition(name_type)

                this.sender_item.display_sender_name_parts
                    .filter((item) => item.name_type === name_type)
                    .map((value, index) => {
                        value.position = index;
                    });
            }

            let deleteIndex = [];
            // テキストボックスにない文字をプレビューエリアから消す
            for (const [index, input_value] of this.input_values.entries()) {
                // inputの場所が違う場合はスキップ
                if (input_value.name_type !== name_type) {
                    continue;
                }
                // 入力ボックスに存在するテキストの場合はスキップ
                let result = input_chars.filter((input_char, index) => {
                    return input_char === input_value.input_text && index === input_value.position;
                });

                if (result.length > 0) {
                    continue;
                }

                // MEMO:ループの最中に要素を消すと不具合になるため。削除対象のindexを配列にまとめる
                deleteIndex.push(index);
            }

            // MEMO: 削除対象に一致しないもののみ抽出することで、削除扱いにする
            this.input_values = this.input_values.filter((input_value, index) => !deleteIndex.includes(index));
            this.sender_item.display_sender_name_parts = this.sender_item.display_sender_name_parts.filter((input_value, index) => !deleteIndex.includes(index));
            this.refreshPosition(name_type);
        },
        /**
         * MEMO: 指定されたname_typeから始まるinput_valuesのindexを返します
         * */
        getStartIndex(name_type) {
            let start_index = 0;
            if (this.inputValueHaveSomeElements && this.input_values.length !== 0) {
                start_index = this.input_values
                    .findIndex(input_value => input_value.name_type === name_type);
            }

            // 開始位置がマイナスの場合、0にする
            return start_index < 0 ? 0 : start_index;
        },
        /**
         * MEMO: 指定されたname_typeのpositionを再設定します
         * */
        refreshPosition(name_type) {
            let input_values = this.findInputValues(name_type, null);
            input_values.forEach((value, index) => {
                value.position = index;
            });
            let display_values = this.findDisplayValues(name_type, null);
            display_values.forEach((value, index) => {
                value.position = index;
            });
        },
        /**
         * MEMO: コピー時の範囲選択
         * */
        setCopySelectionRange(e) {
            const start_index = e.target.selectionStart
            const end_index = e.target.selectionEnd
            this.copy_selection_range.splice(0);
            for (let i = start_index; i < end_index; ++i) {
                // 選択した範囲の位置を配列としてdataに追加
                this.copy_selection_range.push(i);
            }
            this.selection_range.splice(0);
            // MEMO: コピーの範囲選択時にもsetSelectionRangeが呼ばれてしまうため選択範囲をリセットする
            this.delete_by_cut_texts.splice(0);
        },
        /**
         * MEMO: 範囲選択（範囲選択→Delete or Backspace等）
         * */
        setSelectionRange(e) {
            const start_index = e.target.selectionStart
            const end_index = e.target.selectionEnd
            this.selection_range.splice(0);
            for (let i = start_index; i < end_index; ++i) {
                this.selection_range.push(i);
            }
            this.delete_by_cut_texts.splice(0);
        },
        /**
         * MEMO: 登録/更新フォームの表示・非表示
         * */
        open() {
            // MEMO: デフォルトとしてSPサイズ=下からのドロワーで表示する
            this.style_display = '';

            // MEMO: PCサイズ=モーダル
            if (window.matchMedia('(min-width: 640px)').matches) {
                this.style_display = this.style_display === '' ? 'block' : '';
            }

            // MEMO: propsは変更不可のため、値を書き換えられるようにpropsの値を変数に渡す
            this.sender_item = this.senderItem;

            // MEMO: プレビュー用のdataに差出人名パーツの値をセット
            this.input_values = this.sender_item.sender_name_parts;

            // 登録済の選択肢がある場合、選択済みにするため値を設定
            this.selected_pref = this.sender_item.pref_name;

            this.tabSelect(this.sender_item.guest_type);
            // 登録に必要なセレクトボックスの値を取得
            this.getPrefList();
        },
        /**
         * MEMO: 外字検索用のツールチップ表示
         * */
        showExCharacterToolTip(target_input_name) {
            this.isShowExternalCharacterModal = true;
            this.ex_target_input_name = target_input_name;
            CommonModule.designLayer(this.windowWidth, document.querySelector('#designContent'));
        },
        /**
         * MEMO: 外字の選択
         * */
        selectExternalCharacter(input_search_ex_text, external_characters_for_gaiji_relation_key) {

            // MEMO: 検索結果から、表示画面のフォントに一致する外字データを取得します
            const external_character = external_characters_for_gaiji_relation_key ? external_characters_for_gaiji_relation_key
                .find((external_character) => external_character.font_id === Enum.FONT.GOTHIC.id) : null;

            this.modalNotScroll = false;
            this.isExternalAndSearchPostModal = false; //外字検索用のモーダルオーバーレイを閉じる(is-activeクラスを削除)

            setTimeout(() => {
                CommonModule.designLayer(this.windowWidth, document.querySelector('#designContent'));
                this.isShowExternalCharacterModal = false;  // 外字検索用モーダルウィンドウを閉じる
            }, 300);

            if (!external_character) {
                this.ex_target_input_name = '';
                return;
            }


            let name_type = Enum.NameType.SEI.val;  // 姓

            // それぞれのテキストボックスに検索に使用した値を入れる
            switch (this.ex_target_input_name) {
                case 'sei':
                    if (this.sender_item.sei) {
                        // テキストボックスのカーソルのあった位置に外字を挿入する
                        this.sender_item.sei = this.sender_item.sei.substr(0, this.ex_cursor_point)
                            + input_search_ex_text
                            + this.sender_item.sei.substr(this.ex_cursor_point);
                    } else {
                        this.sender_item.sei = input_search_ex_text;
                    }
                    break;
                case 'mei':
                    name_type = Enum.NameType.MEI.val;  // 名

                    if (this.sender_item.mei) {
                        // テキストボックスのカーソルのあった位置に外字を挿入する
                        this.sender_item.mei = this.sender_item.mei.substr(0, this.ex_cursor_point)
                            + input_search_ex_text
                            + this.sender_item.mei.substr(this.ex_cursor_point);
                    } else {
                        this.sender_item.mei = input_search_ex_text;
                    }
                    break;
                case 'old_name_or_age':
                    name_type = Enum.NameType.OLD_NAME.val;  // 名
                    if (this.sender_item.old_name_or_age) {
                        // テキストボックスのカーソルのあった位置に外字を挿入する
                        this.sender_item.old_name_or_age = this.sender_item.old_name_or_age.substr(0, this.ex_cursor_point)
                            + input_search_ex_text
                            + this.sender_item.old_name_or_age.substr(this.ex_cursor_point);
                    } else {
                        this.sender_item.old_name_or_age = input_search_ex_text;
                    }
                    break;
            }

            const start_index = this.getStartIndex(name_type);

            let gaiji = external_character.gaiji_type === 0 ? external_character.gaiji_kanji
                : external_character.gaiji_character_code;

            let data = newChar();
            data.is_use_gaiji = true;
            data.font_id = external_character.font_id;
            data.type = Enum.DataType.FROM.val;
            data.external_character_id = external_character.id;
            data.position = this.ex_cursor_point;
            data.name_type = name_type;
            data.input_text = input_search_ex_text;
            data.gaiji_type = external_character.gaiji_type;
            data.gaiji = gaiji;

            data.gaiji = gaiji;
            data.gaiji_relation_key = external_character.gaiji_relation_key;

            // MEMO: デザイン編集エリアの差出人テキストエリアに出す用の文字
            const displayData = _.cloneDeep(data)

            // MEMO: 検索結果から、表示画面のフォントに一致する外字データを取得します
            const display_external_character = external_characters_for_gaiji_relation_key ? external_characters_for_gaiji_relation_key
                .find((external_character) => external_character.font_id === this.displayFontId) : null;

            let gaiji_display = display_external_character.gaiji_type === Enum.ExternalCharType.KANJI.val
                ? display_external_character.gaiji_kanji
                : display_external_character.gaiji_character_code

            displayData.external_character_id = display_external_character.id
            displayData.gaiji = gaiji_display
            displayData.gaiji_relation_key = display_external_character.gaiji_relation_key;

            // dataの指定位置に、選択した外字データを追加する
            this.input_values.splice(start_index + this.ex_cursor_point, 0, data);
            this.sender_item.display_sender_name_parts.splice(start_index + this.ex_cursor_point, 0, displayData);

            // MEMO: 文字追加したので内部位置を再設定
            this.refreshPosition(name_type)

            // 外字データが追加されたので、data内の文字位置を再設定
            this.refreshInputValues(this.sender_item.sei, Enum.NameType.SEI.val)
            this.refreshInputValues(this.sender_item.mei, Enum.NameType.MEI.val)
            this.refreshInputValues(this.sender_item.old_name_or_age, Enum.NameType.OLD_NAME.val)
        },

        /**
         * 外字検索用のオーバーレイを開く
         */
        externalOpenModal() {
            // CommonModule.designLayer(this.windowWidth, document.querySelector('#designContent'));
            setTimeout(() => {
                this.isExternalAndSearchPostModal = true;
            }, 100);
        },
        /**
         * MEMO: 指定されたposition(テキストボックス内のカーソル位置)に一致しない要素を返します
         * */
        notEvenInputValuesForPosition(name_type, position) {
            return [
                this.input_values.filter(function (item) {
                    return item.name_type !== name_type || item.position !== position;
                }),
                this.sender_item.display_sender_name_parts.filter(function (item) {
                    return item.name_type !== name_type || item.position !== position;
                })
            ]
        },
        /**
         * MEMO: 指定されたname_type、positionで検索します
         * */
        findInputValues(name_type = null, position = null) {
            return this.input_values.filter(function (item) {
                if (name_type !== null && position !== null) {
                    return item.name_type === name_type && item.position === position;
                }

                if (name_type !== null) {
                    return item.name_type === name_type;
                }

                if (position !== null) {
                    return item.position === position;
                }
            });
        },
        /**
         * MEMO: 指定されたname_type、positionで検索します
         * findInputValuesのdisplay_sender_name_parts版です
         * */
        findDisplayValues(name_type = null, position = null) {
            return this.sender_item.display_sender_name_parts.filter(function (item) {
                if (name_type !== null && position !== null) {
                    return item.name_type === name_type && item.position === position;
                }

                if (name_type !== null) {
                    return item.name_type === name_type;
                }

                if (position !== null) {
                    return item.position === position;
                }
            });
        },
        /**
         * MEMO: Filter関数。指定されたNameTypeに一致したデータを返します
         * */
        evenInputValuesForNameType(name_type) {
            return this.input_values.filter(function (item) {
                return item.name_type === name_type && (item.font_id === Enum.FONT.GOTHIC.id || item.font_id === null);
            });
        },

        /**
         * MEMO: 一般用/法人用のタブ切替
         * */
        async tabSelect(config_guest_type) {
            this.isActiveTab = config_guest_type === Enum.GuestType.PEOPLE.val ?
                Enum.GuestType.PEOPLE.val : Enum.GuestType.CORPORATION.val;

            this.isShowRegisterForm = true;

            // MEMO: タブを変更した場合
            if (this.sender_item.guest_type !== config_guest_type) {
                if (this.availableResources.availableAddressOrderingRule.length === 0) {
                    // MEMO: 配置順は配置順ボタンが押下されるまで取得していないため、未取得の場合 取得する
                    await AvailableResources.loadAddressOrderingRule();
                }

                // MEMO: 選択されたタブによって個人用/法人用のデザインに紐づくデフォルトの配置順のIDを取得
                const default_sender_display_rule_id = config_guest_type === Enum.GuestType.PEOPLE.val ?
                    this.makingItem.designData.default_sender_display_rule :
                    this.makingItem.designData.default_sender_display_rule_for_corporation

                // MEMO: デフォルトの配置順を代入して、編集エリアに反映させる
                // TODO: 一致する配置順がない場合はどうするか？
                this.makingItem.selectedSenderOrderingRule = this.availableResources.availableAddressOrderingRule
                    .find((item) => item.id === default_sender_display_rule_id &&
                        item.guest_type === config_guest_type);
            }

            this.sender_item.guest_type = config_guest_type;

            // タブによって法人用の項目の表示・非表示を切り替える
            this.isShowCorporationForm = config_guest_type !== Enum.GuestType.PEOPLE.val;
        },
        /**
         * MEMO: 連名の「+」ボタン
         * */
        addJointNameForm() {
            // MEMO: envで指定している最大件数を超えた場合はアラート
            // ※ 追加後の件数で判定するため、+1してから判定する
            if (process.env.MIX_MAX_JOINT_NAME_FORM_NUMBER < this.sender_item.joint_names.length + 1) {
                alert(`連名は${process.env.MIX_MAX_JOINT_NAME_FORM_NUMBER}件以上追加できません。`);
                return;
            }
            const data = initJointName(this.sender_item.customer_id);
            this.sender_item.joint_names.push(data);
        },
        deleteSenderJoinName(index) {
            this.sender_item.joint_names.splice(index, 1);
        },
        /**
         * MEMO: キャンセルボタン
         * */
        cancel() {
            this.tabSelect(Enum.GuestType.PEOPLE.val);   // タブを一般に変更

            /**
             * 8/8 鳥居 追加 PC、SPで閉じる速度を調整
             */
            this.$refs.slideActive.classList.remove('is-active');
            if (window.matchMedia('(min-width: 640px)').matches) {
                this.$emit('cancel');   //呼び出し元の@cancelを実行
            } else {
                setTimeout(() => {
                    this.$emit('cancel');   //呼び出し元の@cancelを実行
                }, 300);
            }

        },
        /**
         * MEMO: 都道府県一覧の取得
         * */
        getPrefList() {
            // MEMO: 一度配列をリセット
            this.pref.splice(0);

            axios
                .get(config.API_BASE_URL + '/master/pref')
                .then((response) => {
                    this.pref.push({
                        id: '', name: '未選択', sort_no: 0
                    });

                    // MEMO: 取得した結果を配列に追加する
                    for (let i = 0; i < response.data.length; i++) {
                        this.pref.push(response.data[i]);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    console.log('failure');
                });
        },
        /**
         * MEMO: 郵便番号検索
         * */
        getPostNo() {
            this.get_post_no_message = null;
            if (!this.sender_item.post_no) {
                return;
            }

            const params = {
                post_no: this.sender_item.post_no,
            };

            axios
                .post(config.API_BASE_URL + '/post_no/search', params)
                .then((response) => {

                    if (response.data.length === 0) {
                        this.get_post_no_message = '住所が見つかりませんでした。再度検索してください。';
                        return;
                    }

                    // 検索結果が一つの市区町村の場合
                    if (response.data.length === 1) {
                        this.selected_pref = response.data[0].pref_name;
                        this.setPrefName();
                        this.sender_item.post_no = response.data[0].post_no;
                        this.sender_item.address = response.data[0].locality_name + response.data[0].street_address_name;
                        return;
                    }

                    // MEMO: 一度配列をリセット
                    this.post_no_list.splice(0);

                    // MEMO: 取得した結果を配列に追加する
                    for (let i = 0; i < response.data.length; i++) {
                        this.post_no_list.push(response.data[i]);
                    }

                    // 複数選択モーダルを表示
                    setTimeout(() => {
                        this.isExternalAndSearchPostModal = true;
                        CommonModule.designLayer(this.windowWidth, document.querySelector('#designContent'));
                    }, 100);

                    this.isShowSelectPostNoModal = true;
                })
                .catch(function (error) {
                    console.log(error);
                    console.log('failure');
                });
        },
        /**
         * MEMO: 重複した郵便番号の選択処理
         * */
        selectDuplicatePostNo(selected_post_no_data) {
            // テキストボックスにデータを入れ替える
            this.selected_pref = selected_post_no_data.pref_name;
            this.setPrefName();
            this.sender_item.post_no = selected_post_no_data.post_no;
            this.sender_item.address = selected_post_no_data.locality_name + selected_post_no_data.street_address_name;

            // 複数選択モーダルを非表示
            this.isExternalAndSearchPostModal = false;

            setTimeout(() => {
                this.isShowSelectPostNoModal = false;
                CommonModule.designLayer(this.windowWidth, document.querySelector('#designContent'));
            }, 300);

            this.modalNotScroll = false;
        },
        /**
         * MEMO: 宛先一覧の取得
         * */
        async getAddressList() {
            // MEMO: 個人用/法人用を検索条件に指定する
            const params = {
                customer_id: this.customer.id,
                sender_id: this.sender_item.id,
                guest_type: null,
            };
            return await axios
                .post(config.API_BASE_URL + '/address_list/search', params)
                .then((response) => {
                    let address_items = [];
                    // MEMO: 取得した結果を配列に追加する
                    for (let i = 0; i < response.data.length; i++) {
                        address_items.push(response.data[i]);
                    }
                    return address_items;
                })
                .catch(function (error) {
                    console.log(error)
                    console.log('failureA');
                });
        },
        /**
         * 8/19 鳥居：モーダルが立ち上がった時に、スクロールをさせない
         */
        changeOverflow() {
            if (this.windowWidth > 640) {
                this.modalNotScroll = true;
            }
        },

        /**
         * 住所検索のモーダル
         * 編集パネル内のスクロール量を取得してoverflowを付与するために使用
         */
        getScroll_Y() {
            this.scrollY = this.$refs.slideActive.scrollTop;
        },

        setPrefName() {
            this.sender_item.pref_name = this.selected_pref;
        },

        honourGuide() {
            this.showGuide = !this.showGuide;
        }
    },
    async created() {
        // モーダル開く
        this.open();
        this.windowWidth = window.innerWidth;
    },
}
</script>

<style scoped>

</style>