<template>
    <!--Header-->
    <Header v-if="routePathOnlyForSenderList"></Header>
    <section
        class="overflow-y-scroll h-full u-scrollbar__none"
        :class="[
            routePathOnlyForSenderList ? 'l-wrapper bg-gray02 min-h-screen' : 'bg-white',
        ]"
    >
        <div v-if="routePathOnlyForEdit">
            <ul class="grid grid-cols-3 gap-3 sm:flex sm:justify-between px-4">
                <li class="pt-4 font-bold text-blue02 text-left cursor-pointer">
                    <button type="button" @click="purchaseOnlyForCloseButton()">閉じる</button>
                </li>
                <li class="pt-2 text-center sm:hidden">
                    <img src="/images/icon/edit/rectangle.svg" class="mx-auto">
                </li>
            </ul>
        </div>

        <div class="l-wrapper__container">
            <div class="pt-12 sm:pt-16 pb-6 sm:pb-12 text-center">
                <p class="text-xl sm:text-3xl font-bold">差出人の登録・一覧</p>
            </div>
            <button
                class="block w-5/6 sm:w-full max-w-xs h-14 ml-auto mr-auto py-2 rounded-full sm:text-lg font-bold text-center text-white bg-orange02"
                @click="showSenderRegisterPanel(true)">新規登録
            </button>
            
            <div class="pt-8">
                <div class="px-4">
                    <div class="border-red border font-bold text-sm text-red py-4 px-4 sm:max-w-sm sm:mx-auto bg-white">
                        <p>登録した差出人をご使用になる場合<br>宛名にて差出人のご指定をお願いいたします</p>
                    </div>
                </div>
            </div>

        </div>
        <section>
            <div class="is-active pt-8 pb-24">
                <div class="sm:max-w-2xl sm:ml-auto sm:mr-auto">
                    <div class="flex pl-4 bg-white border-t border-x last:border-b border-gray03 overflow-hidden"
                         v-for="(sender_item, index) in sender_list" :key="sender_item.id">
                        <button class="c-slide__operate py-2 pr-6 c-slide__operate__items"
                                :class="{ 'is-active' : isShowSlideMenu && slide_menu_index === index}"
                                @click="showSlideEditMenu(index)">
                            <div>
                                <div class="text-lg font-bold gothic" ref="senderNameParentsElement">
                                    <ListNamePreview :preview-data="this.nameForGothic(sender_item)"></ListNamePreview>
                                </div>
                                <div class="c-slide__operate__nowrap mt-2">
                                    <span class="text-sm" ref="senderInfo">
                                        〒{{ sender_item.post_no }}{{ sender_item.pref_name }}{{
                                            sender_item.address
                                        }}{{ sender_item.building_name }}
                                        TEL:{{ sender_item.tel }}
                                    </span>
                                    <!-- MEMO: 以下法人のみ表示-->
                                    <span v-if="sender_item.guest_type === refEnum.GuestType.CORPORATION.val">
                                        <!-- {{ sender_item.corporate_name1 }}
                                        {{ sender_item.corporate_name2 }}
                                        {{ sender_item.department_name1 }}
                                        {{ sender_item.department_name2 }}
                                        {{ sender_item.position_name }} -->
                                    </span>
                                </div>
                                <p class="u-triangle__arrow"></p>
                            </div>
                        </button>
                        <div class="u-sender__edit__items"
                             :class="{ 'is-active' : isShowSlideMenu && slide_menu_index === index }">
                            <div class="u-sender__edit__items--open__col3"
                                 :class="{ 'is-active' : isShowSlideMenu && slide_menu_index === index }">
                                <button class="bg-beige c-button__open__slide"
                                        @click="showPreview(sender_item); isModal = !isModal">
                                    <div>
                                        <img src="/images/icon/sender/sender_eye_icon.svg" alt="プレビュー"
                                             class="ml-auto mr-auto">
                                        <p class="text-xs font-bold pt-2">プレビュー</p>
                                    </div>
                                </button>
                                <button class="bg-gray02 c-button__open__slide"
                                        @click="showSenderRegisterPanel(true, sender_item)">
                                    <div>
                                        <img src="/images/icon/sender/sender_edit_icon.svg" alt="編集"
                                             class="ml-auto mr-auto">
                                        <p class="text-xs font-bold pt-2">編集</p>
                                    </div>
                                </button>
                                <button class="bg-red c-button__open__slide">
                                    <div @click="showDeleteConfBox(index, sender_item.id)">
                                        <img src="/images/icon/sender/sender_delete_icon.svg" alt="削除"
                                             class="ml-auto mr-auto">
                                        <p class="text-xs font-bold text-white pt-2">削除</p>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--削除確認用ボックス-->
                    <div v-if="deleteStep">
                        <div
                            class="fixed bg-white w-11/12 max-w-xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pt-8 pb-12 z-4">
                            <div class="text-center font-bold">
                                <p>選択された差出人を<br>削除してもよろしいですか？</p>
                            </div>
                            <div class="px-8">
                                <div class="grid grid-cols-2 gap-x-2 pt-8 font-bold">
                                    <button @click="showDeleteConfBox()"
                                            class="border border-gray03 py-2 px-4 rounded-3xl">
                                        <span>キャンセル</span>
                                    </button>
                                    <button @click="deleteSender(this.selectSlideIndex, this.selectSenderId)"
                                            class="flex justify-center items-center gap-x-2 bg-red text-white py-2 px-4 rounded-3xl">
                                        <img src="/images/icon/sender/sender_delete_icon.svg" alt="削除">
                                        <span class="block">削除</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div v-if="isShowMessage" class="fixed top-0 left-0 z-4 w-full h-full flex justify-center">
            <div class="v-deleteModalBox">
                <p>{{ message }}</p>
                <p v-for="error in errors" :key="error.id">{{ error }}</p>
                <div class="text-blue02 pt-10">
                    <button @click="closeDeleteModal()">閉じる</button>
                </div>
            </div>
        </div>
        <div class="c-modal__overlay"
             :class="[
                (routePathOnlyForEdit) ? '' : {'is-active': isCreateAndEditSlide},
                {'is-modal-active': isModal}
            ]"
        >
        </div>

        <!-- MEMO: 差出人登録パネル-->
        <SenderRegisterPanel
            :sender-item="sender_item"
            v-if="isShowSenderRegisterPanel"
            @cancel="closeSenderRegisterPanel"
            :class="{'is-active': isCreateAndEditSlide}"
        />

        <!-- MEMO: プレビューモーダル -->
        <div v-if="isShowPreviewModal" class="sm:max-w-2xl sm:mx-auto c-modal__preview">
            <div class="w-10/12 sm:w-80 mx-auto bg-white py-8 rounded-20">
                <img class="px-6 z-10" :src="sender_item_preview_image" alt="宛名プレビュー">
                <div class="block text-center pt-6">
                    <a href="#" class="font-bold text-blue02" @click="closePreview()">閉じる</a>
                </div>
            </div>
        </div>
    </section>
    <LoadingScreen :is-loading-screen="isLoadingScreen"></LoadingScreen>
</template>

<script>
import Header from "../components/Header";
import SlideUpDown from 'vue3-slide-up-down'
import config, * as Enum from "../const/const";
import SenderRegisterPanel from "../components/SenderList/SenderRegisterPanel";
import SenderPreview from "../components/SenderList/Preview";
import AuthFunctions from "../functions/auth";
import CommonModule from "../functions/common_module";
import LoadingScreen from "../components/LoadingScreen.vue";
import ListNamePreview from "../components/ListNamePreview.vue";

const {
    getCustomer,
} = AuthFunctions();

const initialSenderItem = (customer_id) => {
    return {
        id: '',
        guest_type: Enum.GuestType.PEOPLE.val,
        customer_id: customer_id,
        corporate_name1: '',     // 法人名(法人の時のみ使用)
        corporate_name2: '',     // 法人名(法人の時のみ使用)
        department_name1: '',    // 部署名(法人の時のみ使用)
        department_name2: '',    // 部署名(法人の時のみ使用)
        position_name: '',      // 役職名(法人の時のみ使用)
        sei: '',
        mei: '',
        old_name_or_age: '',
        sender_name_parts: [],
        sender_joint_name_parts: [],
        // DELETE: 後で消す
        use_external_characters: [],
        // DELETE: 後で消す
        joint_names: [],  // 連名
        post_no: '',    // 郵便番号
        pref_name: '',  // 都道府県名
        address: '',  // 市区町村名
        building_name: '',  // 建物・部屋番号
        tel: '', // 電話番号
    }
};

export default {
    name: "SenderList",
    components: {
        ListNamePreview,
        SenderPreview,
        SenderRegisterPanel,
        SlideUpDown,
        Header,
        LoadingScreen,
    },
    data() {
        return {
            customer: null,
            sender_list: [],
            sender_item: null,
            sender_item_preview_image: '',
            isShowSenderRegisterPanel: false,        // 差出人登録コンポーネントの表示/非表示制御
            isShowSlideMenu: false,
            isShowPreviewModal: false,
            slide_menu_index: null,
            display: '',
            isCreateAndEditSlide: false,
            isModal: false,
            maxFigure: 10,
            maxAddress: 20,
            isShowMessage: false,
            message: null,
            errors: {},
            routePathOnlyForEdit: this.$route.path.match(/edit/), //用途：class名をバインドする時の条件判定で使用
            routePathOnlyForSenderList: this.$route.path.match(/sender_list/), //用途：class名をバインドする時の条件判定で使用
            deleteStep: false,
            selectSenderId: '',
            selectSlideIndex: '',
            isLoadingScreen: false,
        }
    },

    emits: [
        'close',
    ],

    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述
    },

    async mounted() {
        this.customer = await getCustomer();
        // MEMO: 会員情報の取得ができない場合、ECのログイン画面へ遷移
        if (!this.customer || !Object.keys(this.customer).length) {
            // MEMO: ここでECのログアウトへリダイレクトURLをつけて飛ばす
            window.location.href = process.env.MIX_EC_LOGOUT_URL
                + '?redirect_url=' + process.env.MIX_APP_URL
                + this.$route.path;
            return;
        }

        await this.getSenderList();
    },

    methods: {
        /**
         * 差出人情報の削除を選択した時に表示する確認ボックス
         * @param {Number} index [スライドの番号]
         * @param {Number} sender_id [選択された差出人番号]
         */
        showDeleteConfBox(index, sender_id) {
            this.selectSlideIndex = index;
            this.selectSenderId = sender_id;
            this.isModal = !this.isModal;
            this.deleteStep = !this.deleteStep;
        },
        /**
         * MEMO: 差出人の削除
         * */
        async deleteSender(index, sender_id) {
            this.isShowMessage = false;
            this.message = null;
            this.errors = {};
            // 削除対象のスライドメニューを閉じる
            this.showSlideEditMenu(index);
            this.deleteStep = false;

            // 論理削除実行
            await axios.delete(config.API_BASE_URL + '/sender_list/destroy/' + sender_id)
                .then((response) => {
                    this.isShowMessage = true;
                    this.message = '差出人の削除が完了しました。';
                    // 宛先リストの再取得
                    this.getSenderList();
                })
                .catch((error) => {
                    this.isShowMessage = true;
                    this.message = '差出人の削除に失敗しました。';
                    this.errors = error.response.data.error.messages;
                });
        },
        /**
         * MEMO: 差出人一覧の取得
         * */
        async getSenderList() {// 会員情報を取得
            // MEMO: 個人用/法人用を検索条件に指定する
            // TODO: ここに、会員IDを追加する
            const params = {
                customer_id: this.customer.id,
                guest_type: null
            };

            this.isLoadingScreen = true;
            // MEMO: 一度配列をリセット
            this.sender_list.splice(0);

            await axios
                .post(config.API_BASE_URL + '/sender_list/search', params)
                .then((response) => {
                    // MEMO: 取得した結果を配列に追加する
                    for (let i = 0; i < response.data.length; i++) {
                        this.sender_list.push(response.data[i])
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    console.log('failure');
                })
                .finally(() => {
                    this.isLoadingScreen = false;
                });
        },
        /**
         * MEMO: 差出人の名前をゴシック体でフィルタリングする
         * 差出人の一覧では、ゴシック体しか使用しないため取り除きます
         * */
        nameForGothic(sender_item) {
            return sender_item.sender_name_parts.filter((item) => {
                return item.font_id === null || item.font_id === Enum.FONT.GOTHIC.id;
            }, this);
        },
        /**
         * 登録パネルのクローズ
         * */
        closeSenderRegisterPanel(data = null, mode = 'cancel') {
            // MEMO: 編集の場合（新規の場合はここは呼ばれない）
            if (data && data.id) {
                if (mode === 'save') {
                    // MEMO: 編集→保存の場合はデータ再読み込み
                    this.getSenderList();
                }
            }

            this.closeSlide();

            CommonModule.threePointLeader(this.$refs.senderNameParentsElement, Number(this.maxFigure));
            CommonModule.addressNameLimit(this.$refs.senderInfo, Number(this.maxAddress));
            this.isShowSenderRegisterPanel = false;
        },
        /**
         * MEMO: 差出人の登録パネルの表示/非表示
         * */
        showSenderRegisterPanel(display = true, data) {
            setTimeout(() => {
                this.isCreateAndEditSlide = true;
            }, 100);

            this.isShowSenderRegisterPanel = display;  // 登録フォームの表示/非表示

            // 一度Propsを初期化
            this.sender_item = initialSenderItem(this.customer.id);

            this.sender_item.pref_name = '未選択';
            // 編集の場合
            if (data) {
                this.sender_item = data;   // SenderRegisterPanelのPropsに渡す会員データをセット
            }
        },
        /**
         * MEMO: 各宛名の編集メニューのスライド表示
         * */
        showSlideEditMenu(index) {
            this.slide_menu_index = index;
            // 現在の行とクリックされた行が同じ場合、行の開閉をセット
            if (this.slide_menu_index === index) {
                this.isShowSlideMenu = !this.isShowSlideMenu;
            }
        },
        /**
         * MEMO: プレビューモーダルの表示
         * */
        async showPreview(data) {
            await axios
                .get(config.API_BASE_URL + '/address_side_image/saved_sender/' + data.id.toString())
                .then((response) => {
                    this.sender_item = data
                    this.sender_item_preview_image = response.data
                    this.isShowPreviewModal = true
                    CommonModule.modalNotScroll(true)
                })
        },
        closePreview() {
            this.isShowPreviewModal = false
            CommonModule.modalNotScroll(false)
            this.closeModal()
        },

        /**
         * 8/8 鳥居
         * 新規登録、編集をクリックした後に発火する登録画面を閉じる処理を追加
         */
        closeSlide() {
            setTimeout(() => {
                this.isCreateAndEditSlide = false;
            }, 100);
        },

        closeModal() {
            this.isModal = false;
        },

        closeDeleteModal() {
            this.isShowMessage = false;
            this.isModal = false;
        },

        //購入フロー画面での閉じるボタン
        purchaseOnlyForCloseButton() {
            this.$emit('close');
        }
    },

    updated() {
        CommonModule.threePointLeader(this.$refs.senderNameParentsElement, Number(this.maxFigure));
        CommonModule.addressNameLimit(this.$refs.senderInfo, Number(this.maxAddress));
    },
}
</script>

<style scoped>

/*論理削除時のモーダル*/
.v-deleteModalBox {
    width: 90%;
    max-width: 42rem;
    background-color: #FFF;
    padding: 2rem 0;
    font-weight: bold;
    text-align: center;
    position: fixed;
    top: calc(50% - 90px);
    z-index: 3;
    -webkit-animation: slideDown .3s ease .3s normal backwards;
    animation: slideDown .3s ease .3s normal backwards;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>