<template>
    <tspan :x="x" :y="y">
        <tspan v-for="(t, index) in contents" :key="index"
               :font-family="t.font"
               :font-size="t.size"
               :class="textLine.class"
               :style="textFragmentStyle"
               glyph-orientation-vertical="90">
            {{ replaceFullWidthText(t.text) }}
        </tspan>
    </tspan>
</template>
<!-- memo: glyph-orientation-vertical は 非推奨のプロパティだが、safariの縦書きの時に文字が転倒するのを防いでくれる -->

<script>
import ReplaceTextFunctions from "../../../functions/replace_text";

const {replaceText} = ReplaceTextFunctions();

export default {
    name: "TextLine",
    props: {
        x: Number,
        y: Number,
        contents: Object,
        is_vertical: Boolean,
    },
    computed: {
        textLine() {
            return []
        },
        textFragmentStyle() {
            return {
                'letter-spacing': '0px',
                'white-space': 'pre',
            }
        },
    },
    methods: {
        replaceFullWidthText(text) {
            return this.is_vertical ? replaceText(text) : text
        },
    },
}
</script>

<style scoped>

</style>