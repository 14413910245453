<template>
    <div id="designContent"
         class="absolute z-1"
         :height=previewHeight
         :width=previewWidth>
        <div id="cardEditArea"
             style="transform: scale(1);"
             :height=previewHeight
             :width=previewWidth>
            <svg id="PostCardPicture"

                 v-bind:class="[isActiveMaterial('A') ? 'writeToPicture p-edit__svgDesign__item cursor-pointer shadow-md' : 'writeToPicture cursor-pointer']"
                 xmlns="http://www.w3.org/2000/svg"
                 :viewBox="svgViewBox"
                 :height=previewHeight
                 :width=previewWidth>
                <g transform="translate(0,0) rotate(0) scale(1.0, 1.0)" ref="designGrop">
                    <image id='background_img' :href="backgroundImageUrl" :style="editComponentBackground"
                           :height=previewHeight :width=previewWidth preserveAspectRatio="xMidYMid slice">
                    </image>
                </g>
                <UserObjectDeployLayer
                    v-if="loaded"
                    @completeLoadDeploydImages="completeLoadDeploydImages"
                    @showAlertMessage="showAlertMessage"/>
            </svg>

            <!--
                MEMO: 部材選択による外枠
            -->
            <svg xmlns="http://www.w3.org/2000/svg"
                 :viewBox="svgViewBox"
                 :height=previewHeight
                 :width=previewWidth
                 class="clipping-line absolute top-0">
                <g v-if="isActiveMaterial('A')">
                    <g class="isLandscapeDesign" v-if="isLandscapeDesign">
                        <line x1="14.17" y1="15.59" x2="413.86" y2="15.59"
                              style="fill: none;stroke: #098bd3;stroke-miterlimit: 10;stroke-dasharray: 3,3"/>
                        <line x1="14.17" y1="279.21" x2="14.17" y2="15.59"
                              style="fill: none;stroke: #098bd3;stroke-miterlimit: 10;stroke-dasharray: 3,3"/>
                        <line x1="411.02" y1="279.21" x2="17.01" y2="279.21"
                              style="fill: none;stroke: #098bd3;stroke-miterlimit: 10;stroke-dasharray: 3,3"/>
                        <line x1="413.86" y1="279.21" x2="413.86" y2="15.59"
                              style="fill: none;stroke: #098bd3;stroke-miterlimit: 10;stroke-dasharray: 3,3"/>
                    </g>
                    <g class="isVerticalDesign" v-else>
                        <line x1="15.59" y1="14.17" x2="279.21" y2="14.17"
                              style="fill: none;stroke: #098bd3;stroke-miterlimit: 10;stroke-dasharray: 3,3"/>
                        <line x1="15.59" y1="413.86" x2="279.21" y2="413.86"
                              style="fill: none;stroke: #098bd3;stroke-miterlimit: 10;stroke-dasharray: 3,3"/>
                        <line x1="15.59" y1="411.02" x2="15.59" y2="17.01"
                              style="fill: none;stroke: #098bd3;stroke-miterlimit: 10;stroke-dasharray: 3,3"/>
                        <line x1="279.21" y1="413.86" x2="279.21" y2="14.17"
                              style="fill: none;stroke: #098bd3;stroke-miterlimit: 10;stroke-dasharray: 3,3"/>
                    </g>
                </g>
                <!-- 台紙[B：横] -->
                <g v-if="isActiveMaterial('B')">
                    <g class="isLandscapeDesign" v-if="isLandscapeDesign">
                        <rect x="-1.5" y="0" width="431" height="294.8" stroke="#FFF" fill="transparent" stroke-width="28.4"/>
                        <rect v-if="isRectBorderBoxChangeStatusEditUserImage"
                            x="0" y="0"
                            width="428.15999999999997"
                            height="294.8"
                            stroke="#eee"
                            fill="transparent"
                            stroke-width="8"/>
                        <g v-if="isRectBorderBoxChangeStatusEditUserImage">
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m2.8-2.8H1.45V291.93H426.58V2.87Z" style="fill: #eee"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m2.6-2.6H1.65V291.73H426.38V3.07Z" style="fill: #ececec"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m2.4-2.4H1.85V291.53H426.18V3.27Z" style="fill: #e9e9e9"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m2.2-2.2H2.05V291.33H426V3.47Z" style="fill: #e6e6e6"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m2-2H2.25V291.13H425.78V3.67Z" style="fill: #e3e3e3"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m1.8-1.8H2.45V290.93H425.58V3.87Z" style="fill: #e0e0e0"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m1.6-1.6H2.65V290.73H425.38V4.07Z" style="fill: #ddd"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m1.4-1.4H2.85V290.53H425.18V4.27Z" style="fill: #dadada"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m1.2-1.2H3.05V290.33H425V4.47Z" style="fill: #d7d7d7"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m1-1H3.25V290.13H424.78V4.67Z" style="fill: #d4d4d4"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m.8-.8H3.45V289.93H424.58V4.87Z" style="fill: #d1d1d1"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m.6-.6H3.65V289.73H424.38V5.07Z" style="fill: #cecece"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m.4-.4H3.85V289.53H424.18V5.27Z" style="fill: #cbcbcb"/>
                            <path d="M423.78,5.67V289.13H4.25V5.67H423.78m.2-.2H4.05V289.33H424V5.47Z" style="fill: #c8c8c8"/>
                        </g>
                    </g>
                    <!-- 台紙[B：縦] -->
                    <g class="isVerticalDesign" v-else>
                        <rect x="0" y="-1.5" width="294.8" height="431" stroke="#FFF" fill="transparent" stroke-width="28.4"/>
                        <rect v-if="isRectBorderBoxChangeStatusEditUserImage"
                            x="0" y="0"
                            width="294.8"
                            height="428.15999999999997"
                            stroke="#eee"
                            fill="transparent"
                            stroke-width="8"/>
                        <g v-if="isRectBorderBoxChangeStatusEditUserImage">
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m2.8-2.8H2.87V426.58H291.93V1.45Z" style="fill: #eee"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m2.6-2.6H3.07V426.38H291.73V1.65Z" style="fill: #ececec"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m2.4-2.4H3.27V426.18H291.53V1.85Z" style="fill: #e9e9e9"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m2.2-2.2H3.47V426H291.33V2.05Z" style="fill: #e6e6e6"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m2-2H3.67V425.78H291.13V2.25Z" style="fill: #e3e3e3"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m1.8-1.8H3.87V425.58H290.93V2.45Z" style="fill: #e0e0e0"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m1.6-1.6H4.07V425.38H290.73V2.65Z" style="fill: #ddd"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m1.4-1.4H4.27V425.18H290.53V2.85Z" style="fill: #dadada"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m1.2-1.2H4.47V425H290.33V3.05Z" style="fill: #d7d7d7"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m1-1H4.67V424.78H290.13V3.25Z" style="fill: #d4d4d4"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m.8-.8H4.87V424.58H289.93V3.45Z" style="fill: #d1d1d1"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m.6-.6H5.07V424.38H289.73V3.65Z" style="fill: #cecece"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m.4-.4H5.27V424.18H289.53V3.85Z" style="fill: #cbcbcb"/>
                            <path d="M289.13,4.25V423.78H5.67V4.25H289.13m.2-.2H5.47V424H289.33V4.05Z" style="fill: #c8c8c8"/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    </div>
</template>

<style>
.clipping-line {
    pointer-events: none;
}
</style>

<script>
import UserObjectDeployLayer from "./UserObjectDeployLayer";
import {inject} from "vue";
import {PreviewScale} from "../../const/const";

export default {
    components: {
        UserObjectDeployLayer,
    },
    setup() {
        const makingItem = inject('makingItemInfo');
        const editStatus = inject('editStatus');

        return {
            makingItem,
            editStatus,
        };
    },
    data() {
        return {
            editComponentBackground: {
                top: '0',
                left: '0',
                pointerEvents: 'none',
            },
            routePathOnlyForEdit: this.$route.path.match(/edit/),
        }
    },
    computed: {
        /**
         * Edit.vueで読み込んでいるMakingItemが読み込み終わっているか判定
         * UserObjectDeployLayerは読み込み完了後に表示させる
         * */
        loaded() {
            return this.makingItem.loaded
        },
        previewHeight() {
            return this.makingItem.designData !== undefined && this.makingItem.designData.preview_image_width && this.makingItem.designData.preview_image_height
                ? this.makingItem.designData.preview_image_height
                : PreviewScale.HEIGHT
        },
        previewWidth() {
            return this.makingItem.designData !== undefined && this.makingItem.designData.preview_image_width && this.makingItem.designData.preview_image_height
                ? this.makingItem.designData.preview_image_width
                : PreviewScale.WIDTH
        },
        svgViewBox() {
            return `0 0 ${this.previewWidth} ${this.previewHeight}`
        },
        backgroundImageUrl() {
            if (this.makingItem.selectedBackground) {
                return this.makingItem.selectedBackground.design_url
            }
            return ''
        },
        materialPatternCode() {
            return this.makingItem.material_pattern_code
        },
        isLandscapeDesign() {
            return this.previewWidth > this.previewHeight
        },

        // 隠しているグレーの背景を切り替え
        // 編集モードの時は非表示[false]
        isRectBorderBoxChangeStatusEditUserImage() {
            return this.editStatus.state === 'edit_user_image' ? false : true;
        }
    },

    updated() {
        /**
         * 鳥居メモ
         * デバイスピクセル：https://www.javadrive.jp/javascript/webpage/index9.html
         */
        let changeScale = {
            "scaleFunction": (routePath) => {
                const locationPath = location.pathname;
                const splitLocationPath = locationPath.split('/');
                const selectionSplitPath = '/' + splitLocationPath[1] + '/';
                const selectionRoutePath = '/' + routePath[0] + '/';

                if (selectionRoutePath === selectionSplitPath) {
                    const designContent = document.querySelector('#designContent'); //デザイン編集画面
                    const svgDesignSelector = document.querySelector('#PostCardPicture'); //SVGデザイン
                    const headerHeight = document.querySelector('#headerObject');
                    const footerHeight = document.querySelector('.js-footerHeight');
                    const mainColumnWidth = document.querySelector('.js-editAreaMainColumn');//メインカラムの横幅を取得
                    const sideMenu = document.querySelector('.l-wrapper__sidemenu');
                    const windowElementHeight = document.documentElement.clientHeight;

                    //画面に対しての空白をもたす
                    let blankSpace_w = 40;
                    let blankSpace_h = 60;

                    let calcGetScaleValueWidth = '';
                    if (window.innerWidth > 640) {
                        calcGetScaleValueWidth = (window.innerWidth - sideMenu.clientWidth - blankSpace_w) / this.previewWidth;
                    } else {
                        calcGetScaleValueWidth = (window.innerWidth - blankSpace_w) / this.previewWidth;
                    }

                    const calcGetScaleValueHeight = (window.innerHeight - headerHeight.clientHeight - footerHeight.clientHeight - blankSpace_h) / designContent.clientHeight;
                    const setScale = (calcGetScaleValueWidth > calcGetScaleValueHeight) ? calcGetScaleValueHeight : calcGetScaleValueWidth

                    //X軸の処理
                    const svgDesignWidth = svgDesignSelector.clientWidth; //SVGデザインの横幅
                    const svgDesignPosition_X = (mainColumnWidth.clientWidth - svgDesignWidth) / 2;

                    //Y軸の処理
                    const svgDesignHeight = svgDesignSelector.clientHeight; //SVGデザインの縦幅
                    const getHeaderHeight = headerHeight.clientHeight; //左カラム編集画面の縦幅
                    const svgDesignPosition_Y = ((windowElementHeight - svgDesignHeight) / 2) - getHeaderHeight;

                    /**
                     * 参考URL：transform(matrix)：https://ginpen.com/2018/11/13/understanding-transform-matrix/
                     * 第1引数：水平方向のX軸
                     * 第2引数：水平方向のY軸
                     * 第3引数：垂直方向のX軸
                     * 第4引数：垂直方向のY軸
                     * 第5引数：移動範囲のX軸
                     * 第6引数：移動範囲のY軸
                     */

                    let setTransformMatrix = '';
                    setTransformMatrix = `matrix(${setScale}, 0, 0, ${setScale}, ${svgDesignPosition_X}, ${svgDesignPosition_Y})`;
                    designContent.style.transform = setTransformMatrix;
                }
            },
        };

        changeScale.scaleFunction(this.routePathOnlyForEdit);
        if (!navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
            window.addEventListener("resize", () => {
                changeScale.scaleFunction(this.routePathOnlyForEdit);
            });
        }

        // this.$emit('loadDesign', false);
    },

    methods: {
        /**
         * 配置している画像の読み込み完了処理
         * */
        completeLoadDeploydImages() {
            // MEMO: Edit.vueのloadDesignを実行して、ローディングを非表示にする
            this.$emit('loadDesign', false);
        },
        showAlertMessage(messages) {
            this.$emit('showAlertMessage', messages)
        },
        isActiveMaterial(target_material) {
            return target_material === this.materialPatternCode
        },
    },
}
</script>
