<template>
    <div class="z-2 overflow-auto pb-12 p-edit__slide--full">
        <ul class="grid grid-cols-3 gap-3 sm:hidden pl-4 pr-4">
            <li class="mt-4 font-bold text-blue02 text-left" @click="$emit('close')">キャンセル</li>
            <li class="mt-2 text-center"><img src="/images/icon/edit/rectangle.svg" alt=""></li>
            <li class="mt-4 font-bold text-blue02 text-right">決定</li>
        </ul>
        <div class="text-center pt-8 sm:pt-0">
            <span class="text-xl font-bold">差出人登録データ</span>
        </div>
        <div class="flex justify-center pt-6">
            <button
                @click="isSelect('individual')"
                :class="{'is-active': isSenderChange === 'individual'}"
                class="c-button__changeColor py-1.5 px-6 rounded-2xl mr-5"
            >個人用
            </button>
            <span class="c-button__separate"></span>
            <button
                @click="isSelect('corporation')"
                :class="{'is-active': isSenderChange === 'corporation'}"
                class="c-button__changeColor py-1.5 px-6 rounded-2xl ml-5"
            >法人用
            </button>
        </div>
        <div v-if="isSenderChange === 'individual'" class="max-w-2xl mt-6 px-8">
            <div class="flex pl-4 bg-white border-t border-x last:border-b border-gray03 overflow-hidden"
                 v-for="(sender_item, index) in userSenders" :key="index">
                <button @click="setEditingSender(sender_item)">
                    <div class="text-lg font-bold" ref="senderNameParentsElement">
                        <template v-for="data in sender_item.use_external_characters">
                            <span
                                :class="{ 'piary_g text-lg font-bold': data.gaiji_type === refEnum.ExternalCharType.FONT.val }"
                                v-if="data.gaiji_type === refEnum.ExternalCharType.FONT.val">
                                {{ String.fromCodePoint(data.gaiji) }}
                            </span>
                            <span v-else-if="data.gaiji_type === refEnum.ExternalCharType.KANJI.val">
                                {{ data.gaiji }}
                            </span>
                            <span v-else>{{ data.input_text }}</span>
                        </template>
                    </div>
                    <div class="c-slide__operate__nowrap mt-2">
                        <span class="text-sm" ref="senderInfo">
                            〒{{ sender_item.post_no }}{{ sender_item.pref_name }}{{
                                sender_item.address
                            }}{{ sender_item.building_name }}
                            TEL:{{ sender_item.tel }}
                        </span>
                    </div>
                </button>
            </div>
        </div>
        <div v-if="isSenderChange === 'corporation'" class="max-w-2xl mt-6 px-8">
            <div class="flex pl-4 bg-white border-t border-x last:border-b border-gray03 overflow-hidden"
                 v-for="(sender_item, index) in userSenders" :key="index">
                <button @click="setEditingSender(sender_item)">
                    <div class="text-lg font-bold" ref="senderNameParentsElement">
                        <template v-for="data in sender_item.use_external_characters">
                            <span
                                :class="{ 'piary_g text-lg font-bold': data.gaiji_type === refEnum.ExternalCharType.FONT.val }"
                                v-if="data.gaiji_type === refEnum.ExternalCharType.FONT.val">
                                {{ String.fromCodePoint(data.gaiji) }}
                            </span>
                            <span v-else-if="data.gaiji_type === refEnum.ExternalCharType.KANJI.val">
                                {{ data.gaiji }}
                            </span>
                            <span v-else>{{ data.input_text }}</span>
                        </template>
                    </div>
                    <div class="c-slide__operate__nowrap mt-2">
                        <span class="text-sm" ref="senderInfo">
                            〒{{ sender_item.post_no }}{{ sender_item.pref_name }}{{
                                sender_item.address
                            }}{{ sender_item.building_name }}
                            TEL:{{ sender_item.tel }}
                        </span>
                        <!-- MEMO: 以下法人のみ表示-->
                        <span v-if="sender_item.guest_type === refEnum.GuestType.CORPORATION.val">
                            {{ sender_item.corporate_name1 }}
                            {{ sender_item.corporate_name2 }}
                            {{ sender_item.department_name1 }}
                            {{ sender_item.department_name2 }}
                            {{ sender_item.position_name }}
                        </span>
                    </div>
                </button>
            </div>
        </div>
    </div>

</template>

<script>
import {inject, nextTick} from "vue";
import * as Enum from "../../../const/const";

export default {
    name: "StoredSenderData",

    setup() {
        const availableResources = inject('availableResources')
        const makingItem = inject('makingItemInfo')
        return {
            availableResources,
            makingItem,
        }
    },
    data() {
        return {
            isSenderChange: 'individual'
        }
    },
    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述
        userSenders() {
            return this.availableResources.userSenders
        },
    },
    methods: {
        isSelect(type) {
            this.isSenderChange = type;
        },
        async setEditingSender(sender) {
            this.makingItem.setBlankSenderItem()
            await nextTick()
            //this.makingItem.senderItem = sender
            this.makingItem.senderItem.sender_id = sender.id
            this.makingItem.senderItem.id = 'senderText'
            this.makingItem.senderItem.address = sender.address
            this.makingItem.senderItem.building_name = sender.building_name
            this.makingItem.senderItem.corporate_name1 = sender.corporate_name1
            this.makingItem.senderItem.corporate_name2 = sender.corporate_name2
            this.makingItem.senderItem.department_name1 = sender.department_name1
            this.makingItem.senderItem.department_name2 = sender.department_name2
            this.makingItem.senderItem.mei = sender.mei
            this.makingItem.senderItem.sei = sender.sei
            this.makingItem.senderItem.old_name_or_age = sender.old_name_or_age
            this.makingItem.senderItem.position_name = sender.position_name
            this.makingItem.senderItem.post_no = sender.post_no
            this.makingItem.senderItem.pref_name = sender.pref_name
            this.makingItem.senderItem.tel = sender.tel
            this.makingItem.senderItem.joint_names = sender.joint_names
            //await nextTick()
        },
    },
}
</script>

<style scoped>

</style>