<template>
    <button  @click="onClickResetButton();"
        class="z-2 text-white text-sm font-bold sm:py-3 sm:px-2 rounded-md mr-md-2">
            <img class="p-resetEditorBtn__ic" src="/images/icon/edit/ic_circle_remake.svg" alt="作り直す">
    </button>
</template>

<script>
import MakingItemInfo from "./MakingItemInfo.vue";
import AvailableResources from "./AvailableResources.vue";
import {useRoute} from "vue-router";
import {inject, ref} from "vue";

export default {
    name: 'DesignResetButton',
    components: {
    },
    emits: ['showYesNoModal'],
    setup() {
        const route = useRoute();
        const windowWidth = ref(window.innerWidth);

        // MEMO: 編集ページの場合のみinjectする（Edit.vueでしかprovideされないため）
        if (route.name === "editpage") {
            const makingItem = inject('makingItemInfo')
            const editStatus = inject('editStatus')
            // setupからメソッドを返す
            const getWindowWidth = () => {
                return windowWidth.value
            };
            return {
                makingItem,
                editStatus,
                getWindowWidth
            }
        }
    },
    data() {
        return {
            showPreview: false,
            isPreviewActive: false,
            isModalActive: false,
        }
    },
    methods: {
        /**
         * 作り直しボタン
         * */
        async onClickResetButton() {
            // MEMO: 呼び出し元のメソッドを呼び出す
            this.$emit('showYesNoModal', true);
        },
        async resetDesign() {
            // MEMO: PCの場合、差出人編集が開いている場合、入力内容がリセットできないためレイアウトメニュー(select_style)を選択状態にする
            if (this.editStatus.activeSideMenu === 'sender_add' && this.getWindowWidth() > 640) {
                this.editStatus.activeSideMenu =  'select_style';
            }

            // MEMO: 一度配置されている差出人を削除する。
            //  ※ 寄せが入っている場合、寄せ と 中身の変更の2回イベントが発生して、位置がおかしくなるため
            this.makingItem.removeSenderText();

            // リセットしたデータを取得する
            await MakingItemInfo.load(
                this.makingItem.design_code,
                this.makingItem.customer_id,
                this.makingItem.code,
                this.makingItem.nenga_id,
                true);

            // 共通の素材
            AvailableResources.init();
            await AvailableResources.load(this.makingItem.design_code)

            // 文例サンプル
            await AvailableResources.loadGreetingTextSamples(this.makingItem.design_code);

            // MEMO: リセット後に差出人が選択状態になっているのを解除する
            this.editStatus.neutralize();

            // MEMO: 呼び出し元のモーダルを閉じる
            this.$emit('showYesNoModal', false);
        }
    },
}
</script>

<style>
.reset-button {
    background-image: url(/images/icon/edit/ic_circle_remake.svg) !important;
    background-size: 100% 100% !important;
    background-position: center;
    background-repeat: no-repeat;
    height: 64px;
    width: 64px;
}

</style>