<!--
    MEMO: 注文に進む
-->
<template>
    <div class="p-edit__slide--full is-purchase sm:px-4 sm:pt-4 overflow-x-hidden"
         ref="purchaseFlowModalTop"
         :class="{'overflow-y-auto' : isShowAwaitModal === false, 'overflow-y-hidden' : isShowAwaitModal === true,  }">
        <div class="grid grid-cols-3 gap-3 px-4">
            <button class="mt-4 sm:mt-0 font-bold text-blue02 text-left" @click="cancel">閉じる</button>
        </div>

        <div class="block text-center font-bold text-lg pt-6 sm:pt-0">
            <span>注文に進む</span>
        </div>
        <div class="sm:px-0">
            <div class="px-6 sm:px-24 pb-8">
                <div class="pt-8" v-if="validationErrors.length">
                    <div class="p-4 bg-red02 border border-red text-red">
                        <p class="pb-4">【ご注意】</p>
                        <div class="flex items-center" v-for="validation_error in validationErrors">
                            <span class="p-purchase__input__text is-attention py-2">・{{ validation_error }}</span>
                        </div>
                    </div>
                </div>
                <div class="pt-8">
                    <div class="p-4 bg-yellow-50 border border-yellow-500">
                        <p class="pb-4">【ご確認】</p>
                        <div class="flex items-center">
                            <label for="cut_off" class="p-purchase__input is-attention py-2 font-bold">
                                <input id="cut_off" type="checkbox" class="p-purchase__input__init" v-model="cutOff">
                                <span class="p-purchase__input__check"></span>
                                <span class="p-purchase__input__text is-attention" v-if="isMourning || isMovingHouse">テキストの配置を確認しました。</span>
                                <span class="p-purchase__input__text is-attention" v-else>テキストや写真の配置を確認しました。</span>
                            </label>
                        </div>
                        <div class="flex items-center" v-if="!isMourning && !isMovingHouse">
                            <label for="in_frame" class="p-purchase__input py-2 font-bold">
                                <input id="in_frame" type="checkbox" class="p-purchase__input__init" v-model="inFrame">
                                <span class="p-purchase__input__check"></span>
                                <span class="p-purchase__input__text is-attention">写真が枠内におさまっているか確認しました。</span>
                            </label>
                        </div>
                    </div>
                </div>
                <!--MEMO: プレビュー -->
                <div class="flex items-center mt-6 px-2">
                    <img :src="designPreviewImg" class="shadow-md mx-auto" alt="デザインプレビュー">
                </div>
                <div class="flex items-center mt-8 pl-4">
                    <label for="mispronunciation" class="p-purchase__input py-2 font-bold">
                        <input
                                id="mispronunciation"
                                type="checkbox"
                                class="p-purchase__input__init"
                                v-model="acceptance"
                        >
                        <span class="p-purchase__input__check"></span>
                        <span class="p-purchase__input__text">作成した内容を全て確認しました。</span>
                    </label>
                </div>
                <div
                        class="w-full rounded text-white mt-4 p-button__purchase"
                        :class="{ active: checkActive }"
                >
                    <button
                            @click="showModalAndNamePrintPanelButton()"
                            name="purchase_next"
                            class="w-full py-2 sm:py-4 font-bold js-showNameAndPrint"
                            :disabled="!allAcceptance"
                    >このデザインを購入する
                    </button>
                </div>
            </div>
        </div>

        <!-- 購入フロー / (仮)PC時 背景-->
        <div v-if="isShowNamePrintService">
            <div class="c-modal__overlay" :class="{'is-active': isShowAwaitModal}"></div>
            <PurchaseFlowService @purchaseFlowOfNamePrint="purchaseFlowChangeScreen"/>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";
import PurchaseFlowService from "./PurchaseFlowService.vue";
import {MourningProductCodes, HouseMovingProductCodes} from "../../const/const";

export default {
    name: "Purchase",
    components: {
        PurchaseFlowService,
    },
    props: {
        validationErrors: {
            type: Array,
            required: true,
        },
        designPreviewImg: {
            type: String,
            required: true
        }
    },
    emits: ['close'],
    setup() {
        const editStatus = inject('editStatus')
        const makingItemInfo = inject('makingItemInfo');
        const availableResources = inject('availableResources')
        const availableLayouts = availableResources.availableLayouts
        return {
            editStatus,
            makingItemInfo,
            availableLayouts,
            availableResources,
        };
    },
    data() {
        return {
            acceptance: false,
            cutOff: false,
            inFrame: false,
            checkActive: false,
            isShowNamePrintService: false,
            isShowAwaitModal: false,
            windowWidth: '',
            design_preview_img: '',
        }
    },
    created() {
        this.windowWidth = window.innerWidth;
    },
    methods: {
        /**
         * このデザインを購入する
         * */
        showModalAndNamePrintPanelButton() {
            // MEMO: 購入フローのモーダルのスクロール位置をTOPにする
            this.$refs.purchaseFlowModalTop.scrollTop = 0;
            this.isShowNamePrintService = true;
            setTimeout(() => {
                // MEMO: 宛名印刷サービスパネルをONにした場合、購入フローのスクロールを無効化する
                this.isShowAwaitModal = true;
            });

            if (this.windowWidth > 640) {
                document.querySelector('#designContent').classList.remove('z-1');
                document.querySelector('.js-headerOverlap').classList.remove('z-1');
            }
        },

        purchaseFlowChangeScreen(nextScreenOrReturn) {
            setTimeout(() => {
                this.isShowNamePrintService = nextScreenOrReturn;
            }, 100);

            this.isShowAwaitModal = nextScreenOrReturn;
        },
        async cancel() {
            // MEMO: デザインの編集情報を再度読み込む
            // await MakingItemInfo.load(this.makingItemInfo.design_code, this.makingItemInfo.customer_id);
            // AvailableResources.initCustomerResources()
            // await AvailableResources.loadCustomerResources(this.makingItemInfo.design_code, this.makingItemInfo.customer_id)

            // MEMO: PCの場合、レイアウトメニュー(select_style)を選択状態にする
            this.editStatus.activeSideMenu = this.windowWidth > 640 ? 'select_style' : '';
            this.$emit('close')
        },
    },
    computed: {
        isMourning() {
            // MEMO: 喪中はがきか判定します
            return MourningProductCodes.includes(this.makingItemInfo.design_code) ? true : false;
        },
        isMovingHouse() {
            // MEMO: 喪中はがきか判定します
            return HouseMovingProductCodes.includes(this.makingItemInfo.design_code) ? true : false;
        },
        allAcceptance() {
            // MEMO: 喪中はがきの場合
            if (this.isMourning || this.isMovingHouse) return (this.checkActive = this.acceptance && this.cutOff);

            // MEMO: 年賀・完成はがきの場合
            return this.checkActive = (this.acceptance && this.cutOff && this.inFrame);
        },
    },
}
</script>

<style scoped>

</style>