<!--
    MEMO: 注文枚数を指定してください
-->
<template>
    <div class="p-purchase__flow">
        <section class="p-purchase__box ">
            <div class="w-full bg-white">
                <div class="grid grid-cols-3 items-center px-2 py-4">
                    <div class="block text-sm">
                        <button @click="returnScreenButton()">＜戻る</button>
                    </div>
                    <div class="block text-center text-xl font-bold">
                        <img src="/images/icon/header/ic_header-logo.svg" alt="my family"
                             class="l-header__logo is-purchase mx-auto">
                    </div>
                </div>
            </div>
            <div class="block text-xl font-bold text-center mt-8">
                <span>注文枚数を指定してください</span>
                <!--MEMO: カート追加エラー -->
                <div v-if="Object.keys(errors).length > 0"
                     class="w-full border border-red text-sm text-red font-bold bg-red02 px-4 py-4">
                    <span>{{ this.add_cart_message }}</span>
                    <p v-for="error in errors" :key="error.id">{{ error[0] }}</p>
                </div>

            </div>

            <div class="py-10">
                <div class="px-4">
                    <div class="bg-white px-4 py-4 rounded-20 sm:max-w-lg sm:w-full sm:ml-auto sm:mr-auto">
                        <div class="flex justify-between items-center">
                            <span class="font-bold">宛名の印刷なし</span>
                            <div class="c-input__selectRightArrow is-puarchase-amount">
                                <select
                                    name="not_sheets"
                                    class="c-input__selectArea w-32 text-xs py-2 px-2 rounded cursor-pointer"
                                    v-model="no_printing_qty"
                                >
                                    <option v-if="makingItemInfo.print_service === refEnum.UsePrintService.YES"
                                            value="0">
                                        0枚
                                    </option>
                                    <option v-else value="0">---</option>
                                    <option v-for="n in Math.floor(1500/ sale_unit)" :key="n"
                                            :value="Math.floor(n * sale_unit)">{{ Math.floor(n * sale_unit) }}枚
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="makingItemInfo.print_service === refEnum.UsePrintService.YES"
                         class="bg-white mt-6 px-4 py-4 rounded-20 sm:max-w-lg sm:w-full sm:ml-auto sm:mr-auto h-full">
                        <div class="flex flex-col">
                            <div class="flex justify-between items-center">
                                <span class="font-bold">宛名の印刷あり</span>
                                <button
                                    @click="showAddressListPanelButton"
                                    class="w-32 text-xs py-2 px-2 border border-gray03 rounded text-start p-edit__rightArrow"
                                >枚数選択/変更
                                </button>
                            </div>
                            <div>
                                <div class="pt-8">
                                    <div class="pb-2 border-b border-gray03 text-center font-bold">
                                        <p>選択中の宛名</p>
                                    </div>
                                </div>
                                <div class="p-purchase__checkAddressList u-scrollbar__none">
                                    <ul>
                                        <li v-for="address_item in this.makingItemInfo.purchase_address_items"
                                            :key="address_item.id">
                                            <div class="relative py-4 border-b border-gray03">
                                                <!-- 名前 -->
                                                <div class="text-lg font-bold items-center"
                                                     ref="selectAddressNameElement">
                                                    <ListNamePreview
                                                        :preview-data="this.nameForGothic(address_item)"/>
                                                </div>
                                                <span class="absolute top-4 right-0 font-bold">{{
                                                        address_item.honorifics
                                                    }}</span>
                                                <div class="c-slide__operate__wrap mt-2">
                                                    <p class="c-slide__operate__nowrap text-sm">
                                                        〒{{ address_item.post_no }}
                                                        {{ address_item.pref_name }}{{
                                                            address_item.address
                                                        }}{{ address_item.building_name }}</p>
                                                    <!-- MEMO: 以下法人のみ表示-->
                                                    <p v-if="address_item.guest_type === refEnum.GuestType.CORPORATION.val">
                                                        {{ address_item.corporate_name1 }}
                                                        {{ address_item.corporate_name2 }}
                                                        {{ address_item.department_name1 }}
                                                        {{ address_item.department_name2 }}
                                                        {{ address_item.position_name }}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 sm:ml-auto sm:mr-auto sm:max-w-lg p-4 bg-red02 border border-red text-red"  v-if="sender_validation_error_message !== ''">
                        <p class="pb-4">【ご注意】</p>
                        <div class="flex items-center">
                            <span class="p-purchase__input__text is-attention">・{{ sender_validation_error_message }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-purchase__position__button sm:relative w-full">
                <div class="bg-white py-2.5 sm:py-4 px-12">
                    <div class="pb-2.5 font-bold text-center">
                        <span>合計枚数&nbsp;/&nbsp;{{ calcQty }}&nbsp;枚</span>
                    </div>

                    <div class="w-full sm:max-w-sm block rounded text-center sm:ml-auto sm:mr-auto p-button__purchase"
                         :class="{ 'active': checkActive }"
                    >
                        <button
                            type="submit"
                            class="w-full text-white font-bold py-2"
                            @click="nextCart()"
                            :disabled=!checkDisabled
                        >カートに追加
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div
        v-if="isShowAddressListPanel"
        class="z-2"
        :class="[
            {'is-active': isShowPanel},
            screenWidthAddClass
        ]"
    >
        <!--MEMO: -->
        <PurchaseAddressList @close="addressListCloseSlide"/>
    </div>

    <PurchaseFlowCart
        v-if="isShowAddToCart"
        @purchaseFlowOfAddToCart="purchaseFlowChangeScreen"
    />
</template>

<script>
import PurchaseAddressList from '../Purchase/PurchaseAddressList.vue'
import PurchaseFlowCart from './PurchaseFlowCart.vue';
import * as Enum from "../../const/const";
import {inject} from "vue";
import config from "../../const/const";
import AuthFunctions from "../../functions/auth";
import CommonModule from "../../functions/common_module";
import ListNamePreview from "../../components/ListNamePreview.vue";
import axios from "axios";

const {
    getCustomer,
} = AuthFunctions();
export default {
    name: 'PurchaseFlowQuantity',
    components: {PurchaseAddressList, PurchaseFlowCart, ListNamePreview},
    setup() {
        const makingItemInfo = inject('makingItemInfo');
        return {
            makingItemInfo
        }
    },
    data() {
        return {
            print_qty: this.makingItemInfo.print_qty,
            no_printing_qty: this.makingItemInfo.no_printing_qty,
            windowWidth: 0,
            isShowAddToCart: false,
            isShowAddressListPanel: false,
            isShowPanel: false,
            add_cart_message: '',
            sender_validation_error_message: '',
            errors: [],
            checkDisabled: false,
            checkActive: false,
            maxFigure: 15,
            sale_unit: process.env.MIX_SALES_UNIT,
        }
    },
    emits: ['backServicePanel'],

    methods: {
        returnScreenButton() {
            this.$emit('backServicePanel', false);
        },
        /**
         * MEMO: 名前をゴシック体でフィルタリングする
         * 一覧では、ゴシック体しか使用しないため取り除きます
         * */
        nameForGothic(address_item) {
            return address_item.address_name_parts.filter((item) => {
                return item.font_id === null || item.font_id === Enum.FONT.GOTHIC.id;
            }, this);
        },
        /**
         * デザイン面と宛名の差出人チェック
         * */
        checkAddressOfSender() {

            // 選択された宛名情報
            const purchase_address_items = this.makingItemInfo.purchase_address_items;

            // MEMO: デザイン面の差出人判定。存在する場合True
            const existDesignSender = Object.keys(this.makingItemInfo.selectedSenderOrderingRule).length !== 0 ? true : false;

            if (this.makingItemInfo.print_service === Enum.UsePrintService.YES) {
                // MEMO:「宛名の印刷をする」場合…
                const purchase_address_item_cnt = Object.keys(purchase_address_items).length;   // 選択された宛名の数
                let sender_selected_cnt = 0;    // 差出人が設定されている宛名の数
                let sender_no_select_cnt = 0;   // 差出人が未設定の宛名の数
                // MEMO: 選択した宛名全てに差出人が設定されているかチェック
                for (const purchase_address_item of purchase_address_items) {
                    purchase_address_item.sender_id ? ++sender_selected_cnt : ++sender_no_select_cnt;
                }

                // MEMO: 1：デザイン面と指定した宛名全てに差出人がなければ、「デザイン・宛名ともに差出人がありません」のメッセージを表示
                // 選択した宛名数と差出人が未選択の数が同じ場合、宛名全てに差出人が無いと判定
                if (purchase_address_item_cnt >0 && purchase_address_item_cnt === sender_no_select_cnt && !existDesignSender) {
                    return 'デザイン・宛名ともに差出人がありません'
                }

                // MEMO: 2：1で問題なければ、指定した宛名で宛名ごとに差出人があったりなかったりしたら、
                //  「差出人がある宛名とない宛名があります。選択した宛名をご確認ください」のメッセージを表示
                if (sender_selected_cnt > 0 && sender_no_select_cnt > 0) {
                    return '差出人がある宛名とない宛名があります。\n選択した宛名をご確認ください'
                }
            } else {
                /**
                 * ●「宛名の印刷しない」場合…
                 * １：デザイン面に差出人がなければ、「デザイン・宛名ともに差出人がありません」のメッセージを表示
                 * */
                if (!existDesignSender) {
                    return 'デザイン・宛名ともに差出人がありません'
                }
            }
            return '';
        },
        /**
         * カートに追加するボタン
         * */
        async nextCart() {
            // MEMO: EC側のカート追加APIを実行
            this.add_cart_message = '';
            this.customer = await getCustomer();
            // MEMO: 会員情報の取得ができない場合、ECのログイン画面へ遷移
            if (!this.customer || !Object.keys(this.customer).length) {
                // MEMO: ここでECのログアウトへリダイレクトURLをつけて飛ばす
                window.location.href = process.env.MIX_EC_LOGOUT_URL
                    + '?redirect_url=' + process.env.MIX_APP_URL
                    + this.$route.path;
                return;
            }

            const post_params = {
                nenga_id: this.makingItemInfo.nenga_id,
                customer_id: this.customer.id,
                product_code: this.makingItemInfo.design_code,
                total_qty: this.calcQty,
                no_printing_qty: this.no_printing_qty,
                print_qty: this.print_qty,
                print_service: this.makingItemInfo.print_service,
                print_style: this.makingItemInfo.print_style,
                material_pattern_code: this.makingItemInfo.material_pattern_code,
                // MEMO: 選択された宛先のIDだけをPOSTデータにする
                purchase_address_ids: this.makingItemInfo.purchase_address_items.map((purchase_address_item) => purchase_address_item.id),
            };

            // MEMO:
            //  宛名印刷を使用しない場合
            //  POSTするタイミングで宛名の印刷をする枚数と、選択した宛先情報を消す
            if (this.makingItemInfo.print_service === Enum.UsePrintService.NO) {
                post_params.print_qty = 0;
                post_params.purchase_address_items = [];
            }

            // カートに追加ボタンを連打防止の為、無効化
            this.toggleAddCartButton(false);

            // カート追加
            // 購入時の宛先保存処理を内包
            axios.post(config.API_BASE_URL + '/ec/cart/add_cart', post_params)
                .then((response) => {
                    if (response.data.result) {
                        this.add_cart_message = 'カートの追加が完了しました。';
                        // MEMO: ECのカート画面へリダイレクトする
                        window.location.href = process.env.MIX_EC_CART_URL
                    }
                })
                .catch((error) => {
                    // MEMO: サーバー側のValidationエラーの場合は（422）メッセージを変える
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else {
                        //
                        this.errors = error.response.data.error;
                    }
                    // カートに追加ボタンを有効化
                    this.toggleAddCartButton(true);
                }
            );
        },
        /**
         * カート追加完了画面の表示
         * */
        purchaseFlowChangeScreen(nextScreenOrReturn) {
            this.isShowAddToCart = nextScreenOrReturn;
        },
        /**
         * 「宛名の印刷あり」の枚数選択/変更モーダルを閉じたとき
         * */
        addressListCloseSlide() {
            this.isShowPanel = false;
            this.sender_validation_error_message = this.checkAddressOfSender();

            if (this.windowWidth >= 640) {
                this.isShowAddressListPanel = false;
                CommonModule.threePointLeader(this.$refs.selectAddressNameElement, Number(this.maxFigure));
            } else {
                setTimeout(() => {
                    this.isShowAddressListPanel = false;
                    CommonModule.threePointLeader(this.$refs.selectAddressNameElement, Number(this.maxFigure));
                }, 300);
            }
        },
        /**
         * 「宛名の印刷あり」の枚数選択/変更モーダルを開いたとき
         * */
        showAddressListPanelButton() {
            // TODO: 以前選択された値をAddressListに渡して選択状態にする
            this.isShowAddressListPanel = true;
            setTimeout(() => {
                this.isShowPanel = true;
            }, 100);
        },
        /**
         * 印刷枚数の設定
         * computedではdataにアクセスできないため
         * */
        setPrintQty(print_qty) {
             this.print_qty = print_qty;
        },
        /**
         * カート追加ボタンの有効無効切り替え
         * */
        toggleAddCartButton(flg) {
            // カートに追加ボタンを連打防止の為、無効化
            this.checkDisabled = flg;
            this.checkActive = flg;
        },
    },

    mounted() {
        CommonModule.threePointLeader(this.$refs.selectAddressNameElement, Number(this.maxFigure));
        this.sender_validation_error_message = this.checkAddressOfSender();

        // MEMO: 宛名の印刷をする場合、販売単位を1にする
        if (this.makingItemInfo.print_service === Enum.UsePrintService.YES) {
            this.sale_unit = 1;
        }
    },

    created() {
        this.windowWidth = window.innerWidth;
    },

    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述

        calcQty() {
            // 印刷ありの枚数( 宛名印刷を利用しない場合は「0」)
            const print_qty = this.makingItemInfo.print_service === Enum.UsePrintService.NO ?
                0 : this.makingItemInfo.purchase_address_items.length;

            this.setPrintQty(print_qty);

            // 合計枚数
            const total_qty = Number(this.print_qty) + Number(this.no_printing_qty);

            // MEMO: 合計枚数が0の場合、ボタンを無効化
            if (total_qty === 0) {
                this.toggleAddCartButton(false);
            } else {
                this.toggleAddCartButton(true);
            }
            return total_qty;
        },
        /**
         * 宛名一覧のCSS切り替え
         * */
        screenWidthAddClass() {
            if (this.windowWidth >= 640) {
                return ['p-purchase__flow'];
            } else {
                return ['p-edit__slide--full'];
            }
        },
    },
}

</script>

<style scoped>

</style>
