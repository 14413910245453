<!-- MEMO: 宛先/差出人の姓・名・旧姓/年齢 プレビュー -->
<template>
    <template v-for="previewDatum in previewData">
        <!-- ピアリーフォント-->
        <span
            :class="{ 'piary_g text-lg font-bold': previewDatum.gaiji_type === refEnum.ExternalCharType.FONT.val}"
            v-if="previewDatum.gaiji_type === refEnum.ExternalCharType.FONT.val">{{
                String.fromCodePoint(previewDatum.gaiji)
            }}</span>

        <!-- その他の外字 -->
        <span v-else-if="previewDatum.gaiji_type === refEnum.ExternalCharType.KANJI.val">{{
                previewDatum.gaiji
            }}</span>

        <!-- 通常の入力 -->
        <span v-else>{{ previewDatum.input_text }}</span>
    </template>
</template>

<script>
import * as Enum from "../const/const";

export default {
    name: "ListNamePreview",
    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述
    },
    props: {
        previewData: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style scoped>

</style>