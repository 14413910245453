<template>
    <div v-if="isSelectedPaperItemCode">
        <div class="relative" >
            <div class="absolute w-11/12 sm:w-auto top-7 left-1/2 sm:left-10 -translate-x-1/2 sm:-translate-x-0 bg-white text-xs sm:text-sm p-4 leading-5 shadow-md border border-gray03 z-2">
                <p>はがきにある<span class="font-bold text-blue02">青い点線より外側</span>は、<br>
                    製造時にカットされる可能性があります。<br>
                    差出人、テキストは青い点線の内側へ配置してください。
                </p>
                <div class="absolute -top-3 -right-2">
                    <button @click="closeAttention"
                        class="bg-white rounded-full">
                        <span class="v-cancel"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";

export default {
    name: 'designBorderAttention',
    setup() {
        const editStatus = inject('editStatus')
        const makingItem = inject('makingItemInfo')
        return {
            editStatus,
            makingItem,
        }
    },

    data() {
        return {
            attention: true,
            windowWidth: '',
        }
    },

    methods: {
        openAttention() {
            this.attention = true;
        },
        closeAttention() {
            this.attention = false;
        },
    },

    computed: {
        // はがきの台紙パターンが複数の時に青枠の注意書きを表示・非表示の切り替え('A'を'B'に変更したら表示)
        isSelectedPaperItemCode() {
            if (this.editStatus.activeSideMenu === 'paper_select') {
                this.openAttention();
            }
            return this.makingItem.material_pattern_code === 'A' && this.attention;
        },
    },

    created() {
        this.windowWidth = window.innerWidth;
    }
}

</script>

<style scoped>
.v-cancel {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    border: 1px solid #00C2FF;
    border-radius: 50%;
}

.v-cancel::before, .v-cancel::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 15px;
    background: #00C2FF;
}

.v-cancel::before {
    transform: translate(-50%,-50%) rotate(45deg);
}

.v-cancel::after {
    transform: translate(-50%,-50%) rotate(-45deg);
}

</style>