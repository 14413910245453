export default function(member) {
    const showModal = document.querySelector('.js-modalOverlay');
    const saveAnimation = document.querySelector('.js-temporarySaveAnimation');

    if (member) {
        showModal.classList.add('is-modal-active');
        // @import svgアニメーションを実行するパッケージ
        import('./lottieAnimation').then(({default: lottie}) => {
            saveAnimation.classList.add('is-active');

            //JSONアニメーションを宣言
            const setTemporarySaveAnimation = lottie.loadAnimation({
                container: saveAnimation,
                renderer: 'svg',
                path: '/images/lottieAnimation/temporarySave.json',
                loop: false, //アニメーションの実行はループさせない
                autoplay: true, //一時保存ボタンを押した時に自動表示
            });

            setTemporarySaveAnimation.onComplete = () => {
                saveAnimation.classList.remove('is-active');
                showModal.classList.remove('is-modal-active');
                saveAnimation.removeChild(saveAnimation.firstChild);
            };
        });
    }
}