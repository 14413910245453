<!-- MEMO: 重複郵便番号モーダル -->
<template>
    <div class="c-modal__externalAndSearchAddress"
         ref="scrollAmount"
    >
        <div class="bg-white z-2 w-full max-w-xl mx-2 sm:mx-0 rounded-3xl overflow-auto u-scrollbar__none"
             ref="modalBox"
        >
            <div class="py-8">
                <div class="px-4 sm:px-8">
                    <div>
                        <p>〒{{ post_no }}</p>
                        <p class="pt-2 pb-4">住所一覧</p>
                    </div>
                    <ul>
                        <li v-for="post_no_data in post_no_list" :key="post_no_data.id"
                            ref="post_lists"
                            class="flex items-center border border-gray03 py-1 px-4 mb-4 rounded-md js-postListArea"
                        >
                            <label class="flex items-center py-2 cursor-pointer">
                                <input @change="postRadio"
                                       type="radio"
                                       class="c-input__searchPost__init appearance-none"
                                       name="checkPostNo"
                                       v-model="check_post_no_data"
                                       :value="post_no_data"
                                       ref="post_input_radio"
                                >
                                <span class="c-input__searchPost__check"></span>
                                <span class="ml-2">{{ post_no_data.pref_name }}{{ post_no_data.locality_name }}{{ post_no_data.street_address_name}}</span>
                            </label>
                        </li>
                        <li class="pt-6 sm:text-lg font-bold">
                            <button type="button" @click="close()" class="block mx-auto text-blue02">決定</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DuplicatePostNoModal",
    emits: ['closeDuplicatePostNoModal'],
    props: {
        postNo: {
            type: String,
            default: '',
        },
        postNoList: {
            type:Object,
            default: {}
        },

        getScrollY: {
            type: Number,
            default: '',
        }
    },

    data() {
        return {
            post_no: null,
            post_no_list: [],
            check_post_no_data: '',             // 郵便番号選択モーダルのチェックボックス
            show: true,
            windowWidth: '',
            cls: ['bg-blue02', 'text-white', 'border-blue02'], //複数の住所が該当した時に背景色とテキストの色を格納
        }
    },
    mounted() {
        this.post_no = this.postNo;
        this.post_no_list = this.postNoList;
        this.check_post_no_data = this.post_no_list[0]; // ラジオのデフォルトとして、先頭をセット

        /**
         * スクロールされた量で住所検索時のモーダルと、検索ボックスの位置を固定
         */
        if (this.windowWidth > 640) {
            this.$refs.scrollAmount.setAttribute('style', 'top:' + this.getScrollY + 'px');
        };

        setTimeout(() => {
            this.scrollChangeHeight();
            this.initRadio();
        }, 100);
    },

    methods: {
        /**
         * MEMO: 重複した郵便番号の選択処理
         * */
        close() {
            this.$emit('closeDuplicatePostNoModal', this.check_post_no_data);
        },

        /**
         * 住所重複時にラジオボタンの値を変えた時に背景色とテキストを切り替える
         * @param {*} e 
         */
        postRadio(e) {
            const ancestorTarget = e.target.closest('.js-postListArea');
            const ancestorLists  = this.$refs.post_lists;

            for (const i of ancestorLists) {
                i.classList.remove(...this.cls);
                ancestorTarget.classList.add(...this.cls);
            };
        },

        /**
         * 住所が重複した時の初期表示に背景色とテキストの色を変える
         * @param {Array} cls 
         */
        initRadio() {
            const elem = this.$refs.post_input_radio;
            for (const i of elem) {
                if (i.checked === true) {
                    i.closest('.js-postListArea').classList.add(...this.cls);
                };
            };
        },

        /**
         * 住所検索された件数に応じて、表示されるボックスの幅を切り替え
         */
        scrollChangeHeight() {
            if (Object.keys(this.post_no_list).length === 0) {
                return;

            } else {
                setTimeout(() => {
                    const modalBox        = this.$refs.modalBox;
                    const modalBoxHeight  = modalBox.clientHeight;
                    const innerHalfHeight = window.innerHeight / 2;
                    modalBoxHeight > innerHalfHeight ? modalBox.classList.add('h-2/3') : modalBox.classList.add('h-auto');
                });
            };
        },
    },

    created() {
        this.windowWidth = window.innerWidth;
    },
}
</script>

<style scoped>

</style>