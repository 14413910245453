<!-- MEMO: 宛先/差出人の姓・名・旧姓/年齢 プレビュー -->
<template>
    <template v-for="previewDatum in previewData">
        <!-- ピアリーフォント -->
        <div
            v-if="previewDatum.gaiji_type === refEnum.ExternalCharType.FONT.val"
            class="relative px-2 leading-9 text-center rounded c-button__border text-white bg-rose01 w-10">
            <p :class="{ 'piary_g': previewDatum.gaiji_type === refEnum.ExternalCharType.FONT.val}">
                {{ String.fromCodePoint(previewDatum.gaiji) }}
            </p>
        </div>

        <!-- その他の外字 -->
        <div
            v-else-if="previewDatum.gaiji_type === refEnum.ExternalCharType.KANJI.val"
            class="relative px-2 leading-9 text-center rounded border border-rose01 w-10 gothic">
            <p>{{ previewDatum.gaiji }}</p>
        </div>

        <div
            v-else-if="previewDatum.input_text === ' '"
            class="relative px-2 leading-9 text-center rounded c-button__border w-10">
            <p>␣</p>
        </div>

        <div
            v-else-if="previewDatum.input_text === '　'"
            class="relative px-2 leading-9 text-center rounded c-button__border w-10">
            <p>□</p>
        </div>

        <!-- 通常の入力 -->
        <div
            v-else
            class="relative px-2 leading-9 text-center rounded text-white bg-gray03 c-button__border w-10 gothic">
            <p class="">{{ previewDatum.input_text }}</p>
        </div>
    </template>
</template>

<script>
import * as Enum from "../const/const";

export default {
    name: "NamePreview",
    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述
    },
    props: {
        previewData: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style scoped>

</style>