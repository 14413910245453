<!--
    MEMO: 外字検索モーダルコンポーネント
-->
<template>
    <div class="c-modal__external c-modal__externalAndSearchAddress js-scrollAmount">
        <div class="bg-white z-2 w-full max-w-xl mx-2 sm:mx-0 rounded-3xl">
            <div class="c-modal__external__inner py-8 px-4">
                <h4 class="text-center text-lg sm:text-2xl font-bold">外字の入力</h4>
                <div class="flex justify-center py-4">
                    <input
                        type="text"
                        class="block bg-white w-full sm:w-1/2 border border-r-0 border-slate-300 rounded-l-md py-2 px-2 sm:text-base focus:outline-none"
                        name="input_search_external_character"
                        v-model="input_search_external_character"
                        maxlength="5"
                        placeholder=""
                        autocomplete="off"
                    >
                    <button type="button"
                            @click="searchExternalCharacter"
                            class="w-2/12 sm:w-12 text-sm bg-blue02 text-white font-bold rounded-r-md"
                    >
                        <img src="/images/icon/ic_search-button.png" class="mx-auto" alt="検索">
                    </button>
                </div>

                <div
                    v-if="isShowMessage"
                    class="w-full sm:w-2/3 bg-white text-center mx-auto pt-4"
                >
                    <div class="border border-red text-red py-4">

                        <p>検索結果はありません。</p>
                        <p v-for="error in errors" :key="error.id">{{ error[0] }}</p>
                    </div>
                </div>

                <ul class="sm:w-9/12 mx-auto">
                    <li v-for="external_character in filterForGothic(external_character_list)"
                        :key="external_character.id"
                        class="c-input__external__text cursor-pointer "
                        @click="close(external_character)"
                    >
                        <span
                            :class="{ 'piary_g': external_character.gaiji_type === refEnum.ExternalCharType.FONT.val}"
                            v-if="external_character.gaiji_type === refEnum.ExternalCharType.FONT.val">{{
                                String.fromCodePoint(external_character.gaiji_character_code)
                            }}
                        </span>
                        <span class="gothic"
                              v-else-if="external_character.gaiji_type === refEnum.ExternalCharType.KANJI.val">{{
                                external_character.gaiji_kanji
                            }}
                        </span>
                    </li>
                </ul>
                <div class="text-center pt-8 text-blue02 sm:text-lg font-bold">
                    <button type="button" @click="close(null)">閉じる</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as Enum from "../const/const";
import config from "../const/const";

export default {
    name: "SearchExternalCharacterModal",
    data() {
        return {
            isShowMessage: false,
            external_character_list: [],        // 外字検索結果用
            external_character_list_all_font: [], // 外字検索結果 フォントによるフィルタなし
            input_search_external_character: '', // 外字検索用テキストボックスの値
            errors: [],
        }
    },

    props: {
        getScrollY: {
            type: Number,
            default: '',
        },
        displayFontId: {
            type: Number,
            default: Enum.FONT.GOTHIC.id,
        },
    },
    mounted() {
        if (window.matchMedia('(min-width: 640px)').matches) {
            const setScrollAmount = document.querySelector('.js-scrollAmount');
            setScrollAmount.setAttribute('style', 'top:' + this.getScrollY + 'px');
        }
    },
    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述
    },
    methods: {
        /**
         * MEMO: 外字検索
         * */
        async searchExternalCharacter() {
            const params = {
                word: this.input_search_external_character,
                gaiji_type: null
            };
            this.isShowMessage = false;
            this.external_character_list.splice(0);
            await axios
                .post(config.API_BASE_URL + '/external_character/search', params)
                .then((response) => {
                    // MEMO: 取得した結果を配列に追加する
                    for (let i = 0; i < response.data.length; i++) {
                        this.external_character_list.push(response.data[i]);
                        this.external_character_list_all_font.push(response.data[i]);
                    }
                })
                .catch((error) => {
                    this.isShowMessage = true;
                    this.errors = error.response.data.errors;
                });
        },
        /**
         * MEMO: モーダルを閉じる
         * */
        close(external_character) {
            const external_character_list = external_character ? this.filterForGaijiRelationKey(external_character.gaiji_relation_key) : null;

            // 呼び出し元の親コンポーネントに選択した文字と表示用の文字を返す
            this.$emit('closeSearchExternalCharacterModal',
                this.input_search_external_character,
                external_character_list,     // 外字モーダルで選択した文字と同じ外字リレーションキーのデータを返す
            );
        },
        /**
         * MEMO: 検索結果の一覧用フィルター
         *  検索結果の中から、フォントがゴシック(font_id=1)のデータを抽出します
         * */
        filterForGothic(external_character_list) {
            return external_character_list.filter((item) => {
                return item.font_id === Enum.FONT.GOTHIC.id;
            });
        },
        filterForGaijiRelationKey(gaiji_relation_key) {
            return this.external_character_list.filter((item) => {
                return item.gaiji_relation_key === gaiji_relation_key;
            });
        },
    }
}
</script>

<style scoped>
.piary_g {
    font-family: 'piary_g';
}

</style>