<template>
    <g class="user-object-deploy-layer">
        <!-- 画像クリッピング枠 -->
        <user-image-clipping-flame
          @selectFlame="selectFlameToDeploy"
          :user-image-layout-file="selectedLayout"
          :flame-transform-map="inverseImageTransFormMap">
        </user-image-clipping-flame>

        <!-- ユーザアップロード画像 -->
        <MoveableUserImage v-for="(image, index) in this.deployedImages"
                           :moveable-target="editStatus.currentTarget"
                           @click="selectNodeToEdit(image)"
                           :key="index"
                           :contents-id="image.id"
                           :user_image_url="image.url"
                           :clip_path="image.clipPath"
                           :transform="image.transform"
                           :width="parseFloat(image.width)"
                           :height="parseFloat(image.height)"
                           :userImageAndMaterial="true"
        >
        </MoveableUserImage>

        <!-- タイトル画像 -->
        <fixed-object v-if="selectedTitle" :image_url="selectedTitle.image_url"
                      :height="previewHeight" :width="previewWidth"></fixed-object>

        <!-- スタンプ画像 -->
        <MoveableUserImage v-for="(image, index) in makingItem.deployedStamps"
                           :moveable-target="editStatus.currentTarget"
                           @click="selectNodeToEdit(image)"
                           :key="index" :contents-id="image.id" :user_image_url="image.url"
                           :clip_path="image.clipPath" :transform="image.transform"
                           :width="stampWidth" :height="stampHeight"
                           :stampObject="true"
        >
        </MoveableUserImage>

        <!-- あいさつ文テキスト -->
        <MoveableTextBox v-for="(ut, index) in userTexts"
                           :moveable-target="editStatus.currentTarget"
                           @click="selectNodeToEdit(ut)"
                           @changeTextAnchor="changeTextAnchor($event, ut)"
                           :key="index" :contents-id="ut.id" :text="ut.text" :font="ut.font"
                           :x="parseFloat(ut.x)" :y="parseFloat(ut.y)" :size="parseFloat(ut.size)"
                           :transform="ut.transform" :anchor="ut.anchor"
                           :color="ut.color"
                           :class="ut.id"
                           :writing-mode="ut.writingMode"
        ></MoveableTextBox>

        <!-- 差出人テキスト（MakingItemが読み込み終わっている場合のみ表示する） -->
        <sender-text
            @selectNodeToEdit="selectNodeToEdit"
            @changeTextAnchor="changeTextAnchor($event, makingItem.senderItem)"
            :moveable-target="editStatus.currentTarget"
            :sender_item="makingItem.senderItem"
            :ordering_rule_template="orderingRuleTemplate"
        ></sender-text>

    </g>
</template>

<script>
import UserImageClippingFlame from "./DeployLayer/UserImageClippingFlame";
import MoveableUserImage from "./DeployLayer/MoveableUserImage";
import MoveableTextBox from "./DeployLayer/MoveableTextBox";
import {inject} from "vue";
import {EditState, PreviewScale} from "../../const/const";
import FixedObject from "./DeployLayer/FixedObject";
import SenderText from "./DeployLayer/SenderText";
import decisionClip from "../../functions/user_image_decision_clipping";
import AvailableResources from "./AvailableResources.vue";
import AuthFunctions from "../../functions/auth";
const {getCustomer} = AuthFunctions();
export default {
    name: "UserObjectDeployLayer",
    components: {SenderText, FixedObject, MoveableTextBox, MoveableUserImage, UserImageClippingFlame},
    emits: ['selectNodeToEdit', 'showAlertMessage', 'completeLoadDeploydImages'],
    setup() {
        const editStatus = inject('editStatus');
        const makingItem = inject('makingItemInfo')
        const availableResources = inject('availableResources')
        return {
            makingItem,
            availableResources,
            editStatus,
        };
    },
    created() {
        this.windowWidth = window.innerWidth;
    },
    async mounted() {
        // MEMO: 会員情報の取得
        const customer = await getCustomer()

        // 画像情報(dtb_user_image)の一覧を取得する
        if (this.makingItem.customer_id) {
            await AvailableResources.loadCustomerResources(this.makingItem.designCode, this.makingItem.customer_id)
        }

        // MEMO: 配置されている画像を読み込む
        const url_set_processes = this.makingItem.deployedImages.map(async (deployed_image) => {
            deployed_image.url = 'loading'
            deployed_image.url = await this.availableResources.fetchImage(deployed_image, this.makingItem.customer_id);

            // MEMO: 画像配置メニュークリック時の画像の再読み込み防止の為、
            //  配置画像と画像リストのファイル名が一致する場合、画像リストの方のURLにも読み込んだBase64データを設定する
            this.availableResources.userImages.map(async (user_images) => {
                if (user_images.image_file === deployed_image.image_file) {
                    user_images.url = deployed_image.url;
                }
            })
        })

        await Promise.all(url_set_processes)
            .finally(() => {
                let isDeleteDeployedImage = false;
                // 読み込む必要がある配置画像数
                let needs_load_deployed_images_count = this.makingItem.deployedImages.length;
                // MEMO: 配置している画像が、画像リストから削除されている または 読み込みに失敗した配置画像の場合は要素から消す
                for (const deployed_image of this.makingItem.deployedImages) {
                    const result = this.availableResources.userImages.filter((user_img) => {
                        return user_img.image_file === deployed_image.image_file;
                    })

                    // MEMO： 代理ログインの場合はスキップ
                    if ((result.length === 0 || deployed_image.url === 'LOAD_FAILED') && !customer.is_proxy_user) {
                        // MEMO: APIで取得した画像 または 画像リストから削除されている場合は読み込み必要数をマイナスする
                        --needs_load_deployed_images_count;
                        this.makingItem.removeDeployedImage(deployed_image.id)
                        isDeleteDeployedImage = true;
                    }
                }

                // MEMO: 1枚でも削除されていたら配置画像削除モーダルを表示する
                if (isDeleteDeployedImage) {
                    this.$emit('showAlertMessage', ['配置済みの写真が削除されました。']);
                }

                // MEMO:
                //  配置画像が0枚 または 配置画像と画像読み込みエラー数が同じ場合は画像の読み込み完了イベント（@load）が発生しないため
                //  Edit.vueのローディングモーダルを非表示にする
                //  配置画像情報取得後にチェックするためここで行う
                if (this.makingItem.deployedImages.length === 0 || this.makingItem.deployedImages.length === needs_load_deployed_images_count) {
                    this.$emit('completeLoadDeploydImages');
                }
            })
    },
    data() {
        return {
            //固定値で出しているので、いつかはDBから情報を持ってきた方がいいか？
            stampWidth: 150,
            stampHeight: 150,
            windowWidth: '',
            deployedImageLoadedCount: 0,
        }
    },
    methods: {
        /**
         * 編集エリア上のオブジェクト選択
         * */
        async selectNodeToEdit(object) {
            if (this.editStatus.state === EditState.EDIT_USER_IMAGE) {
                // ユーザイメージの変形・編集中
                decisionClip(this.editStatus.imageToDeploy, this.editStatus.state, EditState.EDIT_USER_IMAGE, this.editStatus.flameToClip);
                this.editStatus.neutralize()

                return;

            } else if (this.editStatus.state === EditState.SELECT_FLAME) {
                // ユーザイメージ配置先の選択中
                if (object.type === 'userImage') {
                    await this.deployOrReplaceUserImage(object.clipPath, this.editStatus.imageToDeploy)
                    return
                }
            }

            // その他の状態の時は、オブジェクトを選択状態にする
            this.editStatus.imageToDeploy = object;
            if (object.type === 'userImage') {
                this.editStatus.flameToClip = object.clipPath;
                object.clipPath = '';
                await this.editStatus.setTargetObject(object.id, EditState.EDIT_USER_IMAGE);

            } else if (object.type === 'userText' || object.type === 'senderText') {
                this.editStatus.flameToClip = '';
                this.editStatus.currentTargetTextId = object.id
                await this.editStatus.setTargetObject(object.id, EditState.EDIT_OBJECT);

            } else {
                this.editStatus.flameToClip = '';
                await this.editStatus.setTargetObject(object.id, EditState.EDIT_OBJECT);
            }
            this.$emit('selectNodeToEdit', object.id);
        },

        /**
         * @type {String}: flameId
         * 2022/12/7 鳥居
         * スマホ：フレームの選択を切り替えられるようにする。
         * 同じフレームを選んだ時にだけフッターからのスライドを立ち上げる
         */
        async selectFlameToDeploy(flameId) {
            // 他オブジェクト編集中のクリックの場合は、編集中から抜ける
            if (this.editStatus.state === EditState.EDIT_OBJECT) {
                this.editStatus.neutralize();
            }

            // フレーム選択中のクリックの場合は、画像を配置して画像編集状態へ移行する
            if (this.editStatus.state === EditState.SELECT_FLAME) {
                await this.deployOrReplaceUserImage(flameId, this.editStatus.imageToDeploy);
                return;
            }

            // フレームに配置済みの画像がある場合は、配置済み画像の編集状態へ移行する
            const image_already_attached = this.makingItem.deployedImages.find((img) => img.clipPath === flameId)
            if (image_already_attached) {
                await this.selectNodeToEdit(image_already_attached)
                return
            }

            // 画像選択メニューを開く。このフレームを選択状態にする。
            if (this.windowWidth > 640) {
                this.editStatus.activeSideMenu = 'arrange_image';
            } else if (this.windowWidth < 640 && this.editStatus.flameToClip === flameId) {
                this.editStatus.activeSideMenu = 'arrange_image';
            }
            this.editStatus.flameToClip = flameId;
        },

        async deployOrReplaceUserImage(flameId, userImage) {
            // 対象のフレームにその写真が入ってるなら何もしない
            if (userImage.clipPath === flameId) {
                return
            }
            // 対象のフレームに他の写真が入ってるなら除ける
            const image_already_attached = this.makingItem.deployedImages.find((img) => img.clipPath === flameId)
            if (image_already_attached) {
                image_already_attached.deployed = false
                this.makingItem.removeDeployedImage(image_already_attached.id)
            }

            /**
             * 写真編集モードへ
             * 2022/12/2 鳥居：フレームが選択されていなかった場合にのみフレーム番号を追加して、クリッピング可能な状態にする
             */
            if (this.editStatus.flameToClip === '') {
                this.editStatus.flameToClip = flameId;
            }
            this.makingItem.deployedImages.push(userImage);
            await this.editStatus.setTargetObject(userImage.id, EditState.EDIT_USER_IMAGE);
        },
        changeTextAnchor(event, text_object) {
            const targetSvg = document.querySelector(`.${text_object.id}`)
            if (!targetSvg) {
                return
            }
            const targetSvgRect = targetSvg.getBBox()

            // 文字寄せ変更後にテキストがどのぐらい動くか
            // ・左→右ないし上→下ならテキストの長さいっぱいに動く
            // ・右→中央ならテキストの長さの半分だけ後ろに動く
            const anchor_numerals = {
                start: 0.0,
                middle: 0.5,
                end: 1.0,
            }
            // memo: 横書きではxの値しか使わない、縦書きではyの値しか使わないので、常にどっちもする 
            // memo: 縦書きと横書きを編集画面で 切り替える場合は多分これだと動かない
            const compensation = anchor_numerals[event.anchor] - anchor_numerals[event.prev_anchor]
            text_object.x = parseFloat(text_object.x) + (compensation * targetSvgRect.width)
            text_object.y = parseFloat(text_object.y) + (compensation * targetSvgRect.height)
        },
    },
    computed: {
        previewHeight() {
            return this.makingItem.designData !== undefined && this.makingItem.designData.preview_image_width && this.makingItem.designData.preview_image_height
              ? this.makingItem.designData.preview_image_height
              : PreviewScale.HEIGHT
        },
        previewWidth() {
            return this.makingItem.designData !== undefined && this.makingItem.designData.preview_image_width && this.makingItem.designData.preview_image_height
              ? this.makingItem.designData.preview_image_width
              : PreviewScale.WIDTH
        },
        inverseImageTransFormMap() {
            const map = {}
            this.makingItem.deployedImages.forEach((image) => {
                if (image.clipPath !== '') {
                    map[image.clipPath] = image.inverseImageTransform;
                }
            });
            return map;
        },
        selectedTitle() {
            return this.makingItem.selectedTitle
        },
        selectedLayout() {
            return this.makingItem.selectedLayout ?
              this.makingItem.selectedLayout.layout_id
              : ''
        },
        /**
         * 配置されている画像一覧
         * */
        deployedImages() {
            return this.makingItem.deployedImages;
        },
        userTexts() {
            return this.makingItem.userTexts
        },
        sender() {
            return this.makingItem.senderItem
        },
        orderingRuleTemplate() {
            if (this.makingItem.selectedSenderOrderingRule) {
                return this.makingItem.selectedSenderOrderingRule.replace_rule
            }
            return '';
        },
    },
}
</script>

<style scoped>

</style>
