<template>
    <moveable-text-box :moveable-target="moveableTarget"
                       @click="selectNodeToEdit(sender_item)"
                       @selectNodeToEdit="selectNodeToEdit"
                       :contents-id="sender_item.id"
                       :x="parseFloat(sender_item.x)"
                       :y="parseFloat(sender_item.y)"
                       :text_contents="senderTextLines"
                       class="sender_text_entire"
                       :class="[sender_item.id, {'no-print': no_input_value}]"
                       :transform="sender_item.transform"
                       :color="sender_item.color"
                       :anchor="sender_item.anchor"
                       :writing-mode="sender_item.writingMode"
    ></moveable-text-box>
</template>

<script>
import * as Enum from "../../../const/const";
import {VerticalWritingMode} from "../../../const/const";
import MoveableTextBox from "./MoveableTextBox";
import {parseInt} from "lodash";

/**
 * 外字をテキストオブジェクトに変換する関数
 *
 * @param {Object} baseFragment - ベースフラグメントオブジェクト
 * @param {Object} ext - 外字オブジェクト
 * @param {String} senderItemFont - フォント
 * @param {Number} ext_no - 外字の番号
 * @param {Number} fragmentNo - フラグメントの番号
 * @param {Object} fragmentRule - フラグメントルールオブジェクト
 * @returns {Object} textObject - テキストオブジェクト
 */
const externalCharacterToTextObject = (baseFragment, ext, senderItemFont, ext_no, fragmentNo, fragmentRule) => {
    const isUsingGaiji = !!ext.is_use_gaiji;
    const isFontGaijiType = ext.gaiji_type === Enum.ExternalCharType.FONT.val;

    const gj_text = isUsingGaiji
        ? isFontGaijiType
            ? String.fromCodePoint(ext.gaiji)
            : ext.gaiji
        : ext.input_text;

    const gj_font = (isUsingGaiji && isFontGaijiType) ? 'piary_g' : senderItemFont;

    // MEMO: buildTextObjectにidとclass プロパティを追加して返す
    return {
        ...buildTextObject(baseFragment, gj_text, gj_font, fragmentNo, fragmentRule),
        id: `${baseFragment.id}_frag${fragmentNo}_ext${ext_no}`,
        class: `${baseFragment.class}_fragment${fragmentNo}_ext${ext_no}`
    }
}

/**
 * 差出人情報（senderItemのプロパティ）をテンプレートに挿入します。
 * テンプレート内のプレースホルダーをsenderItemの値で置換します。
 * 連名の場合は特別な処理が行われます。
 *
 * @param {Object} senderItem - 送信者の情報を含むオブジェクト
 * @param {String} template - プレースホルダーを含むテンプレート文字列
 * @returns {String} template - 置換後のテンプレート文字列。連名プレースホルダーが残った場合は空文字を返します。
 */
const injectSenderToTemplate = (senderItem, template) => {
    if (isJointName(template)) {
        template = replaceJointNames(senderItem.joint_names, template);
        // 置換後も連名関連のプレースホルダーが残っている場合は空文字を返す
        if (isJointName(template)) return '';

        return template;
    }

    return replaceOtherPlaceholders(senderItem, template)
}

/**
 * 連名関連のプレースホルダーがテンプレートに含まれているか判定する。
 * @param {string} template - 判定するテンプレート文字列。
 * @returns {boolean} - 連名関連のプレースホルダーが含まれている場合はtrue、そうでなければfalse。
 */
const isJointName = (template) => {
    const patterns = [
        /joint_name_[0-9]_sei/,
        /joint_name_[0-9]_mei/,
        /joint_name_[0-9]_old_name_or_age/,
        /joint_name_[0-9]_position_name/
    ];
    return patterns.some(pattern => pattern.test(template));
}

/**
 * テンプレート内の連名関連のプレースホルダーを実際の送信者情報で置換する。
 * @param {Array} joint_names - 連名の配列
 * @param {string} template - 置換するテンプレート文字列。
 * @returns {string} - 置換後のテンプレート文字列。
 */
const replaceJointNames = (joint_names, template) => {
    // 連名が未入力の場合は空文字を返す
    if (joint_names.length === 0) return '';

    return joint_names.reduce((prev, curr, index) => {
        return prev
            .replace(`joint_name_${index}_sei`, curr.sei || '')
            .replace(`joint_name_${index}_mei`, curr.mei || '')
            .replace(`joint_name_${index}_old_name_or_age`, curr.old_name_or_age || '')
            .replace(`joint_name_${index}_position_name`, curr.position_name || '');
    }, template);
}

/**
 * テンプレート内の郵便番号、県名、電話番号などのプレースホルダーを置換する。
 * @param {Object} senderItem - 差出人情報
 * @param {string} template - 置換するテンプレート文字列。
 * @returns {string} - 置換後のテンプレート文字列。
 */
const replaceOtherPlaceholders = (senderItem, template) => {
    let post_no = senderItem.post_no;
    if (/^\d{7}$/.test(post_no)) {
        post_no = `${post_no.substring(0, 3)}-${post_no.substring(3)}`;
    }
    // 県名が未選択(Enum.NoSelectedName)の場合は空文字に置換
    const pref_name = senderItem.pref_name === Enum.NoSelectedName ? '' : senderItem.pref_name;

    // 県名が含まれているかつ未選択の場合は空文字を返す
    if (template.includes('pref_name') && !pref_name) {
        return '';
    }

    return template
        .replace('post_no', post_no ?? '')
        .replace('pref_name', senderItem.pref_name || '')
        .replace('address', senderItem.address || '')
        .replace('building_name', senderItem.building_name || '')
        .replace('tel', senderItem.tel || '')
        .replace('corporate_name1', senderItem.corporate_name1 || '')
        .replace('corporate_name2', senderItem.corporate_name2 || '')
        .replace('department_name1', senderItem.department_name1 || '')
        .replace('department_name2', senderItem.department_name2 || '')
        .replace('position_name', senderItem.position_name || '');
}

/**
 * 基本フラグメントを作成します。
 *
 * @function buildBaseFragment
 * @param {Number} sender_item_id - 差出人のID
 * @param {Object} fragmentRule - フラグメントに関するルール。
 * @param {Object} lineRule - ラインに関するルール。
 * @param {Number} lineNo - ライン番号。
 * @returns {Object} 生成された基本フラグメント。
 */
const buildBaseFragment = (sender_item_id, fragmentRule, lineRule, lineNo) => {
    const lineBaseId = sender_item_id + lineRule.line_id
    return {
        id: lineBaseId,
        text: fragmentRule.text,
        nodeType: fragmentRule.nodeType,
        class: `sender_text_line${lineNo}`,
        size: fragmentRule.size,
        line_no: lineNo,
    }
}

/**
 * テキストオブジェクトを作成します。
 *
 * @function buildTextObject
 * @param {Object} baseFragment - ベースとなるフラグメント。
 * @param {String} text - テキスト内容。
 * @param {String} senderItemFont - 差出人情報のフォント
 * @param {Number} fragmentNo - フラグメント番号。
 * @param {Object} fragmentRule - フラグメントに関するルール。
 * @returns {Object} 生成されたテキストオブジェクト。
 */
const buildTextObject = (baseFragment, text, senderItemFont, fragmentNo, fragmentRule) => {
    return {
        text: text,
        nodeType: baseFragment.nodeType,
        font: senderItemFont,
        size: baseFragment.size,
        fragment_no: fragmentNo,
        template: fragmentRule.template,
        id: baseFragment.id + fragmentRule.fragment_id,
        class: `${baseFragment.class}_fragment${fragmentNo}`,
    }
}

/**
 * 指定されたXMLの要素とフラグメントインデックスを使用してルールオブジェクトを構築します。
 *
 * @param {Element} element - ルールを定義するXML要素。この要素は、text、template、space、またはgroupタグ
 * @param {number} fragmentIndex - フラグメントのインデックス。これは、フラグメントIDを生成するのに使用されます。
 * @returns {Object} ルールオブジェクト。このオブジェクトには、text、nodeType、template、fragment_id、sizeのプロパティが含まれる場合があります。
 *                   `group`タグが渡された場合は、buildGroupRule関数の結果が返されます。
 *
 * @description
 * この関数は、特定のXML要素に基づいてルールオブジェクトを構築します。
 * 要素のタグ名に応じて、異なるプロパティがセットされます。
 * - `text`タグの場合、`text`プロパティに要素の`value`属性がセットされます。
 * - `template`タグの場合、`template`プロパティに要素の`value`属性がセットされます。
 * - `space`タグの場合、`text`プロパティに要素の`value`属性がセットされます。
 * - `group`タグの場合、この関数は`buildGroupRule`関数を実行し、その結果を返します。これにより、子グループのルールオブジェクトが構築されます。
 *
 * その他のタグ名の場合、`nodeType`にはタグ名が、`fragment_id`には`_frag`にインデックスを結合した文字列がセットされます。
 * `size`属性が存在する場合、それはルールオブジェクトの`size`プロパティにセットされます。
 */
const buildRuleObject = (element, fragmentIndex) => {
    let ruleObject = {
        text: undefined,
        nodeType: element.tagName,
        template: undefined,
        fragment_id: `_frag${fragmentIndex}`,
        size: element.getAttribute('size')
    };

    // <text>要素の場合
    if (element.tagName === "text") {
        ruleObject.text = element.getAttribute('value');
    }
    // <template>要素の場合
    else if (element.tagName === 'template') {
        ruleObject.template = element.getAttribute('value');
    }
    // <space>要素の場合
    else if (element.tagName === 'space') {
        ruleObject.text = element.getAttribute('value');
    }
    // <group>要素の場合、子グループなのでbuildGroupRuleを実行して結果を返す
    else if (element.tagName === 'group') {
        return buildGroupRule(element)
    }

    return ruleObject;
}

/**
 * 指定されたグループ要素の子要素に対してルールオブジェクトを構築し、それらのオブジェクトの配列を返します。
 *
 * @param {Element} group - ルールを構築するための子要素を持つグループ要素。
 * @returns {Object[]} グループの子要素に基づいて構築されたルールオブジェクトの配列。
 *
 * @description
 * この関数は、指定されたグループ要素の子要素を走査し、各子要素に対してルールオブジェクトを構築します。
 * このプロセスでは、以下のステップが含まれます：
 * 1. グループ要素の子ノードを取得します。
 * 2. 子ノードのリストを走査し、ノードタイプがElementのものだけを処理します（nodeType === 1）。
 * 3. 各子要素に対して、`buildRuleObject` 関数を使用してルールオブジェクトを構築します。
 * 4. 構築されたルールオブジェクトを配列に追加します。
 * 5. 最終的に、構築されたルールオブジェクトの配列を返します。
 *
 * 注：この関数は、`<group>`要素の直接の子要素のみを処理します。子要素がさらにグループを持っている場合、
 * そのグループの処理は`buildRuleObject` 関数によって行われます（その関数が`buildGroupRule`を再帰的に呼び出す場合があります）。
 */
const buildGroupRule = (group) => {
// MEMO: <group>の子要素を列挙
    const groupChildren = group.childNodes;
    const arrGroupRule = []
    let fragmentIndex = 0; // インデックス用のカウンタを初期化
    for (const groupChild of groupChildren) {
        // MEMO: ノードタイプがElementノードであることを確認 (nodeType === 1)
        if (groupChild.nodeType !== 1) {
            continue;
        }
        const ruleObject = buildRuleObject(groupChild, fragmentIndex)
        arrGroupRule.push(ruleObject)
        fragmentIndex++
    }
    return arrGroupRule
}

/**
 * 特定の条件に基づいて要素の配列をフィルタリングし、必要に応じて新しい配列を返す関数
 *
 * @param {Array} elements - フィルタリングする元の配列. 各要素はオブジェクトで, `nodeType` と `text` プロパティを持っている必要がある
 *
 * @returns {Array} - 以下の条件に基づいて変更された配列
 *                     1. フィルタリング後、全ての要素の `nodeType` が ’text’ または 'space' の場合、空の配列を返す
 *                     2. 元の配列から、`nodeType` が 'template' で `text` が空の要素を除外した配列を返す
 */
const filterReplacedFragments = (elements) => {
    // 条件2に基づいてフィルタリング
    const filteredElements = elements.filter(element => !(element.nodeType === 'template' && element.text === ''));

    // フィルタリング後の配列に対して条件1をチェック
    const allAreTextOrSpaceAfterFiltering = filteredElements.every(element => element.nodeType === 'text' || element.nodeType === 'space');

    // 条件1に一致する場合、空の配列を返す
    if (allAreTextOrSpaceAfterFiltering) {
        return [];
    }

    // 上記条件に一致しない場合、フィルタリング後の配列を返す
    return filteredElements;
}

export default {
    name: "SenderText",
    emits: ['selectNodeToEdit'],
    props: {
        sender_item: {
            type: Object,
        },
        ordering_rule_template: {
            type: String,
            default: '',
        },
        moveableTarget: {
            type: String,
        },
    },
    components: {MoveableTextBox},
    methods: {
        /**
         * MEMO: MoveableTextBoxから呼び出される際には引数が無いため、NULL OKにする
         * */
        selectNodeToEdit(st = null) {
            if (!st) {
                st = this.sender_item;
            }
            this.$emit('selectNodeToEdit', st);
        },
        /**
         * フラグメントルールを元にtextObjectを作ります
         * TODO: injectSender2Template等がmethodで実装されているため、外に出せない
         * @param {Object} fragmentRule - フラグメントルール。
         * @param {number} fragmentNo - フラグメント番号。
         * @param {Object} lineRule - ラインルール。
         * @param {number} lineNo - ライン番号。
         * @returns {Array|Object} 生成されたフラグメントを返す。
         */
        buildReplacedFragment(fragmentRule, fragmentNo, lineRule, lineNo) {
            const baseFragment = buildBaseFragment(this.sender_item.id, fragmentRule, lineRule, lineNo)

            // MEMO: XMLの<text>と<space>
            if (fragmentRule.nodeType === 'text' || fragmentRule.nodeType === 'space') {
                return buildTextObject(baseFragment, baseFragment.text, this.sender_item.font, fragmentNo, fragmentRule)
            }

            // MEMO: XMLの<template>
            const regex = /^primary_name_(?<nameType>.+)$/
            const matches = fragmentRule.template.match(regex)

            // MEMO: 異体字が入る正規表現にマッチした場合は以下で要素を作成する
            if (matches) {
                // プライマリー名[primary_name_*]の本体部分
                const targetExtCharArray = this.extCharacters[matches.groups.nameType];

                const arrNames = targetExtCharArray.map((ext, index) => {
                    const externalCharacterTextObject = externalCharacterToTextObject(baseFragment, ext, this.sender_item.font, index + 1, fragmentNo, fragmentRule);
                    return externalCharacterTextObject
                });
                return arrNames;
            }

            // -----------------------------
            // MEMO: templateあり（通常の文字)
            // -----------------------------
            const text = injectSenderToTemplate(this.sender_item, fragmentRule.template)
            return buildTextObject(baseFragment, text, this.sender_item.font, fragmentNo, fragmentRule)
        },
    },
    computed: {
        no_input_value() {
            if (!this.sender_item) {
                return true
            }
            return !this.sender_item.address
                && !this.sender_item.building_name
                && !this.sender_item.corporate_name1
                && !this.sender_item.corporate_name2
                && !this.sender_item.department_name1
                && !this.sender_item.department_name2
                && !this.sender_item.mei
                && !this.sender_item.sei
                && !this.sender_item.old_name_or_age
                && !this.sender_item.position_name
                && !this.sender_item.post_no
                && !this.sender_item.pref_name
                && !this.sender_item.tel
                && (!this.sender_item.joint_names || !this.sender_item.joint_names.length)
        },
        /**
         * 選択されている配置順のルールを読み込んでパースして行ごとのオブジェクトに変換します
         * */
        parseSenderArrangementRuleByLine() {
            // MEMO: 配置順を読み込んでパースします
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(this.ordering_rule_template, "text/xml");
            const rows = xmlDoc.getElementsByTagName("row");

            // MEMO: rowsはHTMLCollectionなため、indexが取得できないので手動管理する
            let rowIndex = 0;
            // MEMO: 全行のルール
            const allRowRuleObjects = []
            // MEMO: 配置順を各行ごとに変換します
            for (const row of rows) {
                const rowRuleObjects = []   // 各行のルール
                const rowChildren = row.childNodes
                let fragmentIndex = 0;
                // MEMO: 行の各要素を取得
                for (const rowChild of rowChildren) {
                    // MEMO: ノードタイプがElementノードであることを確認 (nodeType === 1)
                    if (rowChild.nodeType !== 1) {
                        continue;
                    }
                    if (rowChild.tagName === 'group') {
                        // MEMO: <group>用のObjectを生成
                        rowRuleObjects.push(buildGroupRule(rowChild))
                        continue;
                    }
                    // MEMO: <group>以外のObjectを生成
                    rowRuleObjects.push(buildRuleObject(rowChild, fragmentIndex))
                    fragmentIndex++
                }

                // MEMO: 配置順の各行の中の最大文字サイズを取得
                const max_size_in_this_line = rowRuleObjects.flat().reduce((max, item) => {
                    const size = parseInt(item.size, 10);
                    return size > max ? size : max;
                }, 0);

                allRowRuleObjects.push({
                    line_id: `_line${rowIndex}`,
                    fragments: rowRuleObjects,
                    size: max_size_in_this_line,
                })
                rowIndex++
            }
            return {lines: allRowRuleObjects}
        },
        senderTextLines() {
            const textLineObjects = []
            if (!this.sender_item) {
                return textLineObjects
            }

            const is_vertical = this.sender_item.writingMode === VerticalWritingMode
            const lineSpacingValues = []
            for (const [lineNo, lineRule] of this.parseSenderArrangementRuleByLine.lines.entries()) {
                const lineReplacedFragments = []
                for (const arrFragmentRule of lineRule.fragments) {
                    const replacedFragments = []
                    // MEMO: ここでルールが配列か調べる必要がある<group>で括られていないルールもあるため
                    if (!arrFragmentRule.length) {
                        const textObject = this.buildReplacedFragment(
                            arrFragmentRule,
                            0,  // arrFragmentRuleが配列じゃないので0固定
                            lineRule,
                            lineNo)

                        replacedFragments.push(textObject)
                    }
                    // MEMO: ルールが配列の場合（<group>で括られている）
                    else {
                        for (const [fragmentNo, fragmentRule] of arrFragmentRule.entries()) {
                            // MEMO: <group>がネストしている場合、子グループをルールに応じて処理する
                            if (fragmentRule.length) {
                                const childReplacedFragments = []
                                for (const [childFragmentNo, childFragmentRule] of fragmentRule.entries()) {
                                    const textObject = this.buildReplacedFragment(
                                        childFragmentRule,
                                        childFragmentNo,  // arrFragmentRuleが配列じゃないので0固定
                                        lineRule,
                                        lineNo)
                                    childReplacedFragments.push(textObject)
                                }

                                // MEMO: 不要な要素を消して、残った要素のみ追加する
                                const filteredReplacedFragments = filterReplacedFragments(childReplacedFragments)

                                if (filteredReplacedFragments.length > 0) {
                                    replacedFragments.push(filteredReplacedFragments)
                                }
                                continue
                            }

                            // MEMO: <group>内の要素を追加
                            const textObject = this.buildReplacedFragment(
                                fragmentRule,
                                fragmentNo,
                                lineRule,
                                lineNo)

                            replacedFragments.push(textObject)
                        }
                    }

                    // MEMO: 不要な要素を消して、残った要素のみ行の要素として追加する
                    const filteredReplacedFragments = filterReplacedFragments(replacedFragments).flat()

                    // MEMO: 差出人の姓、名、旧姓・年齢は一文字ずつ変換するため、平坦化した後に要素がない場合は追加しない
                    if (filteredReplacedFragments.length > 0) {
                        lineReplacedFragments.push(filteredReplacedFragments)
                    }
                }

                // MEMO: 要素のない行＝空行は追加しない
                if (lineReplacedFragments.length === 0) {
                    continue
                }
                // MEMO: グループごとに配列になっているためflat()を使用し平坦化して行にする
                const lineObject = lineReplacedFragments.flat()
                let lastElement = lineObject.slice(-1)[0]; // Get the last element without mutating the array

                // MEMO: 出来上がった行データの末尾のnodeTypeが「space」の場合、末尾の要素を削除する
                if (lastElement.nodeType === "space") {
                    lineObject.pop();
                }

                const yOffsetAmpRate = lineSpacingValues.length === 0 ? 1.0 : 1.8
                lineSpacingValues.push(lineRule.size * yOffsetAmpRate)
                const sumLineSpacing = lineSpacingValues.reduce((p, c) => p + c, 0)
                textLineObjects.push(
                    {
                        line_id: lineRule.line_id,
                        contents: lineObject,
                        size: lineRule.size,
                        x: is_vertical ? sumLineSpacing * (-1) : parseInt(this.sender_item.x),
                        y: is_vertical ? parseInt(this.sender_item.y) : sumLineSpacing,
                    }
                )
            }
            return textLineObjects
        },
        extCharacters() {
            const exSei = this.sender_item.display_sender_name_parts.filter((chr) => chr.name_type === Enum.NameType.SEI.val);
            const exMei = this.sender_item.display_sender_name_parts.filter((chr) => chr.name_type === Enum.NameType.MEI.val);
            const exOldName = this.sender_item.display_sender_name_parts.filter((chr) => chr.name_type === Enum.NameType.OLD_NAME.val);

            return {
                sei: exSei,
                mei: exMei,
                old_name_or_age: exOldName,
            }
        }
        ,
    },
}
</script>

<style scoped>

</style>
