window._ = require('lodash');

try {
    require('bootstrap');
} catch (e) {
    // MEMO: Eslint  Empty block statement対策
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.cookies = require('vue3-cookies');
window.axios = require('axios');

const {cookies} = window.cookies.useCookies();
window.axios.interceptors.request.use(config => {
    //  withCredentials: true
    config.withCredentials = true;
    config.headers = {
        'X-Api-Key': process.env.MIX_API_KEY,
        'X-Requested-With': 'XMLHttpRequest',
    }

    // MEMO: Bearerが保存されている場合、付与する
    if (cookies.isKey(process.env.MIX_PIARY_SSO_COOKIE_KEY)) {
        const cookieData = cookies.get(process.env.MIX_PIARY_SSO_COOKIE_KEY)
        config.headers['Authorization'] = 'Bearer ' + cookieData.access_token;
    }
    return config
});

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
