import * as Yup from "yup";
import * as Enum from "../const/const";
import {addressRegex, companyDepartmentPositionNameRegex, kanaRegex, nameRegex, numberRegex} from './regex_pattern'

/**
 * 宛先登録フォームのバリデーションルール
 * */
export const AddressValidationRule = Yup.object({
    corporate_name1: Yup.string()
        .when("guest_type", {
            is: Enum.GuestType.CORPORATION.val,
            then: () => Yup.string()
                .nullable()
                .transform((value, org) => org === '' ? null : org)
                .matches(companyDepartmentPositionNameRegex, {message: '使用できない文字が含まれています'})
                .required('法人用の場合、必須項目です')
                .max(255, '255文字まで入力可能です')
        }).nullable(),
    corporate_name2: Yup.string()
        .when("guest_type", {
            is: Enum.GuestType.CORPORATION.val,
            then: () => Yup.string()
                .nullable()
                .transform((value, org) => org === '' ? null : org)
                .matches(companyDepartmentPositionNameRegex, {message: '使用できない文字が含まれています'})
                .max(255, '255文字まで入力可能です').nullable()
        }).nullable(),
    department_name1: Yup.string()
        .when("guest_type", {
            is: Enum.GuestType.CORPORATION.val,
            then: () => Yup.string()
                .nullable()
                .transform((value, org) => org === '' ? null : org)
                .matches(companyDepartmentPositionNameRegex, {message: '使用できない文字が含まれています'})
                .max(255, '255文字まで入力可能です').nullable()
        }).nullable(),
    department_name2: Yup.string()
        .when("guest_type", {
            is: Enum.GuestType.CORPORATION.val,
            then: () => Yup.string()
                .nullable()
                .transform((value, org) => org === '' ? null : org)
                .matches(companyDepartmentPositionNameRegex, {message: '使用できない文字が含まれています'})
                .max(255, '255文字まで入力可能です').nullable()
        }).nullable(),
    position_name: Yup.string()
        .when("guest_type", {
            is: Enum.GuestType.CORPORATION.val,
            then: () => Yup.string()
                .nullable()
                .transform((value, org) => org === '' ? null : org)
                .matches(companyDepartmentPositionNameRegex, {message: '使用できない文字が含まれています'})
                .max(255, '255文字まで入力可能です')
        }).nullable(),
    sei: Yup.string()
        .matches(nameRegex, {message: '使用できない文字が含まれています'})
        .max(20, '20文字まで入力可能です')
        .required('必須項目です。(姓)')
        .nullable(),
    mei: Yup.string()
        .matches(nameRegex, {message: '使用できない文字が含まれています'})
        .required('必須項目です。(名)')
        .max(20, '20文字まで入力可能です')
        .nullable(),
    sei_kana: Yup.string()
        .transform((value, org) => org === '' ? null : org)
        .matches(kanaRegex, {message: 'カタカナで入力してください'})
        .max(20, '20文字まで入力可能です')
        .nullable(),
    mei_kana: Yup.string()
        .transform((value, org) => org === '' ? null : org)
        .matches(kanaRegex, {message: 'カタカナで入力してください'})
        .max(20, '20文字まで入力可能です').nullable(),
    honorifics: Yup.string()
        .transform((curr, orig) => orig === '' ? null : curr)
        .matches(nameRegex, {message: '使用できない文字が含まれています'})
        .max(10, '10文字まで入力可能です').nullable(),
    pref_name: Yup.string()
        .transform((value, org) => org === '未選択' ? null : org)
        .required('必須項目です。'),
    post_no: Yup.string()
        .matches(numberRegex, {message: '半角数字で入力してください'})
        .required('必須項目です。')
        .min(7, '7桁で入力してください。').max(7, '7桁で入力してください。')
        .nullable(),
    address: Yup.string()
        .matches(addressRegex, {message: '使用できない文字が含まれています'})
        .required('必須項目です')
        .max(20, '20文字まで'),
    building_name: Yup.string()
        .transform((curr, orig) => orig === '' ? null : curr)
        .matches(addressRegex, {message: '使用できない文字が含まれています'})
        .max(24, '24文字まで入力可能です')
        .nullable(),
    sender_id: Yup.string().nullable(),
    joint_names: Yup.array().of(
        Yup.object().shape({
            sei: Yup.string()
                .transform((value, org) => org === '' ? null : org)
                .test('sei', '使用できない文字が含まれています', (value) => {
                    return nameRegex.test(value);
                })
                .test('sei', '姓・名いずれかを入力してください', (value, ctx) => {
                    if (ctx.parent.honorifics === null && ctx.parent.mei === null && value === null) {
                        return true;
                    }

                    if (ctx.parent.honorifics !== null && ctx.parent.mei === null && value === null) {
                        return false;
                    }

                    return !(ctx.parent.position_name !== null && ctx.parent.mei === null && value === null);
                })
                .max(20, '20文字まで入力可能です')
                .nullable(),
            mei: Yup.string()
                .transform((value, org) => org === '' ? null : org)
                .test('mei', '使用できない文字が含まれています', (value) => {
                    return nameRegex.test(value);
                })
                .test('mei', '姓・名いずれかを入力してください', (value, ctx) => {
                    if (ctx.parent.honorifics === null && ctx.parent.sei === null && value === null) {
                        return true;
                    }
                    if (ctx.parent.honorifics !== null && ctx.parent.sei === null && value === null) {
                        return false;
                    }
                    return !(ctx.parent.position_name !== null && ctx.parent.sei === null && value === null);
                })
                .max(20, '20文字まで入力可能です')
                .nullable(),
            honorifics: Yup.string()
                .transform((curr, orig) => orig === '' ? null : curr)
                .matches(nameRegex, {message: '使用できない文字が含まれています'})
                .max(10, '10文字まで入力可能です')
                .nullable(),
            position_name: Yup.string()
                .transform((curr, orig) => orig === '' ? null : curr)
                .matches(nameRegex, {message: '使用できない文字が含まれています'})
                .max(20, '20文字まで入力可能です')
                .nullable(),
        }, ['sei', 'mei'])
    )
});