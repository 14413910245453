<!--
    MEMO: 宛名印刷サービスを利用しますか？
-->
<template>
    <div class="p-purchase__flow">
        <section class="p-purchase__box">
            <div class="w-full bg-white">
                <div class="grid grid-cols-3 items-center px-2 py-4">
                    <div class="block text-sm">
                        <button @click="returnScreenButton()"
                                class="js-closeNameAndPrintPanel">
                            ＜戻る
                        </button>
                    </div>
                    <img src="/images/icon/header/ic_header-logo.svg" alt="my family"
                         class="l-header__logo is-purchase mx-auto">
                </div>
            </div>
            <div class="block text-xl font-bold text-center mt-8">
                <span>宛名印刷サービスを利用しますか？</span>
            </div>
            <div class="mt-8 px-4 sm:px-0 font-bold">
                <div class="bg-white px-4 py-4 rounded-20 sm:max-w-lg sm:w-full sm:ml-auto sm:mr-auto">
                    <div class="flex items-center">
                        <label for="not_print" class="p-purchase__input p-2">
                            <input
                                id="not_print"
                                class="p-purchase__input__init"
                                type="radio"
                                name="print_service"
                                :value="refEnum.UsePrintService.NO"
                                checked
                                v-model="print_service"
                            >
                            <span class="p-purchase__input__check"></span>
                            <span class="p-purchase__input__text">宛名の印刷をしない</span>
                        </label>
                    </div>
                    <div class="flex items-center">
                        <label for="print" class="p-purchase__input p-2">
                            <input
                                id="print"
                                class="p-purchase__input__init"
                                type="radio"
                                name="print_service"
                                :value="refEnum.UsePrintService.YES"
                                v-model="print_service"
                                @change.once="initFontPreviewImage"
                            >
                            <span class="p-purchase__input__check"></span>
                            <span class="p-purchase__input__text">宛名の印刷をする※印刷代金無料</span>
                        </label>
                    </div>
                    <!-- 2022年運用時はサービス開始時だったので余裕を持たせて、プラス1営業日（社内では発生しない営業日）とうたっていた。必要ないためコメントアウト -->
                    <!-- 
                    <div v-if="print_service === refEnum.UsePrintService.YES" class="pt-2 font-thin text-red text-xs">
                        <span>宛名印刷サービスをご利用の場合は+1営業日お時間をいただきます。</span>
                    </div>
                    -->
                </div>
            </div>

            <div v-if="print_service === refEnum.UsePrintService.YES" class="p-purchase__box--color">
                <div class="mt-8 px-4 font-bold">
                    <div class="bg-white px-4 py-4 rounded-20 sm:max-w-lg sm:w-full sm:ml-auto sm:mr-auto">
                        <p class="text-center">宛名面の書体を選択してください</p>
                        <div class="grid grid-cols-2 pt-4">
                            <div class="flex items-center" v-for="(font, index) in refEnum.FONT" :key="font.id">
                                <label :for="font.css_class_name" class="p-purchase__input p-2"
                                       :class="'is-' + font.css_class_name">
                                    <input
                                        :id="font.css_class_name"
                                        class="p-purchase__input__init"
                                        type="radio"
                                        name="print_style"
                                        :value="font.id"
                                        v-model="print_style">
                                    <span class="p-purchase__input__check"></span>
                                    <span class="p-purchase__input__text">{{ font.font_name }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-6">
                    <div v-for="(font, index) in refEnum.FONT" :key="font.id">
                        <div v-if="print_style === font.id">
                            <img :src="'/images/card/kyotsu_postcard-' + font.css_class_name + '.jpg'"
                                 :alt="font.font_name" class="ml-auto mr-auto p-purchase__typeface--img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-purchase__position__button sm:relative w-full">
                <div class="bg-white py-2.5 sm:py-4 px-12">
                    <div class="w-full sm:max-w-sm block bg-blue02 rounded text-center sm:ml-auto sm:mr-auto">
                        <button class="w-full text-white font-bold py-2" @click="next()">次へ</button>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!--差出人情報の有無を判定の条件にする。なければ条件に入らず次の画面へ-->
    <PurchaseFlowSender
        v-if="noInputSenderInfo"
        @prevScreen="prevScreen"
        @noInputSenderAsItNext="noInputSenderAsItNext"
    />

    <PurchaseFlowQuantity
        v-if="isShowOrderQuantity"
        @backServicePanel="backServicePanel"
    />
</template>

<script>
import PurchaseFlowQuantity from "./PurchaseFlowQuantity.vue";
import PurchaseFlowSender from "./PurchaseFlowSender.vue";
import * as Enum from "../../const/const";
import {inject} from "vue";

export default {
    name: "PurchaseFlowService",
    components: {PurchaseFlowQuantity, PurchaseFlowSender},
    setup() {
        const makingItemInfo = inject('makingItemInfo');
        return {
            makingItemInfo
        }
    },
    data() {
        return {
            print_service: this.makingItemInfo.print_service, //宛名印刷するorしないの初期値
            print_style: this.makingItemInfo.print_style, // 宛名印刷するをチェックした時の書体のデフォルト値
            isShowOrderQuantity: false,
            windowWidth: '',
            noInputSenderInfo: false,
        }
    },

    emits: ['purchaseFlowOfNamePrint'],
    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述

        /**
         * 10/14：鳥居
         * 一時的に楷書体のみ表示する
         * TODO：二次リリースの段階では、複数選択が可能になる予定なので、このソースは破棄する
         */
        tmpFont() {
            return Enum.FONT.KAISHO;
        },
    },
    mounted() {
    },

    methods: {
        returnScreenButton() {
            if (this.windowWidth > 640) {
                setTimeout(() => {
                    document.querySelector('#designContent').classList.add('z-1');
                    document.querySelector('.js-headerOverlap').classList.add('z-1');
                }, 300);
            }
            this.$emit("purchaseFlowOfNamePrint", false);
        },

        checkAddressPrintService(bool) {
            this.makingItemInfo.print_style = this.print_style;     // 宛名面の書体
            this.makingItemInfo.print_service = this.print_service; // 宛名印刷をする/しない
            if (this.makingItemInfo.purchase_address_items)
                this.$emit("purchaseFlowOfNamePrint", true);
            this.isShowOrderQuantity = bool;
        },

        next() {
            if (this.print_service === Enum.UsePrintService.NO) {
                this.noInputSenderInfo = false;
            } else {
                this.noInputSenderInfo = true;

                return;
            }

            this.checkAddressPrintService(true);
        },

        /**
         * 差出人の登録がされていない時の条件
         */
        noInputSenderAsItNext(bool) {
            this.checkAddressPrintService(bool);
        },

        /**
         * 差出人登録をするかしないかのパネル
         * @param {Boolean} prev
         */
        prevScreen(prev) {
            this.noInputSenderInfo = prev;
        },

        /**
         * デザイン画面に戻る
         * @param {Boolean} back
         */
        backServicePanel(back) {
            this.isShowOrderQuantity = back;
        },

        /**
         * 9/28 鳥居
         * 「宛名印刷をする」にチェックを入れた時に初期表示でプレビュー画像を表示されるように変更しました。
         */
        initFontPreviewImage() {
            this.print_style = Enum.FONT.KAISHO.id;
        },
    },

    created() {
        this.windowWidth = window.innerWidth;
    }
};
</script>

<style scoped>

</style>
