<template>
    <div class="p-edit__slide--full sm:pt-8">
        <ul class="grid grid-cols-3 gap-3 sm:hidden pl-4 pr-4">
            <li class="mt-4 font-bold text-blue02 text-left" @click="$emit('close')">閉じる</li>
            <!-- <li class="mt-2"><img src="/images/icon/edit/rectangle.svg" class="mx-auto"></li> -->
            <!-- <li class="mt-4 font-bold text-blue02 text-right">決定</li> -->
        </ul>
        <!--SP-->
        <div class="relative sm:top-0 p-edit__sideMenu">
            <ul class="grid grid-cols-3 text-center text-sm sm:text-base font-bold pt-6 sm:pt-0 sm:pb-6 px-2 sm:border-b sm:border-gray03">
                <li class="flex justify-center items-center">
                    <button class="c-styleTabMenu__button"
                            @click="isSelect('color')"
                            :class="{'is-cont-active': isContChange === 'color'}">カラーの変更
                    </button>
                </li>
                <li class="flex justify-center items-center">
                    <button class="c-styleTabMenu__button"
                            @click="isSelect('image')"
                            :class="{'is-cont-active': isContChange === 'image'}">画像の枚数
                    </button>
                </li>
                <li class="flex justify-center items-center">
                    <button class="c-styleTabMenu__button"
                            @click="isSelect('title')"
                            :class="{'is-cont-active': isContChange === 'title'}">タイトル
                    </button>
                </li>
            </ul>
            <!--SPここまで-->
            <div class="mt-4 overflow-y-auto overflow-x-hidden h-full u-scrollbar__none">
                <div v-if="isContChange === 'color'"
                     class="grid grid-cols-2 gap-x-4 gap-y-6 px-4 text-xs text-center font-bold"
                >
                    <label class="mt-2 mr-6 sm:mr-0 w-full inline-block sm:block py-18 cursor-pointer"
                           v-for="(colorItem, key) in availableBackground" :key="key"
                           :class="[{ active: isColorActive === colorItem.id }]"
                    >
                        <div class="p-edit__color__check"
                             :class="[{ active: isColorActive === colorItem.id }]"
                        >
                            <img :src="getThumbnail(colorItem.code)" alt=""
                                 class="cursor-pointer"
                                 :class="[{ active: isColorActive === colorItem.id }]"
                            >
                        </div>
                        <input type="radio"
                               @change="selectBackground(colorItem)"
                               :value="colorItem.id" v-model="isColorActive" class="hidden">
                        <p class="pt-3 text-xs">{{ colorItem.name }}</p>
                    </label>
                </div>

                <div v-if="isContChange === 'image'"
                     class="grid grid-cols-2 gap-x-4 gap-y-6 px-4 text-xs text-center font-bold"
                >
                    <label
                        class="mt-2 mr-6 sm:mr-0 w-full inline-block sm:block py-18 cursor-pointer"
                        v-for="(imageItem, key) in filteredLayouts" :key="key"
                        :class="[{ active: isImageActive === imageItem.id }, '']"
                    >
                        <div class="p-edit__color__check shadow-sm"
                             :class="[{ active: isImageActive === imageItem.id }]">
                            <img :src="getThumbnailForPhotoNumber(imageItem.photo_number)" alt=""
                                 class="cursor-pointer"
                                 :class="[{ active: isImageActive === imageItem.id }]"
                            >
                        </div>
                        <input type="radio"
                               @change="selectLayout(imageItem)"
                               :value="imageItem.id" v-model="isImageActive" class="hidden">
                        <p class="pt-3">{{ imageItem.name }}</p>
                    </label>
                </div>

                <div v-if="isContChange === 'title'"
                     class="px-4 text-xs text-center font-bold"
                >
                    <label
                        class="mt-2 mr-2 sm:mr-0 last:sm:mb-8 w-full inline-block sm:block border-2 border-black py-4 rounded-5px cursor-pointer c-button__switch__color"
                        v-for="(titleItem, key) in filteredTitles" :key="key"
                        :class="[{ active: isTitleActive === titleItem.id }, '']"
                    >
                        <input type="radio"
                               @change="setTitle(titleItem)"
                               :value="titleItem.id" v-model="isTitleActive" class="hidden">
                        <p class="px-4">{{ titleItem.name }}</p>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {inject, ref, watchEffect} from "vue";
export default {
    name: "SelectCardStyle",
    setup() {
        const availableResources = inject('availableResources')
        const availableTitles = availableResources.availableTitles
        const availableLayouts = availableResources.availableLayouts
        const availableBackground = availableResources.availableBackground

        const makingItem = inject('makingItemInfo')

        const setTitle = (title) => {
            makingItem.setTitle(title)
        }
        const setLayout = (layout) => {
            makingItem.setLayout(layout)
        }
        const setBackground = (bg) => {
            makingItem.setBackground(bg)
        }
        const isColorActive = ref(0)
        const isImageActive = ref(0)
        const isTitleActive = ref(0)

        watchEffect(() => {
            if (typeof (makingItem.selectedBackground.id) === 'number'
                && makingItem.selectedBackground.id !== isColorActive.value) {
                isColorActive.value = makingItem.selectedBackground.id
            }
            if (typeof (makingItem.selectedLayout.id) === 'number'
                && makingItem.selectedLayout.id !== isImageActive.value) {
                isImageActive.value = makingItem.selectedLayout.id
            }
            if (typeof (makingItem.selectedTitle.id) === 'number'
                && makingItem.selectedTitle.id !== isTitleActive.value) {
                isTitleActive.value = makingItem.selectedTitle.id
            }
        })

        return {
            availableTitles,
            availableLayouts,
            availableBackground,
            availableResources,
            makingItem,
            isColorActive,
            isImageActive,
            isTitleActive,
            setTitle,
            setLayout,
            setBackground,
        };
    },
    data() {
        return {
            /**
             * カラー/枚数/タイトルのタブ切り替え
             * 初期表示をカラーの変更で固定
             */
            isContChange: 'color',
            thumbnail_path: '',
        }
    },
    methods: {
        /**
         * カラーの変更/画像の枚数/タイトルのクリック
         * @param {string} menu
         */
        async isSelect(menu) {
            this.isContChange = menu;
        },
        selectBackground(bg) {
            this.setBackground(bg)

            // MEMO: 選択された背景（dtb_design_background）に設定されているdefault_font_color_greeting_textを配置済の挨拶文に反映させる
            for(const [index, userText] of this.makingItem.userTexts.entries()) {
                userText.color = bg.default_font_color_greeting_text;
            }

            // MEMO: 選択された背景（dtb_design_background）に設定されているdefault_font_color_senderを配置済の差出人に反映させる
            this.makingItem.senderItem.color = bg.default_font_color_sender;

            this.refreshSelectedTitle()
        },
        selectLayout(layout) {
            this.setLayout(layout)
            this.refreshSelectedTitle()

            // 配置済みの画像をリセット
            this.makingItem.deployedImages = this.makingItem.deployedImages.filter(
                (img) => img.type !== "userImage")

            // 2022/11/23 鳥居：画像の配置、削除をレイアウトの画像枚数が切り替わった時に初期化する
            const selectedDeployImages = this.availableResources.userImages.filter((item) => item.deployed === true);
            for (const i of selectedDeployImages) {
                i.deployed = false;
            }
        },
        refreshSelectedTitle() {
            const selectedTitleCode = this.makingItem.selectedTitle.code
            // MEMO: 選択した背景と選択した画像の枚数が一致するタイトルデータを取得
            this.setTitle(this.filteredTitles.find(title => title.code === selectedTitleCode))
        },
        refreshSelectedLayout() {
            if (this.filteredLayouts.length === 0) {
                return
            }
            // MEMO: レイアウトの一覧データから部材コードと写真の枚数に一致するレイアウトを取得
            const target_layout = this.filteredLayouts.find(layout =>
                layout.material_pattern === this.selectedMaterialPattern &&
                layout.photo_number === this.makingItem.selectedLayout.photo_number
            )
            // MEMO: レイアウトが一致した場合、レイアウト選択処理へ
            if (target_layout) {
                this.selectLayout(target_layout)
            }
        },
        getThumbnail(code) {
            let arrPath = this.makingItem.selectedLayout.layout_image_thumbnail_file.split('/');
            const file_name = arrPath[arrPath.length - 1];
            const arrFileName = file_name.split('.');
            const base_file_name = arrFileName[0];
            const ext = arrFileName[arrFileName.length - 1];
            const photo_number = this.makingItem.selectedLayout.photo_number;
            arrPath[arrPath.length - 1] = base_file_name + '_photo' + photo_number + '_' + code + '.' + ext;
            return arrPath.join('/');
        },
        getThumbnailForPhotoNumber(photo_number) {
            let arrPath = this.makingItem.selectedLayout.layout_image_thumbnail_file.split('/');
            const file_name = arrPath[arrPath.length - 1];
            const arrFileName = file_name.split('.');
            const base_file_name = arrFileName[0];
            const ext = arrFileName[arrFileName.length - 1];
            const code = this.makingItem.selectedBackground.code;
            arrPath[arrPath.length - 1] = base_file_name + '_photo' + photo_number + '_' + code + '.' + ext;
            return arrPath.join('/');
        }
    },
    computed: {
        selectedMaterialPattern() {
            return this.makingItem.material_pattern_code
        },
        /**
         * 選択されたカラーの変更（背景）と画像の枚数に一致するタイトルをフィルタリングします。
         *
         * @return {Array} フィルタリングされたタイトルの配列
         */
        filteredTitles() {

            // availableTitles 配列をフィルタリング
            return this.availableTitles.filter((v, i) => {
                // 背景コードが一致するかどうかをチェック
                const hasSameBackgroundCode = v.background_code === null || v.background_code === this.makingItem.selectedBackground.code;
                // 写真枚数が一致するかどうかをチェック
                const hasSamePhotoNumber = v.layout_photo_number === null || v.layout_photo_number === this.makingItem.selectedLayout.photo_number;
                // 選択された背景、写真枚数が一致した場合に真を返す
                return hasSameBackgroundCode && hasSamePhotoNumber;
            })
        },
        filteredLayouts() {
            return this.availableLayouts.filter((v, i) => {
                return v.material_pattern === null || v.material_pattern === this.makingItem.material_pattern_code;
            })
        },
    },
    watch: {
        // MEMO: 台紙選択モーダル等で部材コードが変更になった場合
        'makingItem.material_pattern_code': function () {
            // MEMO: レイアウトをリフレッシュする
            this.refreshSelectedLayout()
        },
    },
};
</script>

<style scoped>

</style>
