<template>
    <div>
        <moveable
            :className="targetObjectBorderStyle"
            :target="targetDomId"
            :draggable="true"
            :edgeDraggable="true"
            :scalable="true"
            :keepRatio="true"
            :origin="false"
            :resizable="false"
            @drag="onDrag"
            @scale="onScale">
        </moveable>
        <div>
            <deployed-object-context-menu :style="cmTransform"></deployed-object-context-menu>
        </div>
        <div>
            <set-object-position :style="objTransform" @setObjectPosition="setObjectPosition"></set-object-position>
        </div>
    </div>
</template>

<script>
import Moveable from "vue3-moveable";
import DeployedObjectContextMenu from "./DeployedObjectContextMenu";
import setObjectPosition from "./setObjectPosition.vue";
import {inject, ref} from "vue";
import {EditState} from '../../const/const';

export default {
    name: "MoveableManipulateBox",
    setup() {
        const editStatus = inject('editStatus')
        const makingItem = inject('makingItemInfo')
        return {
            editStatus,
            makingItem
        }
    },
    components: {
        DeployedObjectContextMenu,
        Moveable,
        setObjectPosition,
    },
    props: {
        targetDomId: String,
    },
    data() {
        return {
            contextMenuTransform: ref(''),
            objectPositionTransform: ref(''),
        }
    },
    mounted() {
        this.contextMenuTransform = this.tr();
        this.objectPositionTransform = this.ne();
    },
    computed: {
        cmTransform() {
            return `transform: ${this.contextMenuTransform};`
        },
        objTransform() {
            return `transform: ${this.contextMenuTransform + this.objectPositionTransform};`
        },

        targetObjectBorderStyle() {
            // MEMO: 商品コードがnen0039の場合、背景が赤のため線の色と同じになるため、target_edit_object_nen0039のスタイルを適用する
            let cssClassEditObject =  this.makingItem.design_code === 'nen0039' ? 'target_edit_object_nen0039' : 'target_edit_object';
            return this.editStatus.state === EditState.EDIT_USER_IMAGE ? ['target_image'] : [cssClassEditObject]
        }
    },
    methods: {
        onDrag({transform}) {
            target.style.transform = transform
            this.editStatus.imageToDeploy.transform = transform
            this.contextMenuTransform = this.tr();
            this.objectPositionTransform = this.ne();
        },
        onScale({drag}) {
            target.style.transform = drag.transform;
            this.editStatus.imageToDeploy.transform = drag.transform
            this.contextMenuTransform = this.tr();
            this.objectPositionTransform = this.ne();
        },

        // onResize({width, height}) {
        //     target.style.width = `${width}px`;
        //     target.style.height = `${height}px`;
        // },

        tr() {
            const transform_d = document.querySelector('.moveable-control-box').style.transform;
            return transform_d;
        },

        //オブジェクトの決定ボタンの座標を取得
        ne() {
            const transform_ne = document.querySelector('.moveable-ne').style.transform;
            return transform_ne;
        },

        setObjectPosition() {
            this.$emit('setObjectPosition')
        }
    },
}
</script>

<style>
.rCS1rb3a7w {
    z-index: 2 !important;
}

.moveable-n.moveable-scalable,
.moveable-w.moveable-scalable,
.moveable-sw.moveable-scalable,
.moveable-e.moveable-scalable,
.moveable-s.moveable-scalable,
.moveable-nw.moveable-scalable {
    display: none;
}

.rCS1rb3a7w .moveable-direction[data-rotation="45"] {
    width: 1px !important;
    height: 1px !important;
    opacity: 0 !important;
}

.rCS1rb3a7w .moveable-control {
    width: 20px !important;
    height: 20px !important;
    margin-top: -12px !important;
    margin-left: -12px !important;
}

.target_image .moveable-line {
    background: none !important;
    background-image: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255) 5px, transparent 5px, transparent 5px) !important;
    background-size: 7px 1px !important;
    background-position: 0 0, 0 0, 100% 0, 0 100% !important;
    background-repeat: repeat-x !important;
}

.target_edit_object .moveable-line {
    background: none !important;
    background-image: linear-gradient(to right, rgb(239, 64, 40), rgb(239, 64, 40) 5px, transparent 5px, transparent 5px) !important;
    background-size: 7px 1px !important;
    background-position: 0 0, 0 0, 100% 0, 0 100% !important;
    background-repeat: repeat-x !important;
}

.target_edit_object_nen0039 .moveable-line {
    background: none !important;
    background-image: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255) 5px, transparent 5px, transparent 5px) !important;
    background-size: 7px 1px !important;
    background-position: 0 0, 0 0, 100% 0, 0 100% !important;
    background-repeat: repeat-x !important;
}

.moveable-se.moveable-scalable {
    background-image: url(/images/icon/edit/ic_scale-arrow.svg) !important;
    background-size: 100% 100% !important;
}

</style>
