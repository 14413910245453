<template>
    <div class="p-edit__slide--full sm:pt-8">
        <ul class="grid grid-cols-3 gap-3 sm:hidden pl-4 pr-4">
            <li class="mt-4 font-bold text-blue02 text-left" @click="cancel">キャンセル</li>
            <li class="mt-2 text-center"></li>
            <li class="mt-4 font-bold text-blue02 text-right" @click="store">決定</li>
        </ul>

        <div class="relative sm:top-0 sm:px-4 p-edit__sideMenu--textArea">
            <p class="text-center text-lg font-bold pt-6 sm:pt-0">テキストの編集</p>
            <div class="mt-6 px-4 sm:px-0">
                <textarea name="" id="" cols="30" rows="5"
                          v-model="editing_text_content"
                          class="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm u-scrollbar__none">
                </textarea>
            </div>
            <div class="sm:grid sm:grid-cols-2 sm:gap-x-2 sm:pt-8 hidden">
                <button @click="cancel"
                        class="block sm:w-full max-w-xs sm:max-w-none ml-auto mr-auto py-2 rounded-full sm:text-sm font-bold text-center border-gray03 border">
                    <span>キャンセル</span>
                </button>
                <button @click="store"
                        class="block sm:w-full max-w-xs sm:max-w-none ml-auto mr-auto py-2 rounded-full sm:text-sm font-bold text-center text-white bg-blue02">
                    <span>{{ changeText }}</span>
                </button>

            </div>
            <div class="pt-8 px-4 sm:px-0">
                <p class="text-center text-lg font-bold">文面を例文から選ぶ</p>
                <div class="c-input__selectRightArrow is-editArea">
                    <select @change="isSelectExampleText"
                            class="c-input__selectArea w-full rounded-md mt-6 py-2 px-2 shadow-sm focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 sm:text-sm"
                            v-model="selectedGTEId"
                    >
                        <option v-for="category in greetingCategories" :key="category.id" :value="category.id">
                            {{ category.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="h-full overflow-y-auto u-scrollbar__none mt-8">
                <div class="px-4 sm:px-0">
                    <div v-for="(greeting, index) in greetingList" :key="index"
                         class="py-3 first:border-t border-b border-gray03 hover:bg-orange hover:text-white text-sm">
                        <button @click="selectText(greeting.content)">
                            <span class="block text-left" v-html="create_sample_text_html(greeting.content)"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {inject, ref, watchEffect} from "vue";
import AvailableResources from "../AvailableResources.vue";
import {VerticalWritingMode} from "../../../const/const";

/** テキストで使用できない置換対象の区切り文字の文字コード*/
const separatorsRegex = /[\u2028\u2029\u0085]/g;


export default {
    name: "TextArea",

    data() {
        return {
            activeExampleText: 'exampleText-flower',
            selectedGTEId: 1,
            windowWidth: '',
        }
    },

    setup() {
        const availableResources = inject('availableResources')
        const editStatus = inject('editStatus')
        const makingItem = inject('makingItemInfo')
        const editing_text_content = ref('')
        const is_user_text = () => editStatus.currentTargetTextId !== '' && editStatus.currentTargetTextId !== 'new_text'
        const selected_text = () => makingItem.userTexts.find((text) => text.id === editStatus.currentTargetTextId)
        const set_editing_text_content = () => {
            if (is_user_text() && selected_text()) {
                editing_text_content.value = selected_text().text
                return
            }
            editing_text_content.value = ''
        }
        const isVerticalWritingMode = ref(false)
        const set_writing_mode = () => {
            if (is_user_text() && selected_text()) {
                isVerticalWritingMode.value = selected_text().writingMode === VerticalWritingMode
                return
            }
            if (editStatus.currentTargetTextId === 'new_text') {
                isVerticalWritingMode.value = makingItem.designData.default_writing_mode_greeting_text === VerticalWritingMode
                return
            }
            isVerticalWritingMode.value = false
        }
        const create_sample_text_html = (txt) => {
            return txt.replace(/\n/g, '<br>')
        }

        /**
         * テキスト追加処理
         * */
        const addNewTextObject = () => {
            const design_data = makingItem.designData
            const selectedBackground = makingItem.selectedBackground
            const writingMode = isVerticalWritingMode.value ? 'vertical-rl' : '';
            const textObject = {
                type: 'userText',
                id: 'userText_' + Math.random().toString(32).substring(2),
                text: editing_text_content.value.replace(separatorsRegex, '\n'),
                x: 0,
                y: 0,
                font: design_data.default_font_type_greeting_text,
                size: design_data.default_font_size_greeting_text,
                transform: `translate(${design_data.position_x_greeting_text}px, ${design_data.position_y_greeting_text}px)`,
                anchor: design_data.default_text_align_greeting_text,
                // MEMO: 文字の色は背景に設定されているカラーを使用する
                color: selectedBackground.default_font_color_greeting_text,
                writingMode: writingMode,
            };
            makingItem.userTexts.push(textObject);
        }

        /**
         * 選択されているテキストの更新処理
         * */
        const updateTextObject = () => {
            const writingMode = isVerticalWritingMode.value ? 'vertical-rl' : '';
            const selected_text = makingItem.userTexts.find((text) => text.id === editStatus.currentTargetTextId)
            if (selected_text) {
                selected_text.text = editing_text_content.value.replace(separatorsRegex, '\n')
                selected_text.writingMode = writingMode
                if (selected_text.text === "") {
                    // MEMO: 文字列が未入力の場合は、配置されているオブジェクトを消さないと保存時にエラーになるため削除する
                    makingItem.removeDeployedText(editStatus.currentTargetTextId)
                }
            }
        }
        watchEffect(() => {
            set_editing_text_content()
            set_writing_mode()
        })
        return {
            availableResources,
            editing_text_content,
            isVerticalWritingMode,
            create_sample_text_html,
            editStatus,
            makingItem,
            addNewTextObject,
            updateTextObject,
        }
    },
    created() {
        this.windowWidth = window.innerWidth;
    },
    async mounted() {
        // MEMO: 再読み込み防止の為、挨拶分のサンプルが読み込まれていない場合、取得する
        if (this.availableResources.greetingTextSamples.length === 0) {
            await AvailableResources.loadGreetingTextSamples(this.makingItem.design_code);
        }
        // MEMO: カテゴリを選択状態にする
        this.selectedGTEId = this.loadGreetingTextSamples[0].mtb_greeting_category.id
    },
    methods: {
        isSelectExampleText(select) {
            this.activeExampleText = select.target.value;
        },
        selectText(content) {
            this.editing_text_content = content
        },
        /**
         * 更新する/追加する
         * */
        async store() {
            if (this.editStatus.currentTargetTextId === 'new_text' && this.editing_text_content) {
                this.addNewTextObject();
            } else {
                // MEMO: 更新する場合、赤枠を外す
                this.editStatus.neutralize();
            }
            this.updateTextObject();

            this.cancel();
        },
        cancel() {
            this.editStatus.currentTargetTextId = ''

            /**
             * PCのサイズでは連続してテキストエリアから追加可能になるように
             * ステータスを一度初期化を行い、再度'new_text'を代入してステータスを変更
             * また、コンテキストメニューから「項目を削除する」を実行した場合も同様にステータスの変更をしています。
             */
            if (this.windowWidth > 640) {
                this.editStatus.currentTargetTextId = 'new_text';
            } else {
                this.$emit('close');
            }
        },
    },

    computed: {
        loadGreetingTextSamples() {
            return this.availableResources.greetingTextSamples
        },
        greetingCategories() {
            return this.loadGreetingTextSamples.map(gts => gts.mtb_greeting_category)
        },
        greetingList() {
            if (this.loadGreetingTextSamples.length > 0) {
                for (const gts of this.loadGreetingTextSamples) {
                    if (gts.mtb_greeting_category.id === this.selectedGTEId) {
                        return gts.dtb_greeting_text_sample_array
                    }
                }
            }
            return []
        },
        changeText() {
            const x = {
                new: '追加する',
                update: '更新する',
            };
            if (this.editStatus.currentTargetTextId === 'new_text') {
                return x.new;
            } else {
                return x.update;
            }
        },
    },
}
</script>

<style scoped>

</style>