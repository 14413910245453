/**********************************
 * MEMO: 定数定義用のファイル
 * ■ 使用方法
 * // 使用先でimportする
 * import config from '../const/const'
 *
 * 例）config.PIARY_SSO_COOKIE_KEY で値の参照ができる
 ***********************************/
export default Object.freeze({
    PIARY_SSO_COOKIE_KEY: process.env.MIX_PIARY_SSO_COOKIE_KEY,
    API_BASE_URL: process.env.MIX_API_BASE_URL,

    // 宛名の種類
    GUEST_TYPE: {
        PEOPLE: 1,  // 個人用
        CORPORATION: 2, // 法人用
    },
});

export const GuestType = {
    PEOPLE: {
        val: 1,
        text: '個人用'
    },
    CORPORATION: {
        val: 2,
        text: '法人用'
    }
}

/**
 * データの種類
 * dtb_use_external_character.type
 * */
export const DataType = {
    ADDRESS: {
        val: 0,
        text: '宛名'
    },
    FROM: {
        val: 1,
        text: '差出人'
    }
}

/**
 * 外字タイプ
 * dtb_external_character.gaiji_type
 * */
export const ExternalCharType = {
    KANJI: {
        val: 0,
        text: '漢字'
    },
    FONT: {
        val: 1,
        text: 'フォント'
    }
}

/**
 * 名前タイプ
 * */
export const NameType = {
    SEI: {
        val: 0,
        text: '姓'
    },
    MEI: {
        val: 1,
        text: '名'
    },
    OLD_NAME: {
        val: 2,
        text: '旧姓'
    }
}

/**
 * フォント
 * dtb_fontから、今回のシステムに使用するものを抜粋
 * */
export const FONT = {
    KAISHO: {
        id: 3,
        font_name: '楷書体',
        css_class_name: 'kaisho',
    },
    MINCHO: {
        id: 2,
        font_name: '明朝体',
        css_class_name: 'mincho',
    },
    GOTHIC: {
        id: 1,
        font_name: 'ゴシック体',
        css_class_name: 'gothic',
    },
}

/**
 * デザイン画面の編集の状態
 * @type {{SELECT_FLAME: string, EDIT_OBJECT: string, EDIT_USER_IMAGE: string, NEUTRAL: string}}
 */
export const EditState = {
    NEUTRAL: 'neutral',
    SELECT_FLAME: 'select_flame',
    EDIT_USER_IMAGE: 'edit_user_image',
    EDIT_OBJECT: 'edit_object',
}

/**
 * プレビュー画像のデフォルトの大きさ(ピクセル)
 * @type {{WIDTH: number, HEIGHT: number}}
 */
export const PreviewScale = {
    WIDTH: 294.8,
    HEIGHT: 428.03,
}

export const UsePrintService = {
    NO: 0,
    YES: 1,
}

export const OtherService = {
    PIAPRI: {
        id: 0,
        name: 'myピアプリ',
    },
    HIKITAKU: {
        id: 1,
        name: 'myヒキタク',
    },
    WEB_JO: {
        id: 2,
        name: 'myWeb-Jo',
    }
};

// 県名とがが未選択の時の文字列
export const NoSelectedName = '未選択'

// 縦書きのwriting-mode文字列
export const VerticalWritingMode = 'vertical-rl'

// 喪中はがき判定に使用する商品コードの配列
export const MourningProductCodes = ['nen2001', 'nen2002', 'nen2003', 'nen2004'];

export const HouseMovingProductCodes = ['nen3001', 'nen3002', 'nen3003', 'nen3004'];