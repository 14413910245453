<script>
import config from "../../const/const"
import axios from "axios"
import {reactive, ref} from "vue";

/**
 * 画像本体のアップロード(写真を追加)
 * @param {string | Blob} customerId
 * @param {string | Blob} designCode
 * @param {string | Blob} code
 */
const storeImage = async (new_user_image, customerId, designCode, code) => {
    const fd = new FormData()
    const upload_file = document.getElementById('file_image').files[0]
    fd.append('user_image_file', upload_file)
    fd.append('design_code', designCode)
    fd.append('customer_id', customerId)
    fd.append('code', code)
    let messages = [];
    await axios.post(config.API_BASE_URL + '/user_image/store', fd, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    }).then((res) => {
        if (res.data['save_file_name']) {
            new_user_image.image_file = res.data['save_file_name']
            new_user_image.width = res.data['width']
            new_user_image.height = res.data['height']
            new_user_image.stored = true
        }
    }).catch((error) => {
        new_user_image.stored = false;

        if ('errors' in error.response.data) {
            // Formのエラー
            const keys = Object.keys(error.response.data.errors);
            keys.forEach((key) => {
                for (const message of error.response.data.errors[key]) {
                    messages.push(message);
                }
            });
        } else if ('error' in error.response.data) {
            // サーバーサイドの処理エラー
            messages = error.response.data.error.messages;
        }

    })
    return messages;
}

// 保存済みの画像本体のダウンロード
const fetchImage = async (user_image, customerId) => {
    return axios.get(config.API_BASE_URL + '/user_image/fetch', {
        responseType: "blob",
        params: {
            customer_id: customerId,
            file_name: user_image.image_file,
        },
    }).then(async (res) => {
        const rdr = new FileReader()
        const p = new Promise((resolve) => {
            rdr.onload = () => {
                resolve()
            }
            rdr.readAsDataURL(res.data)
        })
        await p
        return rdr.result
    }).catch((res) => {
        return 'LOAD_FAILED'
    })
}

// 画像本体の物理削除
const deleteImage = async (customerId, image_file, code, nengaId = null, force = false) => {
    let params = {
        customer_id: customerId,
        nenga_id: nengaId,
        image_file: image_file,
        // 会員IDがある場合は、コードは送らない
        code: !customerId && code ? code : null,
        force: force,
    };

    return await axios.post(config.API_BASE_URL + '/user_image/delete', params)
        .then((res) => {
            if (!res.data.result) {
                return false
            }
            return res.data
        }).catch((error) => {
            return false;
        });
}

/**
 * アップロード済画像の配置可否チェック
 * */
const isAvailableUserImage = async (image_file) => {
    return await axios.get(config.API_BASE_URL + '/user_image/is_available/' + image_file)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return false;
        });
}

const available_resources = reactive({
    'fetchImage': fetchImage,
    'storeImage': storeImage,
    'deleteImage': deleteImage,
    'isAvailableUserImage': isAvailableUserImage,
    'userImages':  reactive([])
})

const initializeAvailableResources = () => {
    available_resources.availableTitles = []
    available_resources.availableLayouts = []
    available_resources.availableBackground = []
    available_resources.availableAddressOrderingRule = []
    available_resources.greetingTextSamples = []
    available_resources.loaded = ref(false)
}
const initializeCustomerResources = () => {
    available_resources.userSenders = reactive([])
}

export default {
    name: "AvailableResources",
    availableResources: available_resources,
    init: () => {
        initializeAvailableResources()
        initializeCustomerResources()
    },
    initCustomerResources: () => {
        initializeCustomerResources()
    },
    load: async (designCode) => {
        await axios.get(config.API_BASE_URL + '/design_background/available/' + designCode).then((res) => {
            res.data.forEach((d) => available_resources.availableBackground.push(d))
        })
        await axios.get(config.API_BASE_URL + '/design_layout/available/' + designCode).then((res) => {
            res.data.forEach((d) => available_resources.availableLayouts.push(d))
            available_resources.loaded = true;
        })
        await axios.get(config.API_BASE_URL + '/design_title/available/' + designCode).then((res) => {
            res.data.forEach((d) => available_resources.availableTitles.push(d))
        })
    },
    /**
     * 差出人の配置順
     * */
    loadAddressOrderingRule: async() => {
        await axios.get(config.API_BASE_URL + '/sender_display_rule/available')
            .then((res) => {
                res.data.forEach((oar) => available_resources.availableAddressOrderingRule.push(oar))
            })
    },
    loadCustomerResources: async (designCode, customerId) => {
        await axios.get(config.API_BASE_URL + '/user_image', {
            params: {
                customer_id: customerId,
                design_code: designCode,
            },
        }).then((res) => {
            initializeCustomerResources();
            res.data.forEach((d) => {
                // MEMO: 現在の画像リストに無いものだけ追加する
                const exists = available_resources.userImages.some((user_image) => user_image.image_file === d.image_file);
                if(!exists) {
                    available_resources.userImages.push(d)
                }
            })

            // MEMO: 現在の画像リストと、取得した画像リストに一致しない画像は削除されたため画像リストから削除する
            available_resources.userImages.forEach((user_image, index) => {
                user_image.deleted = false;
                const exists = res.data.some((d) => user_image.image_file === d.image_file);
                if (!exists) {
                    user_image.deleted = true;
                }
            })
        })

        // MEMO: 差出人を読み込む機能が先送りのため、コメントアウト
        // await axios.post(config.API_BASE_URL + '/sender_list/search', {
        //     customer_id: customerId,
        //     guest_type: null
        // }).then((res) => {
        //     res.data.forEach((d) => available_resources.userSenders.push(d))
        // }).catch(function (error) {
        //     console.log(error)
        //     console.log('failure');
        // })
    },
    /**
     * テキスト追加メニューの挨拶文のサンプルを取得します
     * */
    loadGreetingTextSamples: async(designCode) => {
        await axios.get(config.API_BASE_URL + '/greeting_text_sample/available/' + designCode).then((res) => {
            res.data.forEach((gte) => {
                available_resources.greetingTextSamples.push(gte)
            })
        })
    }

}
</script>
