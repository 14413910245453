<template>
    <div>
        <div id="paper_select_modal"
             class="c-modal__overlay flex flex-col justify-center items-center px-4 py-8 is-modal-active">
            <div class="w-full max-w-3xl bg-white overflow-y-auto overflow-x-hidden">
                <ul class="grid grid-cols-3 gap-3 sm:flex sm:justify-between px-4">
                    <li class="mt-4 font-bold text-blue02 text-left" >
                        <button v-if="makingItem.material_pattern_code !== null && makingItem.layout_material_pattern_count > 1" type="button" @click="cancel()">キャンセル</button>
                    </li>
                    <li class="mt-2 text-center sm:hidden">
                        <img src="/images/icon/edit/rectangle.svg">
                    </li>
                    <li class="mt-4 font-bold text-blue02 text-right">
                        <button @click="save" :disabled="isSelectedPaper === null" :class="{'disabled_link': isSelectedPaper  === null}">決定</button>
                    </li>
                </ul>
                <div class="block text-center font-bold text-lg pt-6">
                    <span>はがきの種類を選ぶ</span>
                </div>
                <div class="px-3 pt-8 sm:px-8" v-if="displayGuideMessage === 'all' || displayGuideMessage === 'B'">
                    <div class="w-full border border-red text-red font-bold bg-red02 px-2 py-3 leading-6 u-paperSelect__attention">
                        <p class="text-left" v-if="displayGuideMessage === 'all' || displayGuideMessage === 'B'">・官製はがきはデザイン面に白フチが入ります</p>
                    </div>
                </div>
                <div class="px-2 pt-8 sm:px-8">
                    <!--MEMO: 台紙の選択ボタン -->
                    <ul class="grid grid-cols-2 sm:gap-4 text-center font-bold sm:pt-6">
                        <li class="flex justify-center items-center"
                            v-for="(material_pattern, index) in makingItem.material_patterns" :key="index">
                            <button class="c-styleTabMenu__button is-paper-select"
                                    @click="selectPaper(material_pattern.display_name)"
                                    :class="{'is-cont-active': isSelectedPaper === material_pattern.display_name}">{{
                                    material_pattern.display_name
                                }}
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="pt-2 sm:pt-4 pb-12 overflow-y-auto overflow-x-hidden u-scrollbar__none">
                    <div class="grid grid-cols-2 gap-x-4 gap-y-6 px-4 sm:px-8 text-xs text-center font-bold">
                        <label class="mt-2 mr-6 sm:mr-0 w-full inline-block sm:block py-18 cursor-pointer test"
                               v-for="material_pattern in makingItem.material_patterns" :key="material_pattern.index"
                               :class="[{ active: isSelectedPaper === material_pattern.display_name }]"
                               @click="selectPaper(material_pattern.display_name)">
                            <div class="p-edit__color__check"
                                 :class="[{ active: isSelectedPaper === material_pattern.display_name }]">
                                <img :src="material_pattern.sample_file_path" alt=""
                                     class="cursor-pointer m-auto"
                                     :class="[{ active: isSelectedPaper === material_pattern.display_name }]">
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";

export default {
    name: 'PaperSelectModal',
    emits: ['close'],
    setup() {
        const editStatus = inject('editStatus')
        const makingItem = inject('makingItemInfo')
        return {
            editStatus,
            makingItem,
        }
    },

    data() {
        return {
            windowWidth: '',
            isOpenModal: this.isShowModal,
            isSelectedPaper: null,
        }
    },

    mounted() {
        // MEMO:
        //      新規 + 部材が2種類（年賀/官製）登録されている場合、
        //  MakingItemControllerからの戻り値(material_pattern_code)にNULLが設定されているため
        //  台紙選択モーダルを表示する
        //      新規 + 部材が1種類（年賀/官製）のどちらかの場合、
        //  デフォルトのmaterial_pattern_codeが設定されているためそれを使用する。
        //      更新 OR 一度でも台紙モーダルで台紙を選択済の場合、material_pattern_codeに値が入っているのでそれを使用する
        const material_pattern_code = this.makingItem.material_pattern_code;

        if (material_pattern_code) {
            const material_pattern = this.makingItem.material_patterns.find((item) => item.code === material_pattern_code);
            // MEMO: 選択状態にするタブの名称をセットする
            if (material_pattern) {
                this.isSelectedPaper = material_pattern.display_name;
            }
        }
    },

    methods: {
        /**
         * 台紙の選択
         * */
        selectPaper(display_name) {
            if (this.isSelectedPaper === display_name) {
                this.isSelectedPaper = null;
                return;
            }
            // MEMO: 選択された状態を変更して枠に色を付ける
            this.isSelectedPaper = display_name;
        },
        /**
         * 決定
         * */
        save() {
            // MEMO: 保存する際にPOSTする値をMakingItemにセットする
            const material_pattern = this.makingItem.material_patterns.find((item) => item.display_name === this.isSelectedPaper);
            this.makingItem.material_code_for_address = material_pattern.address;
            this.makingItem.material_code_for_design = material_pattern.design;
            this.makingItem.material_pattern_code = material_pattern.code;
            this.makingItem.isPaperSelectModalFirstDisplay = true;      // 台紙選択モーダルを表示済に変更
            this.$emit('close');
            // MEMO: モーダルを閉じる
            this.isOpenModal = false;
            // MEMO: PCの場合、レイアウトメニュー(select_style)を選択状態にする
            this.editStatus.activeSideMenu = this.windowWidth > 640 ? 'select_style' : '';
        },
        /**
         * キャンセル
         * */
        cancel() {
            this.$emit('close');
            this.isOpenModal = false;
            this.makingItem.isPaperSelectModalFirstDisplay = true;     // 台紙選択モーダルを表示済に変更
            // MEMO: PCの場合、レイアウトメニュー(select_style)を選択状態にする
            this.editStatus.activeSideMenu = this.windowWidth > 640 ? 'select_style' : '';
        },
    },

    computed: {
        displayGuideMessage() {
            // 選択肢が 年賀のみの場合は22行のみ表示
            // 選択肢が Bのみの場合は23
            // 両方対応のときは23，23行目の両方を表示
            if (this.makingItem.layout_material_pattern_count === 2) {
                return 'all'
            }
            return this.makingItem.material_pattern_code;
        }
    },

    created() {
        this.windowWidth = window.innerWidth;
    },
}
</script>
<style scoped>
.disabled_link {
    color: gray;
}
</style>