<template>
    <!--Header-->
    <Header @showPurchaseFlow="showPurchaseFlow" @showAlertMessage="showAlertMessage"></Header>
    <!--カードイメージ-->
    <main>
        <div class="w-full"
             @click="clickOutsideEditArea($event)">
            <section class="l-wrapper__mainColumn absolute bg-gray02 js-editAreaMainColumn" id="editArea">

                <design-border-attention-box></design-border-attention-box>
                <!--プレビュー画面-->
                <preview></preview>
                <!-- 移動・変形・回転をするところ -->
                <image-edit-layer></image-edit-layer>

                <!--表示エリア-->
                <PostCardEditArea
                    @loadDesign="loadDesign"
                    @showAlertMessage="showAlertMessage"></PostCardEditArea>

                <!--画像登録枚数制限オーバー-->
                <image-upper-limit v-if="!is_proxy_user"></image-upper-limit>

                <!-----購入に進む----->
                <Purchase @close="closeSlide" :class="{ 'is-active': isPurchaseModalActive }"
                          :validation-errors="validation_errors"
                          :design-preview-img="design_preview_img"/>
                <!--宛名のデザイン-->
                <PaperSelectModal @close="closePaperSelectModal" v-if="isShowPaperSelectModal"/>
                <!--チュートリアル-->

                 <Tutorial @close="closeTutorial"/>
            </section>

            <!-- フッターメニュー -->
            <FooterMenu @showAlertMessage="showAlertMessage"></FooterMenu>

            <!--サイドメニュー-->
            <side-menu @showAlertMessage="showAlertMessage"></side-menu>

            <edit-page-modal-alert v-if="isModalActive"
                                   :is-modal-active="isModalActive"
                                   :messages="modalMessages"
                                   :confirm-procedure="modalConfirmProcedure"
                                   @close="hydeAlertMessage"
            ></edit-page-modal-alert>
            <PreviewErrorModal
                v-if="isPreviewErrorModal"
                :is-modal-active="isPreviewErrorModal"
                @close="closePreviewErrorModal"></PreviewErrorModal>
        </div>
        <div class="c-modal__overlay" :class="{'is-modal-active' : open }"></div>
        <LoadingScreen :is-loading-screen="isLoadingScreen"
                       :init-loading-design="initLoadingDesign"></LoadingScreen>
        <YesNoModal v-if="isShowYesNoModal"
                    :is-modal-active="isShowYesNoModal"
                    :use-no-button="false"
                    :yes-button-text="'選ぶ'"
                    :message="'販売停止のはがきがあります。\nもう一度はがきを選択してください。'"
                    @close="isShowPaperSelectModal = true; isShowYesNoModal = false;"
        />
    </main>
</template>

<script>
import Header from "../components/Header";
import PostCardEditArea from "../components/Edit/PostCardEditArea.vue";
import ImageEditLayer from "../components/Edit/UserImageEditLayer.vue";
import SideMenu from "../components/Edit/EditPageSideMenu.vue";
import Preview from "../components/Edit/Preview.vue";
import MakingItemInfo from "../components/Edit/MakingItemInfo"
import FooterMenu from "../components/FooterMenu";
import {onMounted, provide, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import AvailableResources from "../components/Edit/AvailableResources";
import EditStatus from "../components/Edit/EditStatus";
import AuthFunctions from "../functions/auth";
import UtilsFunctions from "../functions/utils";
import axios from "axios";
import config from "../const/const";
import ImageUpperLimit from "../components/Edit/ImageUpperLimit";
import Tutorial from "../components/Edit/Tutorial.vue";
import Purchase from "../components/Edit/Purchase.vue";
import LoadingScreen from "../components/LoadingScreen";
import DesignBorderAttentionBox from "../components/Edit/DesignBorderAttentionBox";
import EditPageModalAlert from "../components/Edit/SideMenu/EditPageModalAlert";
import PaperSelectModal from "../components/Edit/PaperSelectModal.vue";
import PreviewErrorModal from "../components/Edit/SideMenu/PreviewErrorModal.vue";
import YesNoModal from "../components/Edit/YesNoModal.vue";

const {generateUniqueStr} = UtilsFunctions();
const {getCustomer} = AuthFunctions();

// MEMO: 販売終了チェック
const checkEnableDesign = async (designCode) => {
    return await axios.get(config.API_BASE_URL + '/design/is_enable_design/' + designCode)
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
}

export default {
    components: {
        YesNoModal,
        PreviewErrorModal,
        PaperSelectModal,
        EditPageModalAlert,
        FooterMenu,
        Header,
        PostCardEditArea,
        ImageEditLayer,
        SideMenu,
        Preview,
        ImageUpperLimit,
        Tutorial,
        Purchase,
        LoadingScreen,
        DesignBorderAttentionBox,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const designCode = route.params.designCode
        const nengaId = route.params.nengaId
        let code = route.params.code;

        // 編集中の各種状態を保持するとこ
        const editStatus = EditStatus.editStatus
        provide('editStatus', editStatus)
        // 作成中アイテムまたは新規作成アイテム
        const makingItem = MakingItemInfo.makingItem
        // MEMO: PostCardEditArea.vue内で使用するため、毎回 初期化するためfalseをセットする
        makingItem.loaded = false;
        provide('makingItemInfo', makingItem)
        // 素材と保存済みのユーザーの写真
        AvailableResources.init()
        const availableResources = AvailableResources.availableResources
        provide('availableResources', availableResources)

        // MEMO: 代理ログイン判定
        //  代理ログインの場合、過去の削除された画像も取得するので数量上限モーダルは表示しない
        const is_proxy_user = ref(false);

        // 非同期処理はマウント後にやる
        onMounted(async () => {
            // MEMO: 販売終了チェック
            const check_enable_design = await checkEnableDesign(designCode);

            // MEMO: 論理削除されている場合はメンテナンスページへ
            if (!check_enable_design) {
                await router.push({name: 'maintenance'});
                return;
            }

            // MEMO: 会員情報の取得
            const customer = await getCustomer()
            is_proxy_user.value = customer.is_proxy_user;

            // MEMO: falseの場合は、何かしらのSSOエラーのため、EC側にリダイレクト
            if (!customer) {
                // MEMO: ここでECのログアウトへリダイレクトURLをつけて飛ばす
                window.location.href = process.env.MIX_EC_LOGOUT_URL
                    + '?redirect_url=' + process.env.MIX_APP_URL
                    + route.path
                return;
            }

            // MEMO: Cookieが存在しない場合は空の連想配列が変える為、Nullに置き換える
            const customer_id = Object.keys(customer).length ? customer.id : null

            // MEMO:
            //  customer_id = SSOでログインしている状態 + codeがURLにあれば、一時保存 or 購入に進む → ECで新規会員登録 → 年賀に戻って着たパターン
            //  designCode + codeのみ = ゲストの初回アクセス → 特に何もなし。
            //  customer_idの値なし、codeなし = codeを発行してURLに追加する
            if (customer_id) {
                const post_params = {
                    customer_id: customer_id,
                    product_code: designCode,
                };

                // MEMO: EC側の購入処理中の場合は、編集不可の為、TOPページへ
                await axios.post(config.API_BASE_URL + '/ec/cart/has_nenga_data', post_params)
                    .catch((error) => {
                        alert(error.response.data.message);
                        // MEMO: エラーが発生した場合、TOPページへリダイレクト
                        router.push({name: 'top'});
                    });

                // MEMO: SSO認証の場合は、ゲストコードは不要なのでコードがある場合、URLを書き換えます
                if (code) {
                    const post_params = {
                        customer_id: customer_id,
                        product_code: designCode,
                        code: code,
                    };
                    // MEMO: dtb_nenga と dtb_user_imageをコードで検索して、customer_idを入れて更新する
                    const result = await axios.post(config.API_BASE_URL + '/making_item/guest/update', post_params)
                        .then((response) => {
                            if (response.data.message) {
                                alert(response.data.message);
                            }
                            return true;
                        })
                        .catch((error) => {
                            alert(error.response.data.message);
                            return false;
                        });

                    if (!result) {
                        // MEMO: エラーが発生した場合、TOPページへリダイレクト
                        await router.push({name: 'top'});
                        return;
                    }

                    // MEMO: ここに来た場合はすでに年賀データがあるため、DBから取得したコードを使用するのでNULLにする
                    code = null;
                    // MEMO: 終わったら、コードは不要になるのでURLから消す
                    await router.push({name: 'editpage', params: {designCode: designCode}})
                }
            } else if (!code) {
                // MEMO: ゲストの初回アクセスの場合(URLにコードが含まれていない かつ SSO認証でもない場合)
                // ランダムなコードを生成して、URLに付与する。
                code = generateUniqueStr();
                await router.push({name: 'editpage', params: {designCode: designCode, code: code}})
            }

            await MakingItemInfo.load(designCode, customer_id, code, nengaId)

            // 共通の素材
            await AvailableResources.load(designCode)

            if (customer_id) {
                // 会員固有の素材
                // await AvailableResources.loadCustomerResources(designCode, customer.id)
            }

            // MEMO: ガイドを表示する
            editStatus.activeSideMenu = 'tutorial'
        })

        // TODO: 参照がなくなったら消す
        provide('sideMenuData', reactive({}))
        return {
            is_proxy_user,
            editStatus,
            makingItem,
        }
    },
    data() {
        return {
            isPurchaseModalActive: false,
            isPreviewErrorModal: false,
            open: false,
            windowWidth: '',
            isLoadingScreen: false,
            initLoadingDesign: false,
            isModalActive: false,
            modalMessages: [],
            modalConfirmProcedure: null,
            validation_errors: [],
            design_preview_img: '',
            isShowPaperSelectModal: false,
            isShowYesNoModal: false,
        }
    },

    methods: {
        /**
         * エリア外（編集エリアの外側やオブジェクト以外）をクリックした際にテキストや画像の選択状態を解除する
         * */
        clickOutsideEditArea(event) {
            // MEMO: 指定された要素の場合はエリア外と判定し選択状態を解除します
            if (event.target.id === 'PostCardPicture' || event.target.id === 'editArea') {
                this.editStatus.neutralize()
            }
        },
        /**
         * 注文に進むモーダルの表示
         * */
        async showPurchaseFlow(validation_errors) {
            this.isLoadingScreen = true;
            // MEMO: デザイン面のプレビュー画像を取得
            const result = await this.getPreviewImage(this.makingItem.customer_id, this.makingItem.nenga_id);

            // MEMO: プレビューに失敗した場合、エラー表示
            if (!result) {
                this.isPreviewErrorModal = true;
                this.isLoadingScreen = false;
                // MEMO: PCの場合、レイアウトメニュー(select_style)を選択状態にする
                this.editStatus.activeSideMenu = this.windowWidth > 640 ? 'select_style' : '';
                return;
            }

            // MEMO: Header.vueで実行した購入時のバリデーション結果
            this.validation_errors = validation_errors;
            this.isPurchaseModalActive = true;

            if (this.windowWidth > 640) {
                this.open = true;
            }
            this.isLoadingScreen = false;
        },
        closePreviewErrorModal() {
            this.isPreviewErrorModal = false;
        },
        /**
         * 宛名のデザインを閉じる処理
         * */
        closePaperSelectModal() {
            this.isShowPaperSelectModal = false;
        },
        /**
         * チュートリアルを閉じる処理
         * */
        closeTutorial() {
            // MEMO: 台紙選択モーダルを一度も表示していない場合 かつ 新規登録の場合は表示する
            //  デザイン編集の場合：年賀IDが登録されている ＝ 台紙選択済なので、ガイドを閉じても台紙選択モーダルは表示しない
            if (!this.makingItem.isPaperSelectModalFirstDisplay && this.makingItem.nenga_id === null) {
                this.isShowPaperSelectModal = true;
            }

            // MEMO: 編集時に保存されている部材コードが販売終了などで無効になっている場合
            const enabled_material_pattern = this.makingItem.material_patterns
                .some(material_pattern => material_pattern.code === this.makingItem.material_pattern_code);

            if (this.makingItem.nenga_id !== null && !enabled_material_pattern) {
                // MEMO: ここで部材削除アラートを出す
                this.isShowYesNoModal = true;
            }
        },
        closeSlide() {
            this.isPurchaseModalActive = false;
            this.open = false;
        },
        loadDesign(change) {
            this.isLoadingScreen = change;
            this.initLoadingDesign = change;
        },
        showAlertMessage(messages, confirm) {
            this.isModalActive = true
            this.modalMessages = messages
            if (confirm) {
                this.modalConfirmProcedure = confirm
            }
        },
        hydeAlertMessage() {
            this.isModalActive = false
            this.modalMessages = []
            this.modalConfirmProcedure = null
        },
        /**
         * プレビュー画像の取得
         * */
        async getPreviewImage(customer_id, nenga_id) {
            return await axios.get(config.API_BASE_URL + '/making_item/get_preview_image/' + customer_id + '/' + nenga_id + '/1')
                .then((response) => {
                    this.design_preview_img = response.data.data
                    return true;
                })
                .catch((error) => {
                    return false;
                });
        },
    },

    created() {
        this.loadDesign(true);
        this.windowWidth = window.innerWidth;
    },
}

</script>

<style>

</style>
