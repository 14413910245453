<template>
    <div>
        <div class="c-modal__overlay flex flex-col justify-center items-center px-4 py-8"
             :class="{'is-modal-active' : open }">
            <div class="w-full max-w-3xl h-5/6 bg-white px-8 overflow-y-auto overflow-x-hidden">
                <div class="pt-8 text-center">
                    <p>画像の保存枚数を超えています。</p>
                    <p>保存する画像を選択してください。</p>
                </div>

                <div class="py-8">
                    <div class="grid grid-cols-2 sm:grid-cols-3 gap-x-4 gap-y-6">
                        <label v-for="(image, index) in userImages" :key="index"
                               class="mt-2 mr-6 sm:mr-0 w-full inline-block sm:block py-18 cursor-pointer">
                            <div class="p-edit__color__check shadow-sm">
                                <img :src="image.url" class="cursor-pointer">
                            </div>
                            <input @change="selectSaveImage"
                                   v-model="selectImage"
                                   :value="image.image_file"
                                   name="imageUpper"
                                   type="checkbox"
                                   class="hidden"
                            >
                        </label>
                    </div>
                </div>
            </div>
            <div class="bg-white w-full max-w-3xl text-center">
                <div class="py-8">
                    <div class="pb-4">
                        <p>選択中：{{ selectCount }} / {{ maxUserImageNumber }}枚</p>
                    </div>
                    <button @click="closeSaveUserImage()"
                            class="c-button__upperImage sm:w-1/3 py-2 sm:py-3 px-12 rounded-3xl font-bold"
                            :disabled="!disabled"
                            :class="{'is-disabled' : !disabled }"
                    >
                        <span>決定</span>
                    </button>
                </div>
            </div>

            <div v-if="failedSave">
                <div class="c-modal__overlay flex flex-col justify-center items-center px-4 py-8"
                     :class="{'is-modal-active' : open }"
                >
                    <div class="w-full max-w-xl bg-white px-8 text-center py-8">
                        <p>保存に失敗しました。</p>
                        <p>画像の選択をやり直してください。</p>
                        <div class="pt-8">
                            <button @click="failedClose" class="text-blue02 font-bold">
                                <span>閉じる</span>
                            </button>
                        </div>
                    </div>
                </div>
                <LoadingScreen :is-loading-screen="isLoadingScreen"></LoadingScreen>
            </div>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";
import LoadingScreen from "../LoadingScreen.vue";

export default {
    name: 'ImageUpperLimit',
    components: {LoadingScreen},
    setup() {
        const availableResources = inject('availableResources')
        const makingItem = inject('makingItemInfo')

        return {
            availableResources,
            makingItem
        };
    },
    data() {
        return {
            maxUserImageNumber: process.env.MIX_MAX_USER_IMAGE_NUMBER,
            open: false,
            selectActive: false,
            selectImage: [],
            selectCount: 0,
            disabled: false,
            userImages: [],
            failedSave: false,
            isLoadingScreen: false,
        }
    },
    watch: {
        // MEMO: availableResourcesが読み込み終わっていない可能性があるのでWatchする
        //  availableResourcesのuserImagesの読み込みが終わってる場合
        'availableResources.userImages': {
            handler: function () {
                if (this.availableResources.loaded) {
                    // MEMO: 画像枚数が設定値を超えている場合表示する
                    if (this.availableResources.userImages.length > this.maxUserImageNumber) {
                        this.userImages = this.availableResources.userImages;   // 一覧用のデータにセット
                        this.open = true;   // モーダルをONに
                        this.disabled = false;  // 決定ボタンを無効に
                    }
                }
            },
            deep: true
        },
    },
    methods: {
        selectSaveImage(e) {
            const target = e.target;
            const targetParent = target.previousElementSibling;

            if (target.checked === true) {
                targetParent.classList.add('active');
                this.selectCount++;

                if (this.selectCount > this.maxUserImageNumber) {
                    this.disabled = false;

                } else {
                    this.disabled = true;
                }
            } else {
                targetParent.classList.remove('active');
                this.selectCount--;

                if (this.selectCount <= this.maxUserImageNumber) {
                    this.disabled = true;
                }

                if (this.selectCount === 0) {
                    this.disabled = false;
                }
            }
        },
        async closeSaveUserImage() {
            const code = this.makingItem.code;
            const customer_id = this.makingItem.customer_id;
            this.isLoadingScreen = true;

            // MEMO: 選択されなかった画像は削除対象として抽出
            const deleteTargetUserImages = this.availableResources.userImages
                .filter((img) => !this.selectImage.includes(img.image_file));

            for (const delete_image of deleteTargetUserImages) {
                // MEMO: DBに登録してあるユーザー画像の削除処理を実行
                const result = await this.availableResources.deleteImage(customer_id, delete_image.image_file, code, null, true)

                if (result) {
                    // MEMO: 成功した場合、登録した写真から削除する
                    this.availableResources.userImages = this.availableResources.userImages.filter((img) => {
                        return img.image_file !== delete_image.image_file;
                    });
                    // MEMO: 成功した場合、編集エリアに配置されている同一ファイル名の画像も削除する
                    this.makingItem.deployedImages = this.makingItem.deployedImages.filter((img) => {
                        return img.image_file !== delete_image.image_file;
                    });
                    this.isLoadingScreen = false;
                } else {
                    // MEMO: 失敗した場合の処理をここに書く
                    this.failedSave = !this.failedSave;
                    this.isLoadingScreen = false;
                    return;
                }
            }
            this.open = !this.open;
        },

        failedClose() {
            this.failedSave = !this.failedSave;
        }
    },
}

</script>

<style scoped>
.c-button__upperImage {
    background-color: #00C2FF;
    color: #FFF;
}

.c-button__upperImage.is-disabled {
    background-color: #CCC;
    color: #333;
}
</style>