import * as Yup from "yup";
import {GuestType} from "../const/const";
import {
    addressRegex,
    companyDepartmentPositionNameRegex,
    nameRegex,
    numberRegex,
    oldNameOrAgeRegex,
    phoneNumberRegex
} from './regex_pattern';

/**
 * 法人用項目の検証ルールを生成します
 *
 * @param {Yup.StringSchema|Yup.MixedSchema} fieldSpecificRule - Yupを使用した検証ルール
 * @returns {Yup.StringSchema} - Yup validation schema.
 */
export const corporationFieldRule = (fieldSpecificRule) => {
    return Yup.string().when("guest_type", {
        is: GuestType.CORPORATION.val,
        then: () =>
            Yup.string()
                .nullable()
                .transform((value, org) => (org === '' ? null : org))
                .matches(companyDepartmentPositionNameRegex, {message: '使用できない文字が含まれています'})
                .max(255, '255文字まで入力可能です')
                .concat(fieldSpecificRule) // add field-specific rule, possibly chaining additional validators
    }).nullable();
}

/**
 * 指定されたパラメータを使用して検証ルールを生成します。
 *
 * @param {RegExp} regex - 正規表現パターン
 * @param {number} maxLength - 最大文字数
 * @param {null} matchesMessage  - 正規表現エラーの場合のエラーメッセージ
 * @param {null} [requiredMessage=null] - 必須の場合のエラーメッセージ
 * @param {boolean} [nullable=true] - NULL許可/不許可。許可する場合 True
 * @returns {Yup.Schema} - The validation rule.
 */
const generateValidationRule = (
    regex,
    maxLength,
    matchesMessage = null,
    requiredMessage = null,
    nullable = true) => {

    let rule = Yup.string()
        .matches(regex, {message: matchesMessage ?? '使用できない文字が含まれています'})
        .max(maxLength, `${maxLength}文字まで入力可能です`);
    if (nullable) {
        rule = rule.transform(nullifyIfEmpty)
            .nullable();
    }
    if (requiredMessage) {
        rule = rule.required(requiredMessage);
    }
    return rule;
};

/**
 * 初期の値が空文字列である場合、該当の値をnullに変換します。
 *
 * @param {any} value - 現在の値。
 * @param {any} original - 元の値。
 * @returns {any} - 元の値が空文字列である場合はnull、そうでない場合は元の値。
 */
const nullifyIfEmpty = (value, original) => original === '' ? null : original;


/**
 * 差出人の検証ルール
 *
 * @type {Yup.ObjectSchema}
 */
export const SenderValidationRule = Yup.object({
    corporate_name1: corporationFieldRule(Yup.string().required('法人用の場合、必須項目です')),
    corporate_name2: corporationFieldRule(Yup.string().nullable()),
    department_name1: corporationFieldRule(Yup.string().nullable()),
    department_name2: corporationFieldRule(Yup.string().nullable()),
    position_name: corporationFieldRule(Yup.string().nullable()),
    sei: generateValidationRule(nameRegex, 20, null, '必須項目です。(姓)', false),
    mei: generateValidationRule(nameRegex, 20, null,'必須項目です。(名)', false),
    // MEMO: 旧姓・年齢は法人の場合は使用しないため、以下のルールを使用する
    old_name_or_age: Yup.string()
        .transform(nullifyIfEmpty)
        .matches(oldNameOrAgeRegex, {message: '使用できない文字が含まれています'})
        .max(15, '15文字まで入力可能です。')
        .nullable(),
    pref_name: Yup.string()
        .transform((curr, orig) => orig === '未選択' ? null : curr)
        .required('必須項目です'),
    post_no: generateValidationRule(numberRegex, 7, '半角数字で入力してください','必須項目です')
        .min(7, '7桁で入力してください'),
    address: generateValidationRule(addressRegex, 20, null,'必須項目です'),
    building_name: generateValidationRule(addressRegex, 24),
    tel: generateValidationRule(phoneNumberRegex, 20),
    joint_names: Yup.array().of(
        Yup.object().shape({
            sei: Yup.string()
                .transform((value, org) => org === '' ? null : org)
                .matches(nameRegex, {message: '使用できない文字が含まれています'})
                .test('SeiOrMeiRequired', '姓・名いずれかを入力してください', (value, ctx) => {
                    if (ctx.parent.old_name_or_age === null && ctx.parent.mei === null && value === null) {
                        return true;
                    }
                    // 旧姓・年齢が入力済 かつ 姓名が未入力の場合は、エラー
                    return !(ctx.parent.old_name_or_age !== null && ctx.parent.mei === null && value === null);
                })
                .max(20, '20文字まで入力可能です')
                .nullable(),
            mei: Yup.string()
                .transform((value, org) => org === '' ? null : org)
                .matches(nameRegex, {message: '使用できない文字が含まれています'})
                .test('SeiOrMeiRequired', '姓・名いずれかを入力してください', (value, ctx) => {
                    // すべて未入力の場合はチェックしない
                    if (ctx.parent.old_name_or_age === null && ctx.parent.sei === null && value === null) {
                        return true;
                    }
                    // 旧姓・年齢が入力済 かつ 姓名が未入力の場合は、エラー
                    return !(ctx.parent.old_name_or_age !== null && ctx.parent.sei === null && value === null);
                })
                .max(20, '20文字まで入力可能です')
                .nullable(),
            old_name_or_age:
                Yup.string()
                    .transform((value, org) => org === '' ? null : org)
                    .matches(oldNameOrAgeRegex, {message: '使用できない文字が含まれています'})
                    .max(15, '15文字まで入力可能です。').nullable()
                    .when(['sei', 'mei'], {
                        is: (sei, mei) => !!sei || !!mei,
                        then: schema => schema,
                        otherwise: schema => schema.nullable().test({
                            test: value => value ? false : true,
                            message: '旧姓・年齢を入力する場合は、姓 または 名の入力が必須です'
                        })
                    }),
            position_name: Yup.string()
                .transform((value, org) => (org === '' ? null : org))
                .matches(companyDepartmentPositionNameRegex, {message: '使用できない文字が含まれています'})
                .max(255, '255文字まで入力可能です')
                .nullable(),
        }, ['sei', 'mei'])
    )
});