import * as Enum from "../const/const";
import config from "../const/const";
import CommonPrintImageFunctions from "./common_print_image";
import _ from "lodash";

const {
    DrawDataType,
    createCanvas,
    drawCanvas,
    createPostNoDataUrl,
    replaceDuplicateSei,
    adjustVerticalFontSize,
    adjustVerticalFontSizeForName,
    getMaxFontSize,
    getMinFontSize,
    getSumHorizonRegexForNameArea,
    evenNameType,
    convertToDrawTargetDataForName,
    convertStringToDrawTargetData,
    adjustHorizonFontSize,
} = CommonPrintImageFunctions();

/**
 * プレビュー関係の共通関数ファイル
 * */
export default function () {

    const type = {
        address: 'address',
        building_name: 'building_name',
        corporate_name1: 'corporate_name1',
        corporate_name2: 'corporate_name2',
        department_name1: 'department_name1',
        department_name2: 'department_name2',
        name: 'name',
        joint_name: 'joint_name',
        position_name: 'position_name',
        sei: 'sei',
        mei: 'mei',
        joint_name_old_name_or_age: 'joint_name_old_name_or_age',
        old_name_or_age: 'old_name_or_age',
        position_name_sei_space: 'position_name_sei_space',
        sei_mei_space: 'sei_mei_space',
    };

    // 下揃えから描画する項目の正規表現
    const bottom_start_regex = /^building_name|^position_name|^sei|^joint_name_sei_|^mei|^joint_name_mei_|^old_name_|^joint_name_old_name_or_age_/;

    // 印刷用の倍率（印刷用は600DPIなので、プレビューの値を8.436倍する）
    const magnificationForPrint = 8.436;

    const canvasSize = {
        width: 280,
        height: 414,
    };
    const canvasSizeForPrint = {
        width: canvasSize.width * magnificationForPrint,
        height: canvasSize.height * magnificationForPrint,
    };

    /**
     * 差出人の住所エリアの設定情報
     * */
    const senderAddressArea = {
        // 書き出し座標
        start: {
            x: 95,
            y: 165,     // 上揃えの上限
        },
        // 終了座標
        end: {
            x: 71,     // start.x - font_size(12px) * 行数(2行)
            y: 325,     // 下揃えの上限
        },
        font_size: 12,
    };

    /**
     * 差出人の住所エリアの設定情報(印刷用)
     * */
    const senderAddressAreaForPrint = {
        // 書き出し座標
        start: {
            x: senderAddressArea.start.x * magnificationForPrint,
            y: senderAddressArea.start.y * magnificationForPrint,     // 上揃えの上限
        },
        // 終了座標
        end: {
            x: senderAddressArea.end.x * magnificationForPrint,     // start.x - font_size(16px) * 行数(2行)
            y: senderAddressArea.end.y * magnificationForPrint,     // 下揃えの上限
        },
        font_size: senderAddressArea.font_size * magnificationForPrint,
    };

    /**
     * 差出人の姓名エリアの設定
     * */
    const senderNameArea = {
        // 書き出し座標
        start: {
            x: 66,     // 住所の終了位置 + 右側の余白（）
            y: 165,     // 上揃えの上限
        },
        // 終了座標
        end: {
            x: 15,
            y: 325,     // 下揃えの上限。差出人は下揃えで書くので
        },
        font: 'kaisho',
        font_size: {
            corporate_name: 16, // 会社名1・2のフォントサイズ
            department_name: 16,    // 部署名1・2のフォントサイズ
            position_name: 16,  // 役職のフォントサイズ
            name: 16,           // 姓・名・敬称のフォントサイズ
            old_name_or_age: 10,    // 旧姓・年齢フォントサイズ
        },
        position_name_sei_space: 20,  // 役職↔姓のスペース
        sei_mei_space: 20,  // 姓⇔名のスペース
    }

    /**
     * 差出人の姓名エリアの設定(印刷用)
     * */
    const senderNameAreaForPrint = {
        // 書き出し座標
        start: {
            x: senderNameArea.start.x * magnificationForPrint,     // 住所の終了位置 + 右側の余白（）
            y: senderNameArea.start.y * magnificationForPrint,     // 上揃えの上限
        },
        // 終了座標
        end: {
            x: senderNameArea.end.x * magnificationForPrint,
            y: senderNameArea.end.y * magnificationForPrint,     // 下揃えの上限
        },
        font: 'kaisho',
        font_size: {
            corporate_name: senderNameArea.font_size.corporate_name * magnificationForPrint, // 会社名1・2のフォントサイズ
            department_name: senderNameArea.font_size.department_name * magnificationForPrint,    // 部署名1・2のフォントサイズ
            position_name: senderNameArea.font_size.position_name * magnificationForPrint,  // 役職のフォントサイズ
            name: senderNameArea.font_size.name * magnificationForPrint,           // 姓・名・敬称のフォントサイズ
            old_name_or_age: senderNameArea.font_size.old_name_or_age * magnificationForPrint,    // 旧姓・年齢フォントサイズ
        },
        position_name_sei_space: senderNameArea.position_name_sei_space * magnificationForPrint,  // 役職↔姓のスペース
        sei_mei_space: senderNameArea.sei_mei_space * magnificationForPrint,  // 姓⇔名のスペース
    }

    /**
     * 郵便番号エリア
     * */
    const senderPostNoArea = {
        start: {
            x: 13,
            y: 351,
        },
        text_space: 0,     // 1文字あたりの間隔(px)
        font_size: 12,
        font: 'kaisho'
    };

    /**
     * 郵便番号エリア(印刷用)
     * */
    const senderPostNoAreaForPrint = {
        start: {
            x: senderPostNoArea.start.x * magnificationForPrint,
            y: senderPostNoArea.start.y * magnificationForPrint,
        },
        text_space: senderPostNoArea.text_space * magnificationForPrint,     // 1文字あたりの間隔(px)
        font_size: senderPostNoArea.font_size * magnificationForPrint,
        font: 'kaisho'
    };

    const joinName = (arrPositionName, arrSei, arrMei, type, name_setting) => {
        const arrName = [];
        if (arrPositionName.length) {
            arrName.push(...arrPositionName);
            arrName.push(...convertStringToDrawTargetData(DrawDataType.position_name_sei_space, ' ', name_setting.position_name_sei_space));
        }
        arrName.push(...arrSei);
        if (arrMei.length) {
            arrName.push(...convertStringToDrawTargetData(DrawDataType.sei_mei_space, ' ', name_setting.sei_mei_space));
            arrName.push(...arrMei);
        }

        return arrName;
    };

    /**
     * 印刷用のイメージを生成しDataUrlを返します
     *
     * @param {Object} sender
     * @param {boolean} is_print
     */
    const createSenderPrintImage = async (sender, is_print) => {
        const clone_sender = _.cloneDeep(sender);
        const sender_post_no_setting = is_print ? senderPostNoAreaForPrint : senderPostNoArea;
        const sender_setting = is_print ? senderAddressAreaForPrint : senderAddressArea;
        const name_setting = is_print ? senderNameAreaForPrint : senderNameArea;
        const width = is_print ? canvasSizeForPrint.width : canvasSize.width;
        const height = is_print ? canvasSizeForPrint.height : canvasSize.height;
        const canvas = createCanvas(
            width,
            height,
            "sender_print_image",
            "ml-auto mr-auto z-10"
        );

        // 差出人欄の郵便番号の描画
        drawSenderPostNo(canvas, clone_sender, sender_post_no_setting);

        // 差出人の描画
        await drawSender(canvas, clone_sender, sender_setting, name_setting);
        // data-urlの取得
        const context = canvas.getContext("2d");
        return context.canvas.toDataURL();
    }

    /**
     * 宛先面の差出人郵便番号の描画をします
     *
     * @param {object} canvas
     * @param {object} sender
     * @param {object} post_no_setting
     */
    const drawSenderPostNo = (canvas, sender, post_no_setting) => {
        const post_no_texts = sender.post_no ? sender.post_no.split('') : null;
        createPostNoDataUrl(
            canvas, post_no_texts,
            post_no_setting.text_space, post_no_setting.start.x, post_no_setting.start.y,
            post_no_setting.font_size, post_no_setting.font
        );
    };

    /**
     * @param {*} canvas
     * @param {Object} sender
     * @param {{font_size: number, start: {x: number, y: number}, end: {x: number, y: number}}|{font_size: number, start: {x: number, y: number}, end: {x: number, y: number}}} address_setting
     * @param {*} name_setting
     */
    const drawSender = async (canvas, sender, address_setting, name_setting) => {

        const arrAddress = convertStringToDrawTargetData(DrawDataType.address, sender.pref_name + sender.address, address_setting.font_size);
        const arrBuildingName = convertStringToDrawTargetData(DrawDataType.building_name, sender.building_name, address_setting.font_size);
        const arrCorporateName1 = convertStringToDrawTargetData(DrawDataType.corporate_name1, sender.corporate_name1, name_setting.font_size.corporate_name);
        const arrCorporateName2 = convertStringToDrawTargetData(DrawDataType.corporate_name2, sender.corporate_name2, name_setting.font_size.corporate_name);
        const arrDepartmentName1 = convertStringToDrawTargetData(DrawDataType.department_name1, sender.department_name1, name_setting.font_size.department_name);
        const arrDepartmentName2 = convertStringToDrawTargetData(DrawDataType.department_name2, sender.department_name2, name_setting.font_size.department_name);
        const arrPositionName = convertStringToDrawTargetData(DrawDataType.position_name, sender.position_name, name_setting.font_size.position_name);
        const sei = evenNameType(sender, Enum.NameType.SEI.val);
        const mei = evenNameType(sender, Enum.NameType.MEI.val);
        const old_name_or_age = evenNameType(sender, Enum.NameType.OLD_NAME.val);
        const arrSei = await convertToDrawTargetDataForName(DrawDataType.sei, sei, name_setting.font, name_setting.font_size.name);
        const arrMei = await convertToDrawTargetDataForName(DrawDataType.mei, mei, name_setting.font, name_setting.font_size.name);
        const arrOldName = await convertToDrawTargetDataForName(DrawDataType.old_name_or_age, old_name_or_age, name_setting.font, name_setting.font_size.old_name_or_age);
        const address_area_height = address_setting.end.y - address_setting.start.y;
        const name_area_height = name_setting.end.y - name_setting.start.y;
        const address_area_width = address_setting.start.x - address_setting.end.x;
        const name_area_width = name_setting.start.x - name_setting.end.x;

        // 連名
        const arrJointNames = [];
        const arrJointOldNames = [];
        for (const [i, joint_name] of sender.joint_names.entries()) {
            // TODO: 連名の外字対応は後回し
            const arrSei = convertStringToDrawTargetData(DrawDataType.sei, joint_name.sei, name_setting.font_size.name);
            const arrMei = convertStringToDrawTargetData(DrawDataType.mei, joint_name.mei, name_setting.font_size.name);
            const arrOldName = convertStringToDrawTargetData(DrawDataType.old_name_or_age, joint_name.old_name_or_age, name_setting.font_size.old_name_or_age);

            // MEMO: 役職名 + 姓 + 名を1行に結合します
            const arrJointName = joinName([], arrSei, arrMei, type, name_setting);

            // MEMO: 役職名 + 姓 + 名を1行にしたものを連名用配列に追加する
            arrJointNames.push(arrJointName);

            // MEMO: 旧姓年齢は次の行に描画するため、別の配列にまとめる
            arrJointOldNames.push(arrOldName);
        }

        // MEMO: 役職名 + 姓 + 名 + 敬称を1行に結合します
        const arrName = joinName(arrPositionName, arrSei, arrMei, DrawDataType, name_setting);

        // MEMO: 右から書き出す順にデータを配列に追加する
        const arrDrawTargets = [
            {
                type: DrawDataType.address,
                data: arrAddress,
                font_size: address_setting.font_size,
                limit_height: address_area_height,
                full_text: arrAddress.map((item) => item.text).join(''),
                length: arrAddress.map((item) => item.text).join('').length,
            },
            {
                type: DrawDataType.building_name,
                data: arrBuildingName,
                font_size: address_setting.font_size,
                limit_height: address_area_height,
                full_text: arrBuildingName.map((item) => item.text).join(''),
                length: arrBuildingName.map((item) => item.text).join('').length,
            },
            // MEMO: 姓名エリア
            {
                type: DrawDataType.corporate_name1,
                data: arrCorporateName1,
                font_size: name_setting.font_size.corporate_name,
                limit_height: name_area_height,
                full_text: arrCorporateName1.map((item) => item.text).join(''),
                length: arrCorporateName1.map((item) => item.text).join('').length,
            },
            {
                type: DrawDataType.corporate_name2,
                data: arrCorporateName2,
                font_size: name_setting.font_size.corporate_name,
                limit_height: name_area_height,
                full_text: arrCorporateName2.map((item) => item.text).join(''),
                length: arrCorporateName2.map((item) => item.text).join('').length,
            },
            {
                type: DrawDataType.department_name1,
                data: arrDepartmentName1,
                font_size: name_setting.font_size.department_name,
                limit_height: name_area_height,
                full_text: arrDepartmentName1.map((item) => item.text).join(''),
                length: arrDepartmentName1.map((item) => item.text).join('').length,
            },
            {
                type: DrawDataType.department_name2,
                data: arrDepartmentName2,
                font_size: name_setting.font_size.department_name,
                limit_height: name_area_height,
                full_text: arrDepartmentName2.map((item) => item.text).join(''),
                length: arrDepartmentName2.map((item) => item.text).join('').length,
            },
            {
                type: DrawDataType.name,
                data: arrName,
                limit_height: name_area_height,
                full_text: arrName.map((item) => item.text).join(''),
                length: arrName.map((item) => item.text).join('').length,
            },
            {
                type: DrawDataType.old_name_or_age,
                data: arrOldName,
                limit_height: name_area_height,
                full_text: arrOldName.map((item) => item.text).join(''),
                length: arrOldName.map((item) => item.text).join('').length,
            },
        ];

        // 連名
        for (const [i, arrJointName] of Object.entries(arrJointNames)) {
            arrDrawTargets.push(
                {
                    type: DrawDataType.joint_name + '_' + i,
                    data: arrJointName,
                    limit_height: name_area_height,
                    full_text: arrJointName.map((item) => item.text).join(''),
                    length: arrJointName.map((item) => item.text).join('').length,
                },
            );

            // MEMO: 連名(旧姓・年齢)は非必須なので、未入力の場合は描画対象に追加しない
            const old_name_full_text = arrJointOldNames[i].map((item) => item.text).join('');
            if (old_name_full_text.match(/^[\u{20}\u{3000}]*$/ug) !== null) {
                continue;
            }
            arrDrawTargets.push(
                {
                    type: DrawDataType.joint_name_old_name_or_age + '_' + i,
                    data: arrJointOldNames[i],
                    limit_height: name_area_height,
                    full_text: arrJointOldNames[i].map((item) => item.text).join(''),
                    length: arrJointOldNames[i].map((item) => item.text).join('').length,
                },
            );
        }

        // MEMO: 姓 と 連名(姓)が同じ場合、空文字に置換します
        replaceDuplicateSei(arrDrawTargets);

        // MEMO: 姓名エリアの縦方向の文字サイズの調整をし、連名とフォントサイズを合わせます
        adjustVerticalFontSizeForName(arrDrawTargets, name_area_height);

        // MEMO: 住所縦方向の文字サイズの調整をする
        adjustVerticalFontSize(arrDrawTargets);

        // MEMO: エリアごとに横方向の文字サイズを調整する
        adjustHorizonFontSize(
            arrDrawTargets,
            address_area_width,
            /^address|^building_name/,
            /^address|^building_name/
        );
        const pattern = getSumHorizonRegexForNameArea(arrDrawTargets);

        adjustHorizonFontSize(
            arrDrawTargets,
            name_area_width,
            pattern,
            /^corporate_name|^department_name|^position_name|^sei|^mei|^old_name_or_age/
        );

        drawSenderCanvas(canvas, arrDrawTargets, address_setting, name_setting);
    }

    /**
     * 指定されたcanvasに差出人を描画します
     *
     * @param {*} canvas
     * @param {*} arrDrawTargets
     * @param {{font_size: number, start: {x: number, y: number}, end: {x: number, y: number}}} address_setting 住所エリアの設定情報
     * @param {*} name_setting 姓名エリアの設定情報
     */
    const drawSenderCanvas = (canvas, arrDrawTargets, address_setting, name_setting) => {
        // MEMO: 描画処理
        const context = canvas.getContext("2d");
        let x = 0;
        let y = 0;

        // MEMO: 姓・名のフォントサイズから最小のフォントサイズを取得する
        const address_min_font_size = getMinFontSize(arrDrawTargets, /^address|^building_name/);
        const address_max_font_size = getMaxFontSize(arrDrawTargets, /^address|^building_name/);
        // MEMO: 会社名1/2、部署名1/2は、姓・名とは別のフォントサイズを指定してるため個別に取得する
        const corporate_name_font_size = getMinFontSize(arrDrawTargets, /^corporate_name/);
        const max_corporate_name_font_size = getMaxFontSize(arrDrawTargets, /^corporate_name/);
        const department_name_font_size = getMinFontSize(arrDrawTargets, /^department_name/);
        const max_department_name_font_size = getMaxFontSize(arrDrawTargets, /^department_name/);
        const max_joint_name_font_size = getMaxFontSize(arrDrawTargets, /^sei$|^mei$/);
        const max_name_font_size = getMaxFontSize(arrDrawTargets, /^sei$|^mei$/);
        const max_position_name_font_size = getMinFontSize(arrDrawTargets, /^position_name/);
        const max_old_name_font_size = getMaxFontSize(arrDrawTargets, /^old_name_or_age/);

        for (const [key, drawDatum] of Object.entries(arrDrawTargets)) {
            let textAlign = 'right';
            const data = drawDatum.data;

            // MEMO: データがない場合はスキップ
            if (!drawDatum.length) {
                continue;
            }

            switch (true) {
                case /^address$/.test(drawDatum.type):
                    data.map((item) => item.font_size = address_min_font_size)
                    x = address_setting.start.x;
                    y = address_setting.start.y;

                    // MEMO: 建物・部屋番号が存在しない場合 左揃えで書き出す
                    if (!arrDrawTargets.some((item) => item.type === DrawDataType.building_name && item.length > 0)) {
                        x = address_setting.end.x;
                        textAlign = 'left';
                    }
                    y = drawData(context, data, x, y, textAlign);
                    break;
                case /^building_name$/.test(drawDatum.type):
                    data.map((item) => item.font_size = address_min_font_size)
                    // MEMO: 建物・部屋番号は下揃えの為、下から書き出すのでY座標をセット
                    x = x - address_max_font_size;
                    y = address_setting.end.y;
                    data.reverse();
                    y = drawData(context, data, x, y, textAlign);
                    break;
                case /^corporate_name1$/.test(drawDatum.type):
                    data.map((item) => item.font_size = corporate_name_font_size)
                    // 中央寄せのため、余白を入れる
                    x = name_setting.start.x;
                    y = name_setting.start.y;
                    y = drawData(context, data, x, y, textAlign);
                    break;
                case /^corporate_name2$/.test(drawDatum.type):
                    data.map((item) => item.font_size = corporate_name_font_size)
                    x = x - max_corporate_name_font_size;
                    y = name_setting.start.y;
                    y = drawData(context, data, x, y, textAlign);
                    break;
                case /^department_name1$/.test(drawDatum.type):
                case /^department_name2$/.test(drawDatum.type):
                    data.map((item) => item.font_size = department_name_font_size)
                    x = x - max_department_name_font_size;
                    // MEMO: 部署名は開始位置(Y)を一文字分下げる
                    y = name_setting.start.y + max_department_name_font_size;
                    y = drawData(context, data, x, y, textAlign);
                    break;
                case /^name$/.test(drawDatum.type):
                    data.reverse(); // 下から書き出すので値を反転

                    y = name_setting.end.y;   // 下揃えなので、書き出しをYの下限にする

                    // MEMO: 会社名や部署名がない場合は開始位置は姓名エリアの指定位置になるので指定位置 + 中央寄せ用のスペースとする
                    if (!hasCorporateData(arrDrawTargets)) {
                        x = name_setting.start.x;
                    } else {
                        // MEMO: 会社名や部署名がある場合は開始位置は次の行になるのでフォントサイズ分 左にずらす
                        x = x - max_corporate_name_font_size;
                    }
                    const font_diff = max_name_font_size - max_position_name_font_size;
                    const position_name_space = font_diff > 0 ? font_diff / 2 : 0;
                    y = drawDataForName(context, data, x, y, position_name_space, textAlign);
                    break;
                case /^old_name_or_age$/.test(drawDatum.type):
                case /^joint_name_old_name_or_age_[0-9]$/.test(drawDatum.type):
                    x = x - max_joint_name_font_size;
                    y = name_setting.end.y;
                    data.reverse();
                    y = drawData(context, data, x, y, textAlign);
                    break;
                case /^joint_name_[0-9]$/.test(drawDatum.type):
                    data.reverse(); // 下から書き出すので値を反転
                    const joint_name_index = Number(drawDatum.type.slice(-1));

                    let check_key;
                    // MEMO: 連名の先頭行の場合、一つ前のデータが旧姓・年齢の場合と姓名の場合で文字サイズが違う可能性があるので調整する
                    if (joint_name_index === 0) {
                        check_key = DrawDataType.old_name_or_age;
                    } else {
                        // MEMO: 連名の先頭行以外の場合は、一つ前のデータが連名(旧姓・年齢)の場合と連名(姓名)の場合で文字サイズが違う場合があるので調整する
                        const before_joint_name_old_name_or_age_index = joint_name_index === 0 ? 0 : joint_name_index - 1;
                        check_key = DrawDataType.joint_name_old_name_or_age + '_' + before_joint_name_old_name_or_age_index;
                    }

                    // MEMO: 対になる連名(旧姓・年齢)がある場合
                    if (arrDrawTargets.some((item) => item.type === check_key && item.length)) {
                        x = x - max_old_name_font_size;
                    } else {
                        x = x - max_joint_name_font_size;  // 一行 左にずらして書き出す
                    }

                    y = name_setting.end.y;   // 下揃えなので、書き出しをYの下限にする
                    const joint_name_font_diff = max_name_font_size - max_position_name_font_size;
                    const joint_name_position_name_space = joint_name_font_diff > 0 ? joint_name_font_diff / 2 : 0;
                    y = drawDataForName(context, data, x, y, joint_name_position_name_space, textAlign);
                    break;
            }
        }
    };

    const hasCorporateData = (arrDrawTargets) => {
        // MEMO: 指定されたデータの中に会社名1、2 部署名1，2のどれかがある場合 Tureを返します
        return arrDrawTargets.some((item) => item.type === DrawDataType.corporate_name1 && item.length > 0) ||
            arrDrawTargets.some((item) => item.type === DrawDataType.corporate_name2 && item.length > 0) ||
            arrDrawTargets.some((item) => item.type === DrawDataType.department_name1 && item.length > 0) ||
            arrDrawTargets.some((item) => item.type === DrawDataType.department_name2 && item.length > 0);
    };

    const drawDataForName = (context, data, x, y, position_name_space, textAlign) => {
        const position_name = data.filter((item) => item.type === DrawDataType.position_name ||
            item.type === DrawDataType.position_name_sei_space);
        const sei = data.filter((item) => item.type === DrawDataType.sei || item.type === DrawDataType.sei_mei_space);
        const mei = data.filter((item) => item.type === DrawDataType.mei || item.type === DrawDataType.mei_honorifics_space);

        y = drawData(context, mei, x, y, textAlign);
        y = drawData(context, sei, x, y, textAlign);
        y = drawData(context, position_name, x - position_name_space, y, textAlign);

        return y;
    };

    const drawData = (context, data, x, y, textAlign) => {
        for (const [index, row] of Object.entries(data)) {
            drawCanvas(context, row.text, x, y, row.font_size, row.font, textAlign);
            // 建物部屋番号は下から書き出すため、フォントサイズ分座標を減算する
            if (bottom_start_regex.test(row.type)) {
                y = y - row.font_size;
                continue;
            }
            y = y + row.font_size;
        }
        return y;
    };

    /**
     * 差出人の情報を取得します
     *
     * @param {number} customer_id
     * @param {number} sender_id
     */
    const getSender = async (customer_id, sender_id) => {
        // MEMO: 個人用/法人用を検索条件に指定する
        // TODO: ここに、会員IDを追加する
        const params = {
            customer_id: customer_id,
            sender_id: sender_id,
        };

        return await axios
            .post(config.API_BASE_URL + '/sender_list/search', params)
            .then((response) => {
                if (response.data.length) {
                    return response.data[0];
                }
                return null;
            })
            .catch(function (error) {
                console.log('failure');
                return null;
            });
    }

    return {
        canvasSize,
        canvasSizeForPrint,
        senderAddressArea,
        senderAddressAreaForPrint,
        senderNameArea,
        senderNameAreaForPrint,
        senderPostNoArea,
        senderPostNoAreaForPrint,
        getSender,
        drawSenderPostNo,
        drawSender,
        createSenderPrintImage,
    }
}
