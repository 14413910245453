import * as Enum from "../const/const";
import _ from "lodash";

/**
 * 宛先/差出人登録関係の共通関数ファイル
 * */
export default function () {
    /**
     * 個人用、法人用に応じて、不要なデータを削除します
     *
     * @params {object} form_data sender_item or address_item
     * */
    const resetFormDataForGuestType = (form_data, mode = 'address') => {
        // MEMO: 個人用の場合、法人の項目を消す
        if (form_data.guest_type === Enum.GuestType.PEOPLE.val) {
            // 宛名、差出人共通項目
            form_data.corporate_name1 = null;   // 会社名1
            form_data.corporate_name2 = null;   // 会社名2
            form_data.department_name1 = null;  // 部署名1
            form_data.department_name2 = null;  // 部署名2
            form_data.position_name = null;     // 役職名

            // MEMO: 差出人は連名を削除するため、宛名のみ連名の役職名を削除
            if (mode === 'address') {
                form_data.joint_names = form_data.joint_names.map((item) => {
                    return {...item, position_name: null}
                })
            }
        }
        // MEMO: 差出人の法人の場合、連名を削除する
        if (mode === 'sender' && form_data.guest_type === Enum.GuestType.CORPORATION.val) {
            form_data.old_name_or_age = null;
            // MEMO: 差出人の旧姓・年齢をパーツから削除
            form_data.sender_name_parts = form_data.sender_name_parts.filter((item) => item.name_type !== Enum.NameType.OLD_NAME.val)

            // MEMO: 連名を削除
            form_data.joint_names = []

            form_data.sender_joint_name_parts = []
        }

        return form_data;
    };

    /**
     * 外字使用フラグがONのデータを抽出します
     *
     * @params {object} data
     * */
    const filterIsUseGaiji = (data) => {
        return data.filter(function (item) {
            return item.is_use_gaiji === true;
        });
    };

    /**
     * 指定されたデータの中からPOST可能なデータを抽出します
     *
     * @params {object} data
     * */
    const filterActiveJointNames = (data) => {
        return data.filter(function (item) {
            // 姓名の入力があるデータのみ
            return item.sei || item.mei;
        });
    };

    /**
     * MEMO: 入力された文字列用連想配列を生成します
     * */
    const newChar = () => {
        return {
            id: null,
            font_id: null,
            is_use_gaiji: false,
            type: null,      // EnumDataType
            external_character_id: null,   // 外字のID
            position: 0,  // MEMO: 入力後はカーソルは進んでるため、-1にする
            name_type: null, // EnumNameType
            input_text: null,
            gaiji_type: null,   // EnumExternalCharType。外字を使わない場合,null
            gaiji: null,    // 外字用
            gaiji_relation_key: null,    // 外字リレーションキー
        };
    };

    /**
     * 入力された連名情報を差出人連名パーツ用の配列に変換します
     * */
    const convertToSenderJointNameParts = (joint_names) => {

        const ret = [];
        for (const [index, joint_name] of joint_names.entries()) {
            const arrSei = joint_name.sei ? Array.from(joint_name.sei) : [];
            const arrMei = joint_name.mei ? Array.from(joint_name.mei) : [];
            const arrOldName = joint_name.old_name_or_age ? Array.from(joint_name.old_name_or_age) : [];
            const arrSenderJointNameParts = [];
            createJointNameChar(arrSenderJointNameParts, index, Enum.NameType.SEI.val, arrSei);
            createJointNameChar(arrSenderJointNameParts, index, Enum.NameType.MEI.val, arrMei);
            createJointNameChar(arrSenderJointNameParts, index, Enum.NameType.OLD_NAME.val, arrOldName);
            ret.push(arrSenderJointNameParts);
        }
        return ret;
    };

    const convertToAddressJointNameParts = (joint_names) => {
        const ret = [];
        for (const [index, joint_name] of joint_names.entries()) {
            const arrSei = joint_name.sei ? Array.from(joint_name.sei) : [];
            const arrMei = joint_name.mei ? Array.from(joint_name.mei) : [];
            const arrJointNameParts = [];
            createJointNameChar(arrJointNameParts, index, Enum.NameType.SEI.val, arrSei);
            createJointNameChar(arrJointNameParts, index, Enum.NameType.MEI.val, arrMei);
            ret.push(arrJointNameParts);
        }
        return ret;
    };

    /**
     * 差出人連名文字用のObjectを返します
     * */
    const createJointNameChar = (arrNameParts, index, name_type, arrName) => {
        for (const [position, char] of arrName.entries()) {
            arrNameParts.push({
                is_use_gaiji: false,    // 連名は外字使わないのでfalse固定
                external_character_id: null,
                address_id: null,
                sender_id: null,
                position: position,
                name_type: name_type,
                input_text: char,
                gaiji_type: null,
                gaiji: null,
                font_id: null,
                joint_name_index: index,
                gaiji_relation_key: null,
            });
        }
    };

    /**
     * 連名用のObjectを初期化して返します
     * */
    const initJointName = (customer_id) => {
        return {
            id: null,
            customer_id: customer_id, // TODO: SSO連携するまで固定
            sender_id: null,
            sei: null,
            mei: null,
            position_name: null,
            old_name_or_age: null,
            time: Date.now(),   // キャッシュ対策。動的に変わる値を入れないと、配列がキャッシュを優先して表示してしまうため追加
        }
    };

    const initAddressJointName = (customer_id) => {
        return {
            id: null,
            customer_id: customer_id,
            address_id: null,
            sei: null,
            mei: null,
            position_name: null,
            honorifics: '様',
            time: Date.now(),   // キャッシュ対策。動的に変わる値を入れないと、配列がキャッシュを優先して表示してしまうため追加
        }
    };

    const getBlackSenderNameParts = () => {
        const arrBlankName = [
            {
                text: '山',
                name_type: Enum.NameType.SEI.val,
            },
            {
                text: '田',
                name_type: Enum.NameType.SEI.val,
            },
            {
                text: '太',
                name_type: Enum.NameType.MEI.val,
            },
            {
                text: '郎',
                name_type: Enum.NameType.MEI.val,
            }];

        let blank_sender_name_parts = [];
        for (const [index, data] of arrBlankName.entries()) {
            let name_part = new newChar();
            name_part.input_text = data.text;
            name_part.name_type = data.name_type;
            name_part.position = index;
            blank_sender_name_parts.push(name_part);
        }
        return blank_sender_name_parts;
    }

    /**
     * 空のデザイン面の差出人情報を返します
     * */
    const getBlankDesignSenderItem = () => {
        return {
            id: 'senderText',
            type: 'senderText',
            sender_id: null,
            guest_type: Enum.GuestType.PEOPLE.val,  // MEMO: 個人固定
            post_no: '5016218',
            pref_name: '岐阜県',
            address: '羽島市123-456',
            building_name: 'マンション789号室',
            tel: '',
            sei: '山田',
            mei: '太郎',
            old_name_or_age: '',
            sender_name_parts: _.cloneDeep(getBlackSenderNameParts()),
            display_sender_name_parts: _.cloneDeep(getBlackSenderNameParts()),
            sender_joint_name_parts: [],
            joint_names: [
                {
                    id: null,
                    sei: '',
                    mei: '花子',
                    old_name_or_age: '(旧姓：佐藤)',
                },
            ],
            corporate_name1: '',
            corporate_name2: '',
            department_name1: '',
            department_name2: '',
            position_name: '',
            addresses: [],
            is_design: 1,
            anchor: 'start',
            transform: 'translate(0px, 0px)',
            x: 0,
            y: 0,
            font: 'gothic',
            color: '#000000',
        };

    };

    return {
        filterIsUseGaiji,
        filterActiveJointNames,
        resetFormDataForGuestType,
        newChar,
        initJointName,
        initAddressJointName,
        convertToSenderJointNameParts,
        convertToAddressJointNameParts,
        getBlankDesignSenderItem
    }
}
