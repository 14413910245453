<template>
    <button @click="Save"
            class="l-footer__icon l-footer__icon--tmpSave px-12 pb-6 pt-8 inline-block">
    </button>
</template>

<script setup>
import {inject} from "vue";
import AuthFunctions from "../../../functions/auth";
import SaveDesignImageFunctions from "../../../functions/save_design_image";

const {getCustomer} = AuthFunctions();
const {SaveAsPicture} = SaveDesignImageFunctions();
const emit = defineEmits(['cancel', 'tmpValidateMessage', 'tmpMemberMessage', 'tmpSavePrevLoading', 'showAnimation', 'showAlertMessage']);
const makingItemInfo = inject('makingItemInfo');
const availableResources = inject('availableResources')
const editStatus = inject('editStatus');
const windowWidth = window.innerWidth;


const Save = async () => {
    // 一時保存時に、PCの時は前に選択されていたサイドメニューを保持する
    if (windowWidth < 640) {
        editStatus.activeSideMenu = 'none'
    }

    editStatus.neutralize()

    const customer = await getCustomer();

    // MEMO: 会員の場合、1 非会員の場合、0
    let memberNum = !customer || !Object.keys(customer).length ? 0 : 1;
    if (memberNum === 0) {
        emit('tmpMemberMessage', '内容を保存する');
    } else if (memberNum === 1) {
        // MEMO: 配置対象の画像の有効/無効チェック
        //  ※ 配置した画像が他の商品の画像リストから削除されている可能性があるので、ここでチェック
        let isDeleteDeployedImage = false;
        for (const deployed_img of makingItemInfo.deployedImages) {
            const result = await availableResources.isAvailableUserImage(deployed_img.image_file);
            // MEMO: 代理ログインの場合はスキップする
            if (!result && !customer.is_proxy_user) {
                // 複数枚画像リストから削除されている可能性もあるため途中でbreakしない
                makingItemInfo.removeDeployedImage(deployed_img.id)
                // MEMO: 画像リストから配置できない対象の画像を削除する
                availableResources.userImages = availableResources.userImages.filter((user_image) => {
                    return user_image.image_file !== deployed_img.image_file
                });
                isDeleteDeployedImage = true;
            }
        }

        // MEMO: 1枚でも削除されていたら配置画像削除モーダルを表示する。エラーがある場合は一度画面を確認してもらいため、購入にすすむモーダルは表示しない
        if (isDeleteDeployedImage) {
            // MEMO: FooterMenu→EditのshowAlertMessageを実行し表示する
            emit('showAlertMessage', ['他の商品でアップロードした写真が\n削除されたのでご確認ください。'])
            return;
        }

        emit('tmpSavePrevLoading', true);
        // MEMO: 宛先の画像、データ、差出人を含めてデータを保存（）
        // 鳥居: ロード画面とアニメーションの実行 Footerでアニメーションやモーダルを表示
        const save_result = await SaveAsPicture(makingItemInfo);

        // MEMO: 差出人の保存に失敗した場合（入力内容にエラーがある場合等）
        if (!save_result.result) {
            // MEMO: エラーメッセージの表示
            emit('tmpValidateMessage', save_result.message);
            // ローディング削除
            emit('tmpSavePrevLoading', false);
            return
        }

        // MEMO: 戻り値にある年賀IDをセットする
        makingItemInfo.nenga_id = save_result.data.nenga_id;

        // ローディング削除
        emit('tmpSavePrevLoading', false);
        // 保存用アニメーション表示
        emit('showAnimation', true);
    }
}

</script>

<style>

</style>