<template>
    <div class="p-edit__slide--full p-edit__stamp__repeatGridImage">
        <ul class="grid grid-cols-3 gap-3 sm:hidden pl-4 pr-4 bg-white">
            <li class="mt-4 font-bold text-blue02 text-left" @click="close">閉じる</li>
            <!-- <li class="mt-2 text-center"><img src="/images/icon/edit/rectangle.svg"></li> -->
            <!-- <li class="mt-4 font-bold text-blue02 text-right">決定</li> -->
        </ul>
        <div class="relative p-edit__sideMenu">
            <div class="px-4 pt-6 sm:pt-8 pb-8 bg-white text-base">
                <Swiper
                    ref="swiperTop"
                    class="swiper-stamp"
                    :centered-slides="false"
                    :slides-per-view="swiperCol"
                    :free-mode="true"
                    :loop="false"
                    :space-between="15"
                >
                    <SwiperSlide v-for="(cat, key) in stampCategories" :key="key">
                        <button @click="changeCategory"
                                :value="cat.id"
                                class="w-full c-button__stamp__category"
                                :class="{'is-active': isStampCategory === cat.id}"
                        >
                            {{ cat.name }}
                        </button>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div class="h-full overflow-y-auto u-scrollbar__none" ref="stampScroll">
                <ul class="grid grid-cols-3 sm:grid-cols-2 gap-2 px-2 sm:px-4 py-6 text-center font-bold p-edit__material">
                    <PhotoListThumbnail v-for="(image, index) in stamps" :key="index"
                                        @deployImage="deployImage(image)"
                                        @toggleMenu="toggleItemMenu"
                                        :deletable="false" :stampContent="true"
                                        :image-id="image.imageId.toString()"
                                        :imageUrl="image.stamp_image_thumbnail_file"
                                        :imageIndex="index"
                                        :menu-active="menuActiveItem"/>
                    <li ref="observe_element">&nbsp;</li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
import {inject} from "vue";
import PhotoListThumbnail from "./PhotoListThumbnail";
import config, {EditState} from "../../../const/const";
import axios from "axios";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css/free-mode';
import 'swiper/css';

export default {
    name: "SelectStamp",
    emits: ['close'],
    components: {
        PhotoListThumbnail,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            menuActiveItem: null,
            stampCategories: [],
            stamps: [],
            isStampCategory: '',
            isChangeCategory: false,
            windowWidth: '',
            page: 0,
            observer: null,
        }
    },
    setup() {
        const editStatus = inject('editStatus');
        const makingItem = inject('makingItemInfo');

        return {
            editStatus,
            makingItem,
        };
    },
    created() {
        this.windowWidth = window.innerWidth;
    },
    async mounted() {
        // MEMO: カテゴリの一覧取得
        // カテゴリの先頭を取得して、先頭のカテゴリに一致するスタンプの一覧を取得します
        this.stampCategories = await this.getStampCategory(this.makingItem.design_code);
        const category = this.stampCategories[0];
        this.isStampCategory = category.id;
        this.observer = new IntersectionObserver(async entries => {
            const entry = entries[0]
            if (entry && entry.isIntersecting) {
                // MEMO: カテゴリ変更した場合、「changeCategory」で先頭のページは取得している為、ページ数を次にする為カウントアップ
                if(this.isChangeCategory) {
                    this.isChangeCategory = false;
                    this.page++;
                }
                // MEMO: スタンプの一覧取得
                await this.getStamps(this.isStampCategory, this.page);
                this.page++;
            }
        })
        const observe_element = this.$refs.observe_element
        this.observer.observe(observe_element)
    },
    methods: {
        /**
         * カテゴリ一覧取得
         * */
        async getStampCategory(designCode)  {
            return await axios.get(config.API_BASE_URL + '/design_stamp_category/available/' + designCode)
                .then((res) => res.data)
                .catch(() => {
                  alert('スタンプカテゴリの取得に失敗しました。')
                });
        },
        /**
         * 指定されたカテゴリ/ページのスタンプ一覧取得
         * */
        async getStamps(category_id, page) {
            const addStamps =  await axios.get(config.API_BASE_URL + '/design_stamp/available/' + category_id + '/' + page)
                .then((res) => res.data)
                .catch(() => {
                    return false;
                });

            if(!addStamps) {
                alert('スタンプの取得に失敗しました。')
                return;
            }

            for(const stamp of addStamps) {
                this.stamps.push(stamp);
            }
        },
        /**
         * 選択されたカテゴリに一致するスタンプを表示する
         * */
        async changeCategory(e) {
            if (this.isStampCategory === Number(e.target.value) && this.stamps.length > 0) {
                return;
            }
            // 選択されたカテゴリのIDを取得
            this.isStampCategory = Number(e.target.value);

            // MEMO: スタンプのページをリセット
            this.page = 0;
            this.isChangeCategory = true;
            this.stamps.splice(0);
            await this.getStamps(this.isStampCategory, this.page);
        },
        /**
         * カテゴリを切り替えた時にスクロール位置を戻す
         */
        moveScrollTop() {
            this.$refs.stampScroll.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        async deployImage(img) {
            const img_to_deploy = Object.assign({}, img)
            img_to_deploy.id = img.imageId + '_' + Math.random().toString(32).substring(2)
            img_to_deploy.stamp_id = img.id
            img_to_deploy.transform = 'translate(0px, 0px)';
            this.makingItem.deployedStamps.push(img_to_deploy);
            this.editStatus.imageToDeploy = img_to_deploy
            await this.editStatus.setTargetObject(img_to_deploy.id, EditState.EDIT_OBJECT);
            
            if (this.windowWidth < 640) {
                this.close();
            }
        },
        toggleItemMenu(imageIndex) {
            this.menuActiveItem = imageIndex;
        },
        close() {
            this.toggleItemMenu(null);
            this.$emit('close');
        },
    },

    computed: {
        swiperCol() {
            const col = {
                pc: 2.5,
                sp: 2.75,
            };
            return this.windowWidth > 640 ? col.pc : col.sp;
        },
    },
}
</script>

<style>
.swiper-stamp > .swiper-wrapper {
    /* column-gap: 1rem; */
}
</style>