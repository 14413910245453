<template>
    <div class="p-edit__slide--quarter overflow-auto sm:pt-8">
        <div class="relative sm:top-0 sm:px-4">
            <div>
                <ul class="grid grid-cols-3 sm:hidden gap-3 sm:gap-24 pl-4 pr-4">
                    <li class="mt-4 font-bold text-blue02 text-left" @click="$emit('close')">閉じる</li>
                    <!-- <li class="mt-2 text-center"><img src="/images/icon/edit/rectangle.svg"></li> -->
                    <!-- <li class="mt-4 font-bold text-blue02 text-right">決定</li> -->
                </ul>
                <div class="">
                    <ul class="flex justify-center items-center sm:hidden mt-6 px-5 sm:px-0 text-xs text-center font-bold">
                        <!-- <li>
                            <button
                                @click="isSelect('text_size')"
                                :class="{'is-active': isTabChange === 'text_size'}"
                                class="text-base font-bold u-font__style__list"
                            >文字サイズ
                            </button>
                        </li> -->
                        <li>
                            <button
                                @click="isSelect('text_align')"
                                :class="{'is-active': isTabChange === 'text_align'}"
                                class="text-base font-bold u-font__style__list"
                            >文字揃え
                            </button>
                        </li>
                        <!-- <li>
                            <button
                                @click="isSelect('text_kind')"
                                :class="{'is-active': isTabChange === 'text_kind'}"
                                class="text-base font-bold u-font__style__list"
                            >文字の種類
                            </button>
                        </li> -->
                    </ul>

                    <!-- <div v-if="isTabChange === 'text_size' || windowWidth > 640">
                        <p class="hidden sm:block font-bold">文字サイズ</p>
                        <div class="pt-6">
                            <div class="flex justify-center sm:justify-start items-center">
                                <button
                                    @click="countDown"
                                    class="p-button__text__size is-left text-xs w-6"
                                >◀
                                </button>
                                <span class="p-edit__text__size__math">{{ count }}</span>
                                <button
                                    @click="countUp"
                                    class="p-button__text__size is-right text-0.75rem w-6"
                                >▶
                                </button>
                            </div>
                        </div>
                    </div> -->

                    <div v-if="isTabChange === 'text_align' ||  windowWidth > 640">
                        <p class="hidden sm:block font-bold">文字揃え</p>
                        <div class="mt-8 sm:mt-4">
                            <ul class="p-edit__textAlign__wrap">
                                <li>
                                    <button
                                        @click="setTextAnchor('start')"
                                        :class="[
                                            {'is-active': currentAnchor === 'start'},
                                            alignButtonClass('start'),
                                            ]"
                                        class="c-button__textStyle"
                                    >
                                    </button>
                                    <span class="text-xs font-bold pl-2">{{ isVerticalText ? '上' : '左' }}揃え</span>
                                </li>
                                <li>
                                    <button
                                        @click="setTextAnchor('middle')"
                                        :class="[
                                            {'is-active': currentAnchor === 'middle'},
                                            alignButtonClass('middle')
                                            ]"
                                        class="c-button__textStyle"
                                    >
                                    </button>
                                    <span class="text-xs font-bold pl-2">中央揃え</span>
                                </li>
                                <li>
                                    <button
                                        @click="setTextAnchor('end')"
                                        :class="[
                                            {'is-active': currentAnchor === 'end'},
                                            alignButtonClass('end')
                                            ]"
                                        class="c-button__textStyle"
                                    >
                                    </button>
                                    <span class="text-xs font-bold pl-2">{{ isVerticalText ? '下' : '右' }}揃え</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- <div v-if="isTabChange === 'text_kind' ||  windowWidth > 640">
                        <p class="hidden sm:block font-bold sm:pt-12">文字の種類</p>
                        <div class="mt-8 sm:mt-4">
                            <ul class="p-edit__textKind__wrap u-scrollbar__none">
                                <li class="inline-block">
                                    <button
                                        @click="isSelectKind('gothic')"
                                        :class="{'is-active': isChangeKind === 'gothic'}"
                                        class="c-button__textStyle c-button__textStyle--gothic"
                                    >
                                    </button>
                                    <span class="text-xs font-bold ml-1">ゴシック体</span>
                                </li>
                                <li class="inline-block ml-3 sm:ml-0">
                                    <button
                                        @click="isSelectKind('mincho')"
                                        :class="{'is-active': isChangeKind === 'mincho'}"
                                        class="c-button__textStyle c-button__textStyle--mincho"
                                    >
                                    </button>
                                    <span class="text-xs font-bold ml-1">明朝体</span>
                                </li>
                                <li class="inline-block ml-3 sm:ml-0">
                                    <button
                                        @click="isSelectKind('gyosho')"
                                        :class="{'is-active': isChangeKind === 'gyosho'}"
                                        class="c-button__textStyle c-button__textStyle--gyosho"
                                    >
                                    </button>
                                    <span class="text-xs font-bold ml-1">行書体</span>
                                </li>
                                <li class="inline-block ml-3 sm:ml-0">
                                    <button
                                        @click="isSelectKind('pop')"
                                        :class="{'is-active': isChangeKind === 'pop'}"
                                        class="c-button__textStyle c-button__textStyle--pop"
                                    >
                                    </button>
                                    <span class="text-xs font-bold ml-1">POP体</span>
                                </li>
                                <li class="inline-block ml-3 sm:ml-0">
                                    <button
                                        @click="isSelectKind('handwrite')"
                                        :class="{'is-active': isChangeKind === 'handwrite'}"
                                        class="c-button__textStyle c-button__textStyle--handwrite"
                                    >
                                    </button>
                                    <span class="text-xs font-bold ml-1">手書き風</span>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {computed, inject} from "vue";
import {VerticalWritingMode} from "../../../const/const";

export default {
    name: 'SelectTextStyle',

    setup() {
        const editStatus = inject('editStatus')
        const makingItem = inject('makingItemInfo')
        const currentTargetText = () => {
            if (editStatus.currentTargetTextId === 'senderText') {
                return makingItem.senderItem
            } else if (editStatus.currentTargetTextId !== '' &&
                editStatus.currentTargetTextId !== 'new_text') {
                for (const text of makingItem.userTexts) {
                    if (text.id === editStatus.currentTargetTextId) {
                        return text
                    }
                }
            }
            return false
        }
        const setTextAnchor = (anchor) => {
            const targetText = currentTargetText()
            if (!targetText) {
                return
            }
            targetText.anchor = anchor
        }
        const isVerticalText = () => {
            const targetText = currentTargetText()
            if (!targetText) {
                return
            }
            return targetText.writingMode === VerticalWritingMode
        }
        return {
            setTextAnchor,
            currentAnchor: computed(()=>currentTargetText().anchor),
            isVerticalText: computed(()=>isVerticalText()),
        }
    },

    data() {
        return {
            isTabChange: 'text_align',
            isChangeKind: 'gothic',
            count: 12,
            windowWidth: '',
        }
    },

    mounted() {
        window.addEventListener('DOMContentLoaded', this.loadWindow);
    },

    methods: {
        isSelect(menu) {
            this.isTabChange = menu;
        },
        isSelectKind(kind) {
            this.isChangeKind = kind;
        },

        countDown() {
            if (this.count > 6) {
                this.count--;
            }

        },
        countUp() {
            if (this.count < 32) {
                this.count++;
            }

        },
        loadWindow() {
            this.windowWidth = window.innerWidth
        },
        alignButtonClass(target_anchor) {
            const base_class = "c-button__textStyle__align--"
            const position = this.isVerticalText
                ? target_anchor === 'middle'
                    ? 'vertical-center'
                    : target_anchor === 'end'
                        ? 'vertical-bottom'
                        : 'vertical-top'
                : target_anchor === 'middle'
                    ? 'horizontal-center'
                    : target_anchor === 'end'
                        ? 'horizontal-right'
                        : 'horizontal-left'
            return base_class + position
        }
    },
}
</script>