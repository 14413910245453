<script>
import {nextTick, reactive} from "vue";
import {EditState} from "../../const/const";

const _neutralize = () => {
    edit_status.state = EditState.NEUTRAL;
    edit_status.currentTarget = '';
    edit_status.imageToDeploy = null;
    edit_status.flameToClip = ''
}

const set_target_object = async (objectId, nextState) => {
    edit_status.currentTarget = '';
    await nextTick();
    edit_status.currentTarget = objectId;
    await nextTick();
    edit_status.state = nextState;
}

const edit_status = reactive({
    state: EditState.NEUTRAL,
    currentTarget: '',
    currentTargetTextId: '',
    flameToClip: '',
    activeSideMenu: 'none',
    neutralize: _neutralize,
    setTargetObject: set_target_object,
});

export default {
    name: "EditStatus",
    editStatus: edit_status,
}
</script>