/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

//window.Vue = require('vue').default;

import {createApp} from 'vue';
import router from './router'
import utils from "./common/utils"; // MEMO: 共通関数
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

createApp({})
    .use(router)
    .use(FloatingVue)
    .mixin(utils)
    .mount('#app');
