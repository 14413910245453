<template>
    <div class="p-purchase__flow">
        <div class="p-purchase__box">            
            <div v-if="confirm" class="w-full bg-white">
                    <div class="grid grid-cols-3 items-center px-2 py-4">
                        <div class="block text-sm">
                            <button @click="returnScreenButton()"
                                    class="js-closeNameAndPrintPanel">
                                ＜戻る
                            </button>
                        </div>
                        <div class="block text-center text-xl font-bold">
                            <img src="/images/icon/header/ic_header-logo.svg" alt="my family" class="l-header__logo is-purchase mx-auto">
                        </div>
                    </div>
                </div>

            <div v-if="confirm">
                <div class="block text-xl font-bold text-center pt-8">
                    <p>差出人を登録しますか？</p>
                </div>
                <div class="pt-6 p-purchase__box--color">
                    <div>
                        <img src="/images/card/kyotsu_postcard_sender.jpg" alt="差出人 登録" class="mx-auto">
                    </div>
                    <div class="pt-8">
                        <div class="flex justify-center items-center gap-x-4 px-8">
                            <button @click="isSelect('no')"
                                :class="{'is-active': active === 'no'}"
                                class="w-full sm:w-1/3 text-center font-bold py-2.5 rounded-full p-purchase__senderRegister">
                                <span>登録しない</span>
                            </button>
                            <button @click="senderInput(); isSelect('yes')"
                                    :class="{'is-active': active === 'yes'}"
                                    class="w-full sm:w-1/3 text-center font-bold py-2.5 rounded-full p-purchase__senderRegister"
                                    >
                                    <span>登録する</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="w-full absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 px-4">
                    <div class="flex justify-center items-center gap-x-2">
                        <div class="w-full sm:w-48">
                            <button class="w-full text-center bg-blue02 text-white font-bold py-4 rounded-sm" @click="asItNext()">
                                <span>しない</span>
                            </button>
                        </div>
                        <div class="w-full sm:w-48">
                            <button class="w-full text-center bg-blue02 text-white font-bold py-4 rounded-sm" @click="senderInput()">
                                <span>登録する</span>
                            </button>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="p-purchase__position__button sm:relative w-full">
                <div class="bg-white py-2.5 sm:py-4 px-12">
                    <div class="w-full sm:max-w-sm block bg-blue02 rounded text-center sm:ml-auto sm:mr-auto">
                        <button class="w-full text-white font-bold py-2" @click="asItNext()">次へ</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="isShowSnederListPanel"
        class="z-2"
        :class="[
            {'is-active': isShowPanel},
            screenWidthAddClass
        ]"
    >
        <SenderList @close="senderListCloseSlide"></SenderList>
    </div>
</template>

<script>
    import SenderList from '../../pages/SenderList';
    export default {
        name: 'PurchaseFlowSender',

        components: {
            SenderList,
        },

        data() {
            return {
                confirm: true,
                isShowSnederListPanel: false,
                isShowPanel: false,
                windowWidth: '',
                active: 'no',
            }
        },

        emits: [
            'prevScreen',
            'noInputSenderAsItNext',
        ],

        methods: {
            returnScreenButton() {
                this.$emit("prevScreen", false);
            },

            senderInput() {
                this.isShowSnederListPanel = true;
                setTimeout(() => {
                    this.isShowPanel = true;
                }, 100);
            },

            asItNext() {
                this.$emit('noInputSenderAsItNext', true);
            },

            senderListCloseSlide() {
                this.isShowPanel = false;

                if (this.windowWidth > 640) {
                    this.isShowSnederListPanel = false;
                } else {
                    setTimeout(() => {
                        this.isShowSnederListPanel = false;
                    }, 300);
                }
            },

            isSelect(check) {
                this.active = check;
            }
        },

        computed: {
            screenWidthAddClass() {
                if (this.windowWidth >= 640) {
                    return ['p-purchase__flow'];
                } else {
                    return ['p-edit__slide--full'];
                }
            },
        },

        created() {
            this.windowWidth = window.innerWidth;
        }
    }
</script>

<style>

</style>