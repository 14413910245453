<template>
    <div v-if="showModal">
        <div class="fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 bg-white w-11/12 sm:max-w-lg z-4">
            <div class="py-8">
                <div class="px-8 text-sm font-bold">
                    <p>{{ validateMessage }}</p>
                </div>
                <div class="text-blue02 font-bold text-center pt-8">
                    <button @click="close()">
                        <span>閉じる</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="c-modal__overlay" :class="{ 'is-modal-active': showModal }"></div>
</template>

<script>
export default {
    name: 'EditValidateModal',

    props: {
        validateMessage: String,
        showModal: Boolean
    },

    emits: ['validateModal'],
    data() {
        return {

        }
    },

    methods: {
        close() {
            this.$emit('validateModal', false)
        },
    }
}
</script>