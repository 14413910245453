<template>
    <Header></Header>
    <section
            class="overflow-y-scroll h-full u-scrollbar__none l-wrapper bg-gray02 min-h-screen">
        <div>
            <div class="l-wrapper__container pt-8">
                <div class="px-4 mx-4 sm:mx-0 bg-white">
                    <div class="pt-8 sm:pt-16 pb-6 sm:pb-12 text-center">
                        <p class="text-xl sm:text-3xl font-bold">宛名の登録・一覧</p>
                    </div>
                    <div>
                        <div
                                class="sm:grid grid-cols-2 gap-x-4 flex justify-between items-center text-sm">
                            <div class="w-2/6 sm:w-full">
                                <button
                                        class="block h-20 w-full max-w-xs sm:max-w-none ml-auto mr-auto px-4 rounded-3xl sm:text-lg font-bold text-center text-white bg-orange02"
                                        @click="showAddressRegisterPanel(true)">
                                    <span>宛名の<br class="block sm:hidden">新規登録</span>
                                </button>
                            </div>
                            <div class="w-4/6 sm:w-full">
                                <button
                                    class="block h-20 w-full max-w-xs sm:max-w-none ml-auto mr-auto px-4 rounded-3xl sm:text-lg font-bold text-center text-white bg-blue02"
                                    @click="showOtherServicesButton()">他サービスから取り込む
                                </button>
                            </div>
                        </div>
                        <div class="text-xs py-4 leading-5">
                            <p>※他サービス（myピアプリ、myヒキタク、myWeb-Jo）から宛名を取り込むことができます。</p>
                        </div>
                    </div>
                </div>
                <section>
                    <div class="is-active pt-6 pb-24">
                        <div class="sm:max-w-2xl sm:ml-auto sm:mr-auto">
                            <div
                                class="flex pl-4 bg-white border-t border-x last:border-b border-gray03 overflow-hidden relative"
                                v-for="(address_item, index) in address_items" :key="address_item.id">

                                <button class="c-slide__operate py-2 pr-6 c-slide__operate__items"
                                        :class="{ 'is-active' : isShowSlideMenu && slide_menu_index === index }"
                                        @click="showSlideEditMenu(index)">
                                    <div>
                                        <div class="text-lg font-bold gothic" ref="addressNameParentsElement">
                                            <ListNamePreview
                                                :preview-data="this.nameForGothic(address_item)"/>
                                        </div>
                                        <div class="c-slide__operate__wrap mt-2">
                                            <span class="c-slide__operate__nowrap text-sm"
                                                  ref="addressInfo"> 〒{{ address_item.post_no }}
                                                {{
                                                    address_item.pref_name
                                                }}{{ address_item.address }}{{ address_item.building_name }}<br>
                                            </span>
                                            <!-- MEMO: 以下法人のみ表示-->
                                            <span v-if="address_item.guest_type === refEnum.GuestType.CORPORATION.val">
                                                <!-- {{ address_item.corporate_name1 }}
                                                {{ address_item.corporate_name2 }}
                                                {{ address_item.department_name1 }}
                                                {{ address_item.department_name2 }}
                                                {{ address_item.position_name }} -->
                                            </span>
                                        </div>
                                        <div v-if="hasErrors[address_item.id]" class="text-xxs text-red pt-1">
                                            <p>*入力内容に誤りがあります。右の編集ボタンから入力内容の確認をしてください。</p>
                                        </div>
                                        <p class="u-triangle__arrow"></p>
                                    </div>
                                </button>
                                <div class="u-sender__edit__items"
                                     :class="{ 'is-active' : isShowSlideMenu && slide_menu_index === index }">
                                    <div class="u-sender__edit__items--open__col3"
                                         :class="{ 'is-active' : isShowSlideMenu && slide_menu_index === index }">
                                        <button class="bg-beige c-button__open__slide"
                                                @click="showPreview(address_item); isModal = !isModal">
                                            <div>
                                                <img src="/images/icon/sender/sender_eye_icon.svg"
                                                     class="ml-auto mr-auto" alt="プレビュー">
                                                <p class="text-xs font-bold pt-2">プレビュー</p>
                                            </div>
                                        </button>
                                        <button class="bg-gray02 c-button__open__slide">
                                            <div
                                                @click="showAddressRegisterPanel(true, address_item)">
                                                <img src="/images/icon/sender/sender_edit_icon.svg"
                                                     class="ml-auto mr-auto" alt="編集">
                                                <p class="text-xs font-bold pt-2">編集</p>
                                            </div>
                                        </button>
                                        <button class="bg-red c-button__open__slide">
                                            <div
                                                @click="showDeleteConfBox(index, address_item.id)">
                                                <img src="/images/icon/sender/sender_delete_icon.svg"
                                                     class="ml-auto mr-auto" alt="削除">
                                                <p class="text-xs font-bold text-white pt-2">削除</p>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!--削除確認用ボックス-->
                            <div v-if="deleteStep">
                                <div
                                    class="fixed bg-white w-11/12 max-w-xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pt-8 pb-12 z-4">
                                    <div class="text-center font-bold">
                                        <p>選択された宛名を<br>削除してもよろしいですか？</p>
                                    </div>
                                    <div class="px-8">
                                        <div class="grid grid-cols-2 gap-x-2 pt-8 font-bold">
                                            <button @click="showDeleteConfBox()"
                                                    class="border border-gray03 py-2 px-4 rounded-3xl">
                                                <span>キャンセル</span>
                                            </button>
                                            <button @click="deleteAddress(this.selectSlideIndex, this.selectAddressId)"
                                                    class="flex justify-center items-center gap-x-2 bg-red text-white py-2 px-4 rounded-3xl">
                                                <img src="/images/icon/sender/sender_delete_icon.svg" alt="削除">
                                                <span class="block">削除</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <!--宛先削除実行時-->
                <div v-if="isShowMessage" class="fixed top-0 left-0 z-4 w-full h-full flex justify-center">
                    <div class="v-deleteModalBox" style="white-space: pre-wrap;">
                        <p>{{ message }}</p>
                        <div class="text-blue02 pt-10">
                            <button @click="closeDeleteModal()">閉じる</button>
                        </div>
                    </div>
                </div>

                <!-- MEMO: 宛名登録パネル-->
                <AddressRegisterPanel
                    :address-item="address_item"
                    v-if="isShowAddressRegisterPanel"
                    @cancel="closeAddressRegisterPanel"
                    :class="{'is-active': isCreateAndEditSlide}"
                />

                <!-- MEMO: プレビューモーダル -->
                <div v-if="isShowPreviewModal" class="sm:max-w-2xl sm:mx-auto c-modal__preview">
                    <div class="w-10/12 sm:w-80 mx-auto bg-white py-8 rounded-20">
                        <img class="px-6 z-10" :src="address_item_preview_image" alt="宛名プレビュー">
                        <div class="block text-center pt-6">
                            <a href="#" class="font-bold text-blue02" @click="closeModal()">閉じる</a>
                        </div>
                    </div>
                </div>

                <!-- MEMO: ピアプリ、ヒキタク、Web招待状から宛名データを取り込む -->
                <OtherServicesImport
                    v-if="isShowOtherServicesModal"
                    @closeOtherServices="closeOtherServicesModalAndPanel"
                    :class="{'is-active': isShowOtherServicesModal}"
                />
                <!-- MEMO: 取り込みの確認モーダル-->
                <OtherServicesImportConfModal
                    v-if="isShowOtherServiceImportConfModal"
                    :service-name="guestImportServiceName"
                    :class="{'is-active': isShowOtherServiceImportConfModal}"
                    @closeOtherServicesImportConfModal="closeOtherServicesImportConfModal"
                />
            </div>
        </div>
        <!-- 8/26 鳥居
            /edit/, /new/で、「編集」または「新規登録」のボタンを選択した時に、モーダルが立ち上がらないように制御
            /address_list/ではモーダルが立ち上がる条件にしています。
        -->
        <div
            class="c-modal__overlay"
            :class="[
                {'is-active': isCreateAndEditSlide},
                {'is-modal-active': isModal}
            ]"
        >
        </div>
    </section>
    <LoadingScreen :is-loading-screen="isLoadingScreen"></LoadingScreen>
</template>

<script>
import Header from "../components/Header";
import SearchSelectBox from "../components/SearchSelectBox";
import AddressRegisterPanel from "../components/AddressList/AddressRegisterPanel";
import SlideUpDown from 'vue3-slide-up-down'
import config, * as Enum from "../const/const";
import AuthFunctions from "../functions/auth";
import OtherServicesImport from "../components/OtherServicesImport";
import CommonModule from "../functions/common_module";
import LoadingScreen from "../components/LoadingScreen.vue";
import ListNamePreview from "../components/ListNamePreview.vue";
import OtherServicesImportConfModal from "../components/OtherServicesImportConfModal.vue";

const {
    getCustomer,
} = AuthFunctions();

const initAddressItem = (customer) => {
    return {
        id: '',
        customer_id: customer.id,
        guest_type: Enum.GuestType.PEOPLE.val,
        corporate_name1: '',     // 法人名(法人の時のみ使用)
        corporate_name2: '',     // 法人名(法人の時のみ使用)
        department_name1: '',    // 部署名(法人の時のみ使用)
        department_name2: '',    // 部署名(法人の時のみ使用)
        position_name: '',      // 役職名(法人の時のみ使用)
        sei: '',
        mei: '',
        sei_kana: '',
        mei_kana: '',
        address_name_parts: [],
        address_joint_name_parts: [],
        use_external_characters: [],
        joint_names: [],  // 連名
        post_no: '',    // 郵便番号
        pref_name: '',  // 都道府県名
        address: '',  // 市区町村名
        building_name: '',  // 建物・部屋番号
        honorifics: '様',   // 敬称(新規の場合、デフォルトで様を指定)
        sender_id: '',    // 差出人ID
        other_service_guest_id: '',    // 他サービスの会員ID
    }
};

export default {
    name: "AddressList",
    components: {
        OtherServicesImportConfModal,
        ListNamePreview,
        SlideUpDown,
        SearchSelectBox,
        Header,
        AddressRegisterPanel,
        OtherServicesImport,
        LoadingScreen,
    },
    props: {
        from_vue_name: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述
    },

    emits: ['close'],
    created() {
        this.windowWidth = window.innerWidth;
    },
    async mounted() {
        this.customer = await getCustomer();
        // MEMO: 会員情報の取得ができない場合、ECのログイン画面へ遷移
        if (!this.customer || !Object.keys(this.customer).length) {
            // MEMO: ここでECのログアウトへリダイレクトURLをつけて飛ばす
            window.location.href = process.env.MIX_EC_LOGOUT_URL
                + '?redirect_url=' + process.env.MIX_APP_URL
                + this.$route.path;
            return;
        }
        await this.getAddressList();
    },
    data() {
        return {
            customer: null,
            address_items: [],
            address_item: null,
            address_item_preview_image: '',
            isShowAddressRegisterPanel: false,      // 宛先登録コンポーネント
            isShowSlideMenu: false,
            isShowPreviewModal: false,
            isShowOtherServicesModal: false,    // 他のサービス選択モーダル
            isShowOtherServiceImportConfModal: false,
            isOpenOtherServicesPanel: false,
            slide_menu_index: 0,
            display: '',
            isCreateAndEditSlide: false, //SP_編集ボタン発火後のスライド
            isModal: false,
            windowWidth: 0,
            guestImportServiceName: '',
            maxFigure: 10,
            maxAddress: 20,
            isShowMessage: false,
            message: null,      // メッセージ用
            deleteStep: false,
            selectAddressId: '',
            selectSlideIndex: '',
            isLoadingScreen: false,
            hasErrors: [],
        }
    },
    methods: {
        /**
         * スライド削除ボタンを選択した後に表示する確認ボックス
         * @param {Number} index [スライドの番号]
         * @param {Number} address_id [選択された宛名番号]
         */
        showDeleteConfBox(index = null, address_id = null) {
            this.selectSlideIndex = index;
            this.selectAddressId = address_id;
            this.deleteStep = !this.deleteStep;
            this.isModal = !this.isModal;
        },
        /**
         * MEMO: 宛先の削除
         * */
        async deleteAddress(index, address_id) {
            this.isShowMessage = false;
            this.message = null;
            // 削除対象のスライドメニューを閉じる
            this.showSlideEditMenu(index);
            this.deleteStep = false;

            // 論理削除実行
            await axios.delete(config.API_BASE_URL + '/address_list/destroy/' + address_id)
                .then((response) => {
                    this.isShowMessage = true;
                    this.message = '宛先の削除が完了しました。';
                    // 宛先リストの再取得
                    this.getAddressList();
                })
                .catch((error) => {
                    this.isShowMessage = true;
                    this.message = '宛先の削除に失敗しました。';
                });
        },
        /**
         * MEMO: 名前をゴシック体でフィルタリングする
         * 一覧では、ゴシック体しか使用しないため取り除きます
         * */
        nameForGothic(address_item) {
            return address_item.address_name_parts.filter((item) => {
                return item.font_id === null || item.font_id === Enum.FONT.GOTHIC.id;
            }, this);
        },
        /**
         * MEMO: 宛先一覧の取得
         * */
        async getAddressList() {
            // MEMO: 個人用/法人用を検索条件に指定する
            const params = {
                customer_id: this.customer.id,
                guest_type: null,
            };

            this.isLoadingScreen = true;
            // MEMO: 一度配列をリセット
            this.address_items.splice(0);
            await axios
                .post(config.API_BASE_URL + '/address_list/search', params)
                .then((response) => {
                    this.hasErrors = response.data.validation_errors;
                    // MEMO: 取得した結果を配列に追加する
                    for (let i = 0; i < response.data.result.length; i++) {
                        this.address_items.push(response.data.result[i]);
                    }
                })
                .catch(function (error) {
                    console.log('failure');
                })
                .finally(() => {
                    this.isLoadingScreen = false;
                });
        },
        /**
         * MEMO: 宛名の登録パネルの表示/非表示
         * */
        async showAddressRegisterPanel(display = true, data) {
            setTimeout(() => {
                this.isCreateAndEditSlide = true;
            }, 100);

            // 一度Propsを初期化
            this.address_item = initAddressItem(this.customer);


            // 編集の場合
            if (data) {
                this.address_item = data;   // AddressRegisterPanelのPropsに渡す会員データをセット
            }

            // 登録画面の初期選択状態の値をセット
            // MEMO: 外部連携から取り込んだ宛名の場合NULLが入る可能性があるため。
            if (this.address_item.pref_name === null || this.address_item.pref_name === '') {
                this.address_item.pref_name = '未選択';
            }

            this.isShowAddressRegisterPanel = display;  // 登録フォームの表示/非表示
        },
        /**
         * 登録パネルのクローズ
         * */
        async closeAddressRegisterPanel(data = null, mode = 'cancel') {
            // MEMO: 編集の場合（新規の場合はここは呼ばれない）
            if (data && data.id) {
                if (mode === 'save') {
                    // MEMO: 編集→保存の場合はデータ再読み込み
                    await this.getAddressList();
                }
                if (this.address_item.pref_name === '未選択') {
                  this.address_item.pref_name = ''
                }
            }

            this.address_item = null;
            this.closeSlide();

            CommonModule.threePointLeader(this.$refs.addressNameParentsElement, Number(this.maxFigure));
            CommonModule.addressNameLimit(this.$refs.addressInfo, Number(this.maxAddress));
            this.isShowAddressRegisterPanel = false;
        },
        /**
         * MEMO: 各宛名の編集メニューのスライド表示
         * */
        showSlideEditMenu(index) {
            this.slide_menu_index = index;
            // 現在の行とクリックされた行が同じ場合、行の開閉をセット
            if (this.slide_menu_index === index) {
                this.isShowSlideMenu = !this.isShowSlideMenu;
            }
        },
        /**
         * MEMO: プレビューモーダルの表示
         * */
        async showPreview(data) {
            await axios
                .get(config.API_BASE_URL + '/address_side_image/saved_address/' + data.id.toString())
                .then((response) => {
                    this.address_item = data
                    this.address_item_preview_image = response.data
                    this.isShowPreviewModal = true
                    CommonModule.modalNotScroll(true)
                })
        },
        /**
         * 8/24 鳥居
         * 外部サービスから取り込み時のポップアップ表示 / 非表示
         */
        showOtherServicesButton() {
            setTimeout(() => {
                this.isOpenOtherServicesPanel = true;
            }, 100);
            this.isShowOtherServicesModal = !this.isShowOtherServicesModal;
            CommonModule.modalNotScroll(this.isShowOtherServicesModal);
        },
        /**
         * 他のサービス選択モーダルを閉じる
         * */
        closeOtherServicesModalAndPanel(serviceName) {
            this.isOpenOtherServicesPanel = true;
            CommonModule.modalNotScroll(this.isOpenOtherServicesPanel);
            if (serviceName === 'cancel') {
                setTimeout(() => {
                    this.isShowOtherServicesModal = !this.isShowOtherServicesModal;
                }, 300);
                CommonModule.modalNotScroll(false);
                return;
            }

            this.guestImportServiceName = serviceName;
            // MEMO: 確認モーダルの表示 と 他サービス選択モーダルの非表示
            this.isShowOtherServiceImportConfModal = !this.isShowOtherServiceImportConfModal;
            this.isShowOtherServicesModal = !this.isShowOtherServicesModal;
        },
        /**
         * 他サービスからの取り込み確認モーダルを閉じる
         * */
        async closeOtherServicesImportConfModal(mode) {
            if (mode === 'cancel') {
                // MEMO: 確認モーダルの非表示 と 他サービス選択モーダルの表示
                this.isShowOtherServiceImportConfModal = !this.isShowOtherServiceImportConfModal;
                this.isShowOtherServicesModal = !this.isShowOtherServicesModal;
            } else if (mode === 'error') {
                // MEMO: 確認モーダルの非表示 と 他サービス選択モーダルの非表示表示
                this.isShowOtherServiceImportConfModal = false;
                this.isShowOtherServicesModal = false;
                this.isShowMessage = true;
                this.isModal = true;
                this.message = '他サービスの取り込みに失敗しました。';
                // MEMO: スクロール可能にする
                CommonModule.modalNotScroll(false);
            } else if (mode === 'no_data') {
                // MEMO: 確認モーダルの非表示 と 他サービス選択モーダルの非表示表示
                this.isShowOtherServiceImportConfModal = false;
                this.isShowOtherServicesModal = false;
                this.isShowMessage = true;
                this.isModal = true;
                this.message = '他サービスにデータが無いため取り込みを中断しました。';
                // MEMO: スクロール可能にする
                CommonModule.modalNotScroll(false);
            } else {
                // MEMO: 処理が成功したとき
                // MEMO: 確認モーダルの非表示 と 他サービス選択モーダルの非表示表示
                this.isShowOtherServiceImportConfModal = false;
                this.isShowOtherServicesModal = false;
                this.isShowMessage = true;
                this.isModal = true;
                this.message = `${mode}からの取り込みが完了しました。\n※内容に間違いがないかご確認ください。`;

                // MEMO: 宛名の再取得
                await this.getAddressList();

                // MEMO: スクロール可能にする
                CommonModule.modalNotScroll(false);
            }
        },
        /**
         * 8/8 鳥居
         * 新規登録、編集をクリックした後に発火する登録画面を閉じる処理を追加
         */
        closeSlide() {
            setTimeout(() => {
                this.isCreateAndEditSlide = false;
            }, 100);
        },

        closeModal() {
            this.isShowPreviewModal = false
            CommonModule.modalNotScroll(false)
            this.isModal = false;
        },
        //宛先削除実行時
        closeDeleteModal() {
            this.isShowMessage = false;
            this.isModal = false;
        }
    },

    updated() {
        CommonModule.threePointLeader(this.$refs.addressNameParentsElement, Number(this.maxFigure));
        CommonModule.addressNameLimit(this.$refs.addressInfo, Number(this.maxAddress));
    },
}
</script>

<style scoped>
/* 論理削除実行モーダル*/
.v-deleteModalBox {
    width: 90%;
    max-width: 42rem;
    background-color: #FFF;
    padding: 2rem 0;
    font-weight: bold;
    text-align: center;
    position: fixed;
    top: calc(50% - 90px);
    z-index: 3;
    -webkit-animation: slideDown .3s ease .3s normal backwards;
    animation: slideDown .3s ease .3s normal backwards;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

</style>