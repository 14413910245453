<!--YesNo確認モーダル-->
<template>
    <div
        class="fixed w-full h-full left-1/2 top-1/2 z-100 flex justify-center items-center -translate-y-1/2 -translate-x-1/2" style="z-index:10000">
        <div class="v-validateBox">
            <div class="font-bold">
                <div class="px-8">
                    <div class="leading-7">
                        <p style="white-space: pre-line;" >{{ message }}</p>
                    </div>
                    <div :class="[useNoButton && useYesButton ? 'grid-cols-2' : '']"
                         class="grid gap-x-2 pt-8 font-bold" >
                        <button v-if="useNoButton" @click="close(false)"
                                class="border border-gray03 py-2 px-4 rounded-3xl">
                            <span>{{ noButtonText }}</span>
                        </button>
                        <button v-if="useYesButton" @click="close(true)"
                                class="flex justify-center items-center gap-x-2 text-white bg-blue02 py-2 px-4 rounded-3xl">
                            <span class="block">{{ yesButtonText }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-modal__overlay" :class="{'is-modal-active': isModalActive}"></div>
    </div>
</template>

<script>
export default {
    name: "YesNoModal",
    props: {
        isModalActive: Boolean,
        message: String,
        useYesButton: {
            default: true,
            required: false,
        },
        useNoButton: {
            default: true,
            required: false,
        },
        yesButtonText: {
            default: 'リセット',
            required: false,
        },
        noButtonText: {
            default: 'キャンセル',
            required: false,
        }
    },
    methods: {
        /**
         * モーダルを閉じる
         * */
        async close(flg) {
            // MEMO: 呼び出し元のCloseを呼び出す
            this.$emit('close', flg)
        },
    },
}
</script>

<style scoped>
.v-validateBox {
    width: 90%;
    max-width: 32rem;
    background-color: #FFF;
    padding: 2rem 0;
    font-weight: bold;
    text-align: center;
    position: fixed;
    top: calc(50% - 150px);
    z-index: 3;
    -webkit-animation: slideDown .3s ease .3s normal backwards;
    animation: slideDown .3s ease .3s normal backwards;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>