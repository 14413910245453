import * as Enum from "../const/const";
import config from "../const/const";
import ReplaceTextFunctions from "./replace_text";

const {replaceText} = ReplaceTextFunctions();
/**
 * プレビュー関係の共通関数ファイル
 * */
export default function () {
    const DrawDataType = {
        address: 'address',
        building_name: 'building_name',
        corporate_name1: 'corporate_name1',
        corporate_name2: 'corporate_name2',
        department_name1: 'department_name1',
        department_name2: 'department_name2',
        position_name: 'position_name',
        sei: 'sei',
        mei: 'mei',
        honorifics: 'honorifics',
        old_name_or_age: 'old_name_or_age',
        name: 'name',
        joint_name: 'joint_name',
        joint_name_old_name_or_age: 'joint_name_old_name_or_age',
        position_name_sei_space: 'position_name_sei_space',
        sei_mei_space: 'sei_mei_space',
        mei_honorifics_space: 'mei_honorifics_space',
    };

    /**
     * キャンバスの生成メソッド
     * @param {number} width
     * @param {number} height
     * @param {string} elm_id
     * @param {string} classes
     * */
    const createCanvas = (width, height, elm_id, classes) => {
        let canvas = document.createElement("canvas");
        canvas.id = elm_id;
        canvas.width = width;
        canvas.height = height;
        canvas.class = classes;

        return canvas;
    }

    /**
     * 指定されたキャンバスに描画する
     *
     * @param {CanvasRenderingContext2D} context キャンバスのコンテキスト
     * @param {*} text 描画する文字
     * @param {number} x 描画するX座標
     * @param {*} y 描画するY座標
     * @param {*} font_size フォントサイズ
     * @param {string} font フォント
     * @param {string} text_align 文字の寄せ方向
     * @param {string} text_baseline 文字のベースラインの指定
     * @param {boolean} use_replace replaceText関数を実行するかしないか
     */
    const drawCanvas = (context, text, x, y, font_size, font, text_align = 'right', text_baseline = 'top', use_replace = true) => {
        context.beginPath();
        context.font = font_size + "px " + font;
        context.textAlign = text_align;
        context.textBaseline = text_baseline;

        text = use_replace ? replaceText(text) : text;

        // MEMO: 縦書き下括弧の場合、
        if (text === '\u{FE36}') {
            // フォントサイズの半分だけ、Y座標を下にずらす
            context.fillText(text, x, y + (font_size / 2));
            return y;
        }
        context.fillText(text, x, y);
    };

    /**
     * 姓名エリア横幅の計算をする際に対象となるデータの正規表現を取得します
     *
     * @param {*[]} arrDrawTargets
     */
    const getSumHorizonRegexForNameArea = (arrDrawTargets) => {
        let name_area_sum_target_pattern = [
            'old_name_or_age',
            'name',
        ];

        // 指定された対象データに差出人 OR 宛先にキーが存在する場合 かつ 文字がある場合
        if (arrDrawTargets.some((item) => item.type === 'corporate_name1' && item.length)) {
            name_area_sum_target_pattern.push('corporate_name1');
        }
        if (arrDrawTargets.some((item) => item.type === 'corporate_name2' && item.length)) {
            name_area_sum_target_pattern.push('corporate_name2');
        }
        if (arrDrawTargets.some((item) => item.type === 'department_name1' && item.length)) {
            name_area_sum_target_pattern.push('department_name1');
        }
        if (arrDrawTargets.some((item) => item.type === 'department_name2' && item.length)) {
            name_area_sum_target_pattern.push('department_name2');
        }

        for (let i = 0; i < process.env.MIX_MAX_JOINT_NAME_FORM_NUMBER; i++) {
            const joint_name_type = 'joint_name_' + i;
            if (arrDrawTargets.some((item) => item.type === joint_name_type && item.length)) {
                name_area_sum_target_pattern.push(joint_name_type);
            }
            const joint_name_old_name_or_age = 'joint_name_old_name_or_age_' + i;

            if (arrDrawTargets.some((item) => item.type === joint_name_old_name_or_age && item.length)) {
                name_area_sum_target_pattern.push(joint_name_old_name_or_age);
            }
        }

        // 配列から対象の正規表現を生成する
        let pattern = "";
        name_area_sum_target_pattern.forEach((key, index, array) => {
            pattern += '^' + key;
            if (array.length - 1 !== index) {
                pattern += "|";
            }
        });
        return new RegExp(pattern);
    };

    /**
     * 横方向の最大フォントサイズの合計値を計算する
     *
     * @param {*[]} arrDrawTargets
     * @param {RegExp} sum_target_pattern 集計対象にする正規表現パターン（役職+姓名+敬称は行で描画するため、除外する）
     * */
    const getSumHorizonMaxFontSize = (arrDrawTargets, sum_target_pattern) => {
        // 行ごとの最大文字数の合計
        let sum_horizon_font_size = 0;
        // sum_target_patternに一致する項目の最大フォントサイズを合計する
        for (const [key, drawRowData] of Object.entries(arrDrawTargets)) {
            if (!drawRowData.length) {
                continue;
            }

            if (!sum_target_pattern.test(drawRowData.type)) {
                continue;
            }

            const row_data = drawRowData.data;
            // ******************************************
            // MEMO: 各スペースを除く最大フォントサイズを取得する
            // ******************************************
            const data = row_data
                .sort(function (a, b) {
                    return (a.font_size > b.font_size) ? -1 : 1;  // 文字サイズで降順に並び替え
                })
                .find((item) => !/space/.test(item.type));

            // MEMO: 行の中の最大文字サイズを取得する
            sum_horizon_font_size += data.font_size;
        }

        return sum_horizon_font_size;
    }

    /**
     * 姓名エリアの右側の余白を取得します
     * @param {*[]} drawData
     * @param name_setting
     * */
    const getNameAreaCenteredWhiteSpace = (arrDrawTargets, name_setting) => {
        // NameArea全体の幅
        const area_width = name_setting.start.x - name_setting.end.x;

        // 横の文字サイズの合計対象の正規表現を取得する
        const pattern = getSumHorizonRegexForNameArea(arrDrawTargets);

        // 横の文字サイズの合計
        const sum_horizon_font_size = getSumHorizonMaxFontSize(arrDrawTargets, pattern);

        // 全体の余白
        const sum_white_space = area_width - sum_horizon_font_size;

        // console.log(`area_width: ${area_width}
        // / sum_horizon_font_size: ${sum_horizon_font_size}
        // / sum_white_space: ${sum_white_space}
        // / ${sum_horizon_font_size + sum_white_space}
        // `)
        // 余白がある場合、余白を /2 して 先頭行に追加することで中央揃えを実現する
        // 余白がない場合は0を返す
        return area_width < sum_horizon_font_size ? 0 : sum_white_space / 2;
        // return sum_white_space;    // 余白を /2 して 先頭行に追加することで中央揃えを実現する
    };

    /**
     * 姓、連名(姓)が同じ場合、空文字に置換します
     * */
    const replaceDuplicateSei = (arrDrawTargets) => {
        const name = arrDrawTargets.find((item) => item.type === 'name');

        const full_text = name.data
            .filter((item) => item.type === 'sei')
            .map((item) => item.text).join('');
        if (!full_text) {
            return;
        }

        // 連名の各項目の最大文字数を求める
        const joint_names = arrDrawTargets.filter((item) => /^joint_name_[0-9]/.test(item.type));
        for (const [i, joint_name] of Object.entries(joint_names)) {
            const joint_full_text = joint_name.data
                .filter((item) => item.type === 'sei')
                .map((item) => item.text).join('');

            if (!joint_full_text) {
                continue;
            }

            // 姓と連名(姓)が同じ場合、空白に置換する
            if (full_text === joint_full_text) {
                joint_name.data
                    .filter((item) => item.type === 'sei')
                    .map((item) => item.text = ' ');
            }
        }
    };

    /**
     * 役職 + 姓名 + 敬称の縦の文字サイズを調整します
     * @param {object} arrDrawTargets 描画対象データ
     * @param {number} limit_height 行の最大高さ
     * */
    const adjustVerticalFontSizeForName = (arrDrawTargets, limit_height) => {
        const pattern = new RegExp('^name$|^joint_name_[0-9]$');

        // MEMO: 描画対象データから、姓名、連名に関する行データを取得し、文字数の多い順で並び替える
        const targets = arrDrawTargets
            .filter((item) => pattern.test(item.type) && item.length)
            .sort(function (a, b) {
                return (a.length > b.length) ? -1 : 1;  // 文字数の降順ソート
            });

        if (!targets.length) {
            return;
        }

        // MEMO: 最長の文字数を持つ行データのフォントサイズの合計(縦の長さ)を取得する
        const sum = targets[0].data.reduce((sum, item) => sum + parseInt(item.font_size), 0);

        // MEMO: 描画エリアの長さを超えていた場合、縮小する
        if (limit_height > sum) {
            return;
        }

        // MEMO: 対象となる姓名、連名に関する行のフォントサイズもまとめて小さくする
        for (let [i, target] of Object.entries(targets)) {
            target.data.map((item) => item.font_size = item.font_size > 1 ? item.font_size - 0.1 : item.font_size);
        }

        return adjustVerticalFontSizeForName(arrDrawTargets, limit_height);
    };

    /**
     * 縦の文字サイズを調整します
     *
     * @param {object} arrDrawTargets 描画対象データ
     */
    const adjustVerticalFontSize = (arrDrawTargets) => {
        const pattern = new RegExp('^name$|^joint_name_[0-9]$');

        for (const [i, drawRowData] of Object.entries(arrDrawTargets)) {
            if (!drawRowData.length) {
                continue;
            }
            // 姓名、連名はadjustVerticalFontSizeForName こっちで処理するため、除外
            if (pattern.test(drawRowData.type)) {
                continue;
            }

            const sum = drawRowData.data.reduce((sum, item) => sum + parseInt(item.font_size), 0);
            // 縦の高さの限界値を超えていたら、フォントサイズを-1する
            if (drawRowData.limit_height <= sum) {
                drawRowData.data.map((item) => item.font_size = item.font_size > 1 ? item.font_size - 0.1 : item.font_size);
                return adjustVerticalFontSize(arrDrawTargets);
            }
        }
    }

    /**
     * 横方向の文字サイズを調整します
     *
     * @param {*[]} arrDrawTargets
     * @param {number} width
     * @param {RegExp} sum_target_pattern 集計対象にする正規表現パターン（役職+姓名+敬称は行で描画するため、除外する）
     * @param {RegExp} scale_down_target_pattern 文字サイズを縮小する対象の正規表現パターン
     */
    const adjustHorizonFontSize = (arrDrawTargets, width, sum_target_pattern, scale_down_target_pattern) => {
        // MEMO: X軸の対象行の最大文字数を合計する
        const sum_horizon_font_size = getSumHorizonMaxFontSize(arrDrawTargets, sum_target_pattern);

        // 描画エリアの幅よりフォントサイズの合計値の方が大きい場合
        // フォントサイズを縮小して枠内に収まるように調整する
        if (width <= sum_horizon_font_size) {
            for (const [i, drawRowData] of Object.entries(arrDrawTargets)) {
                drawRowData.data
                    // 縮小対象を抽出
                    .filter((item) => scale_down_target_pattern.test(item.type))
                    // フォントサイズを小さくする
                    .map((item) => {
                        item.font_size = item.font_size > 1 ? item.font_size - 0.1 : item.font_size;
                    });
            }
            return adjustHorizonFontSize(arrDrawTargets, width, sum_target_pattern, scale_down_target_pattern);
        }
        return true;
    }

    /**
     * 指定されたキーに一致する値の最大文字数を返します。
     *
     * @param {object} item address_item or sender_item
     * @param {object} keys 対象となる連想配列のキー配列
     * */
    const alignLength = (item, keys) => {
        for (const key of keys) {
            const max_length = getMaxLength(item, key);
            if (!max_length) continue;
            padSpace(item, key, max_length);
        }
    };

    /**
     * 指定されたキーに一致する値の最大文字数を返します。
     *
     * @param {object} item address_item or sender_item
     * @param {string} key address_item or sender_item の連想配列のキー
     * */
    const getMaxLength = (items, key) => {
        let max_length = 0;
        const item = items.find((item) => item.type === key);

        // 指定されたキーの値がitemに存在しない場合は0
        if (typeof item === 'undefined') {
            max_length = 0;
        } else {
            // MEMO: 指定されたキーに一致する値がある場合は文字数をセット
            max_length = item.data.length;
        }

        const joint_name = items.find((item) => item.type === DrawDataType.joint_name);

        // 連名がない場合はここまでの最大文字数を返す
        if (typeof joint_name === 'undefined') {
            return max_length;
        }
        // 連名情報の中に指定されたキーに一致する値がある場合
        for (const [i, joint_name_item] of joint_name.data.entries()) {

            const item = joint_name_item.find((item) => item.type === key);
            // タイプが一致しない場合はスキップ
            if (typeof item === 'undefined') {
                continue;
            }
            if (max_length < item.data.length) {
                max_length = item.data.length;
            }
        }
        return max_length;
    }

    /**
     * 指定されたキーに一致する値の最大文字数を返します。
     *
     * @param {object} items
     * @param {string} key address_item or sender_item の連想配列のキー
     * @param {number} length 桁埋めする数
     * */
    const padSpace = (items, key, length) => {
        const diff = (src, dist) => {
            return src - dist;
        };

        let padCnt = length;
        const item = items.find((item) => item.type === key);
        // タイプに一致するデータがある場合
        if (typeof item !== 'undefined') {
            padCnt = diff(length, item.data.length);
            for (let i = 0; i < padCnt; i++) {
                item.data.push({
                    type: key, text: ' ',
                    font: 'kaisho', font_size: item.font_size
                });
            }
        }

        // 連名がない場合は桁あわせ終了
        if (!items.some((item) => item.type === DrawDataType.joint_name)) {
            return;
        }

        const joint_name = items.find((item) => item.type === DrawDataType.joint_name);

        for (const [i, joint_name_item] of joint_name.data.entries()) {
            let padCnt = length;

            const item = joint_name_item.find((item) => item.type === key);
            if (typeof item === 'undefined') {
                continue;
            }

            if (item.data) {
                padCnt = diff(length, item.data.length);
            }

            for (let i = 0; i < padCnt; i++) {
                item.data.push({
                    type: key, text: ' ',
                    font: 'kaisho', font_size: item.font_size
                });
            }
        }
    }

    /**
     * 描画対象のデータから、指定されたパターンに一致する項目の中で、最小の文字サイズを返します。
     * @param {*} arrDrawTargets
     * @param {RegExp} pattern
     * */
    const getMaxFontSize = (arrDrawTargets, pattern) => {
        let max_font_size = 0;

        Object.entries(arrDrawTargets).forEach(([key, drawRowData]) => {
            if (!drawRowData.data.length) {
                return;
            }
            drawRowData.data.filter((item) => {
                if (pattern.test(item.type)) {

                    const font_size = item.font_size;
                    if (max_font_size === 0 || max_font_size < font_size) {
                        max_font_size = font_size;
                    }
                }
            })
        });

        return max_font_size;
    };

    /**
     * 描画対象のデータから、指定されたパターンに一致する項目の中で、最小の文字サイズを返します。
     * @param {*} arrDrawTargets
     * @param {RegExp} pattern
     * */
    const getMinFontSize = (arrDrawTargets, pattern) => {
        let min_font_size = 0;

        Object.entries(arrDrawTargets).forEach(([key, drawRowData]) => {
            if (!drawRowData.data.length) {
                return;
            }

            drawRowData.data.filter((item) => {
                if (pattern.test(item.type)) {
                    // console.log(drawRowData)
                    const font_size = item.font_size;
                    if (min_font_size === 0 || min_font_size > font_size) {
                        min_font_size = font_size;
                    }
                }
            })
        });

        return min_font_size;
    };

    /**
     *
     * */
    const evenNameType = (item, name_type) => {
        return item.use_external_characters.filter(function (item) {
            return item.name_type === name_type;
        });
    };

    /**
     * 姓 名を文字と使用するフォントの連想配列に変換します
     * @param { DrawDataType} type
     * @param {{[p: string]: T}} data
     * @param { string } font
     * @param { integer } font_size
     */
    const convertToDrawTargetDataForName = async (type, data, font, font_size) => {
        let return_texts = [];

        for (const [i, name_datum] of Object.entries(data)) {

            let text = name_datum.input_text;

            text = text.replace(/-|ー/g, '丨')
                .replace(/\（|\(/g, '\u{FE35}')
                .replace(/\）|\)/g, '\u{FE36}');

            if (!name_datum.is_use_gaiji) {
                return_texts.push({type: type, text: text, font: font, font_size: font_size});
                continue;
            }
            // MEMO: 漢字の場合はgaijiを使用する
            if (name_datum.gaiji_type === Enum.ExternalCharType.KANJI.val) {
                return_texts.push({type: type, text: name_datum.gaiji, font: font, font_size: font_size});
                continue;
            }

            // MEMO: 外字の場合 かつ フォントの場合はpiary_gフォントに変更し楷書用の外字コードを取得する
            const gaiji_character_code = await search(name_datum.input_text, Enum.FONT.KAISHO.id, name_datum.gaiji_type);
            if (gaiji_character_code) {
                return_texts.push({
                    type: type,
                    text: String.fromCodePoint(gaiji_character_code),
                    font: 'piary_g',
                    font_size: font_size
                });
            }
        }
        return return_texts;
    }

    /**
     * 外字検索
     * @param {*} word
     * @param {number|string} font_id
     * @param {*} gaiji_type
     */
    const search = async (word, font_id, gaiji_type) => {

        const params = {
            word: word,
            font_id: font_id,
            gaiji_type: gaiji_type
        };

        // MEMO: 指定されたフォントの外字コードを取得する
        const response = await axios.post(config.API_BASE_URL + '/external_character/search', params)
            .then((response) => {
                return response.data
            })
        return await response[0].gaiji_character_code;
    }

    /**
     * 文字列を文字と使用するフォントの連想配列に変換します
     * */
    const convertStringToDrawTargetData = (type, data, font_size, font = 'kaisho') => {
        if (!data) {
            return [];
        }
        const arrText = Array.from(data);
        let ret = [];
        for (let text of arrText) {

            text = text.replace(/-|ー/g, '丨')
                .replace(/\（|\(/g, '\u{FE35}')
                .replace(/\）|\)/g, '\u{FE36}');
            if (text === '\u{FE35}' || text === '\u{FE36}') {
                //    context.fillText(text, x, y + font_size / 2);
            }
            ret.push({type: type, text: text, font: font, font_size: font_size});
        }
        return ret;
    };

    /**
     * SVGに埋め込むための郵便番号画像のURLを生成します
     * @param {object} canvas   描画対象のキャンバス
     * @param {array} post_no_texts
     * @param {number} text_space
     * @param {number} draw_start_x
     * @param {number} draw_start_y
     * @param {number} font_size
     * @param {string} font
     * */
    const createPostNoDataUrl = (canvas, post_no_texts, text_space, draw_start_x, draw_start_y, font_size, font) => {
        // 郵便番号
        const context = canvas.getContext("2d");
        let x = draw_start_x;

        for (let i in post_no_texts) {
            const text = post_no_texts[i];
            x = x + font_size;
            if (i !== 0) {
                x = x + text_space; // MEMO: 横位置の微調整のために、加算
            }
            drawCanvas(context, text, x, draw_start_y, font_size, font, undefined, undefined, false);
        }
        return context.canvas.toDataURL()
    };

    return {
        DrawDataType,
        createCanvas,
        drawCanvas,
        getSumHorizonMaxFontSize,
        getNameAreaCenteredWhiteSpace,
        getSumHorizonRegexForNameArea,
        replaceDuplicateSei,
        adjustVerticalFontSizeForName,
        adjustVerticalFontSize,
        adjustHorizonFontSize,
        getMaxFontSize,
        getMinFontSize,
        alignLength,
        evenNameType,
        convertToDrawTargetDataForName,
        convertStringToDrawTargetData,
        createPostNoDataUrl
    }
}
