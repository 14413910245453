<template>
    <image :id="idOrTarget" :href="this.user_image_url"
           :width="width"
           :height="height"
           preserveAspectRatio="xMidYMid meet"
           :clip-path="clipPathUrl"
           :style="editableImageStyle"
           :class="clip_path"/>
</template>

<script>
export default {
    name: "MoveableUserImage",
    props: {
        contentsId: String,
        moveableTarget: String,
        user_image_url: String,
        clip_path: String,
        transform: String,
        width: Number,
        height: Number,

        stampWidth: Number,
        stampHeight: Number,
        uploadImageWidth: Number,
        uploadImageHeight: Number,
        stampObject: {
            type: Boolean,
            default: false,
        },
        userImageAndMaterial: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        idOrTarget() {
            if (this.contentsId === this.moveableTarget) {
                return 'target';
            }
            return this.contentsId;
        },
        clipPathUrl() {
            return this.clip_path === '' ? '' : `url('#${this.clip_path}')`;
        },
        editableImageStyle() {
            return {
                mixBlendMode: this.mixBlendMode,
                top: '0',
                left: '0',
                transform: this.transform,
            }
        },
        mixBlendMode() {
            if (this.stampObject) {
                return 'normal'
            }
            return this.idOrTarget === "target" ? 'multiply' : 'normal'
        },

        /**
         * スタンプ、アップロード画像の固定幅
         */
        fix() {
            const stamp = {
                'width': this.stampWidth,
                'height': this.stampHeight,
            };
            const image = {
                'width': this.uploadImageWidth,
                'height': this.uploadImageHeight,
            }

            return this.stampObject === true ? stamp : image;
        },
    },
}
</script>

<style scoped>

</style>