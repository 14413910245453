<template>
    <g :id="idOrTarget" :style="textBoxStyle">
        <text :style="textObjectStyle"
              :text-anchor="anchor"
              :writing-mode="writingMode"
              dominant-baseline="auto">
            <TextLine v-for="line in TextContent" :key="line.line_id"
                      :x="line.x"
                      :y="line.y"
                      :contents="line.contents"
                      :is_vertical="isVertical"
            ></TextLine>
        </text>
    </g>
</template>

<script>

import TextLine from "./TextLine.vue";
import {VerticalWritingMode} from "../../../const/const";

const textLine = (txt, line_no, font, size, x, y, is_vertical) => {
    return {
        line_id: line_no,
        contents: [
            {
                font: font,
                size: size,
                text: txt,
            },
        ],
        x: is_vertical ? (1.75 * line_no + 1) * size * -1 : x,
        y: is_vertical ? y : (1.75 * line_no + 1) * size,
    };
}

export default {
    components: {TextLine},
    props: {
        contentsId: String,
        moveableTarget: String,
        text: String,
        text_contents: Array,
        font: String,
        x: Number,
        y: Number,
        size: Number,
        transform: String,
        anchor: String,
        color: String,
        writingMode: String,
    },
    name: "MoveableTextBox",
    computed: {
        idOrTarget() {
            if (this.contentsId === this.moveableTarget) {
                return 'target';
            }
            return this.contentsId;
        },
        isVertical() {
            return this.writingMode === VerticalWritingMode
        },
        TextContent() {
            if (this.text_contents) {
                return this.text_contents
            }
            const texts_arr = this.text.split('\n');
            return texts_arr.map((txt, index) => {
                return textLine(txt, index, this.font, this.size, this.x, this.y, this.isVertical)
            });
        },
        textBoxStyle() {
            return {
                'transform': this.transform,
            }
        },
        textObjectStyle() {
            return {
                'fill': this.color ? this.color : '#000000',
                'text-rendering': 'geometricPrecision',
                'writing-mode': this.writingMode,
            }
        },
        textFragmentStyle() {
            return {
                'letter-spacing': '0px',
            }
        },
    },
    watch: {
        /**
         * テキストオブジェクトのtext_anchorが変更になったらイベントを上に伝えてテキストのX座標を見直してもらう
         * */
        anchor: function (anchor, prev_anchor) {
            if (anchor !== prev_anchor) {
                this.$emit('changeTextAnchor', {anchor: anchor, prev_anchor: prev_anchor})
            }
        },
        /**
         * 差出人の入力内容の変更検知
         * */
        text_contents: function () {
            // MEMO: 配置されている差出人を削除した場合は、赤枠を非表示にするためselectNodeToEditは実行しない
            if(this.text_contents.length !== 0) {
                // MEMO: 変更を検知したら赤枠のサイズも文字に合わせて変更させるため、UserObjectDeployLayerのselectNodeToEditを呼び出す
                this.$emit('selectNodeToEdit', null);
            }
        }
    },
}
</script>

<style scoped>
g {
    stroke-width: 0px;
}

g:hover {
    /* stroke-width: 1px;
    stroke: red;
    stroke-dasharray: 2 3; */
}
</style>
