<!--
    MEMO: 宛名プレビュー用SVG
-->
<template>
    <svg id="img00" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         :width="svgWidth"
         :height="svgHeight"
         :viewBox="viewBox">
        <defs>
            <clipPath id="clip-path">
                <path
                    d="M29.61,95.37V161.3H75.38V95.37Zm22.88,52.39A21.53,21.53,0,1,1,74,126.23,21.53,21.53,0,0,1,52.49,147.76Z"
                    style="fill: none"/>
            </clipPath>
        </defs>
        <!-- MEMO: ハガキ背景 ここから-->
        <g v-if="materialIs('')">
            <image href="/images/card/atena_kyotu.svg" :width="svgWidth" :height="svgHeight"></image>
        </g>
        <g v-if="materialIs('B')">
            <image href="/images/card/atena_kansei.svg" :width="svgWidth" :height="svgHeight"></image>
        </g>
        <g v-if="materialIs('A')">
            <circle cx="52.49" cy="126.23" r="20.11" style="fill: none;stroke: #c41c21;stroke-miterlimit: 10"/>
            <rect width="283.46" height="419.53" style="fill: none"/>
            <path d="M282.46,1V418.53H1V1H282.46m1-1H0V419.53H283.46V0Z" style="fill: #727171"/>
            <g>
                <path
                    d="M125.74,21.44l-1.14,0a.14.14,0,0,1-.09,0s0,0,0-.08V20.8c0-.05,0-.08,0-.09s0,0,.08,0h.45v-.95h-.77a.08.08,0,0,1-.09-.09v-.62a.08.08,0,0,1,.09-.09h.77v-.89h-.45l-.08,0s0,0,0-.08v-.55a.08.08,0,0,1,.09-.09l1.15,0h.59v-.67c-.47.06-.9.1-1.31.12h-.13q-.12,0-.15-.06a.79.79,0,0,1-.08-.22,1.52,1.52,0,0,0-.22-.46,15.36,15.36,0,0,0,3.27-.41,2.49,2.49,0,0,0,.49-.17,3,3,0,0,1,.45.56.4.4,0,0,1,.07.16q0,.09-.18.09l-.33,0c-.32.08-.67.16-1,.22v.8h.52l1.13,0c.07,0,.1,0,.1.09V18c0,.05,0,.08,0,.09h-.44V19H129a.17.17,0,0,1,0,.08v.62s0,.07,0,.08h-.53v.94h.34a.14.14,0,0,1,.09,0,.22.22,0,0,1,0,.09v.56s0,.07,0,.08a.14.14,0,0,1-.09,0l-1.13,0h-.51v.73c.69-.11,1.25-.21,1.67-.3a2.59,2.59,0,0,0-.06.52.45.45,0,0,1,0,.27.53.53,0,0,1-.25.07q-1.49.29-3.18.54a1.24,1.24,0,0,0-.39.09.52.52,0,0,1-.2.07s-.08,0-.11-.14c-.07-.27-.13-.56-.18-.85a8.81,8.81,0,0,0,1-.06c.22,0,.52-.05.9-.09v-.85Zm.07-2.46h.53v-.9h-.53Zm0,1.73h.53v-.95h-.53Zm1.37-2.63V19h.54v-.9Zm0,2.63h.54v-.95h-.54Zm3,.92h.09c.76,0,1.14-.25,1.14-.73a1.8,1.8,0,0,0-.24-.92,4.82,4.82,0,0,0-.88-1,16.76,16.76,0,0,0,.9-2.33h-1.09v5l0,1.89c0,.06,0,.08-.1.08h-.81s0,0,0-.07l0-1.88V17.84l0-1.9a.14.14,0,0,1,0-.09h.08l.81,0h1.26a.67.67,0,0,0,.23,0,.39.39,0,0,1,.14,0c.07,0,.19.09.37.27a.66.66,0,0,1,.22.36.27.27,0,0,1-.08.17.79.79,0,0,0-.19.33c-.3.79-.57,1.43-.8,1.92a3.49,3.49,0,0,1,.91,1.16,2.24,2.24,0,0,1,.17.95c0,.92-.53,1.43-1.58,1.53h-.16c-.07,0-.11,0-.13-.06a1,1,0,0,1,0-.22A1.27,1.27,0,0,0,130.23,21.63Z"
                    style="fill: #d0161f"/>
                <path
                    d="M141.73,18.92a8.17,8.17,0,0,0,1.55-2.86,2.67,2.67,0,0,0,.13-.65,6.89,6.89,0,0,1,.86.25c.1,0,.15.08.15.14a.46.46,0,0,1-.1.17.84.84,0,0,0-.18.35c-.15.43-.31.83-.47,1.2v4.72l0,1.37a.13.13,0,0,1,0,.08l-.08,0h-.74a.14.14,0,0,1-.09,0,.54.54,0,0,1,0-.08l0-1.37V20.3c0-.53,0-.93,0-1.2-.16.21-.32.42-.49.62s-.13.12-.17.12-.08-.05-.1-.15A3.17,3.17,0,0,0,141.73,18.92Zm4-2.17h-1.23s-.07,0-.09,0a.54.54,0,0,1,0-.08V16a.14.14,0,0,1,0-.09h.08l1.23,0h3l1.25,0c.06,0,.08,0,.08.1v.65c0,.05,0,.08,0,.09h-2.57v.61h.79l1.2,0s.08,0,.09,0,0,0,0,.08l0,.84v1.46l0,.85s0,.07,0,.08h-2.18c0,.25-.08.46-.12.61a3.19,3.19,0,0,1-.32.74,9.23,9.23,0,0,0,3.34.6,1.92,1.92,0,0,0-.23.62.67.67,0,0,1-.08.24s-.07,0-.14,0h-.11a7.87,7.87,0,0,1-3.33-.86,4.89,4.89,0,0,1-1.86.91l-.21.05c-.06,0-.12-.08-.18-.23a2.1,2.1,0,0,0-.29-.58,5.14,5.14,0,0,0,1.8-.65,5,5,0,0,1-.83-.88.09.09,0,0,1,0-.06s0-.05.09-.1l.42-.28a.21.21,0,0,1,.09-.05s0,0,.08.06a4.19,4.19,0,0,0,.73.73,2.07,2.07,0,0,0,.18-.37,3.49,3.49,0,0,0,.15-.54h-1.93a.17.17,0,0,1,0-.08V17.45c0-.05,0-.08,0-.09a.15.15,0,0,1,.08,0l1.21,0h.73v-.61Zm.89,1.93v-.62h-1.18v.62Zm0,.66h-1.18V20h1.16C146.59,19.74,146.6,19.53,146.6,19.34Zm.84.64h1.26v-.64h-1.24C147.46,19.58,147.45,19.79,147.44,20Zm0-1.3h1.23v-.62h-1.23Z"
                    style="fill: #d0161f"/>
                <path
                    d="M160.83,16a6.17,6.17,0,0,1,1,.32.21.21,0,0,1,.13.18.49.49,0,0,1-.06.2,2.06,2.06,0,0,0-.16.5,16.19,16.19,0,0,0-.45,3.27c0,.52,0,.9,0,1.15l.29-.65c.11-.24.2-.44.26-.6a3.12,3.12,0,0,0,.32.57.18.18,0,0,1,.05.13.22.22,0,0,1-.05.14,5.54,5.54,0,0,0-.46,1.37,1.37,1.37,0,0,0,0,.28,1.56,1.56,0,0,0,0,.2v0s0,.07-.11.09a3.9,3.9,0,0,1-.67.16h-.07c-.05,0-.08,0-.11-.12a7.72,7.72,0,0,1-.39-2.75,22.76,22.76,0,0,1,.37-3.41,6.35,6.35,0,0,0,.08-.81A2,2,0,0,0,160.83,16ZM165,20.75c0-.5,0-1.19,0-2.07l-1,0q-.5,0-.87,0c-.1,0-.15,0-.16-.12a5.17,5.17,0,0,1-.06-.82,11.76,11.76,0,0,0,1.2.06l.85,0v-.95a4.15,4.15,0,0,0-.12-.88,5.8,5.8,0,0,1,1.09.17c.11,0,.16.08.16.15a.48.48,0,0,1-.05.16.89.89,0,0,0-.07.31c0,.21,0,.52,0,1,.4,0,.83-.09,1.28-.17h.05s.08,0,.09.11a2.85,2.85,0,0,1,0,.64c0,.08,0,.13-.1.14a8.94,8.94,0,0,1-1.38.19c0,.88,0,1.69.06,2.41a6.16,6.16,0,0,1,1.5.86s.07.06.07.09a.23.23,0,0,1,0,.09,5,5,0,0,1-.41.7.15.15,0,0,1-.08.05s0,0-.08-.05a6.11,6.11,0,0,0-1-.72q-.12,1.17-1.65,1.17a1.83,1.83,0,0,1-1.25-.38,1.12,1.12,0,0,1-.36-.87,1.11,1.11,0,0,1,.5-1,1.78,1.78,0,0,1,1.07-.31A3.38,3.38,0,0,1,165,20.75Zm0,.85a2.54,2.54,0,0,0-.78-.12c-.45,0-.68.15-.68.43s.25.46.73.46a.78.78,0,0,0,.56-.2.48.48,0,0,0,.18-.39A1,1,0,0,0,165,21.6Z"
                    style="fill: #d0161f"/>
                <path
                    d="M179.8,18c.09-.33.18-.71.26-1.14a5.21,5.21,0,0,0,.1-1,9.44,9.44,0,0,1,1.07.3.18.18,0,0,1,.15.18.18.18,0,0,1-.06.13,1,1,0,0,0-.18.35c0,.13-.1.32-.18.57s-.11.39-.15.51a5.39,5.39,0,0,1,.72,0c.86,0,1.29.58,1.29,1.73a9.1,9.1,0,0,1-.15,1.64,4.5,4.5,0,0,1-.34,1.19,1.26,1.26,0,0,1-1.21.84,2.4,2.4,0,0,1-.94-.14.23.23,0,0,1-.16-.25,3,3,0,0,0-.17-.83,2.5,2.5,0,0,0,1,.23.55.55,0,0,0,.36-.11,1.2,1.2,0,0,0,.21-.37,6.24,6.24,0,0,0,.35-2.24,1.27,1.27,0,0,0-.12-.67.47.47,0,0,0-.39-.16,6.45,6.45,0,0,0-.76.07A26.61,26.61,0,0,1,178.73,23c0,.07-.07.11-.13.11l-.1,0a2.46,2.46,0,0,1-.62-.4.19.19,0,0,1-.07-.13.17.17,0,0,1,0-.1,20.8,20.8,0,0,0,1.65-3.53l-.88.15a3.51,3.51,0,0,0-.5.15l-.11,0a.2.2,0,0,1-.18-.14q-.12-.33-.27-.93c.33,0,.72,0,1.18-.06S179.41,18,179.8,18Zm3.1-.08c.34-.22.58-.38.73-.46h.06a.17.17,0,0,1,.11.06,5.82,5.82,0,0,1,1.49,2.19.13.13,0,0,1,0,.07.15.15,0,0,1-.06.11,3.2,3.2,0,0,1-.68.44.16.16,0,0,1-.09,0,.1.1,0,0,1-.11-.1A6.11,6.11,0,0,0,182.9,17.88Zm.5-1.38.47-.33a.09.09,0,0,1,.06,0l.08,0a3.91,3.91,0,0,1,.85,1,.09.09,0,0,1,0,.06.1.1,0,0,1,0,.08l-.38.28-.07,0a.12.12,0,0,1-.09-.06A4.44,4.44,0,0,0,183.4,16.5Zm.83-.6.44-.32.06,0a.12.12,0,0,1,.08,0,3.42,3.42,0,0,1,.85.93.11.11,0,0,1,0,.07.14.14,0,0,1-.05.09l-.36.28-.07,0a.12.12,0,0,1-.09-.06A4.49,4.49,0,0,0,184.23,15.9Z"
                    style="fill: #d0161f"/>
                <path
                    d="M198.39,17l-.21-.58a2.79,2.79,0,0,0-.32-.58,7,7,0,0,1,1.09-.1c.13,0,.2.06.2.17l0,.17a2.57,2.57,0,0,0,0,.27c.05.16.1.32.15.46a15.08,15.08,0,0,0,1.84-.47l.07,0s.06,0,.08.07a5.26,5.26,0,0,1,.15.6v0c0,.07,0,.11-.11.14-.5.15-1.09.31-1.76.46.08.23.19.48.31.75a10.54,10.54,0,0,0,1.64-.47l.08,0s.07,0,.09.08a3.89,3.89,0,0,1,.16.59s0,.05,0,.07,0,.07-.09.1a10.12,10.12,0,0,1-1.52.43,7.12,7.12,0,0,0,1.18,1.56.17.17,0,0,1,.08.12.49.49,0,0,1,0,.1,2.77,2.77,0,0,1-.36.56.2.2,0,0,1-.15.09.22.22,0,0,1-.12-.06,2.9,2.9,0,0,0-.53-.29,4.36,4.36,0,0,0-1.7-.29,2.08,2.08,0,0,0-1,.21.55.55,0,0,0-.31.48q0,.45.63.63a6.28,6.28,0,0,0,1.52.14l1,0c.07,0,.11,0,.11.09v0a6.31,6.31,0,0,1-.18.82.2.2,0,0,1-.19.13c-.21,0-.43,0-.65,0A5,5,0,0,1,197,23a1.39,1.39,0,0,1,0-2.4,2.86,2.86,0,0,1,1.56-.37,3.92,3.92,0,0,1,1.55.23,11.14,11.14,0,0,1-.75-1.07,18.56,18.56,0,0,1-2.46.27.16.16,0,0,1-.17-.13,4.46,4.46,0,0,1-.16-.73,17.55,17.55,0,0,0,2.42-.22c0-.08-.09-.2-.17-.38l-.17-.37a18.3,18.3,0,0,1-2.19.21.16.16,0,0,1-.18-.12c0-.07-.06-.31-.14-.72A16.61,16.61,0,0,0,198.39,17Z"
                    style="fill: #d0161f"/>
            </g>
            <g>
                <path
                    d="M40.68,128.49l-1.12,0c-.05,0-.08,0-.09,0s0,0,0-.08v-.71c0-.06,0-.09.1-.09l1.12,0h.22v-1.26l0-.81s0-.08,0-.09H41l1.24,0h1.15v-1.2H41.52a8,8,0,0,1-1.05,1.29c-.08.09-.15.14-.19.14s-.09-.06-.18-.17a2.1,2.1,0,0,0-.5-.49,6.59,6.59,0,0,0,1.67-2.17,3.07,3.07,0,0,0,.23-.66,7.61,7.61,0,0,1,.86.2c.11,0,.17.09.17.15a.31.31,0,0,1-.11.17.87.87,0,0,0-.23.33l-.18.38h5s.08,0,.08,0a.13.13,0,0,1,0,.08v.67s0,.07,0,.08a.13.13,0,0,1-.08,0l-1.23,0H44.34v1.2h1l1.23,0c.07,0,.1,0,.1.1v.67c0,.07,0,.1-.1.1l-1.23,0h-1v1.32h1.91l1.12,0c.07,0,.1,0,.1.09v.71c0,.05,0,.08,0,.09h-.09l-1.12,0H44.34v.44l0,1.47a.14.14,0,0,1,0,.09h-.88a.14.14,0,0,1-.09,0,.13.13,0,0,1,0-.08l0-1.47v-.44Zm1.17-.86h1.52v-1.32H41.85Z"
                    style="fill: #d0161f"/>
                <path
                    d="M57.62,122.9H59a1.83,1.83,0,0,0,0-.37,2.73,2.73,0,0,0,0-.32l.82.07c.11,0,.16,0,.16.1a.24.24,0,0,1,0,.13.87.87,0,0,0-.08.3s0,.05,0,.09h1.61c.08,0,.11,0,.11.11s0,.28,0,.5a4.83,4.83,0,0,1-.22,1.34c-.14.32-.47.48-1,.48H60c-.11,0-.18,0-.2-.06a.77.77,0,0,1-.07-.24,1.62,1.62,0,0,0-.15-.47,3.32,3.32,0,0,0,.58,0c.22,0,.35,0,.41-.17a3.18,3.18,0,0,0,.13-.81h-.92a3,3,0,0,1-.6,1.23,3.45,3.45,0,0,1-.9.75.76.76,0,0,1-.22.11s-.12-.07-.22-.2a1.91,1.91,0,0,0-.42-.43,3.21,3.21,0,0,0,1-.66,2.1,2.1,0,0,0,.46-.8h-.16l-1.07,0s-.08,0-.09,0,0,0,0-.07V123s0-.08,0-.09Zm2.25,6.21a8.18,8.18,0,0,1,.79.35c.09,0,.13.07.13.12s0,.09-.14.11l-.36.16a6.47,6.47,0,0,1-2,.63l-.19,0c-.07,0-.15-.07-.22-.2a1.92,1.92,0,0,0-.41-.54,7.37,7.37,0,0,0,2-.42A2.25,2.25,0,0,0,59.87,129.11ZM63,125.59l1.24,0a.14.14,0,0,1,.09,0s0,0,0,.08l0,.88v1.58l0,.88c0,.05,0,.08,0,.09H58.68a.2.2,0,0,1,0-.09l0-.88v-1.58l0-.88s0-.08,0-.09h.08l1.25,0Zm-3.41.63v.38h3.86v-.38Zm0,1.3h3.86v-.38H59.55Zm3.86,1v-.41H59.55v.41Zm.71-5.82.92,0a.09.09,0,0,1,.11.11l0,.72v.91l0,.71a.14.14,0,0,1,0,.09H62a.78.78,0,0,1,0-.09l0-.71v-.91l0-.72a.09.09,0,0,1,.11-.11l.92,0Zm-1.06,6.49a7.16,7.16,0,0,0,2.45.59,2.51,2.51,0,0,0-.43.57c-.07.12-.15.18-.23.18l-.2,0a8,8,0,0,1-1.37-.36,5.43,5.43,0,0,1-1.08-.53s-.06,0-.06-.06,0-.05.1-.07l.6-.29a.31.31,0,0,1,.12,0Zm1.21-4.68v-1.1H62.89v1.1Z"
                    style="fill: #d0161f"/>
            </g>
            <g>
                <path
                    d="M114.18,388.52V388c.84,0,2-.09,2-1.08s-1.34-1.36-2.2-1.36a4.41,4.41,0,0,0-.89.1v1.78a1,1,0,0,1-1,1.15,1.39,1.39,0,0,1-1.38-1.43c0-1,1-1.62,1.82-1.89v-1.49H111v-.49h1.58v-1.17h.6v1.17h1.43v.49h-1.43v1.34a4.7,4.7,0,0,1,.92-.09c1.18,0,2.74.43,2.74,1.88S115.28,388.52,114.18,388.52Zm-1.65-2.74c-.54.21-1.25.66-1.25,1.33a.8.8,0,0,0,.81.86c.34,0,.44-.28.44-.58Zm4.24-.86a19.64,19.64,0,0,0-1.65-1.64l.38-.41a17.8,17.8,0,0,1,1.72,1.64Z"
                    style="fill: #d0161f"/>
                <path
                    d="M120.47,382.24c0,.12-.08.26-.14.41h4.85v.54h-2.52v1.37h2.28v.53h-2.28v1.64h2.9v.54h-2.9v1.83H122v-1.83h-3.66v-.54h1.42v-2.17H122v-1.37h-1.9a11.14,11.14,0,0,1-.55,1.15,12.05,12.05,0,0,1-.64,1l-.5-.36a10.87,10.87,0,0,0,1.53-3.18l.6.12Zm-.08,4.49H122v-1.64h-1.63Z"
                    style="fill: #d0161f"/>
                <path
                    d="M133.24,382.89h-3V385h2.44v.55h-2.44v2.62h3.16v.56h-6.88v-.56h3.08v-2.62h-2.36V385h2.36v-2.11h-2.92v-.56h6.56Zm-.39,4.38-.43.41q-.33-.36-.66-.69c-.22-.22-.44-.44-.67-.64l.4-.44C132,386.4,132.46,386.85,132.85,387.27Z"
                    style="fill: #d0161f"/>
            </g>
            <rect x="20.89" y="21.84" width="63.21" height="63.21" style="fill: #d0161f"/>
            <rect x="125.26" y="33.45" width="17.57" height="23.86"
                  style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 1.5px"/>
            <rect x="145.43" y="33.45" width="17.57" height="23.86"
                  style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 1.5px"/>
            <rect x="165.61" y="33.45" width="17.57" height="23.86"
                  style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 1.5px"/>
            <rect x="186.79" y="33.45" width="17.57" height="23.86"
                  style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
            <rect x="206.07" y="33.45" width="17.57" height="23.86"
                  style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
            <rect x="225.35" y="33.45" width="17.57" height="23.86"
                  style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
            <rect x="244.62" y="33.45" width="17.57" height="23.86"
                  style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
            <line x1="183.19" y1="45.38" x2="186.79" y2="45.38"
                  style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 1.5px"/>
            <rect x="15.74" y="377.9" width="85.33" height="27.85" style="fill: #eccfcc"/>
            <path d="M176.83,378.7V405H107.5V378.7h69.33m.8-.8H106.7v27.85h70.93V377.9Z" style="fill: #d0161f"/>
            <g>
                <path
                    d="M28.9,387.8h3.17a3.76,3.76,0,0,1,2.55.7,2.11,2.11,0,0,1,.63,1.65,1.91,1.91,0,0,1-.64,1.49,2.65,2.65,0,0,1-1.44.62v.11a2.91,2.91,0,0,1,1.84.83,2.16,2.16,0,0,1,.54,1.5,2.39,2.39,0,0,1-1,2.06,3.88,3.88,0,0,1-2.31.62H28.9Zm1.1.91v3.17h1.73a2.86,2.86,0,0,0,1.85-.5,1.42,1.42,0,0,0,.53-1.15,1.34,1.34,0,0,0-.55-1.2,3.21,3.21,0,0,0-1.64-.32Zm0,4.08v3.68h2.11c1.51,0,2.27-.6,2.27-1.82a1.6,1.6,0,0,0-.75-1.42,3.29,3.29,0,0,0-1.82-.44Z"
                    style="fill: #231815"/>
                <path
                    d="M41.29,387.8a2.31,2.31,0,0,1,2.19,1.29,7.63,7.63,0,0,1,.64,3.54q0,4.92-2.85,4.92a2.32,2.32,0,0,1-2.21-1.32,7.84,7.84,0,0,1-.64-3.58C38.42,389.42,39.37,387.8,41.29,387.8Zm0,.9a1.42,1.42,0,0,0-1.38,1,8.22,8.22,0,0,0-.39,2.94,7.47,7.47,0,0,0,.5,3.22,1.33,1.33,0,0,0,1.25.8,1.43,1.43,0,0,0,1.37-1.05,8.69,8.69,0,0,0,.38-3,7.47,7.47,0,0,0-.48-3.19A1.34,1.34,0,0,0,41.29,388.7Z"
                    style="fill: #231815"/>
                <path
                    d="M49.81,387.8A2.31,2.31,0,0,1,52,389.09a7.76,7.76,0,0,1,.64,3.54q0,4.92-2.86,4.92a2.34,2.34,0,0,1-2.21-1.32,8,8,0,0,1-.64-3.58Q46.93,387.81,49.81,387.8Zm0,.9a1.42,1.42,0,0,0-1.38,1,8.22,8.22,0,0,0-.39,2.94,7.5,7.5,0,0,0,.49,3.22,1.34,1.34,0,0,0,1.25.8,1.42,1.42,0,0,0,1.37-1.05,8.45,8.45,0,0,0,.38-3,7.47,7.47,0,0,0-.48-3.19A1.32,1.32,0,0,0,49.81,388.7Z"
                    style="fill: #231815"/>
                <path
                    d="M58.33,387.8a2.31,2.31,0,0,1,2.18,1.29,7.61,7.61,0,0,1,.65,3.54c0,3.28-1,4.92-2.86,4.92a2.33,2.33,0,0,1-2.21-1.32,7.84,7.84,0,0,1-.64-3.58Q55.45,387.81,58.33,387.8Zm0,.9a1.42,1.42,0,0,0-1.38,1,8.18,8.18,0,0,0-.4,2.94,7.47,7.47,0,0,0,.5,3.22,1.33,1.33,0,0,0,1.25.8,1.42,1.42,0,0,0,1.37-1.05,8.69,8.69,0,0,0,.38-3,7.47,7.47,0,0,0-.48-3.19A1.33,1.33,0,0,0,58.33,388.7Z"
                    style="fill: #231815"/>
                <path
                    d="M66.84,387.8A2.31,2.31,0,0,1,69,389.09a7.63,7.63,0,0,1,.64,3.54q0,4.92-2.85,4.92a2.32,2.32,0,0,1-2.21-1.32,7.84,7.84,0,0,1-.64-3.58C64,389.42,64.92,387.8,66.84,387.8Zm0,.9a1.42,1.42,0,0,0-1.38,1,8.22,8.22,0,0,0-.39,2.94,7.47,7.47,0,0,0,.5,3.22,1.33,1.33,0,0,0,1.25.8,1.42,1.42,0,0,0,1.37-1.05,8.69,8.69,0,0,0,.38-3,7.47,7.47,0,0,0-.48-3.19A1.33,1.33,0,0,0,66.84,388.7Z"
                    style="fill: #231815"/>
                <path
                    d="M81,393.31a.25.25,0,0,1,0,.07s-.05.08-.16.13l-.29.11-.15.05s0,0,0-.07l-.28-.7c-.5.06-1,.11-1.34.14v3.51l0,1.9c0,.06,0,.09-.1.09h-.59c-.07,0-.11,0-.11-.09l0-1.9v-3.43l-1.5.12a1,1,0,0,0-.49.1l-.15.06s-.09-.06-.12-.18l-.12-.7c.17,0,.44,0,.81,0h.39c.13-.16.42-.56.87-1.19a18,18,0,0,0-2-2.13c0-.06-.08-.09-.08-.11a.29.29,0,0,1,.08-.14l.22-.28.12-.08s.06,0,.12.06l.57.53c.27-.42.57-.93.88-1.53a4.73,4.73,0,0,0,.36-.89,7.22,7.22,0,0,1,.7.33c.09.06.14.11.14.17s0,.09-.11.14a.76.76,0,0,0-.22.27c-.54.89-1,1.56-1.28,2q.55.58.93,1.05c.22-.33.58-.92,1.08-1.76a5.93,5.93,0,0,0,.38-.89,5.49,5.49,0,0,1,.69.37c.09.05.13.1.13.16s0,.1-.11.15a2.34,2.34,0,0,0-.46.59c-.7,1.12-1.42,2.17-2.17,3.15.67,0,1.39-.1,2.17-.18-.12-.25-.3-.56-.52-.93a.27.27,0,0,1,0-.08s0-.07.13-.11l.26-.11.12,0s0,0,.08.06A13.84,13.84,0,0,1,81,393.31Zm-3.68,1.12a.4.4,0,0,1-.06.16,1.47,1.47,0,0,0-.12.39,12,12,0,0,1-.78,2.45c0,.12-.09.17-.13.17a.47.47,0,0,1-.19-.1,1.36,1.36,0,0,0-.4-.22,8.84,8.84,0,0,0,.8-2.37,4.63,4.63,0,0,0,.07-.75,6.06,6.06,0,0,1,.64.13C77.28,394.31,77.34,394.36,77.34,394.43Zm3.34,1.83a.18.18,0,0,1,0,.06s-.06.09-.18.13l-.27.07-.13,0s-.07,0-.08-.09a9.45,9.45,0,0,0-.61-2.12.39.39,0,0,1,0-.06s.06-.07.17-.11l.26-.06a.24.24,0,0,1,.1,0s.08,0,.1.08A10.76,10.76,0,0,1,80.68,396.26Zm7,1.79a.09.09,0,0,1-.1.1l-1.79,0H81.89l-1.81,0a.09.09,0,0,1-.1-.1v-.56c0-.07,0-.11.1-.11l1.34,0V390l0-2.32c0-.07,0-.11.1-.11l1.47,0h1.82l1.47,0a.1.1,0,0,1,.11.11l0,2.32v7.39l1.26,0c.07,0,.1,0,.1.11Zm-2.13-7.28v-2.42H82.19v2.42Zm0,3.22v-2.51H82.19V394Zm0,3.42v-2.69H82.19v2.69Z"
                    style="fill: #231815"/>
            </g>
            <rect x="183.32" y="377.9" width="85.33" height="27.85" style="fill: #eccfcc"/>
            <g>
                <path
                    d="M204.07,387.8a2.31,2.31,0,0,1,2.19,1.29,7.63,7.63,0,0,1,.64,3.54q0,4.92-2.86,4.92a2.34,2.34,0,0,1-2.21-1.32,8,8,0,0,1-.64-3.58Q201.19,387.81,204.07,387.8Zm0,.9a1.42,1.42,0,0,0-1.38,1,8.22,8.22,0,0,0-.39,2.94,7.5,7.5,0,0,0,.49,3.22,1.35,1.35,0,0,0,1.25.8,1.43,1.43,0,0,0,1.38-1.05,8.69,8.69,0,0,0,.38-3,7.43,7.43,0,0,0-.49-3.19A1.32,1.32,0,0,0,204.07,388.7Z"
                    style="fill: #231815"/>
                <path
                    d="M212.59,387.8a2.33,2.33,0,0,1,2.19,1.29,7.76,7.76,0,0,1,.64,3.54c0,3.28-1,4.92-2.86,4.92a2.33,2.33,0,0,1-2.21-1.32,7.84,7.84,0,0,1-.64-3.58Q209.71,387.81,212.59,387.8Zm0,.9a1.42,1.42,0,0,0-1.38,1,8.18,8.18,0,0,0-.4,2.94,7.47,7.47,0,0,0,.5,3.22,1.33,1.33,0,0,0,1.25.8,1.42,1.42,0,0,0,1.37-1.05,8.45,8.45,0,0,0,.38-3,7.47,7.47,0,0,0-.48-3.19A1.33,1.33,0,0,0,212.59,388.7Z"
                    style="fill: #231815"/>
                <path
                    d="M221.1,387.8a2.31,2.31,0,0,1,2.19,1.29,7.63,7.63,0,0,1,.64,3.54q0,4.92-2.85,4.92a2.32,2.32,0,0,1-2.21-1.32,7.84,7.84,0,0,1-.64-3.58Q218.23,387.81,221.1,387.8Zm0,.9a1.4,1.4,0,0,0-1.37,1,8,8,0,0,0-.4,2.94,7.47,7.47,0,0,0,.5,3.22,1.33,1.33,0,0,0,1.25.8,1.42,1.42,0,0,0,1.37-1.05,8.69,8.69,0,0,0,.38-3,7.47,7.47,0,0,0-.48-3.19A1.33,1.33,0,0,0,221.1,388.7Z"
                    style="fill: #231815"/>
                <path
                    d="M229.62,387.8a2.31,2.31,0,0,1,2.19,1.29,7.63,7.63,0,0,1,.64,3.54q0,4.92-2.86,4.92a2.32,2.32,0,0,1-2.2-1.32,7.84,7.84,0,0,1-.64-3.58C226.75,389.42,227.7,387.8,229.62,387.8Zm0,.9a1.42,1.42,0,0,0-1.38,1,8.22,8.22,0,0,0-.39,2.94,7.5,7.5,0,0,0,.49,3.22,1.35,1.35,0,0,0,1.25.8,1.43,1.43,0,0,0,1.38-1.05,8.69,8.69,0,0,0,.38-3,7.28,7.28,0,0,0-.49-3.19A1.32,1.32,0,0,0,229.62,388.7Z"
                    style="fill: #231815"/>
                <path
                    d="M238.14,387.8a2.31,2.31,0,0,1,2.19,1.29,7.76,7.76,0,0,1,.64,3.54q0,4.92-2.86,4.92a2.33,2.33,0,0,1-2.21-1.32,7.84,7.84,0,0,1-.64-3.58Q235.26,387.81,238.14,387.8Zm0,.9a1.42,1.42,0,0,0-1.38,1,8.22,8.22,0,0,0-.39,2.94,7.5,7.5,0,0,0,.49,3.22,1.34,1.34,0,0,0,1.25.8,1.42,1.42,0,0,0,1.37-1.05,8.45,8.45,0,0,0,.38-3,7.47,7.47,0,0,0-.48-3.19A1.32,1.32,0,0,0,238.14,388.7Z"
                    style="fill: #231815"/>
                <path
                    d="M246.65,387.8a2.31,2.31,0,0,1,2.19,1.29,7.63,7.63,0,0,1,.64,3.54q0,4.92-2.85,4.92a2.32,2.32,0,0,1-2.21-1.32,7.84,7.84,0,0,1-.64-3.58Q243.78,387.81,246.65,387.8Zm0,.9a1.41,1.41,0,0,0-1.37,1,8,8,0,0,0-.4,2.94,7.47,7.47,0,0,0,.5,3.22,1.33,1.33,0,0,0,1.25.8,1.42,1.42,0,0,0,1.37-1.05,8.69,8.69,0,0,0,.38-3,7.47,7.47,0,0,0-.48-3.19A1.33,1.33,0,0,0,246.65,388.7Z"
                    style="fill: #231815"/>
            </g>
            <g>
                <rect x="262.35" y="359.58" width="6.28" height="6.29" style="fill: #d16060"/>
                <rect x="254.66" y="359.58" width="6.28" height="6.29" style="fill: #d16060"/>
                <rect x="262.35" y="351.99" width="6.28" height="6.29" style="fill: #d16060"/>
                <rect x="254.66" y="351.99" width="6.28" height="6.29" style="fill: #d16060"/>
            </g>
            <g>
                <polyline points="49.89 348.81 49.89 347.81 48.89 347.81"
                          style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="47.85" y1="347.81" x2="17.26" y2="347.81"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.0743000507354736,1.0371500253677368"/>
                <polyline points="16.74 347.81 15.74 347.81 15.74 348.81"
                          style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="15.74" y1="349.88" x2="15.74" y2="365.25"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.120370388031006,1.060185194015503"/>
                <polyline points="15.74 365.78 15.74 366.78 16.74 366.78"
                          style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="17.77" y1="366.78" x2="48.37" y2="366.78"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.0743000507354736,1.0371500253677368"/>
                <polyline points="48.89 366.78 49.89 366.78 49.89 365.78"
                          style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="49.89" y1="364.72" x2="49.89" y2="349.34"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.120370388031006,1.060185194015503"/>
            </g>
            <g>
                <line x1="27.12" y1="347.81" x2="27.12" y2="348.81"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="27.12" y1="349.88" x2="27.12" y2="365.25"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.120370388031006,1.060185194015503"/>
                <line x1="27.12" y1="365.78" x2="27.12" y2="366.78"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
            </g>
            <g>
                <line x1="38.51" y1="347.81" x2="38.51" y2="348.81"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="38.51" y1="349.88" x2="38.51" y2="365.25"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.120370388031006,1.060185194015503"/>
                <line x1="38.51" y1="365.78" x2="38.51" y2="366.78"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
            </g>
            <g>
                <polyline points="98.63 348.81 98.63 347.81 97.63 347.81"
                          style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="96.61" y1="347.81" x2="54.36" y2="347.81"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.0361757278442383,1.0180878639221191"/>
                <polyline points="53.85 347.81 52.85 347.81 52.85 348.81"
                          style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="52.85" y1="349.88" x2="52.85" y2="365.25"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.120370388031006,1.060185194015503"/>
                <polyline points="52.85 365.78 52.85 366.78 53.85 366.78"
                          style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="54.87" y1="366.78" x2="97.12" y2="366.78"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.0361757278442383,1.0180878639221191"/>
                <polyline points="97.63 366.78 98.63 366.78 98.63 365.78"
                          style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="98.63" y1="364.72" x2="98.63" y2="349.34"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.120370388031006,1.060185194015503"/>
            </g>
            <rect x="101.89" y="350.19" width="40.15" height="16.59" rx="1.7"
                  style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
            <g>
                <line x1="64.3" y1="347.81" x2="64.3" y2="348.81"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="64.3" y1="349.88" x2="64.3" y2="365.25"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.120370388031006,1.060185194015503"/>
                <line x1="64.3" y1="365.78" x2="64.3" y2="366.78"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
            </g>
            <g>
                <line x1="75.74" y1="347.81" x2="75.74" y2="348.81"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="75.74" y1="349.88" x2="75.74" y2="365.25"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.120370388031006,1.060185194015503"/>
                <line x1="75.74" y1="365.78" x2="75.74" y2="366.78"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
            </g>
            <g>
                <line x1="87.19" y1="347.81" x2="87.19" y2="348.81"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
                <line x1="87.19" y1="349.88" x2="87.19" y2="365.25"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px;stroke-dasharray: 2.120370388031006,1.060185194015503"/>
                <line x1="87.19" y1="365.78" x2="87.19" y2="366.78"
                      style="fill: none;stroke: #d0161f;stroke-miterlimit: 10;stroke-width: 0.8px"/>
            </g>
            <g style="clip-path: url(#clip-path)">
                <line x1="35.56" y1="97.33" x2="35.56" y2="159.33"
                      style="fill: none;stroke: #c41c21;stroke-miterlimit: 10"/>
                <line x1="69.11" y1="97.22" x2="69.11" y2="159.22"
                      style="fill: none;stroke: #c41c21;stroke-miterlimit: 10"/>
                <line x1="63.52" y1="97.22" x2="63.52" y2="159.22"
                      style="fill: none;stroke: #c41c21;stroke-miterlimit: 10"/>
                <line x1="57.93" y1="97.22" x2="57.93" y2="159.22"
                      style="fill: none;stroke: #c41c21;stroke-miterlimit: 10"/>
                <line x1="52.33" y1="97.22" x2="52.33" y2="159.22"
                      style="fill: none;stroke: #c41c21;stroke-miterlimit: 10"/>
                <line x1="46.74" y1="97.22" x2="46.74" y2="159.22"
                      style="fill: none;stroke: #c41c21;stroke-miterlimit: 10"/>
                <line x1="41.15" y1="97.22" x2="41.15" y2="159.22"
                      style="fill: none;stroke: #c41c21;stroke-miterlimit: 10"/>
            </g>
            <g>
                <path
                    d="M70.14,28.84a2.47,2.47,0,0,1,2-.87c1.52,0,2.69,1.16,2.69,3.12a3.26,3.26,0,0,1-3.2,3.5c-2.22,0-3.42-1.78-3.42-4.19,0-2.25,1-5.92,5.7-5.92V26A7,7,0,0,0,73,26a3.31,3.31,0,0,0-2.91,2.82Zm2.73,2.34c0-1-.5-1.87-1.39-1.87a1.51,1.51,0,0,0-1.41,1.6c0,1.27.55,2.29,1.5,2.29S72.87,32.29,72.87,31.18Z"
                    style="fill: #fff"/>
                <path
                    d="M79.73,29.16a2.47,2.47,0,0,1,2,2.46c0,1.64-1.31,3-3.6,3a4.81,4.81,0,0,1-2.47-.6l.43-1.45a4.06,4.06,0,0,0,1.91.53,1.57,1.57,0,0,0,1.77-1.54c0-1.1-1-1.62-2-1.62h-.67V28.56h.63c.76,0,1.75-.43,1.75-1.38,0-.69-.45-1.2-1.36-1.2a3.43,3.43,0,0,0-1.72.54L76,25.14a5,5,0,0,1,2.56-.68c1.88,0,2.87,1.11,2.87,2.41a2.39,2.39,0,0,1-1.69,2.26Z"
                    style="fill: #fff"/>
            </g>
        </g>
        <!-- MEMO: ハガキ背景 ここまで-->
        <!-- MEMO: 宛名画像 ここから-->
        <component id="preview_image"
                   v-if="hasSvgImageString"
                   :is="makeComponent"></component>
        <!-- MEMO: 宛名画像 ここまで-->
    </svg>
</template>

<script>

export default {
    name: "PreviewSvgTemplate",
    props: {
        isShowBackGround: {
            type: Boolean,
            default: true,
        },
        svgWidth: {
            type: Number,
            default: 283,
        },
        svgHeight: {
            type: Number,
            default: 419,
        },
        svgImageString: {
            type: String,
            required: false,
        },
        viewBox: {
            type: String,
            default: '0 0 283 419', // MEMO: 少し大きくしないと下と右の枠線が消える
        },
        material: {
            type: String,
            default: '',
        },
    },
    computed: {
        hasSvgImageString() {
            return this.svgImageString !== undefined && this.svgImageString !== null && this.svgImageString !== ''
        },
        makeComponent() {
            return {
                name: 'address_side_image',
                template: this.svgImageString,
            }
        },
    },
    methods: {
        materialIs(material) {
            return material === this.material
        },
    },
}
</script>

<style scoped>

</style>