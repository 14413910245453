<script>
import config, * as Enum from "../../const/const"
import {reactive, ref} from "vue"
import UtilsFunctions from "../../functions/utils";
import RegisterFunctions from "../../functions/register";
import _ from "lodash";
import axios from "axios";

const {generateUniqueStr} = UtilsFunctions();
const {getBlankDesignSenderItem} = RegisterFunctions();
const position2TranslateValue = (x, y) => {
    return `translate(${x.toString()}px, ${y.toString()}px)`
}

const example_text_hard_image = {
    imagesAnimal: [
        {src: '/images/example_text_images/sample.jpg'},
        {src: '/images/example_text_images/sample.jpg'},
        {src: '/images/example_text_images/sample.jpg'},
        {src: '/images/example_text_images/sample.jpg'},
        {src: '/images/example_text_images/sample.jpg'},
    ],

    imagesFlower: [
        {src: '/images/icon/edit/flower.svg'},
        {src: '/images/icon/edit/flower.svg'},
        {src: '/images/icon/edit/flower.svg'},
        {src: '/images/icon/edit/flower.svg'},
        {src: '/images/icon/edit/flower.svg'},
    ],
}

const hard_material = {
    animal: [
        {src: '/images/example_text_images/sample.jpg', text: 'ゴリラ', menu: 'animal'},
        {src: '/images/example_text_images/sample.jpg', text: 'キリン', menu: 'animal'},
        {src: '/images/example_text_images/sample.jpg', text: 'ラクダ', menu: 'animal'},
        {src: '/images/example_text_images/sample.jpg', text: 'カンガルー', menu: 'animal'},
        {src: '/images/example_text_images/sample.jpg', text: 'ダチョウ', menu: 'animal'},
    ],
}

const removeTargetElement = (base_array, target_id) => {
    return base_array.filter((element_object) => {
        return element_object.id !== target_id
    });
}

const making_item = reactive({
    edit_mode: '',
    design_id: 0,
    design_code: '',
    nenga_id: 0,
    customer_id: 0,
    code: '',
    loaded: false,  // 読み込み完了フラグ
    selectedTitle: {},
    selectedLayout: ref({}),
    selectedBackground: {},
    deployedImages: reactive([]),
    deployedStamps: reactive([]),
    userTexts: reactive([]),
    sender_id: null,
    senderItem: _.cloneDeep(getBlankDesignSenderItem()),
    selectedSenderOrderingRule: {},
    exampleTextImages: example_text_hard_image,
    material: hard_material,
    material_patterns: [],
    material_pattern_code: null,        // MEMO: 部材パターンコード
    material_code_for_address: null,
    material_code_for_design: null,
    enabled_material_pattern: true,     // MEMO: 保存していた部材コードが無効化判定
    layout_material_pattern_count: 0,   // MEMO: デザインに登録されている部材パターンの種類数
    isPaperSelectModalFirstDisplay: false,  // MEMO: 台紙選択モーダルを一度でも表示したかどうか
    designData: {},

    setTitle: (title) => {
        making_item.selectedTitle = title
    },
    setLayout: (layout) => {
        making_item.selectedLayout = layout
    },
    setBackground: (background) => {
        making_item.selectedBackground = background
    },
    removeDeployedImage: (target_id) => {
        making_item.deployedImages = removeTargetElement(making_item.deployedImages, target_id)
    },
    removeStamp: (target_id) => {
        making_item.deployedStamps = removeTargetElement(making_item.deployedStamps, target_id);
    },
    removeDeployedText: (target_id) => {
        making_item.userTexts = removeTargetElement(making_item.userTexts, target_id)
    },
    setBlankSenderItem: () => {
        making_item.senderItem = _.cloneDeep(getBlankDesignSenderItem());
    },
    removeSenderText: () => {
        making_item.selectedSenderOrderingRule = []
    },
    isDefaultSender: (sender_item) => {
        const compare_target_keys = [
            'guest_type', 'corporate_name1', 'corporate_name2', 'department_name1', 'department_name2',
            'sei', 'mei', 'old_name_or_age', 'position_name', 'position_name',
            'post_no', 'pref_name',
            'address', 'building_name',
        ];

        const blank_sender_item = getBlankDesignSenderItem();

        let changed = false;
        for (const key of compare_target_keys) {
            if (blank_sender_item[key] === sender_item[key]) {
                continue;
            }
            // MEMO: 変更がある場合は中断してフラグを変更
            changed = true;
            break
        }

        if (changed) {
            // 変更がある場合は、デフォルトじゃないのでfalse
            return false;
        }

        // MEMO: 連名は姓と名が入力されていないと配列自体が空になるのでblankがあるのに
        //  フォームの値がある場合は変更とみなす
        if (blank_sender_item.joint_names && !sender_item.joint_names.length) {
            return false;
        }

        // MEMO: 連名の変更チェック
        for (const [index, src_joint_name] of blank_sender_item.joint_names.entries()) {
            if (src_joint_name.sei === sender_item.joint_names[index].sei &&
                src_joint_name.mei === sender_item.joint_names[index].mei &&
                src_joint_name.old_name_or_age === sender_item.joint_names[index].old_name_or_age) {
                continue;
            }
            changed = true;
            break;
        }

        return changed === true ? false : true
    },
})

/**
 * フォント名からフォントIDを返します
 * */
const convertFontNameToFontId = (font_name) => {
    switch (font_name) {
        case Enum.FONT.MINCHO.css_class_name:
            return Enum.FONT.MINCHO.id;
        case Enum.FONT.KAISHO.css_class_name:
            return Enum.FONT.KAISHO.id;
        case Enum.FONT.GOTHIC.css_class_name:
            return Enum.FONT.GOTHIC.id;
        default:
            return Enum.FONT.GOTHIC.id;
    }
};

export default {
    name: "MakingItemInfo",
    makingItem: making_item,
    load: async (inject_design_code, inject_customer_id, code = null, nengaId = null, reset = false) => {
        making_item.design_code = inject_design_code
        making_item.customer_id = inject_customer_id
        await axios.get(config.API_BASE_URL + '/making_item/edit', {
            params: {
                nenga_id: nengaId,
                customer_id: making_item.customer_id,
                design_code: making_item.design_code,
                reset: reset    // 作り直しする場合 True
            },
        }).then((res) => {
            const data = res.data
            making_item.edit_mode = 'new';
            making_item.design_id = data.design_id;
            making_item.nenga_id = data.nenga_id ?? null;
            making_item.code = data.code;
            // MEMO: dtb_nengaのコードが未登録 かつ 引数でコードが未指定の場合は生成してセットする
            if ((data.code === undefined || !data.code) && !code) {
                making_item.code = generateUniqueStr();
            } else if (code) {
                // 引数でコードが指定されていたらその値を使用する
                making_item.code = code;
            }

            making_item.selectedLayout = data.layout;
            making_item.selectedBackground = data.background;
            making_item.selectedTitle = data.title;
            making_item.deployedStamps = data.deployed_stamps;
            making_item.deployedImages = data.deployed_images;
            making_item.userTexts = data.deployed_texts;

            making_item.designData = data.design_data
            if (data.sender_id) {
                making_item.senderItem = data.sender

                const design_font_id = convertFontNameToFontId(data.design_data.default_font_type_sender);

                // MEMO: 同じ連想配列を使用してフィルターすると元の値が変わってしまうため、デザイン編集エリアの差出人の検索用にクローンする
                const design_sender_name_parts = _.cloneDeep(data.sender.sender_name_parts);

                // MEMO: sender_name_partsにはすべてのフォントが入っているため、入力欄（ゴシック体）とデザイン編集エリア（デザインのフォント）
                //  に表示に使用するフォントで絞り込む
                making_item.senderItem.sender_name_parts = data.sender.sender_name_parts
                    .filter((name_parts_item) => name_parts_item.font_id === Enum.FONT.GOTHIC.id || !name_parts_item.font_id);

                // MEMO: デザイン編集
                making_item.senderItem.display_sender_name_parts = design_sender_name_parts.filter((name_parts_item) => {
                    return name_parts_item.font_id === design_font_id || !name_parts_item.font_id
                });
            } else {
                // MEMO: 差出人を初期化する
                making_item.setBlankSenderItem();
                // MEMO: 新規の場合はsetBlankSenderItemで初期化されるため以下の値を再度設定する
                making_item.senderItem.transform = position2TranslateValue(data.design_data.position_x_sender, data.design_data.position_y_sender)
                making_item.senderItem.anchor = data.design_data.default_text_align_sender
                making_item.senderItem.color = data.sender.color
            }
            // fontは現状に保存されないので新規・編集のどちらでもつける
            making_item.senderItem.font = data.design_data.default_font_type_sender
            making_item.senderItem.writingMode = data.design_data.default_writing_mode_sender
            making_item.selectedSenderOrderingRule = data.selected_sender_display_rule

            making_item.print_service = data.print_service;
            making_item.print_style = data.print_style;
            making_item.total_qty = data.total_qty;
            making_item.no_printing_qty = data.no_printing_qty;
            making_item.print_qty = data.print_qty;
            making_item.material_patterns = data.material_patterns;
            // MEMO: リセットした際には既存の値を維持する必要があるため、初回ロード時のみ値を設定する。
            making_item.material_pattern_code = making_item.material_pattern_code ?? data.material_pattern_code;
            making_item.material_code_for_address = making_item.material_code_for_address ?? data.material_code_for_address;
            making_item.material_code_for_design = making_item.material_code_for_design ?? data.material_code_for_design;
            making_item.layout_material_pattern_count = data.layout_material_pattern_count;
            making_item.purchase_address_items = data.purchase_addresses;
            // MEMO: 読み込み完了にする
            making_item.loaded = true;
        }).catch((error) => {
            // MEMO: 有効なデザインが見つからない場合ECのTOPへリダイレクト
            if ('check_design_existence_error' in error.response.data) {
                window.location.href = process.env.MIX_EC_TOP_URL
            }
            window.location.href = process.env.MIX_EC_TOP_URL
        })
    },


}
</script>
