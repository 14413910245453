<template>
    <div class="p-edit__slide--full sm:pt-8">
        <ul class="grid grid-cols-3 gap-3 sm:hidden pl-4 pr-4">
            <li class="mt-4 font-bold text-blue02 text-left" @click="$emit('close')">閉じる</li>
            <li class="mt-2 text-center"><img src="/images/icon/edit/rectangle.svg" alt=""></li>
            <!-- <li class="mt-4 font-bold text-blue02 text-right">決定</li> -->
        </ul>
        <div class="text-center pt-8 sm:pt-0">
            <span class="text-xl font-bold">文字の配置を変更</span>
        </div>
        <!-- <div class="flex justify-center gap-2 pt-4">
            <button
                @click="isPlacementSelect('pattern-first')"
                :class="{'is-active': isPlacementChange === 'pattern-first'}"
                class="c-button__changeColor sm:text-sm py-2 px-3 sm:px-2 rounded-lg"
            >住所→名前の配置
            </button>
            <button
                @click="isPlacementSelect('pattern-second')"
                :class="{'is-active': isPlacementChange === 'pattern-second'}"
                class="c-button__changeColor sm:text-sm py-2 px-3 sm:px-2 rounded-lg"
            >名前→住所の配置
            </button>
        </div> -->
        <div class="relative sm:top-0 p-edit__sideMenu">
            <div class="overflow-y-auto overflow-x-hidden h-full mt-6">
                <div v-if="isPlacementChange === 'pattern-first'">
                    <div v-for="(rule, index) in filteredOrderingRules" :key="index"
                         class="border-y border-gray03"
                         :class="{ 'bg-orange02 text-white': selectedOrderingRuleId === rule.id}">
                        <button @click="selectRule(rule)"
                                class="px-4 py-4 text-left hover:bg-orange hover:text-white">
                            <span class="text-xs font-bold leading-6" v-html="rule.sample"></span>
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="isPlacementChange === 'pattern-second'" class="mt-6">
                <ul class="px-6 py-4 border-y border-gray03">
                    <button class="text-left">
                        <li class="text-xs font-bold leading-6">山田たろう・花子</li>
                        <li class="text-xs font-bold leading-6">〒000-0000 東京都〇〇区〇〇 0丁目0番地 〇〇マンション 00号室
                            E-mail：xxx-xxxx@xxxx.xxx TEL:000-0000-000
                        </li>
                    </button>
                </ul>
                <ul class="px-6 py-4 border-y border-gray03">
                    <button class="text-left">
                        <li class="text-xs font-bold leading-6">山田たろう・花子</li>
                        <li class="text-xs font-bold leading-6">〒000-0000 東京都〇〇区〇〇 0丁目0番地 〇〇マンション 00号室
                        </li>
                        <li class="text-xs font-bold leading-6">E-mail：xxx-xxxx@xxxx.xxx TEL:000-0000-000</li>
                    </button>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";
import AvailableResources from "../AvailableResources.vue";

export default {
    name: "SenderAddressOrderingRule",
    setup() {
        const makingItem = inject('makingItemInfo')
        const availableResources = inject('availableResources')
        return {
            makingItem,
            availableResources
        }
    },
    data() {
        return {
            isPlacementChange: 'pattern-first',
        }
    },
    async mounted() {
        //
        if (this.availableResources.availableAddressOrderingRule.length === 0) {
            // MEMO: 配置ルールを取得
            await AvailableResources.loadAddressOrderingRule();
        }

    },
    methods: {
        isPlacementSelect(patternType) {
            this.isPlacementChange = patternType;
        },
        /**
         * 配置順の選択
         * */
        selectRule(rule) {
            this.makingItem.selectedSenderOrderingRule = rule
        },
    },
    computed: {
        selectedOrderingRuleId() {
            return this.makingItem.selectedSenderOrderingRule.id
        },
        selectedGuestType() {
            return this.makingItem.senderItem.guest_type
        },
        filteredOrderingRules() {
            return this.availableResources.availableAddressOrderingRule.filter((rule) => {
                return rule.guest_type === this.selectedGuestType
            })
        },
    },
}
</script>

<style scoped>

</style>