<template>
    <div>
        <div class="c-modal__overlay flex flex-col justify-center items-center px-4 py-8"
             :class="showTutorial">
            <div class="w-full max-w-3xl bg-white overflow-y-auto overflow-x-hidden">
                <div class="pb-4">
                    <swiper
                            :slides-per-view="1"
                            :pagination="{ clickable: true }"
                            :navigation="true"
                            class="swiper-wrapper-tutorial">
                        <swiper-slide v-for="img in tutorial" :key="img.index" class="cursor-grab swiper-tutorial">
                            <img :src="img.url" alt="tutorial" class="mx-auto w-3/4 sm:w-1/2">
                        </swiper-slide>
                    </swiper>
                    <div class="flex justify-center items-center pt-8 pb-4">
                        <button @click="close" class="p-edit__tutorial__skip text-sm text-blue02 font-bold">
                            <span>閉じる</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";
import SwiperCore, {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';

SwiperCore.use([Navigation, Pagination]);

export default {
    name: 'Tutorial',

    components: {
        Swiper,
        SwiperSlide,
    },
    emits: ['close'],
    setup() {
        const editStatus = inject('editStatus')
        const makingItem = inject('makingItemInfo')
        return {
            editStatus,
            makingItem,
        }
    },

    data() {
        return {
            isShowTutorial: true,
            tutorial: [
                {url: '/images/card/nen_tutorial01.jpg'},
                {url: '/images/card/nen_tutorial02.jpg'},
                {url: '/images/card/nen_tutorial03.jpg'},
                {url: '/images/card/nen_tutorial04.jpg'},
                {url: '/images/card/nen_tutorial05.jpg'},
                {url: '/images/card/nen_tutorial06.jpg'},
                {url: '/images/card/nen_tutorial07.jpg'},
                {url: '/images/card/nen_tutorial08.jpg'},
                {url: '/images/card/nen_tutorial09.jpg'},
                {url: '/images/card/nen_tutorial10.jpg'},
            ],
            windowWidth: '',
        }
    },


    methods: {
        close() {
            this.editStatus.activeSideMenu = this.windowWidth > 640 ? 'select_style' : '';
            this.$emit('close')
        },
    },

    computed: {
        showTutorial() {
            if (this.editStatus.activeSideMenu === 'tutorial') {
                return { 'is-modal-active': true };
            }
            return { 'is-modal-active': false };
        }
    },

    created() {
        this.windowWidth = window.innerWidth;
    },
}
</script>

<style>
.swiper-button-next,
.swiper-button-prev {
    border-radius: 50%;
    border: 1px solid #333;
    width: 30px;
    height: 30px;
    transition: opacity 0.3s;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    content: "";
    width: 30%;
    height: 30%;
    border-top: 1px solid #333;
}

.swiper-button-next:after {
    border-right: 1px solid #333;
    transform: rotate(45deg) translate(-20%, 20%);
}

.swiper-button-prev:after {
    border-left: 1px solid #333;
    transform: rotate(-45deg) translate(20%, 20%);
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: -5px !important;
}

/* .p-edit__tutorial__close {
    display: block;
    position: absolute;
    top: 0;
    right: 5px;
    width: 30px;
    height: 30px;
    border: 2px solid #333; 
    border-radius: 50%;
    background: #fff; 
}
.p-edit__tutorial__close::before, .p-edit__tutorial__close::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 22px;
    background: #333;
}
.p-edit__tutorial__close::before {
    transform: translate(-50%,-50%) rotate(45deg);
}

.p-edit__tutorial__close::after {
    transform: translate(-50%,-50%) rotate(-45deg);
} */

</style>