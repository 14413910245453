//文字列の長さをバイト数で取得するパッケージ
import stringWidth from "string-width";

export default {
    threePointLeader,
    addressNameLimit,
    modalNotScroll,
    designLayer,
}

/**
 * 名前の3点リーダーの共通関数です
 * @param[HTMLElement] : refで宣言したDOM要素
 * @param[Number] : 最大文字数
 */
function threePointLeader(parentElem, maxNameCount) {
    if (parentElem != null) {        
        for (const elem of parentElem) {
            const lastChild    = elem.lastElementChild;
            const elementCount = elem.childElementCount;
            const childArray   = elem.children;

            if (elementCount > maxNameCount) {
                for (let i = maxNameCount; i < childArray.length; i++) {
                    childArray[i].classList.add('hidden');
                };   

                if (lastChild.innerHTML === '...') {
                    lastChild.removeAttribute('class');

                } else {
                    const newElement = document.createElement('span');
                    newElement.textContent = '...';
                    elem.appendChild(newElement);
                };

            } else {
                if (lastChild.innerHTML === '...') {
                    lastChild.remove();
                };
            };
        };

        return;
    };
};

/**
 * 住所の3点リーダーを付与する関数です
 * @param[HTMLElement] : refで宣言したDOM要素
 * @param[Number] : 最大文字数
 * stringWidth : 半角「1」,全角「2」のNumber型で返します
 */
function addressNameLimit(info, max) {
    if (info != null) {
        for (let i of info) {
            if (stringWidth(i.textContent) > max) {
                const clamp = '...';
                const textReplace = i.textContent.substr(0, (max - 1));
                i.textContent = textReplace + clamp;
            };
        };

        return;
    };
};
/**
 * モーダルを開いた時にスクロールをさせないようにする
 * @param[Boolean]
 */
function modalNotScroll(modal) {
    const body = document.body;
    modal ? body.style.overflow = 'hidden' : body.removeAttribute('style');
}

/**
 * デザイン画面で外字検索、住所検索した時にデザイン画面のz-indexを切り替える
 * @param {Number} width 
 * @param {Object} selector 
 */
function designLayer(width, selector) {
    if (width > 640 && selector.classList.contains('z-1')) {
        selector.classList.remove('z-1');
    } else {
        selector.classList.add('z-1');
    };
    return;
};