<!--
MEMO: 他のサービス取り込み確認モーダル
-->
<template>
    <div class="c-modal__external c-modal__externalAndSearchAddress">
        <!-- MEMO: 保存時のローディング用 -->
        <LoadingScreen :is-loading-screen="isLoadingScreen"
                       :other-service-import="otherServiceImport"></LoadingScreen>
        <!--削除確認用ボックス-->
        <div>
            <div
                class="fixed bg-white w-11/12 max-w-xl top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pt-8 pb-12 z-4">
                <div class="text-center font-bold">
                    <p>{{ serviceName }}<br>からデータを取り込みますがよろしいですか？</p>
                </div>
                <div class="px-8">
                    <div class="grid grid-cols-2 gap-x-2 pt-8 font-bold">
                        <button @click="cancel()"
                                class="border border-gray03 py-2 px-4 rounded-3xl">
                            <span>キャンセル</span>
                        </button>
                        <button @click="importOtherServices()"
                                class="flex justify-center items-center gap-x-2 text-white bg-blue02 py-2 px-4 rounded-3xl">
                            <span class="block">取り込む</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import config from "../const/const";
import * as Enum from "../const/const";
import AuthFunctions from "../functions/auth";
import LoadingScreen from "./LoadingScreen";

const {
    getCustomer,
} = AuthFunctions();

const getOtherServiceId = (service_name) => {
    switch (service_name) {
        case Enum.OtherService.HIKITAKU.name:
            return Enum.OtherService.HIKITAKU.id;
        case Enum.OtherService.WEB_JO.name:
            return Enum.OtherService.WEB_JO.id;
        case Enum.OtherService.PIAPRI.name:
            return Enum.OtherService.PIAPRI.id;
    }
};
export default {
    name: "OtherServicesImportConfModal",
    components: {LoadingScreen},
    props: {
        serviceName: {
            type: String,
            required: true,
            default: null,
        },
    },
    data() {
        return {
            customer: null,
            isLoadingScreen: false,
            otherServiceImport: false,
            // isShowConf: false,
            // serviceName: null,
        }
    },
    emits: [
        'closeOtherServicesImportConfModal',
    ],
    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述
    },
    async mounted() {
        this.customer = await getCustomer();
        // MEMO: 会員情報の取得ができない場合、ECのログイン画面へ遷移
        if (!this.customer || !Object.keys(this.customer).length) {
            // MEMO: ここでECのログアウトへリダイレクトURLをつけて飛ばす
            window.location.href = process.env.MIX_EC_LOGOUT_URL
                + '?redirect_url=' + process.env.MIX_APP_URL
                + this.$route.path;
            return;
        }
    },
    methods: {
        /**
         * 他のサービスの選択 及び キャンセル
         * */
        cancel() {
            // MEMO: 呼び出し元へ、選択された他のサービス名を返す
            this.$emit('closeOtherServicesImportConfModal', 'cancel');
        },
        /**
         * 確認モーダルの取り込み押下時
         * */
        async importOtherServices() {
            this.isLoadingScreen = true;
            this.otherServiceImport = true;

            // MEMO: サービス名に一致するゲスト情報を取得する
            const other_service_id = getOtherServiceId(this.serviceName);

            await axios
                .get(config.API_BASE_URL + '/other_service/store/' + this.customer.id + '/' + other_service_id)
                .then(() => {
                    // MEMO:処理成功をした時
                    this.$emit('closeOtherServicesImportConfModal', this.serviceName);
                })
                .catch((error) => {
                    // MEMO: 呼び出し元へエラーを返す
                    this.$emit('closeOtherServicesImportConfModal', error.response.status === 503 ? 'no_data' : 'error');
                })
                .finally(() => {
                    this.isLoadingScreen = false
                    this.otherServiceImport = false;
                });
        },
        /**
         * 他のサービス選択モーダルを閉じる
         * */
        closeOtherServiceScreen(cancel) {
            this.$emit('closeOtherServices', cancel);
        },
    },
}

</script>

<style scoped>

</style>