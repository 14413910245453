<template>
    <div class="c-modal__external c-modal__externalAndSearchAddress">
        <!--------------------------------------------------- 
            ピアプリ、Web-Jo、ヒキタクから宛名読み込み時のロード画面
            グルグル回っているものはGIFに差し替える予定です
        ----------------------------------------------------->
        <!-- MEMO: 保存時のローディング用 -->
        <LoadingScreen :is-loading-screen="isLoadingScreen"
                       :other-service-import="otherServiceImport"></LoadingScreen>
        <div
            class="w-full sm:mx-auto bg-white py-8 sm:py-12 mx-4 rounded-3xl"
            :class="[routePathOnlyForEdit ? 'max-w-lg' : 'sm:max-w-2xl']"
        >
            <div class="pt-4">
                <button
                    @click="closeOtherServiceScreen(refEnum.OtherService.PIAPRI.name)"
                    class="flex justify-center items-center gap-x-4 w-5/6 sm:w-2/3 ml-auto mr-auto py-3 rounded-full sm:text-lg font-bold text-center text-white bg-rose02">
                    <img src="/images/icon/addressee/ic_add-guest.svg">
                    <span>myピアプリから取り込む</span>
                </button>
            </div>
            <div class="pt-4 sm:pt-6">
                <button
                    @click="closeOtherServiceScreen(refEnum.OtherService.HIKITAKU.name)"
                    class="flex justify-center items-center gap-x-4 w-5/6 sm:w-2/3 ml-auto mr-auto py-3 rounded-full sm:text-lg font-bold text-center text-white bg-blue03">
                    <img src="/images/icon/addressee/ic_add-guest.svg">
                    <span>myヒキタクから取り込む</span>
                </button>
            </div>
            <div class="pt-4 sm:pt-6">
                <button
                    @click="closeOtherServiceScreen(refEnum.OtherService.WEB_JO.name);"
                    class="flex justify-center items-center gap-x-4 w-5/6 sm:w-2/3 ml-auto mr-auto py-3 rounded-full sm:text-lg font-bold text-center text-white bg-navy01">
                    <img src="/images/icon/addressee/ic_add-guest.svg">
                    <span>myWeb-Joから取り込む</span>
                </button>
            </div>
            <div class="pt-8 sm:pt-12 text-center text-blue02 font-bold sm:text-lg">
                <button @click="closeOtherServiceScreen()">閉じる</button>
            </div>
        </div>
    </div>

</template>

<script>

import * as Enum from "../const/const";
import AuthFunctions from "../functions/auth";
import LoadingScreen from "./LoadingScreen";

const {
    getCustomer,
} = AuthFunctions();

export default {
    name: "OtherServicesImport",
    components: {LoadingScreen},
    async mounted() {
        this.customer = await getCustomer();
        // MEMO: 会員情報の取得ができない場合、ECのログイン画面へ遷移
        if (!this.customer || !Object.keys(this.customer).length) {
            // MEMO: ここでECのログアウトへリダイレクトURLをつけて飛ばす
            window.location.href = process.env.MIX_EC_LOGOUT_URL
                + '?redirect_url=' + process.env.MIX_APP_URL
                + this.$route.path;
        }
    },
    data() {
        return {
            customer: null,
            isLoadingScreen: false,
            routePathOnlyForEdit: this.$route.path.match(/edit/),
            otherServiceImport: false,
        }
    },
    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述
    },
    emits: {
        closeOtherServices: String,
        readingAnimation: null,
    },
    methods: {
        /**
         * 他のサービスの選択 及び キャンセル
         * */
        closeOtherServiceScreen(service_name = 'cancel') {
            // MEMO: 呼び出し元へ、選択された他のサービス名を返す
            this.$emit('closeOtherServices', service_name);
        },
    },
}

</script>

<style scoped>

</style>