<template>
    <div class="p-purchase__flow">
        <section class="p-purchase__box">
            <div class="w-full bg-white">
                <div class="grid grid-cols-3 items-center px-2 py-4">
                    <div class="block text-sm">
                        <button @click="returnScreenButton()">＜戻る</button>
                    </div>
                    <img src="/images/icon/header/ic_header-logo.svg" alt="my family" class="l-header__logo">
                </div>
            </div>
            <div class="block text-xl font-bold text-center mt-8">
                <p>商品が追加されました</p>
                <p>カートよりお支払いにお進みください</p>
            </div>
            <div class="block mt-6 text-center text-red text-xs">
                <p>※まだ注文は完了していません</p>
            </div>
            <div class="text-center text-xs text-gray01 mt-6 leading-5">
                <span>注文データはアプリTOPの
                    <a href="#" class="text-blue02 underline">注文履歴</a>からご確認いただけます。
                </span>
                <p>データは決済が完了するまで編集可能です。</p>
            </div>
            <div class="p-purchase__position__button sm:relative w-full">
                <div class="bg-white py-2.5 sm:py-4 px-12">
                    <div class="w-full py-2 sm:max-w-sm block bg-blue02 rounded text-center sm:ml-auto sm:mr-auto">
                        <a href="https://piary.jp" target="_blank" class="w-full text-white font-bold">支払いに進む</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'PurchaseFlowCart',

    methods: {
        returnScreenButton() {
            this.$emit('purchaseFlowOfAddToCart');
        },
    },
}
</script>

<style scoped>

</style>
