<template>
    <!--Header-->
    <Header></Header>
    <section class="l-wrapper min-h-screen bg-gray02">
        <div class="pt-8 sm:pt-12">
            <div class="px-4">
                <div class="border-red border font-bold text-sm text-red py-4 px-4 sm:max-w-sm sm:mx-auto bg-white">
                    <p>お使いのブラウザではご利用できません</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "UserAgentError"
}
</script>

<style scoped>

</style>