<template>
    <footer class="l-footer l-footer__arrow--color js-footerHeight js-footerLayer">
        <div class="l-footer__arrow"></div>
        <div class="w-full max-w-3xl mx-auto bg-white">
            <ul :class="[isMourning ? 'l-footer__items_mourning' : 'l-footer__items', isMovingHouse ? 'l-footer__items_moving' : '']"
                class="js-footerItems" ref="scroll_X">
                <div class="l-footer__leftArrow sm:hidden" ref="leftArrow"></div>
                <li class="w-full hover:bg-beige inline-block sm:px-0 ml-5 sm:ml-0 js-footerItemList">
                    <!--MEMO: 一時保存ボタン -->
                    <TemporarySaveButton @tmpValidateMessage="tmpValidateMessage"
                                         @tmpMemberMessage="tmpMemberMessage"
                                         @tmpSavePrevLoading="tmpSavePrevLoading"
                                         @showAnimation="showAnimation"
                                         @showAlertMessage="showAlertMessage"></TemporarySaveButton>
                </li>
                <li class="w-full hover:bg-beige inline-block sm:px-0 js-footerItemList">
                    <!--MEMO: レイアウトボタン -->
                    <button @click="toggleActive('select_style')"
                            :class="{'is-colorActive': colorIsActive('select_style')}"
                            class="l-footer__icon l-footer__icon--style px-12 pt-8 inline-block"
                    >
                    </button>
                </li>

                <li class="w-full hover:bg-beige inline-block sm:px-0 js-footerItemList">
                    <!--MEMO: テキスト追加ボタン -->
                    <button @click="toggleActive('add_text')"
                            :class="{'is-colorActive': colorIsActive('add_text')}"
                            class="l-footer__icon l-footer__icon--addText px-12 pb-6 pt-8 inline-block"
                    >
                    </button>
                </li>

                <!--MEMO: 差出人追加ボタン -->
                <li class="w-full hover:bg-beige inline-block sm:px-0 js-footerItemList">
                    <SenderAddButton></SenderAddButton>
                </li>

                <!--MEMO: 画像配置ボタン -->
                <li v-if="!isMourning && !isMovingHouse" class="w-full hover:bg-beige inline-block sm:px-0 js-footerItemList">
                    <button @click="toggleActive('arrange_image')"
                            :class="{'is-colorActive': colorIsActive('arrange_image')}"
                            class="l-footer__icon l-footer__icon--arrangeImage px-12 pb-6 pt-8 inline-block"
                    >
                    </button>
                </li>
                <!--MEMO: スタンプ -->
                <li v-if="!isMourning" class="w-full hover:bg-beige inline-block sm:px-0 js-footerItemList">
                    <button @click="toggleActive('put_stamp')"
                            :class="{'is-colorActive': colorIsActive('put_stamp')}"
                            class="l-footer__icon l-footer__icon--stamp px-12 pb-6 pt-8 inline-block"
                    >
                    </button>
                </li>
                <!--MEMO: 宛名のデザイン-->
                <li class="w-full hover:bg-beige inline-block sm:px-0 js-footerItemList">
                    <button @click="toggleActive('paper_select')"
                            :class="{'is-colorActive': colorIsActive('paper_select')}"
                            class="l-footer__icon l-footer__icon--paper_select px-12 pb-6 pt-8 inline-block"
                    >
                    </button>
                </li>
                <li class="w-full hover:bg-beige inline-block sm:px-0 js-footerItemList">
                    <button @click="toggleActive('tutorial')"
                            :class="{'is-colorActive': colorIsActive('tutorial')}"
                            class="l-footer__icon l-footer__icon--tutorial px-12 pb-6 pt-8 inline-block"
                    >
                    </button>
                </li>

                <!-- 直下のdivタグはスクロールで矢印を表示の計算に使用している -->
                <div class="pl-5 sm:hidden"></div>
                <div class="l-footer__rightArrow sm:hidden" ref="rightArrow"></div>
            </ul>
        </div>
    </footer>
    <!--MEMO: 宛名のデザイン -->
    <PaperSelectModal
            @close="closePaperSelectModal"
            v-if="isShowPaperSelectModal"/>
    <NonMemberModal
            :show-modal="isShowNomMemberModal"
            :non-member-message="nonMemberMessage"
            @cancel="closeNonMemberModal"></NonMemberModal>

    <EditValidateModal
            :validateMessage="message"
            :showModal="showModal"
            @validateModal="validateModal"></EditValidateModal>
    <LoadingScreen :is-loading-screen="isLoadingScreen"></LoadingScreen>

    <!--一時保存のアニメーション-->
    <div class="fixed w-1/2 sm:w-1/5 h-full top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 z-4 p-edit__temporarySaveComplete js-temporarySaveAnimation"></div>
    <div class="c-modal__overlay js-modalOverlay"></div>
</template>

<script>
import {inject} from "vue";
import TemporarySaveButton from "./Edit/SideMenu/TemporarySaveButton";
import NonMemberModal from "./Edit/SideMenu/NonMemberModal";
import SenderAddButton from "./Edit/SideMenu/SenderAddButton";
import EditValidateModal from "./Edit/SideMenu/EditValidateModal";
import LoadingScreen from "./LoadingScreen.vue";
import TemporarySave from "../functions/temporarySave"
import PaperSelectModal from "./Edit/PaperSelectModal.vue";
import {MourningProductCodes, HouseMovingProductCodes} from "../const/const";

export default {
    name: "FooterMenu",
    components: {
        PaperSelectModal,
        SenderAddButton, NonMemberModal, TemporarySaveButton, EditValidateModal, LoadingScreen
    },
    emits: ['showAlertMessage'],
    setup() {
        const editStatus = inject('editStatus')
        const makingItem = inject('makingItemInfo')
        return {
            editStatus,
            makingItem,
        }
    },
    data() {
        return {
            isColorActive: '',
            windowWidth: 0,
            isShowNomMemberModal: false,
            validateEditMessage: false,
            message: null,
            showModal: false,
            isLoadingScreen: false,
            nonMemberMessage: '',
            isShowPaperSelectModal: false
        };
    },
    mounted() {
        /**
         * @param[number]
         * Footer要素の数を乗算
         */
        function calcFooterItemsWidth() {
            const totalFooterItems = document.querySelectorAll('.js-footerItemList');
            for (let i = 0; i < totalFooterItems.length; i++) {
                const getItemList = totalFooterItems.length;
                const getItemsSize = totalFooterItems[i].clientWidth;

                return getItemsSize * getItemList;
            }
        }

        /**
         * Footerスクロール量に応じて、矢印のガイドをつけ外しする
         */
        (() => {
            if (this.windowWidth < 640) {
                const targetFooterItem = this.$refs.scroll_X;
                const leftArrow = this.$refs.leftArrow;
                const rightArrow = this.$refs.rightArrow;
                const calcTargetWidth = (calcFooterItemsWidth() - targetFooterItem.clientWidth) + (leftArrow.clientWidth + rightArrow.clientWidth);

                leftArrow.classList.add('hidden'); //初期は非表示

                targetFooterItem.addEventListener('scroll', () => {
                    const calcScroll_X = targetFooterItem.scrollLeft;

                    if (calcScroll_X > 0) {
                        leftArrow.classList.remove('hidden')
                    } else {
                        leftArrow.classList.add('hidden');
                    }

                    if (calcScroll_X > calcTargetWidth - 1 || calcScroll_X === calcTargetWidth) {
                        rightArrow.classList.add('hidden');
                    } else {
                        rightArrow.classList.remove('hidden')
                    }
                });
            }
        })();
    },

    created() {
        this.windowWidth = window.innerWidth;
    },
    computed: {
        isMourning() {
            // MEMO: 喪中の場合、ご確認に表示する内容を切り替えるため判定を返します
            return MourningProductCodes.includes(this.makingItem.design_code) ? true : false;
        },
        isMovingHouse(){
            //MEMO:Check if the product choosen is moving house(mirroring mourning code)
            return HouseMovingProductCodes.includes(this.makingItem.design_code) ? true : false;
        },
    },
    methods: {

        /**
         * はがきの台紙選択モーダルを閉じる
         * */
        closePaperSelectModal() {
            this.isShowPaperSelectModal = false;
        },
        /**
         * フッターメニューのクリック時の切り替え
         * @param {string} menu_name
         */
        toggleActive(menu_name) {
            this.editStatus.neutralize()

            if (menu_name === 'add_text') {
                this.editStatus.currentTargetTextId = 'new_text'
            }

            if (menu_name === 'paper_select') {
                this.isShowPaperSelectModal = true;
            }

            this.editStatus.activeSideMenu = menu_name
        },
        colorIsActive(selectIcon) {
            return this.editStatus.activeSideMenu === selectIcon;
        },

        /**
         * 一時保存時のバリデーションテキストを表示
         * @param {String} msg
         */
        tmpValidateMessage(msg) {
            this.showModal = !this.showModal;
            this.message = msg;
        },

        validateModal() {
            this.showModal = !this.showModal;
            this.isLoadingScreen = false;
        },

        /**
         * 状態：ゲスト時の一時保存ボタン押した後の文言
         * @param {Strign} msg
         */
        tmpMemberMessage(msg) {
            this.isShowNomMemberModal = true;
            this.nonMemberMessage = msg;
        },

        closeNonMemberModal() {
            this.isShowNomMemberModal = false;
        },

        /**
         * 一時保存実行時にアニメーションする前に、ローディング画面を挟む
         * @param {Boolean} show
         */
        tmpSavePrevLoading(show) {
            this.isLoadingScreen = show;
        },

        showAnimation(value) {
            TemporarySave(value);
        },
        // バリデーションとかのモーダルは上のEditでだす
        showAlertMessage(messages, confirm) {
            this.$emit('showAlertMessage', messages, confirm)
        },
    },
}
</script>

<style>

</style>
