<template>
    <div class="context_menu_area moveable rCS1rb3a7w moveable-control moveable-direction moveable-se moveable-scalable"
         :style="contextMenuAreaStyle">
        <div :style="selectedObjectFlame"></div>
        <button @click="toggleMenuList"
                class="absolute top-0 right-0 translate-x-2/4 -translate-y-2/4 rounded-2xl z-5 cursor-pointer c-edit__setting__icon c-setting__icon">
        </button>

        <div v-if="contextMenuItem" class="absolute top-0 left-0 w-36 bg-white z-4">
            <ul class="z-2 shadow-toggle">
                <li class="px-3 py-4" v-show="contentIsText">
                    <button @click="openTextEditMenu"
                            class="flex items-center gap-3">
                        <img src="/images/icon/edit/content_change_icon.svg" alt="内容を編集">
                        <p class="text-xs font-bold">内容を編集</p>
                    </button>
                </li>
                <li class="px-3 py-4" v-show="contentIsText">
                    <button @click="openTextStyleMenu"
                            class="flex items-center gap-3">
                        <img src="/images/icon/edit/text_style_icon.svg" alt="文字スタイル">
                        <p class="text-xs font-bold">文字スタイル</p>
                    </button>
                </li>
                <!-- <li class="px-3 py-4" v-show="contentIsImage">
                    <button @click="openPhotoSelectMenu"
                            class="flex items-center gap-3">
                        <img src="/images/icon/edit/image_change_icon.svg" alt="画像を変更">
                        <p class="text-xs font-bold">画像を変更</p>
                    </button>
                </li> -->
                <li class="px-3 py-4">
                    <button class="flex items-center gap-3" @click="removeDeployedObject">
                        <img src="/images/icon/edit/item_delete_icon.svg" alt="項目を削除">
                        <p class="text-xs font-bold">項目を削除</p>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";

export default {
    name: "DeployedObjectContextMenu",
    setup() {
        const makingItem = inject('makingItemInfo')
        const editStatus = inject('editStatus')
        return {editStatus, makingItem};
    },
    props: {
        'targetObjectId': String,
    },

    data() {
        return {
            menuListIsVisible: false,
            selectedObjectFlame: {
                fill: 'none',
                stroke: '#000000',
                stroke_width: '5px',
                stroke_dasharray: '15, 5',
            },
            contextMenuItem: false,
            windowWidth: '',
        }
    },
    methods: {
        toggleActive(menu_name) {
            this.editStatus.activeSideMenu = this.editStatus.activeSideMenu === menu_name ? 'none' : menu_name;
        },
        toggleMenuList() {
            this.menuListIsVisible = !this.menuListIsVisible;
            this.contextMenuItem = !this.contextMenuItem;
        },
        openTextEditMenu() {
            if (this.contentIsUserText) {
                this.editStatus.activeSideMenu = 'add_text'
            } else if (this.contentIsSenderText) {
                this.editStatus.activeSideMenu = 'sender_add'
            }

            //PCのみフォーカスが外れないように変更
            if (this.windowWidth > 640) {
                this.contextMenuItem = false;
            } else {
                this.editStatus.neutralize();
            }
        },
        openPhotoSelectMenu() {
            this.editStatus.activeSideMenu = 'arrange_image'
        },
        openTextStyleMenu() {
            this.editStatus.activeSideMenu = 'select_text_style'
            this.contextMenuItem = false;
        },
        removeDeployedObject() {
            if (this.contentIsUserText) {
                this.makingItem.removeDeployedText(this.editStatus.currentTarget)
            } else if (this.contentIsSenderText) {
                this.makingItem.removeSenderText()
            } else if (this.contentIsImage) {
                this.editStatus.imageToDeploy.deployed = false
                this.makingItem.removeDeployedImage(this.editStatus.currentTarget)
            } else if (this.contentIsStamp) {
                this.makingItem.removeStamp(this.editStatus.currentTarget)
            }
            this.editStatus.neutralize()
            if (this.windowWidth > 640) {
                this.editStatus.currentTargetTextId = 'new_text';
            }
        },
    },
    computed: {
        targetContentType() {
            return this.editStatus.currentTarget ?
                this.editStatus.currentTarget.split('_')[0] :
                'none'
        },
        contentIsUserText() {
            return this.targetContentType === 'userText'
        },
        contentIsSenderText() {
            return this.targetContentType === 'senderText'
        },
        contentIsText() {
            return this.contentIsUserText || this.contentIsSenderText
        },
        contentIsImage() {
            return this.targetContentType === 'userImage';
        },
        contentIsStamp() {
            return this.targetContentType === 'stamp';
        },
        contextMenuAreaStyle() {
            return {
                transform: 'translate(0px, 0px)',
            }
        },
    },

    created() {
        this.windowWidth = window.innerWidth;
    }
}
</script>

<style scoped>
.context_menu_area {
    position: absolute;
    z-index: 2;
}

</style>