<template>
    <Header></Header>
    <section class="l-wrapper min-h-screen bg-gray02">
        <div class="fixed top-0 left-0 w-full h-full flex justify-center items-center">
            <div class="bg-white px-4 py-8">
                <p>現在、年賀状の販売を停止しております。</p>
                <div class="pt-8">
                    <a href="https://piary.jp" class="block bg-orange w-1/2 px-4 py-2 mx-auto rounded-20 text-white">
                        <span>ピアリーに戻る</span>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Header from '../components/Header.vue';

export default {
    name: "Maintenance",

    components: {
        Header
    },
}
</script>