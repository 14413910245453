<template>
    <section class="l-wrapper__sidemenu absolute right-0 js-onlyForTemporarySaveButton" ref="sideMenuArea">
        <!-----スタイル----->
        <select-card-style @close="closeSlide" :class="{ 'is-active': isActive('select_style') }"></select-card-style>

        <!-----テキスト追加----->
        <TextArea
            v-if="isActive('add_text')"
            @close="closeSlide"
            :class="{ 'is-active': isActive('add_text') }"></TextArea>

        <!-----差出人 追加----->
        <SenderAdd
            @close="closeSlide"
            :class="{ 'is-active': isActive('sender_add') }"></SenderAdd>
        <!-----
        <sender-register-panel
            :class="{ 'is-active': isActive('sender_add') }"
            :sender-item="senderItem"></sender-register-panel>
        ----->

        <!-----差出人を読み込む----->
        <stored-sender-data @close="closeSlide"
                            :class="{ 'is-active': isActive('stored_sender') }"></stored-sender-data>

        <!-----配置順の変更----->
        <SenderAddressOrderingRule
            v-if="isActive('sender_address_ordering_rule')"
            @close="closeSlide"
            :class="{ 'is-active': isActive('sender_address_ordering_rule') }"></SenderAddressOrderingRule>
        <!-----画像の配置----->
        <UserPhotoList
            v-if="isActive('arrange_image')"
            @validateAddImage="showAlertMessage"
            @close="closeSlide" @showLoading="showLoading"
            :class="{ 'is-active': isActive('arrange_image') }"></UserPhotoList>

        <!-----スタンプ----->
        <SelectStamp v-if="isActive('put_stamp')"
                     @close="closeSlide"
                     :class="{ 'is-active': isActive('put_stamp') }"></SelectStamp>

        <!-----歯車アイコン_文字スタイル----->
        <select-text-style @close="closeSlide"
                           v-if="isActive('select_text_style')"
                           :class="{ 'is-active': isActive('select_text_style') }"></select-text-style>

        <!-----移動、回転------>
        <div class="c-lower__slide--quarter js-clickOpen__moveAndRotationEditBox z-100 u-hidden">
            <div class="relative sm:top-0">
                <ul class="grid grid-cols-3 gap-3 sm:hidden pl-4 pr-4">
                    <button>
                        <li class="mt-4 font-bold text-blue02 text-left js-lowerSlideCancelButton">キャンセル</li>
                    </button>
                    <li class="mt-2 text-center"><img src="public/images/icon/edit/rectangle.svg" alt=""></li>
                    <button>
                        <li class="mt-4 font-bold text-blue02 text-right">決定</li>
                    </button>
                </ul>
                <div class="flex justify-between">
                    <ul class="u-edit__move mt-6 px-5 sm:px-0 text-xs text-center font-bold">
                        <button>
                            <li class="u-edit__move__left"><img src="/images/icon/edit/left_move_icon.svg" alt=""></li>
                        </button>
                        <button>
                            <li class="u-edit__move__top"><img src="/images/icon/edit/top_move_icon.svg" alt=""></li>
                        </button>
                        <button>
                            <li class="u-edit__move__down"><img src="/images/icon/edit/down_move_icon.svg" alt=""></li>
                        </button>
                        <button>
                            <li class="u-edit__move__right"><img src="/images/icon/edit/right_move_icon.svg" alt="">
                            </li>
                        </button>
                        <li class="u-edit__move__text">移動</li>
                    </ul>
                    <ul class="u-edit__rotation mt-6 px-5 sm:px-0 text-xs text-center font-bold">
                        <button>
                            <li class="u-edit__rotation01"><img src="/images/icon/edit/right_rotation_icon.svg" alt="">
                            </li>
                        </button>
                        <button>
                            <li class="u-edit__rotation02"><img src="/images/icon/edit/left_rotation_icon.svg" alt="">
                            </li>
                        </button>
                        <li class="u-edit__rotation03">回転</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <LoadingScreen :is-loading-screen="isLoadingScreen"></LoadingScreen>
</template>

<script>
import TextArea from "./SideMenu/TextArea";
import SelectCardStyle from "./SideMenu/SelectCardStyle";
import UserPhotoList from "./SideMenu/UserPhotoList";
import SenderAdd from "./SideMenu/SenderAdd";
import SelectStamp from "./SideMenu/SelectStamp";
import {inject} from "vue";
import StoredSenderData from "./SideMenu/StoredSenderData";
import SenderAddressOrderingRule from "./SideMenu/SenderAddressOrderingRule";
import SelectTextStyle from "./SideMenu/SelectTextStyle";
import SenderRegisterPanel from "../SenderList/SenderRegisterPanel";
import LoadingScreen from "../LoadingScreen";
import EditPageModalAlert from "./SideMenu/EditPageModalAlert";

export default {
    components: {
        EditPageModalAlert,
        SenderRegisterPanel,
        SenderAddressOrderingRule,
        StoredSenderData,
        UserPhotoList,
        SelectCardStyle,
        TextArea,
        SenderAdd,
        SelectStamp,
        SelectTextStyle,
        LoadingScreen,
    },

    emits: [
        'showAlertMessage',
    ],

    data() {
        return {
            windowWidth: '',
            sideMenuElement: '',
            validateFileSize: false,
            isModalActive: false,
            user_upload_image_errors: [],
            isLoadingScreen: false,
            modalConfirmProcedure: () => {
            },
        }
    },

    setup() {
        const makingItem = inject('makingItemInfo')
        const editStatus = inject('editStatus')
        return {editStatus, makingItem};
    },

    methods: {
        closeSlide() {
            this.editStatus.activeSideMenu = 'none';
        },

        isActive(selection) {
            return this.editStatus.activeSideMenu === selection;
        },

        // バリデーションとかのモーダルは上のEditでだす
        showAlertMessage(messages, confirm) {
            this.$emit('showAlertMessage', messages, confirm)
        },

        /**
         * 画像アップロード時：ロード画面の切り替え
         * @param {Boolean} val
         */
        showLoading(val) {
            this.isLoadingScreen = val;
        },
    },

    created() {
        this.windowWidth = window.innerWidth;
    },
}

</script>

<style scoped>
</style>