/**
 * 宛名面の宛先
 * 宛名面の差出人
 * デザイン面の差出人
 * 上記に関する入力周りの関数をまとめたファイルです
 * */
export default function () {

    /**
     * 入力イベントを無効化します
     * */
    const disableEvent = (e) => {
        e.preventDefault();
        return false;
    };


    return {
        disableEvent,
    }
}