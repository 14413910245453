<template>
    <div class="p-edit__slide--full sm:pt-8">
        <ul class="grid grid-cols-3 gap-3 sm:hidden pl-4 pr-4">
            <li class="mt-4 font-bold text-blue02 text-left" @click="close">閉じる</li>
            <!-- <li class="mt-2 text-center"><img src="/images/icon/edit/rectangle.svg" alt=""></li> -->
            <!-- <li class="mt-4 font-bold text-blue02 text-right" @click="close">決定</li> -->
        </ul>
        <div class="relative h-full sm:top-0 pt-6 sm:pt-0 p-edit__sideMenu">
            <div class="px-4 h-full text-center">
                <!-- 10/16 鳥居：二次リリースに素材集を追加。コメントアウトを外す -->
                <!-- <div class="grid grid-cols-2 pb-8">
                    <button
                        @click="isSelect('register')"
                        :class="{'is-active': isTabChange === 'register'}"
                        class="c-button__tab c-button__tab--left text-sm font-bold"
                    >登録した写真
                    </button>

                  
                    <button
                        @click="isSelect('material')"
                        :class="{'is-active': isTabChange === 'material'}"
                        class="c-button__tab c-button__tab--right text-sm font-bold"
                    >素材集
                    </button>
                </div> -->

                <div class="pb-4">
                    <p class="w-full py-2 text-lg font-bold">アップロードした写真</p>
                </div>

                <div v-if="isTabChange === 'register'" class="h-full overflow-y-auto pb-8 u-scrollbar__none">
                    <div>
                        <ul class="grid grid-cols-3 gap-2 sm:grid sm:grid-cols-2 sm:gap-3 p-edit__material">
                            <li class="mt-2 inline-block sm:block p-edit__material__items is-material"
                                v-if="isShowAddImageButton">
                                <label for="file_image">
                                    <form name="form">
                                        <img src="/images/icon/edit/nen_add-image.svg" alt="" class="cursor-pointer">
                                        <input
                                            @change="addImage"
                                            type="file"
                                            id="file_image"
                                            accept='image/*'
                                            name="image_arrangement"
                                            value=""
                                            ref="preview"
                                            class="c-input__file__init"
                                        >
                                    </form>
                                </label>
                            </li>
                            <PhotoListThumbnail
                                v-for="(image, index) in userImages" :key="index"
                                @deployImage="deployImage"
                                @deleteImage="deleteImage"
                                @toggleMenu="toggleItemMenu"
                                :image-id="image.id"
                                :imageUrl="image.url"
                                :imageIndex="index"
                                :menu-active="menuActiveItem"
                                :deployable="!image.deployed"
                                :is-proxy-user="customer.is_proxy_user"
                                :style="deletedUserImage(image.deleted_at)"
                            >
                            </PhotoListThumbnail>
                        </ul>
                    </div>
                </div>

                <!-- <div v-if="isTabChange === 'material'" class="sm:pt-0 bg-white text-base">
                    <swiper
                        ref="swiperTop"
                        class="swiper"
                        :centered-slides="false"
                        :slides-per-view="3"
                        :free-mode="true"
                    >
                        <swiper-slide v-for="(i, index) in makingItem.material.animal" :key="index">
                            <p>{{ i.text }}</p>
                            <button @click="changeMaterial(i.menu)"
                                    class="w-full c-button__stamp__category">
                            </button>
                        </swiper-slide>
                    </swiper>
                </div> -->
                <!-- <ul class="grid grid-cols-3 gap-2 sm:grid sm:grid-cols-2 sm:gap-3 p-edit__material pb-48 sm:pb-24">
                    <photo-list-thumbnail
                        v-for="(image, index) in userImages" :key="index"
                        @deployImage="deployImage"
                        @deleteImage="deleteImage"
                        @toggleMenu="toggleItemMenu"
                        :image-id="image.id"
                        :imageUrl="image.url"
                        :imageIndex="index"
                        :menu-active="menuActiveItem"
                    >
                    </photo-list-thumbnail>
                </ul> -->
            </div>
        </div>
    </div>
</template>

<script>
import PhotoListThumbnail from "./PhotoListThumbnail";
import {inject} from "vue";
import {EditState} from "../../../const/const";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css/free-mode';
import 'swiper/css';
import AvailableResources from "../AvailableResources.vue";
import AuthFunctions from "../../../functions/auth";

const { getCustomer } = AuthFunctions();
export default {
    name: "UserPhotoList",
    components: {PhotoListThumbnail, Swiper, SwiperSlide},
    emits: ['toggleMenu', 'validateAddImage', 'showLoading', 'close'],
    setup() {
        const editStatus = inject('editStatus');
        const makingItem = inject('makingItemInfo')
        const availableResources = inject('availableResources')

        return {
            editStatus,
            availableResources,
            makingItem,
        };
    },
    data() {
        return {
            menuActiveItem: null,
            isTabChange: 'register',
            activeMaterial: '',
            uploadThumbnail: null,
            windowWidth: '',
            isShowAddImageButton: true,
            customer: {}
        }
    },
    created() {
        this.windowWidth = window.innerWidth;
    },
    /**
     * MEMO: 画像配置メニューがクリックされるタイミングで実行
     * */
    async mounted() {
        this.customer = await getCustomer();

        this.$emit('showLoading', true);
        // 画像情報(dtb_user_image)の一覧を取得する
        if (this.makingItem.customer_id) {
            // 会員固有の素材
            await AvailableResources.loadCustomerResources(this.makingItem.designCode, this.makingItem.customer_id)
        }

        const url_set_processes = this.availableResources.userImages.map(async (img) => {
            // MEMO: loadCustomerResourcesが差分読み込みのため、urlに値がないデータのみ再度取得する
            // 他の商品で画像が追加された場合など
            if(img.url === '') {
                img.url = await this.availableResources.fetchImage(img, this.makingItem.customer_id)
            }
        })

        await Promise.all(url_set_processes)
            .then(() => {
                // MEMO: 配置されている画像と画像リストを比較し、画像リストに存在しない配置画像は編集エリアから削除する
                if (this.deleteDeployedImage()) {
                    // MEMO: 1枚でも削除されていたら配置画像削除モーダルを表示する
                    this.$emit('validateAddImage', ['配置済みの写真が削除されました。']);
                }

                // MEMO: 他の商品で削除された画像が無いかチェックし、削除されている画像は要素から削除します
                if (this.hasDeletedUserImage()) {
                    this.$emit('validateAddImage', ['他の商品でアップロードした写真が\n削除されたのでご確認ください。']);

                    this.availableResources.userImages = this.availableResources.userImages.filter((user_image) => {
                        return !user_image.deleted;
                    })
                }
            })
            .finally(() => {
                this.$emit('showLoading', false);
            })
    },
    watch: {
        // MEMO: 画像リストの変更を監視します。
        //  変更があった場合、画像枚数の上限をチェックして追加ボタンの表示を制御します
        'availableResources.userImages': {
            handler: function (afterUserImages, beforeUserImages) {
                // MEMO: アップロード画像用の変数の要素数に変化がなければ何もしない
                if(afterUserImages.length === beforeUserImages.length) {
                    return;
                }

                // 画像登録枚数上限チェック
                // 上限超えていたら追加ボタン非表示
                this.isShowAddImageButton = this.availableResources.userImages.length < process.env.MIX_MAX_USER_IMAGE_NUMBER;
            },
            deep: true
        },
    },

    computed: {
        deployedImages() {
            return this.makingItem.deployedImages
        },
        customerId() {
            return this.makingItem.customer_id
        },
        nengaId() {
            return this.makingItem.nenga_id
        },
        designCode() {
            return this.makingItem.design_code
        },
        userImages() {
            // MEMO: 配置済の画像が1枚もない、または 配置後に「この項目を削除」後に配置するボタンを表示させるため
            //  一度 画像リストの画像すべてを配置可能にする
            this.availableResources.userImages.forEach((item) => {
                item.deployed = false;
            })

            // MEMO: 画像リストの画像が配置されている場合は「配置する」ボタンを非表示にする
            // deployedImage・・・配置済の画像リスト
            for (const deployedImage of this.makingItem.deployedImages) {
                this.availableResources.userImages.forEach((item) => {
                    // MEMO: 配置済の画像のファイル名と画像リストのファイル名が一致した場合、画像リストの画像を配置済みに変更
                    if (deployedImage.image_file === item.image_file) {
                        item.deployed = true;
                    }
                })
            }
            return this.availableResources.userImages;
        },
    },
    methods: {
        /**
         * 写真を追加ボタン
         * */
        async addImage(event) {
            this.$emit('showLoading', true);
            const code = this.makingItem.code;
            const new_id = String(this.userImages.length + 1);
            const new_img = {
                type: 'userImage',
                id: `userImage_${new_id}`,
                url: URL.createObjectURL(event.target.files[0]),
                transform: 'translate(0px, 0px)',
                stored: false,
                deployed: false,
                deleted: false,
            }

            // MEMO: サーバー側のバリデーションに引っかかるため、ゲストの場合は「空文字」を送信する
            const messages = await this.availableResources.storeImage(new_img, this.customerId ?? '', this.designCode, code)

            // アップロードされた画像の値を初期化して、同じ画像がアップロードされるようにしました。
            event.target.value = null;

            // MEMO: AvailableResources.vueのstoreImageに失敗した場合
            if (!new_img.stored && messages.length) {
                this.$emit('showLoading', false);
                this.$emit('validateAddImage', messages); //EditPageSideMenu.vueでモーダルとバリデーションのメッセージが表示されます。
                return;
            }

            this.userImages.push(new_img);
            this.$emit('showLoading', false);
        },

        /**
         * 画像の配置を選択した時に、フレーム番号が既に指定されていたらフレーム選択をスキップしてクリッピングモードに移行
         * @param {Array} image
         */
        async directUserImageClipping(image) {
            this.editStatus.state = EditState.EDIT_USER_IMAGE;
            this.makingItem.deployedImages.push(image);
            await this.editStatus.setTargetObject(image.id, EditState.EDIT_USER_IMAGE);
        },
        /**
         * 配置する
         * @param {number} img_index
         */
        async deployImage(img_index) {
            const image = this.userImages[img_index];

            // MEMO: 代理の場合、削除されている画像でも配置可能にするためスキップ
            if(!this.customer.is_proxy_user) {
                // MEMO: 配置対象の画像の有効/無効チェック
                const result = await this.availableResources.isAvailableUserImage(image.image_file);
                if (!result) {
                    // MEMO: 画像リストから配置できない対象の画像を削除する
                    this.availableResources.userImages = this.userImages.filter((user_image) => {
                        return user_image.image_file !== image.image_file
                    });

                    if (this.windowWidth < 640) {
                        this.editStatus.activeSideMenu = 'none';
                        // MEMO: SPの場合、アラートメッセージを表示させるためアップロードした写真パネルを閉じる
                        this.close();
                    }

                    this.$emit('validateAddImage', ['他の商品でアップロードした写真が\n削除されたため配置できません。']);
                    return;
                }
            }

            if (this.editStatus.state === EditState.NEUTRAL) {
                image.clipPath = '';
                image.inverseImageTransform = '';

                // MEMO: デザインの背景画像よりユーザー画像が大きい場合、背景画像より-20小さくする
                if (this.makingItem.designData.preview_image_width < image.width) {
                    const org_width = image.width;
                    const org_height = image.height;
                    image.width = this.makingItem.designData.preview_image_width - 20;
                    image.height = org_height * (image.width / org_width);
                }
                this.editStatus.state = EditState.SELECT_FLAME;
                this.editStatus.imageToDeploy = image;

                if (this.windowWidth < 640) {
                    this.editStatus.activeSideMenu = 'none';
                }
            }

            if (this.editStatus.flameToClip !== '') {
                await this.directUserImageClipping(image)
            }
        },
        /**
         * 削除する
         * */
        async deleteImage(img_id) {
            const code = this.makingItem.code;
            // 削除対象となるデータを取得
            const deleteUserImage = this.userImages.find((img) => {
                return img.id === img_id;
            });

            // MEMO: 画像の削除処理を実行
            const result = await this.availableResources.deleteImage(this.customerId, deleteUserImage.image_file, code, this.nengaId, false)
            if (!result) {
                this.$emit('validateAddImage', ['画像の削除に失敗しました。']); //EditPageSideMenu.vueでモーダルとバリデーションのメッセージが表示されます。
                return
            }
            // MEMO: 他のデザインで使用中の画像の場合
            if (result.alert) {
                const forceDelete = async () => {
                    const result = await this.availableResources.deleteImage(this.customerId, deleteUserImage.image_file, code, this.nengaId, true)
                    if (!result) {
                        this.$emit('validateAddImage', ['画像の削除に失敗しました。'])
                        return
                    }
                    // MEMO: 成功した場合、登録した写真から削除する
                    this.availableResources.userImages = this.userImages.filter((img) => {
                        return img.id !== img_id;
                    });
                    // MEMO: 成功した場合、編集エリアに配置されている同一ファイル名の画像も削除する
                    this.makingItem.deployedImages = this.deployedImages.filter((img) => {
                        return img.image_file !== deleteUserImage.image_file;
                    });
                }
                if (this.windowWidth < 640) {
                    this.editStatus.activeSideMenu = 'none';
                    // MEMO: SPの場合、アラートメッセージを表示させるためアップロードした写真パネルを閉じる
                    this.close();
                }
                this.$emit('validateAddImage', result.alert.messages, forceDelete)
                return
            }

            // MEMO: 成功した場合、登録した写真から削除する
            this.availableResources.userImages = this.userImages.filter((img) => {
                return img.id !== img_id;
            });

            // MEMO: 成功した場合、編集エリアに配置されている同一ファイル名の画像も削除する
            this.makingItem.deployedImages = this.deployedImages.filter((img) => {
                return img.image_file !== deleteUserImage.image_file;
            });

            //9/14 鳥居：アップロードしたサムネイル画像を削除
            URL.revokeObjectURL(this.uploadThumbnail = img_id);

            //9/14 鳥居：画像を削除し、再度画像をアップロードした時に「配置」、「削除」ボタンが表示されていたままなので数値を初期化
            this.menuActiveItem = NaN;
        },

        toggleItemMenu(imageIndex) {
            this.menuActiveItem = imageIndex;
            if (this.editStatus.state === EditState.EDIT_OBJECT) {
                this.editStatus.state = EditState.NEUTRAL;
                this.editStatus.currentTarget = '';
            }
        },

        close() {
            this.toggleItemMenu(null);
            this.$emit('close');
        },

        /**
         * タブの切り替え
         */
        isSelect(type) {
            this.isTabChange = type;
        },

        //素材集のカテゴリを切り替える
        changeMaterial(menu) {
            this.activeMaterial = menu;
        },
        /**
         * 配置されている画像と画像リストを比較し
         * 画像リストに存在しない配置画像は編集エリアから削除します
         * */
        deleteDeployedImage() {
            // MEMO: 代理ログインの場合はスキップ
            if (this.customer.is_proxy_user) {
                return false;
            }
            let isDeleteDeployedImage = false;
            for (const deployed_img of this.makingItem.deployedImages) {
                const result = this.availableResources.userImages.filter((user_img) => {
                    return user_img.image_file === deployed_img.image_file && !user_img.deleted;
                })

                if (result.length === 0) {
                    this.makingItem.removeDeployedImage(deployed_img.id)
                    isDeleteDeployedImage = true;
                }
            }
            return isDeleteDeployedImage;
        },
        /**
         * 削除されたアップロードした写真があるか判定します
         **/
        hasDeletedUserImage() {
            if (this.customer.is_proxy_user) {
                return false;
            }
            const result = this.availableResources.userImages.filter((user_image) => {
                return user_image.deleted;
            })

            // MEMO: 削除された画像がある場合はTrue
            return result.length > 0;
        },
        deletedUserImage(deleted_at) {
            if(!deleted_at) {
                return;
            }
            return {
                'border': 'red 2px solid',
            }
        },
    },
}
</script>

<style scoped>

</style>
