/**
 *  姓名用
 * */
export const nameRegex = new RegExp([
    '^[0-9０-９',             // 全ての数字
    'a-zA-ZＡ-Ｚａ-ｚ',        // 全ての英字（大文字・小文字）
    'Ⅰ-Ⅹ',                    // ローマ数字の1から10
    'ぁ-んァ-ヴｦ-ﾝー・： 　',   // ひらがな、カタカナ、長音記号・中黒・コロンを含むスペースなどの日本語文字・特殊記号
    '\\:\\(\\（\\）\\)',      //  括弧とコロンなどの特殊文字
    'ヶ々〇〻',                 // 特定の漢字
    '\u2E80-\u2FDF',          // CJKの部首補完と康煕部首を指定します
    '\u3400-\u4DBF',          // CJK統合漢字拡張A
    '\u4E00-\u9FFF',          // CJK統合漢字
    '\uF900-\uFAFF',          // CJK互換漢字
    '\uD840-\uD87F\uDC00-\uDFFF', // CJK互換漢字補助
    ']+$'
].join(''))

/**
 * 旧姓・年齢用
 * */
export const oldNameOrAgeRegex = new RegExp([
    '^[0-9０-９',             // 全ての数字（半角・全角）
    'a-zA-ZＡ-Ｚａ-ｚ',        // 全てのアルファベット（大文字・小文字、半角・全角）
    'Ⅰ-Ⅹ',                    // ローマ数字のIからX（1から10）
    'ぁ-んァ-ヴｦ-ﾝー・： 　',   // ひらがな、カタカナ、長音記号・中黒・コロンを含むスペースなどの日本語文字・特殊記号
    '\\:\\(\\（\\）\\)',      // \\:\\(\\（\\）\\) 括弧やコロンなどの特殊文字
    'ヶ々〇〻',                 // 特定の漢字文字
    '\u2E80-\u2FDF',          //  CJK部首補助と康煕部首
    '\u3400-\u4DBF',          // CJK統合漢字拡張A
    '\u4E00-\u9FFF',          // CJK統合漢字
    '\uF900-\uFAFF',          // CJK互換漢字
    '\uD840-\uD87F\uDC00-\uDFFF', // CJK互換漢字補助
    ']+$'
].join('')) // Creates RegExp

/** カナ用 */
export const kanaRegex = /^[ァ-ヴｦ-ﾝ 　]+$/;

/** 数字のみ */
export const numberRegex = /^[0-9]+$/;

/**
 * 電話番号
 * 対象の文字列が0-9までの数字、各種のハイフン記号、およびプラス記号のみ許可
 * */
export const phoneNumberRegex = /^[0-9＋\-\‐\‑\‒\–\—\―\⁃\⁻\₋\−\─\━\﹣\－\ｰ\+]+$/;

/** 住所 */
export const addressRegex = new RegExp([
    '^[0-9０-９',                         // 全ての数字（半角・全角）
    'a-zA-ZＡ-Ｚａ-ｚ',                    // 全てのアルファベット（大文字・小文字、半角・全角）
    'Ⅰ-Ⅹ',                                // ローマ数字のIからX（1から10）
    'ぁ-んァ-ヴｦ-ﾝー・： 　',               // ひらがな、カタカナ、長音記号、中黒、スペース
    '\\:\\(\\（\\）\\)',                  // 特殊文字（コロン、丸括弧）
    '\\-\\‐\\‑\\‒\\–\\—\\―\\⁃\\⁻\\₋\\−\\─\\━\\﹣\\－\\ｰ\\|', // ハイフンと同等の記号
    'ヶ々〇〻',                             // 特定の漢字文字
    '\u2E80-\u2FDF',                      // CJKの部首補助と康煕部首
    '\u3400-\u4DBF',                      // CJK統合漢字拡張A
    '\u4E00-\u9FFF',                      // CJK統合漢字
    '\uF900-\uFAFF',                      // CJK互換漢字
    '\uD840-\uD87F\uDC00-\uDFFF',          // CJK互換漢字補助
    ']+$'
].join('')) // Creates RegExp

/** 会社名/部署名/役職名用（名前のパターンに「＆(全角)」を許可したもの） */
export const companyDepartmentPositionNameRegex = new RegExp([
    '^[0-9０-９',                         // Matches any number
    'a-zA-ZＡ-Ｚａ-ｚ',                    // Matches any letter lowercase or uppercase
    'Ⅰ-Ⅹ',                                // Matches Roman numerals from 1 to 10
    'ぁ-んァ-ヴｦ-ﾝー・： &＆ ',             // Matches Japanese characters and ampersand
    '\\:\\(\\（\\）\\)',                  // Matches special characters such as brackets and colon
    'ヶ々〇〻',                             // Matches specific Kanji characters
    '\u2E80-\u2FDF',                      // Matches CJK radicals supplement & Kangxi radicals
    '\u3400-\u4DBF',                      // Matches CJK unified ideographs extension A
    '\u4E00-\u9FFF',                      // Matches CJK unified ideographs
    '\uF900-\uFAFF',                      // Matches CJK compatibility ideographs
    '\uD840-\uD87F\uDC00-\uDFFF',          // Matches CJK compatibility ideographs supplement
    ']+$'
].join('')) // Creates RegExp

