import * as Yup from "yup";
import {GuestType} from "../const/const";
import {addressRegex, nameRegex, numberRegex, oldNameOrAgeRegex, phoneNumberRegex} from './regex_pattern';

/**
 * デザイン面の差出人登録フォームのバリデーションルール
 * */
export const DesignSenderValidationRule = Yup.object({
    corporate_name1: Yup.string()
        .when("guest_type", {
            is: GuestType.CORPORATION.val,
            then: () => Yup.string().max(255, '255文字まで入力可能です'),
        }),
    corporate_name2: Yup.string()
        .when("guest_type", {
            is: GuestType.CORPORATION.val,
            then: () => Yup.string().max(255, '255文字まで入力可能です。').nullable(),
        }).nullable(),
    department_name1: Yup.string()
        .when("guest_type", {
            is: GuestType.CORPORATION.val,
            then: () => Yup.string().max(255, '255文字まで入力可能です。').nullable(),
        }).nullable(),
    department_name2: Yup.string()
        .when("guest_type", {
            is: GuestType.CORPORATION.val,
            then: () => Yup.string().max(255, '255文字まで入力可能です。').nullable(),
        }).nullable(),
    position_name: Yup.string()
        .when("guest_type", {
            is: GuestType.CORPORATION.val,
            then: () => Yup.string().max(255, '255文字まで入力可能です。').nullable(),
        }).nullable(),
    sei: Yup.string()
        .transform((curr, orig) => orig === '' ? null : curr)
        .matches(nameRegex, {message: '使用できない文字が含まれています'})
        .max(20, '20文字まで入力可能です。')
        .nullable(),
    mei: Yup.string()
        .transform((curr, orig) => orig === '' ? null : curr)
        .matches(nameRegex, {message: '使用できない文字が含まれています'})
        .max(20, '20文字まで入力可能です。')
        .nullable(),
    old_name_or_age: Yup.string()
        .transform((curr, orig) => orig === '' ? null : curr)
        .matches(oldNameOrAgeRegex, {message: '使用できない文字が含まれています'})
        .max(15, '15文字まで入力可能です。')
        .nullable(),
    pref_name: Yup.string(),
    post_no: Yup.string()
        .transform((curr, orig) => orig === '' ? null : curr)
        .matches(numberRegex, {message: '半角数字で入力してください'})
        .min(7, '7桁で入力してください。').max(7, '7桁で入力してください。')
        .nullable(),
    address: Yup.string()
        .transform((curr, orig) => orig === '' ? null : curr)
        .matches(addressRegex, {message: '使用できない文字が含まれています'})
        .max(20, '20文字まで')
        .nullable(),
    building_name: Yup.string()
        .transform((curr, orig) => orig === '' ? null : curr)
        .matches(addressRegex, {message: '使用できない文字が含まれています'})
        .max(24, '20文字まで')
        .nullable(),
    tel: Yup.string()
        .transform((curr, orig) => orig === '' ? null : curr)
        .matches(phoneNumberRegex, {message: '使用できない文字が含まれています'})
        .max(20, '20文字まで').nullable()
        .nullable(),
    joint_names: Yup.array().of(
        Yup.object().shape({
            sei: Yup.string()
                .transform((curr, orig) => orig === '' ? null : curr)
                .matches(nameRegex, {message: '使用できない文字が含まれています'})
                .max(255, '255文字まで入力可能です。')
                .nullable(),
            mei: Yup.string()
                .transform((curr, orig) => orig === '' ? null : curr)
                .matches(nameRegex, {message: '使用できない文字が含まれています'})
                .max(255, '255文字まで入力可能です。')
                .nullable(),
            old_name_or_age: Yup.string()
                .transform((curr, orig) => orig === '' ? null : curr)
                .matches(oldNameOrAgeRegex, {message: '使用できない文字が含まれています'})
                .max(255, '255文字まで入力可能です。')
                .nullable(),
        }, ['sei', 'mei'])
    )
});