/**********************************
 * MEMO: ルートをここに記載する
 ***********************************/
import {createRouter, createWebHistory} from "vue-router";
import Top from "../pages/Top"
import AddressList from "../pages/AddressList";
import Edit from "../pages/Edit"
import SenderList from "../pages/SenderList";
import OrderHistory from "../pages/OrderHistory";
import UserAgentError from "../pages/UserAgentError";
import Maintenance from "../pages/Maintenance";

const routes = [
    {
        path: "",
        component: Top,
        meta: {
            title: '年賀Family__TOP',
        },
    },
    {
        path: "/",
        component: Top,
        meta: {
            title: '年賀Family__TOP',
        },
    },
    {
        name: 'top',
        path: "/top",
        component: Top,
        meta: {
            title: '年賀Family__TOP',
        },
    },
    {
        // MEMO: 宛先一覧・登録
        path: "/address_list",
        component: AddressList,
        meta: {
            title: '年賀Family__TOP__宛先登録・一覧',
        },
    },
    {
        // MEMO: 差出人一覧・登録
        path: "/sender_list",
        component: SenderList,
        meta: {
            title: '年賀Family__TOP__差出人登録・一覧',
        },
    },
    {
        // MEMO: 注文履歴一覧
        path: '/order_history',
        component: OrderHistory,
        meta: {
            title: '年賀Family__TOP__注文履歴',
        },
    },
    {
        // MEMO: デザイン編集画面
        name: 'editpage',
        // MEMO:
        //  designCode + codeのみ = ゲストの初回アクセス
        //  designCode + code = 一時保存 or 購入に進む → ECで新規会員登録 → 年賀に戻って来たパターン
        //  designCode + nengaId = 代理ログインの編集

        path: "/edit/:designCode/:nengaId([0-9]{1,18})?/:code([0-9a-zA-Z]{1,32})?",
        component: Edit,
        meta: {
            title: '年賀Family__TOP__編集',
        },
    },
    {
        // MEMO: ユーザーエージェントエラー
        path: '/user_agent_error',
        component: UserAgentError,
        meta: {
            title: '年賀Family__エラー',
        },
    },
    {
        name: 'maintenance',
        // MEMO: メンテナンス
        path: '/maintenance',
        component: Maintenance,
        meta: {
            title: '年賀Family__メンテナンス',
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

/**
 * 全てのページ遷移前に特定の処理
 * */
router.beforeEach(async (to, from, next) => {

    // MEMO: safari / chrome / edge 以外はエラーページ
    const agent = window.navigator.userAgent.toLowerCase();
    if ((agent.indexOf("edg") != -1 || agent.indexOf("edge") != -1) ||
        agent.indexOf("chrome") != -1 ||
        agent.indexOf("safari") != -1) {
        // MEMO: 【共通処理】ルーターに設定した、メタデータのタイトルを設定する
        if (to.meta.title) {
            document.title = to.meta.title;
        }
    } else {
        // MEMO: 無限ループ防止の為、一度遷移したら終了
        if (to.fullPath !== '/user_agent_error') {
            next({path: '/user_agent_error'});
        }
    }
    next();
});

export default router;
