<template>
    <div
        class="fixed w-full h-full left-1/2 top-1/2 z-3 flex justify-center items-center -translate-y-1/2 -translate-x-1/2">
        <div class="v-validateBox">
            <div class="font-bold">
                <div class="px-4">
                    <div class="leading-7">
                        <p>プレビューの生成に失敗しました。<br>お手数ですがもう一度お願いいたします。</p>
                    </div>
                    <div class="text-center text-blue02 pt-6">
                        <button @click="cancel">閉じる</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-modal__overlay" :class="{'is-modal-active': isModalActive}"></div>
    </div>
</template>

<script>
export default {
    name: "PreviewErrorModal",
    props: {
        isModalActive: Boolean,
    },
    methods: {
        async cancel() {
            this.$emit('close')
        },
    },
}
</script>

<style scoped>
.v-validateBox {
    width: 90%;
    max-width: 32rem;
    background-color: #FFF;
    padding: 2rem 0;
    font-weight: bold;
    text-align: center;
    position: fixed;
    top: calc(50% - 150px);
    z-index: 3;
    -webkit-animation: slideDown .3s ease .3s normal backwards;
    animation: slideDown .3s ease .3s normal backwards;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>