<template>
    <button @click="ShowSenderObject"
            :disabled="isDisabled"
            :class="{'is-reActive': isReActive}"
            class="l-footer__icon l-footer__icon--senderList px-12 pb-6 pt-8 inline-block"></button>
</template>

<script>
export default {
    name: "SenderAddButton",
}
</script>
<script setup>
import {inject, computed} from "vue";
import AvailableResources from "../AvailableResources";

const availableResources = inject('availableResources')
const makingItem = inject('makingItemInfo')

const ShowSenderObject = async () => {
    // MEMO: デザイン編集エリアに差出人がある場合は何もしない
    if (Object.keys(makingItem.selectedSenderOrderingRule).length !== 0) {
        return
    }

    // MEMO: デザイン編集エリアに差出人が無い場合、配置順の一覧データが未取得の場合は取得する
    if (availableResources.availableAddressOrderingRule.length === 0) {
        // MEMO: AvailableResources.vueのloadAddressOrderingRuleを実行して、availableAddressOrderingRuleに配置順をセットする
        await AvailableResources.loadAddressOrderingRule();
    }

    // MEMO: 配置順の種類からデフォルトの配置順を使用する。ない場合は先頭の配置順を使用する
    const rule = availableResources.availableAddressOrderingRule
        .find((item) => item.id === makingItem.designData.default_sender_display_rule);

    makingItem.selectedSenderOrderingRule = rule ? rule : availableResources.availableAddressOrderingRule[0];
}

const isDisabled = computed(() => {
    // MEMO: 差出人が編集エリアに配置済の場合は、フッターメニューの差出人追加を無効にする
    if (Object.keys(makingItem.selectedSenderOrderingRule).length !== 0) {
        return true;
    }
})

const isReActive = computed(() => {
    if (Object.keys(makingItem.selectedSenderOrderingRule).length !== 0) {
        return true;
    }
})
</script>

<style scoped>

</style>