<template>
    <Header></Header>
    <section class="l-wrapper bg-gray02">
        <div class="l-wrapper__container pb-16">
            <div class="pt-12 sm:pt-16 pb-6 sm:pb-12 text-center">
                <p class="text-xl sm:text-3xl font-bold">{{ orderMessage }}</p>
            </div>

            <!-- MEMO: 購入履歴一覧 -->
            <div class="grid grid-cols-2 gap-3 px-4 sm:px-0">
                <div v-for="nenga_item in nenga_items" class="sm:mx-0 py-4 px-4 rounded-lg bg-white flex flex-col">
                    <div class="relative border border-gray02 p-3 cursor-pointer hover:shadow-md transition-shadow"
                         @click="showPreview(nenga_item.id)">
                        <img :src="preview_images[nenga_item.id]" class="w-40 mx-auto shadow-md" alt="デザインプレビュー">
                        <div class="absolute bottom-2 right-3 hidden sm:block">
                            <img src="/images/icon/top/ic_top-scale.svg" alt="拡大">
                        </div>
                    </div>
                    <div class="pt-4 text-xs text-gray01">
                        <p>注文番号 : {{ nenga_item.order_id }}</p>
                        <p>商品コード : {{ nenga_item.design.product_code }}</p>
                        <p>商品名 : {{ nenga_item.design.name }}</p>
                    </div>
                    <div class="pt-4 mt-auto">
                        <button @click="showPreview(nenga_item.id)"
                                class="w-full border border-gray03 py-3 text-sm text-center cursor-pointer hover:bg-orange hover:text-white hover:border-orange">
                            <span class="font-bold">詳細</span>
                        </button>
                    </div>
                </div>
            </div>

            <!-- <li class="w-full text-sm border border-gray03 rounded font-bold py-3 hover:bg-orange hover:text-white hover:border-orange">
                                      <button class="w-full block" @click="copy()">
                                          デザインを複製して新たに作成する
                                      </button>
                                  </li> -->
            <!-- MEMO: プレビューモーダル -->
            <section>
                <transition>
                    <div class="flex justify-center items-center c-modal__overlay is-modal-active z-1"
                         v-if="isShowPreview">
                        <div class="bg-white mx-auto py-8 rounded-2xl w-10/12 max-w-sm z-2">
                            <div class="px-8 w-full mx-auto">
                                <img :src="modal_preview_img" class="shadow-md mx-auto" alt="デザインプレビュー">
                            </div>
                            <div class="text-center pt-8">
                                <div class="px-12">
                                    <div
                                            class="w-full text-sm border border-gray03 rounded font-bold hover:bg-orange hover:text-white hover:border-orange">
                                        <button @click="showAddressList(true)"
                                                class="w-full py-3">
                                            <span>宛名の確認</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="pt-8 font-bold text-blue02">
                                    <button type="button"
                                            @click="showPreview()">
                                        閉じる
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <!-- <transition>
                    <div class="" v-if="isShowPreview"></div>
                </transition> -->
            </section>

            <!-- MEMO: 宛先リスト -->
            <section>
                <transition>
                    <div v-if="isShowAddressList"
                         class="fixed flex justify-center items-center w-full h-full top-0 left-0 z-4">
                        <div
                                class="flex flex-col bg-white w-10/12 max-w-sm overflow-y-auto overflow-x-hidden mx-auto px-4 py-8 rounded-2xl u-scrollbar__none"
                                :class="addressConfirmBoxHeight"
                        >
                            <div>
                                <div class="px-5 py-2 border border-gray03 address_preview_item" v-for="address_item in address_items"
                                     @click="showAddressPreview(address_item.id)">
                                    <ul class="flex justify-between">
                                        <li class="font-bold">
                                            <ListNamePreview :preview-data="this.nameForGothic(address_item)"/>
                                        </li>
                                        <li class="font-bold">{{ address_item.honorifics }}</li>
                                    </ul>
                                    <ul class="flex flex-wrap break-all">
                                        <li class="pt-2 text-xs leading-1.1rem">
                                            〒{{ address_item.post_no }}
                                            {{ address_item.pref_name }}
                                            {{ address_item.address }}
                                            {{ address_item.building_name }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div v-if="isShowItemNonMessage">
                                <div class="text-center py-4 font-bold">
                                    <p>宛名が選択されていません</p>
                                </div>
                            </div>
                            <div class="pt-8 font-bold text-blue02 text-center mt-auto">
                                <button type="button"
                                        @click="showAddressList(false)">
                                    <span>閉じる</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition>
                    <div class="fixed top-0 left-0 bg-black opacity-50 w-full h-full z-3" v-if="isShowOverlay"></div>
                </transition>
            </section>
            <!-- MEMO: 宛名プレビューモーダル -->
            <div v-if="isShowAddressPreviewModal" class="sm:max-w-2xl sm:mx-auto c-modal__preview">
                <div class="w-10/12 sm:w-80 mx-auto bg-white py-8 rounded-20">
                    <img class="px-6 z-10" :src="address_item_preview_image" alt="宛名プレビュー">
                    <div class="block text-center pt-6">
                        <a href="#" class="font-bold text-blue02" @click="closeAddressPreviewModal()">閉じる</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <LoadingScreen :is-loading-screen="isLoadingScreen"/>
</template>

<script>
import Header from "../components/Header";
import config, * as Enum from '../const/const'
import AuthFunctions from "../functions/auth";
import ListNamePreview from "../components/ListNamePreview.vue";
import LoadingScreen from "../components/LoadingScreen.vue";
import axios from "axios";
import CommonModule from "../functions/common_module";
const {
    getCustomer,
} = AuthFunctions();

export default {
    name: "OrderHistory",
    components: {LoadingScreen, Header, ListNamePreview},
    data() {
        return {
            customer: null,
            nenga_items: [],
            address_items: [],
            isShowPreview: false,
            isShowOverlay: false,
            isShowAddressList: false,
            preview_images: [],
            modal_preview_img: '',
            showing_nenga_id: '',
            orderMessage: '',
            isLoadingScreen: false,
            isShowAddressPreviewModal: false,
            address_item_preview_image: '',
            isShowItemNonMessage: false,
        }
    },
    computed: {
        refEnum: () => Enum,    // MEMO: templateでEnumを参照するために必要な記述

        addressConfirmBoxHeight() {
            if (this.address_items.length) {
                return ['h-5/6'];
            }
        },
    },
    async mounted() {
        this.customer = await getCustomer();
        // MEMO: 会員情報の取得ができない場合、ECのログイン画面へ遷移
        if (!this.customer || !Object.keys(this.customer).length) {
            // MEMO: ここでECのログアウトへリダイレクトURLをつけて飛ばす
            window.location.href = process.env.MIX_EC_LOGOUT_URL
                + '?redirect_url=' + process.env.MIX_APP_URL
                + this.$route.path;
            return;
        }

        await this.getOrderHistoryItems();
    },
    methods: {
        /**
         * プレビュー画像の取得
         * */
        async getPreviewImage(customer_id, nenga_id) {
            return await axios.get(config.API_BASE_URL + '/order_history/get_preview_image/' + customer_id + '/' + nenga_id + '/0')
                .then((response) => response.data.data)
                .catch(() => {
                    console.log('failure');
                });
        },
        /**
         * MEMO: 名前をゴシック体でフィルタリングする
         * 一覧では、ゴシック体しか使用しないため取り除きます
         * */
        nameForGothic(address_item) {
            return address_item.purchase_address_name_parts.filter((item) => {
                return item.font_id === null || item.font_id === Enum.FONT.GOTHIC.id;
            }, this);
        },
        /**
         * MEMO: 履歴一覧の取得
         * */
        async getOrderHistoryItems() {
            this.isLoadingScreen = true;
            const params = {
                customer_id: this.customer.id,
            };

            const result = await axios.post(config.API_BASE_URL + '/order_history/search', params)
                .then((response) => {
                    this.orderMessage = 'ご注文はありません。';
                    if (response.data.length) {
                        this.orderMessage = 'ご注文履歴';
                    }
                    for (let i = 0; i < response.data.length; i++) {
                        this.nenga_items.push(response.data[i]);
                    }
                    return true;
                })
                .catch((error) => {
                    console.log('failure');
                    return false;
                });

            if (!result) {
                this.isLoadingScreen = false;
            }

            // 画像のBase64データの取得
            for await (const nenga_item of this.nenga_items) {
                this.preview_images[nenga_item.id] = await this.getPreviewImage(this.customer.id, nenga_item.id);
            }

            this.isLoadingScreen = false;
        },
        /**
         * MEMO: プレビューモーダルの表示/非表示
         * */
        showPreview(nenga_id) {
            CommonModule.modalNotScroll(nenga_id ? true : false);
            this.isShowPreview = !this.isShowPreview;
            this.modal_preview_img = this.preview_images[nenga_id];
            this.showing_nenga_id = nenga_id;
        },
        /**
         * MEMO: 宛先リストの表示
         * */
        async showAddressList(display) {
            const params = {
                nenga_id: this.showing_nenga_id,
            };

            await axios.post(config.API_BASE_URL + '/order_history/get_purchase_address_list', params)
                .then((response) => {
                    this.address_items.splice(0);
                    for (let i = 0; i < response.data.length; i++) {
                        this.address_items.push(response.data[i]);
                    }
                }).catch((error) => {
                console.log('failure');
            });

            this.isShowItemNonMessage = this.address_items.length === 0 ? true : false;
            this.isShowOverlay = display; // 背景グレー
            this.isShowAddressList = display;    // 宛名リスト
        },
        /**
         * MEMO: 宛名プレビューモーダルの表示
         * */
        async showAddressPreview(address_id) {
            this.isShowAddressList = false;

            const endpoint = `${config.API_BASE_URL}/address_side_image/saved_purchase_address/${address_id}`;

            try {
                const response = await axios.get(endpoint);
                this.address_item_preview_image = response.data
                this.isShowAddressPreviewModal = true;
            } catch (error) {
                alert('プレビュー画像の取得ができませんでした');
                this.isShowAddressList = true;
            }
        },
        /**
         * MEMO: 宛名プレビューモーダルのクローズ
         * */
        closeAddressPreviewModal() {
            this.isShowAddressPreviewModal = false;
            this.isShowAddressList = true;    // 宛名リスト
        },
        /**
         * MEMO: デザインを複製して新たに作成する
         * */
        copy() {
            // MEMO: 個人用/法人用を検索条件に指定する
            const params = {
                nenga_id: this.showing_nenga_id,
            };
            axios.post(config.API_BASE_URL + '/making_item/copy', params)
                .then((response) => {
                    window.location.href = process.env.MIX_APP_URL + '/edit/' + response.data.product_code;
                })
                .catch((error) => {
                    alert(error.response.data.error.messages[0]);
                });
        }
    },
}
</script>

<style scoped>
.v-leave-active,
.v-enter-active {
    transition: opacity .3s;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.address_preview_item:hover{
    cursor: pointer;
}
</style>