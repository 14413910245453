<template>
    <li class="p-edit__material__items p-edit__material__items--shadow">
        <div :class="{'is-image-modal': isActive, 'cursor-pointer ': isNotActive}"
             class="p-edit__material__items--modal js-registerImageInnerModal">
            <div :class="{'is-active': isActive }">
                <img v-show="this.imageLoaded" :src="this.imageUrl" class="mx-auto border border-gray03" @load="imageLoadedEvent" alt="">
                <div @click="toggleMenu" class="absolute top-0 bottom-0 left-0 right-0 z-10">
                    <div :class="[{'hidden': isNotActive}, isDeployPosition]"
                         class="absolute flex justify-center items-center flex-col gap-y-2 w-full js-registerDetailButton">
                        <button
                            v-if="this.deployable"
                            @click="$emit('deployImage', this.imageIndex)"
                            name="position"
                            class="p-button__post__and__delete"
                        >配置する
                        </button>
                        <button
                            v-if="this.deletable && !this.isProxyUser"
                            @click="$emit('deleteImage', this.imageId)"
                            name="delete"
                            class="p-button__post__and__delete"
                        >削除する
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    name: "PhotoListThumbnail",
    props: {
        imageUrl: String,
        imageIndex: Number,
        imageId: String,
        menuActive: Number,
        deletable: {type: Boolean, default: true},
        deployable: {type: Boolean, default: true},
        stampContent: Boolean,
        isProxyUser: {type: Boolean, default: false},   // MEMO: 代理ログインの場合、削除ボタンは無効化する
    },
    emits: {
        deployImage: String,
        deleteImage: String,
        toggleMenu: String,
    },
    data() {
        return {
            imageLoaded: false,
        }
    },
    methods: {
        toggleMenu() {
            this.$emit('toggleMenu', this.isNotActive ? this.imageIndex : null);
        },
        /**
         *画像の読み込み完了イベント
         * */
        imageLoadedEvent() {
            // MEMO: 読み込み終わったら要素を表示する
            this.imageLoaded = true;
        },
    },
    computed: {
        isActive() {
            return this.menuActive === this.imageIndex;
        },
        isNotActive() {
            return this.menuActive !== this.imageIndex;
        },

        //配置ボタン：スタンプとフレームに選択されたサムネイル画像で条件を分ける
        isDeployPosition() {
            if (this.stampContent === true || this.deployable === false) {
                return  ['top-10', 'sm:top-12'];
            } else {
                return ['top-5', 'sm:top-8'];
            }
        },
    },
}
</script>

<style scoped>

</style>
