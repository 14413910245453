<template>
    <g>
        <g v-for="(image, index) in userImageArea" :key="index">
            <clipPath :id=" 'clip-' + image.id ">
                <component :is="makeComponent(image.flame)"
                           :transform="flameTransformMap['clip-' + image.id]"></component>
            </clipPath>
        </g>
        <rect v-show="userImageClipCover"
              x="0" y="0" width="100%" height="100%"
              transform="translate(0, 0) rotate(0)" class="designBlackCover">
        </rect>
        <g v-for="(image, index) in userImageArea" :key="index"
           :class="userImageAreaStyle(image.id)">
            <component :is="makeComponent(image.flame)"
                       @click="selectFlame('clip-' + image.id)"
                       :style="editComponentImage" class="selectedFrameColor"
                       :class="{ 'is-active': activatedClippingFrameColor === 'clip-' + image.id || selectNowClippingFrame }"
                       pointer-events="visible"
                       :id=" 'imagearea-' + image.id "></component>
        </g>
        <!-- 背景画像をクリックした時に、画像のクリッピングをする -->
        <rect v-show="transparent" @click="transparentRectArea"
              x="0" y="0" width="100%" height="100%"
              transform="translate(0, 0) rotate(0)" class="fill-transparent">
        </rect>
    </g>
</template>

<script>
import {inject} from "vue";
import {EditState} from "../../../const/const";
import decisionClip from "../../../functions/user_image_decision_clipping";

export default {
    name: "UserImageClippingFlame",
    props: [
        'userImageLayoutFile',
        'flameTransformMap',
    ],
    setup() {
        const editStatus = inject('editStatus');
        const availableResources = inject('availableResources')
        const makingItem = inject('makingItemInfo')
        const availableLayouts = availableResources.availableLayouts

        return {
            availableResources,
            availableLayouts,
            editStatus,
            makingItem
        };
    },
    mounted() {
    },
    data() {
        return {
            editComponentImage: {},
            cover: false,
        }
    },
    computed: {
        loaded() {
            return this.availableResources.loaded
        },
        parsedLayouts() {
            const layouts = {}
            if (this.loaded) {
                const dp = new DOMParser()
                const s = new XMLSerializer()

                for (const [_, l] of this.availableLayouts.entries()) {
                    const layoutDoc = dp.parseFromString(l.file_string, 'image/svg+xml')
                    const layoutNodeList = layoutDoc.querySelectorAll('[id^=img]')
                    layouts[l.layout_id] = Array.from(layoutNodeList).map((v) => {
                        return {
                            id: v.id,
                            flame: s.serializeToString(v),
                        }
                    })
                }
            }
            return layouts
        },
        userImageArea() {
            if (this.parsedLayouts[this.userImageLayoutFile] === undefined) {
                return []
            }
            return this.parsedLayouts[this.userImageLayoutFile]
        },
        activatedClippingFlame() {
            return this.editStatus.flameToClip;
        },

        activatedClippingFrameColor() {
            return this.editStatus.flameToClip;
        },

        selectNowClippingFrame() {
            if (this.editStatus.state === EditState.SELECT_FLAME) {
                return true;
            }
        },

        userImageClipCover() {
            if (this.editStatus.state === EditState.EDIT_USER_IMAGE || this.editStatus.state === EditState.SELECT_FLAME) {
                return true;
            }
            return false;
        },

        transparent() {
            if (this.editStatus.state === EditState.EDIT_USER_IMAGE) {
                return true;
            }
            return false;
        }

    },
    methods: {
        userImageAreaStyle(image_id) {
            let cssClass = '';
            if (this.activatedClippingFlame === 'clip-' + image_id) {
                cssClass += 'photoFlamesActive';
            }
            // MEMO: 商品コードがnen0039の場合、背景が赤のため線の色と同じになるため、photoFlamesNen0039のスタイルを適用する
            cssClass += this.makingItem.design_code === 'nen0039' ? ' photoFlamesNen0039' : ' photoFlames';
            return cssClass
        },
        selectFlame(flameId) {
            this.$emit('selectFlame', flameId);
        },
        makeComponent(template) {
            return {
                name: 'PhotoFlame',
                template: template,
            };
        },
        clippingPathTransformStyle(index) {
            return {
                'transform': this.flameTransformMap['clip-' + index],
            }
        },

        /**
         * 画像をフレームに配置する時：背景デザインをクリックすると画像のクリッピングが実行される。
         */
        transparentRectArea() {
            decisionClip(this.editStatus.imageToDeploy, this.editStatus.state, EditState.EDIT_USER_IMAGE, this.editStatus.flameToClip);
            this.editStatus.neutralize()
        },
    },
}

</script>

<style scoped>
.photoFlames {
    stroke: #ef4028;
    fill: transparent;
    stroke-dasharray: 2, 2;
    opacity: 1.0;
}
.photoFlamesNen0039 {
    stroke: #ffffff;
    fill: transparent;
    stroke-dasharray: 2, 2;
    opacity: 1.0;
}

.photoFlamesActive {
    -webkit-animation: dash 2s linear infinite;
    animation: dash 2s linear infinite;
}

@-webkit-keyframes dash {
    to {
        stroke-dashoffset: 12;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 12;
    }
}

.designBlackCover {
    fill: rgba(0, 0, 0, 0.5);
}

.selectedFrameColor.is-active {
    fill: #FFF !important;
}
</style>
