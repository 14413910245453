<template>
    <header id="headerObject" class="w-full h-14 fixed z-1 js-headerOverlap">
        <div v-if="this.customer.is_proxy_user" class="bg-orange" style="text-align: center">【代理ログイン中】</div>
        <div class="flex justify-center items-center sm:w-full h-14 bg-white shadow-md w-full">
            <button class="absolute top-5 left-5 js-openMenu" @click="openMenu()"
                    :class="{'active' : open }">
                <img src="/images/icon/header/ic_header-hamburger.svg">
            </button>
            <div v-if="this.windowWidth >= 640 || !routePathOnlyForEdit">
                <h1 class="sm:block text-xl font-bold">
                    <router-link to="/">
                        <img src="/images/icon/header/ic_header-logo.svg" alt="my family" class="l-header__logo">
                    </router-link>
                </h1>
            </div>
            <!--作り直しボタン-->
            <div v-if="routePathOnlyForEdit && windowWidth <= 640" class="l-header__design-reset-button">
                <DesignResetButton @showYesNoModal="toggleYesNoModal" ref="designResetButton"></DesignResetButton>
            </div>
            <div v-if="routePathOnlyForEdit" class="l-header__purchase">
                <button @click="toggleActive();"
                        class="bg-blue02 text-white text-sm font-bold py-3 px-8 rounded-md">
                    <span>購入にすすむ</span>
                </button>
            </div>
        </div>
    </header>
    <nav
        class="l-header__sidebar u-scrollbar__none"
        :class="{'open' : open }"
    >
        <div class="h-14 flex items-center border-gray01 border-b py-4 mb-6">
            <img src="/images/icon/header/ic_header-logo.svg" alt="my family" class="l-header__logo">
        </div>
        <ul class="pb-12">
            <li v-if="customerType" class="pb-10">
                <router-link to="/sender_list">
                    <img src="/images/icon/header/header_icon_02.svg" class="mx-4 inline" alt="差出人登録・一覧">
                    <span class="align-middle">差出人登録・一覧</span>
                </router-link>
            </li>

            <li v-if="customerType" class="pb-10">
                <router-link to="/address_list">
                    <img src="/images/icon/header/header_icon_03.svg" class="mx-4 inline" alt="宛名登録・一覧">
                    <span class="align-middle">宛名登録・一覧</span>
                </router-link>
            </li>

            <li v-if="customerType" class="pb-10">
                <router-link to="/order_history">
                    <img src="/images/icon/header/header_icon_04.svg" class="mx-4 inline" alt="注文履歴">
                    <span class="align-middle">注文履歴</span>
                </router-link>
            </li>

            <li v-if="routePathOnlyForEdit" class="pb-10">
                <button @click="openTutorial('tutorial')">
                    <img src="/images/icon/header/header_icon_08.svg" class="mx-4 inline" alt="チュートリアル">
                    <span class="align-middle">ガイド</span>
                </button>
            </li>

            <li class="pb-10">
                <a href="https://www.piary.jp/support/inquiry/" target="_blank">
                    <img src="/images/icon/header/header_icon_05.svg" class="mx-4 inline" alt="サポート・問い合わせ">
                    <span class="align-middle">サポート・問い合わせ</span>
                </a>
            </li>

            <li v-if="customerType" class="pb-10">
                <router-link to="/top">
                    <img src="/images/icon/header/header_icon_06.svg" class="mx-4 inline" alt="TOP">
                    <span class="align-middle">TOPに戻る</span>
                </router-link>
            </li>

            <li>
                <a href="https://www.piary.jp/" target="_blank">
                    <img src="/images/icon/header/header_icon_07.svg" class="mx-4 inline" alt="サイトに戻る">
                    <span class="align-middle">ピアリーに戻る</span>
                </a>
            </li>
        </ul>
    </nav>
    <div>
        <span class="l-header__close cursor-pointer js-closeMenu"
              @click="openMenu()"
              :class="{'open' : open }"
        >
        </span>
    </div>
    <div class="c-modal__overlay" :class="{'is-modal-active' : open }"></div>
    <template></template>
    <YesNoModal v-if="isShowYesNoModal"
                :is-modal-active="isShowYesNoModal"
                :message="'作成した内容がすべてリセットされます。\nよろしいですか？'"
                @close="resetDesign"
    />
    <NonMemberModal
        :show-modal="isShowNomMemberModal"
        :non-member-message="nonMemberMessage"
        @cancel="closeNonMemberModal"></NonMemberModal>

    <EditValidateModal
        :validateMessage="message"
        :showModal="showModal"
        @validateModal="validateModal"></EditValidateModal>

    <LoadingScreen :is-loading-screen="isLoadingScreen"></LoadingScreen>
</template>

<script>
import AuthFunctions from "../functions/auth";
import SaveDesignImageFuinctions from "../functions/save_design_image"
import {inject} from "vue";
import LoadingScreen from "./LoadingScreen.vue";
import NonMemberModal from "./Edit/SideMenu/NonMemberModal.vue";
import EditValidateModal from "./Edit/SideMenu/EditValidateModal";
import {useRoute} from 'vue-router'
import DesignResetButton from "./Edit/DesignResetButton.vue";
import YesNoModal from "./Edit/YesNoModal.vue";

const {
    getCustomer,
} = AuthFunctions();

const {SaveAsPicture, createSenderRegisterData} = SaveDesignImageFuinctions();

export default {
    name: "Header",
    data() {
        return {
            open: false,
            windowWidth: 0,
            routePathOnlyForEdit: this.$route.path.match(/edit/),
            customerType: false,
            isLoadingScreen: false,
            showModal: false,
            isShowNomMemberModal: false,
            message: null,
            nonMemberMessage: '',
            customer: [],
            isShowYesNoModal: false,
        }
    },

    components: {
        YesNoModal,
        DesignResetButton,
        LoadingScreen,
        NonMemberModal,
        EditValidateModal,
    },

    setup() {
        const route = useRoute();
        // MEMO: 編集ページの場合のみinjectする（Edit.vueでしかprovideされないため）
        if (route.name === "editpage") {
            const editStatus = inject('editStatus')
            const makingItem = inject('makingItemInfo')
            const availableResources = inject('availableResources')
            const availableLayouts = availableResources.availableLayouts
            return {
                editStatus,
                makingItem,
                availableLayouts,
                availableResources,
            }
        }
    },

    async mounted() {
        this.customer = await getCustomer();

        // MEMO: 会員情報の取得ができない場合、ECのログイン画面へ遷移
        // Cookieがあるのに、エラー担った場合が考えられる ＝ 未ログイン
        if (!this.customer) {
            // MEMO: ここでECのログアウトへリダイレクトURLをつけて飛ばす
            window.location.href = process.env.MIX_EC_LOGOUT_URL
                + '?redirect_url=' + process.env.MIX_APP_URL
                + this.$route.path;
            return;
        }

        //ログインの有無でハンバーガーメニュー中身の表示切り替えをする
        if (Object.keys(this.customer).length) {
            this.customerType = true; //ログイン済み
        } else {
            this.customerType = false; //ゲスト
        }
    },

    created() {
        this.windowWidth = window.innerWidth;
    },

    emits: ['showPurchaseFlow', 'showAlertMessage'],

    methods: {
        openMenu() {
            // MEMO: 値を反転させて設定
            this.open = !this.open;
        },

        openTutorial(list) {
            this.open = false;
            this.editStatus.activeSideMenu = list;
        },
        /**
         * 作り直す確認モーダルの開閉
         * */
        toggleYesNoModal(flg = false) {
            this.isShowYesNoModal = flg;
        },
        /**
         * 作り直すの実行
         * */
        resetDesign(flg) {
            // MEMO: YesNoModalでNoの場合
            if(!flg) {
                // MEMO: YesNoModalを閉じる
                this.toggleYesNoModal(false);
                return;
            }

            // MEMO: DesignResetButton.vueのresetDesignを呼び出す
            this.$refs.designResetButton.resetDesign();
        },
        async toggleActive() {
            // 編集状態を取り消す
            //this.editStatus.activeSideMenu = 'none'
            this.editStatus.neutralize()

            // MEMO: トークンの有効期限切れを防止するため、再度取得
            this.customer = await getCustomer();

            // MEMO: 非会員の場合はモーダル表示
            if (!this.makingItem.customer_id || !this.customer || !Object.keys(this.customer).length) {
                this.isShowNomMemberModal = true;
                this.nonMemberMessage = '購入にすすむ'
                return;
            }

            // MEMO: 配置対象の画像の有効/無効チェック
            //  ※ 配置した画像が他の商品の画像リストから削除されている可能性があるので、ここでチェック
            let isDeleteDeployedImage = false;
            for (const deployed_img of this.makingItem.deployedImages) {
                const result = await this.availableResources.isAvailableUserImage(deployed_img.image_file);
                // MEMO: 代理ログインの場合はスキップする
                if (!result && !this.customer.is_proxy_user) {
                    // 複数枚画像リストから削除されている可能性もあるため途中でbreakしない
                    this.makingItem.removeDeployedImage(deployed_img.id)
                    isDeleteDeployedImage = true;
                }
            }

            // MEMO: 1枚でも削除されていたら配置画像削除モーダルを表示する。エラーがある場合は一度画面を確認してもらいため、購入にすすむモーダルは表示しない
            if (isDeleteDeployedImage) {
                // MEMO: EditのshowAlertMessageを実行し表示する
                this.$emit('showAlertMessage', ['他の商品でアップロードした写真が\n削除されたのでご確認ください。'])
                return;
            }

            // MEMO: 購入時のバリデーションの実行
            const validation_errors = this.validate();

            // MEMO: 印刷画像と編集情報の保存を実行
            this.isLoadingScreen = true;
            const ret = await SaveAsPicture(this.makingItem);
            if (!ret.result) {
                // MEMO: エラーがある場合、エラー用モーダルを表示する
                this.showModal = !this.showModal;
                this.message = ret.message;
                this.isLoadingScreen = false;
                return;
            }

            // MEMO: 戻り値にある年賀IDをセットする
            this.makingItem.nenga_id = ret.data.nenga_id;
            // MEMO: エラーがない場合、呼び出し元の「showPurchaseFlow」を実行する。
            this.isLoadingScreen = false;
            this.$emit('showPurchaseFlow', validation_errors)
        },
        /**
         * MEMO: 購入に進む際のバリデーション
         * */
        validate() {
            // MEMO: 要素数として使用するため-1して使用する
            const deployedImagesLength = this.makingItem.deployedImages
                .filter((item) => item.type === 'userImage')
                .length;

            let validation_errors = [];

            // MEMO: 画像の余白チェック
            for (const [index, deployImage] of this.makingItem.deployedImages.entries()) {
                const clip_id = deployImage.clipPath
                const frame_id = clip_id.match(/clip-(img\d+)/)[1]

                // MEMO: 座標比較元となる要素を取得する
                const parentElem = document.querySelector(`#imagearea-${frame_id}`);
                const parentBBox = parentElem.getBBox();
                const parentTopLeft = {x: parentBBox.x, y: parentBBox.y};
                const parentBottomRight = {x: parentBBox.x + parentBBox.width, y: parentBBox.y + parentBBox.height};

                const img_element = document.querySelector(`.clip-${frame_id}`);
                if (!img_element) {
                    continue;
                }

                const style = window.getComputedStyle(img_element);
                const matrix = style.transform || style.webkitTransform || style.mozTransform
                const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')
                const translateX = Number(matrixValues[4]);
                const translateY = Number(matrixValues[5]);

                const width = Number(style.width.replace('px', ''));
                const height = Number(style.height.replace('px', ''));
                const imageTopLeft = {x: translateX, y: translateY};
                const imageBottomRight = {x: translateX + width, y: translateY + height};
                const calcTopLeftX = parentTopLeft.x - imageTopLeft.x;
                const calcTopLeftY = parentTopLeft.y - imageTopLeft.y;
                const calcBottomRightX = parentBottomRight.x - imageBottomRight.x;
                const calcBottomRightY = parentBottomRight.y - imageBottomRight.y;

                // MEMO: 左上の頂点の計算結果がどちらか0以下の場合、エラー
                // invalidの最初の一回でエラーにしてループを終わりにする。
                if (calcTopLeftX < 0 || calcTopLeftY < 0) {
                    validation_errors.push('配置された画像に隙間があります。');
                    break;
                }
                // MEMO: 右下の頂点の計算結果がどちらかが0より上の場合エラー
                if (calcBottomRightX > 0 || calcBottomRightY > 0) {
                    validation_errors.push('配置された画像に隙間があります。');
                    break;
                }
            }

            // 画像の配置枚数
            const jsonSelectedLayout = JSON.stringify(this.makingItem.selectedLayout)
            const dp = new DOMParser()

            // 画像の配置箇所の数
            let photo_number = 0;
            for (const [_, layout] of this.availableLayouts.entries()) {
                const jsonLayout = JSON.stringify(layout)
                // MEMO: 選択されてるレイアウトと一致するレイアウトのSVGを読み込んで配置できる写真の数を取得する
                if (jsonSelectedLayout === jsonLayout) {
                    const layoutDoc = dp.parseFromString(layout.file_string, 'image/svg+xml')
                    const layoutNodeList = layoutDoc.querySelectorAll('[id^=img]')
                    photo_number = layoutNodeList.length;
                }
            }

            // MEMO: 画像の配置箇所より配置枚数が少ない場合はエラー
            if (photo_number > deployedImagesLength) {
                validation_errors.push('写真の設定がされていない箇所があります。')
            }

            // MEMO: 差出人が配置されていない（配置ルールがNULL ＝ 編集エリアから削除されている）場合はエラー
            if (Object.keys(this.makingItem.selectedSenderOrderingRule).length === 0) {
                validation_errors.push('差出人情報が未入力です。')
            } else {
                // MEMO: デフォルトデータと比較するため、一度POST形式に変換する
                const sender_item = createSenderRegisterData(this.makingItem.senderItem);
                // MEMO: デフォルトと値が同じ場合は警告を出す
                if (this.makingItem.isDefaultSender(sender_item)) {
                    validation_errors.push('差出人の内容が初期値のままになっています。')
                }
            }

            return validation_errors;
        },
        closeNonMemberModal(message) {
            // メッセージがある場合、エラー用モーダルを開く
            if (message !== undefined || message) {
                this.showModal = !this.showModal;
                this.message = message;
            }
            this.isShowNomMemberModal = false;
            this.isLoadingScreen = false;
        },

        validateModal() {
            this.showModal = !this.showModal;
        },
    },

}
</script>

<style scoped>

</style>