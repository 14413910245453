
export default function userImageDecisionClipping(currentTargetImageObject, currentState, stateUserImage, flameToClip) {
    const target = document.getElementById('target');
    if (currentState === stateUserImage) {
        /* memo: クリッピング処理（の位置合わせ）が完了した
        * 実際にclip-pathを指定したりクリッピングパスの変形を適用したりする。
        * scaleは画像のwidthとheightに適用して恒久化しちゃう
        * */
        const t_info = extractTransformValue(target.style.transform)
        currentTargetImageObject.transform = createTranslateStyle(t_info)
        target.style.transform = createTranslateStyle(t_info)
        const img_width = currentTargetImageObject.width
        const img_height = currentTargetImageObject.height

        currentTargetImageObject.width = img_width * t_info.scale_x
        currentTargetImageObject.height = img_height * t_info.scale_y

        const inv_info = calculateInverseTransform(t_info);
        currentTargetImageObject.clipPath = flameToClip;
        currentTargetImageObject.inverseImageTransform = createTranslateRequest(inv_info);
        currentTargetImageObject.deployed = true

    } else {
        currentTargetImageObject.transform = target.style.transform
    }
}

function extractTransformValue(transform_info_string) {
    let transform_info = {
        rotate: 0,
        scale_x: 1,
        scale_y: 1,
        translate_x: 0,
        translate_y: 0,
    };
    if (transform_info_string === '') return transform_info;

    const reg_transform_type = /^([a-zA-Z]+)\(/;
    const fot_arr = reg_transform_type.exec(transform_info_string);
    const first_order_transform = fot_arr.length === 2 ? fot_arr[1] : '';

    const reg_s = /scale\(([0-9\.\-]+),.([0-9\.\-]+)\)/;
    const arr_scale = reg_s.exec(transform_info_string);
    if (arr_scale) {
        transform_info.scale_x = Number(arr_scale[1]);
        transform_info.scale_y = Number(arr_scale[2]);
    }
    const reg_t = /translate\(([0-9\.\-]+)px,.([0-9\.\-]+)px\)/;
    const arr_translate = reg_t.exec(transform_info_string);
    if (arr_translate) {
        const scale_modify_x = first_order_transform === 'scale' ? transform_info.scale_x : 1;
        const scale_modify_y = first_order_transform === 'scale' ? transform_info.scale_y : 1;
        transform_info.translate_x = Number(arr_translate[1]) * scale_modify_x;
        transform_info.translate_y = Number(arr_translate[2]) * scale_modify_y;
    }
    const reg_r = /rotate\(([0-9\.\-]+)deg\)/;
    const arr_rotate = reg_r.exec(transform_info_string);
    if (arr_rotate) {
        transform_info.rotate = Number(arr_rotate[1]);
    }

    return transform_info;
}

function calculateInverseTransform(transform_info) {
    const scale_x = transform_info.scale_x === 0 ? 0 : 1 / transform_info.scale_x;
    const scale_y = transform_info.scale_y === 0 ? 0 : 1 / transform_info.scale_y;
    return {
        rotate: -1 * transform_info.rotate,
        scale_x: scale_x,
        scale_y: scale_y,
        translate_x: -1 * transform_info.translate_x,
        translate_y: -1 * transform_info.translate_y,
    };
}

function createTransformRequest(transform_info) {
    const t_scale = `scale(${transform_info.scale_x}, ${transform_info.scale_y})`;
    const t_translate = `translate(${transform_info.translate_x}, ${transform_info.translate_y})`;
    const t_rotate = `rotate(${transform_info.rotate})`;
    return `${t_scale} ${t_translate} ${t_rotate}`;
}

function createTranslateRequest(transform_info) {
    return `translate(${transform_info.translate_x}, ${transform_info.translate_y})`
}

function createTranslateStyle(transform_info) {
    return `translate(${transform_info.translate_x}px, ${transform_info.translate_y}px)`
}