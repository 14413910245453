<!-- MEMO: TOPページ-->
<template>
    <!-- MEMO: 保存時のローディング用 -->
    <LoadingScreen :is-loading-screen="isLoadingScreen"></LoadingScreen>
    <!--Header-->
    <Header></Header>
    <section class="l-wrapper bg-gray02">    
        <div class="l-wrapper__container pt-16 pb-8">
            <div>
                <div class="grid grid-cols-2 gap-0 sm:gap-2 border border-gray03 sm:border-none font-bold">
                    <router-link to="/sender_list"
                                 class="brock sm:flex flex-col sm:flex-row justify-center items-center gap-3 bg-white py-4 border-r sm:border-none border-gray03 hover:bg-beige">
                        <img src="/images/icon/top/top_icon01.svg" alt="差出人登録・一覧" class="ml-auto mr-auto sm:m-0">
                        <p class="text-sm sm:text-base text-center sm:text-left pt-6 sm:pt-0">差出人の<br>登録・一覧</p>
                    </router-link>
                    <router-link to="/address_list"
                                 class="brock sm:flex flex-col sm:flex-row justify-center items-center gap-3 bg-white py-4 border-r sm:border-none border-gray03 hover:bg-beige">
                        <img src="/images/icon/top/top_icon02.svg" alt="宛名の登録・一覧" class="ml-auto mr-auto sm:m-0">
                        <p class="text-sm sm:text-base text-center sm:text-left pt-6 sm:pt-0">宛名の<br>登録・一覧</p>
                    </router-link>

                </div>
                <div
                    class="grid grid-cols-3 mt-0 sm:mt-2 gap-0 sm:gap-2 border-b sm:border-none border-gray03 font-bold">
                    <a href="https://www.piary.jp/postcard/#postcard_lineup"
                       class="brock sm:flex flex-col sm:flex-row justify-center items-center gap-3 bg-white py-4 border-r sm:border-none border-gray03 hover:bg-beige">
                        <img src="/images/icon/top/top_icon03.svg" alt="新しいデザインで作る" class="ml-auto mr-auto sm:m-0">
                        <p class="text-sm sm:text-base text-center sm:text-left pt-6 sm:pt-0">新しいデザイン<br>で作る</p>
                    </a>
                    <router-link to="/order_history"
                                 class="brock sm:flex flex-col sm:flex-row justify-center items-center gap-3 bg-white py-4 border-r sm:border-none border-gray03 hover:bg-beige">
                        <img src="/images/icon/top/top_icon04.svg" alt="注文履歴" class="ml-auto mr-auto sm:m-0">
                        <p class="text-sm sm:text-base text-center sm:text-left pt-6 sm:pt-0">注文履歴</p>
                    </router-link>
                    <a href="https://www.piary.jp/support/inquiry/" target="_blank"
                       class="brock sm:flex flex-col sm:flex-row justify-center items-center gap-3 bg-white py-4 border-r sm:border-none border-gray03 hover:bg-beige">
                        <img src="/images/icon/top/top_icon05.svg" alt="サポート問い合わせ" class="ml-auto mr-auto sm:m-0">
                        <p class="text-sm sm:text-base text-center sm:text-left pt-6 sm:pt-0">サポート<br>問い合わせ</p>
                    </a>
                </div>
            </div>
            <!-- MEMO: 作成中のアイテム -->
            <MakingList v-if="isShowMakingList" :customer="customer"></MakingList>
        </div>
    </section>
</template>

<script>
// MEMO: 使用する各種コンポーネントを読み込む
import Header from "../components/Header";
import MakingList from "../components/MakingList"
import AuthFunctions from "../functions/auth";
import LoadingScreen from "../components/LoadingScreen";

const {
    getCustomer,
} = AuthFunctions();
export default {
    components: {LoadingScreen, Header, MakingList},
    data() {
        return {
            customer: null,
            isLoadingScreen: false,
            isShowMakingList: false,
        }
    },
    async mounted() {
        this.isLoadingScreen = true;
        const customer = await getCustomer()
        // MEMO: falseの場合は、何かしらのSSOエラーのため、EC側にリダイレクト
        //  Cookieが存在しない場合は空の連想配列が返る為、ゲストとみなしてECのログイン画面にリダイレクト
        if (!customer || !Object.keys(customer).length) {
            // MEMO: ここでECのログアウトへリダイレクトURLをつけて飛ばす
            window.location.href = process.env.MIX_EC_LOGOUT_URL
                + '?redirect_url=' + process.env.MIX_APP_URL
                + this.$route.path
            return;
        }

        this.customer = Object.keys(customer).length ? customer : null
        this.isShowMakingList = true;
        this.isLoadingScreen = false;
    },
    methods: {}
};
</script>