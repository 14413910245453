<template>
    <div class="overflow-auto sm:pt-8 p-edit__slide--full u-scrollbar__none">
        <div class="relative sm:top-0">
            <ul class="grid grid-cols-3 gap-3 sm:hidden pl-4 pr-4">
                <li class="mt-4 font-bold text-blue02 text-left" @click="$emit('close')">閉じる</li>
                <!-- <li class="mt-2 text-center"><img src="/images/icon/edit/rectangle.svg" alt=""></li> -->
                <!-- <li class="mt-4 font-bold text-blue02 text-right">決定</li> -->
            </ul>
            <div class="pt-6 sm:pt-0">
                <ul class="flex justify-center gap-4 px-2">
                    <!-- 鳥居：二次以降にコメントアウトを削除 -->
                    <!-- <li>
                        <button @click="toggleActive('stored_sender')"
                                class="border px-4 sm:px-2 py-2 font-bold border-gray03 rounded-lg">
                            差出人を読み込む
                        </button>
                    </li> -->
                    <li>
                        <button @click="toggleActive('sender_address_ordering_rule')"
                                class="border px-4 sm:px-2 py-2 font-bold border-gray03 rounded-lg">
                            配置順の変更
                        </button>
                    </li>
                </ul>
            </div>
            <SenderEditForm
                v-if="formIsVisible"
                :sender-item="senderItem"
                :display-font-id="senderFontId"
            >
            </SenderEditForm>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";
import * as Enum from "../../../const/const.js";
import SenderEditForm from "../../SenderEditForm";

export default {
    name: "SenderAdd",
    components: {SenderEditForm},
    setup() {
        const editStatus = inject('editStatus')
        const makingItem = inject('makingItemInfo')

        return {
            editStatus,
            makingItem,
        };
    },
    data() {
        return {
            isSenderChange: 'individual',
        }
    },
    computed: {
        formIsVisible() {
            return this.editStatus.activeSideMenu === 'sender_add'
        },
        senderItem() {
            return this.makingItem.senderItem
        },
        senderFontId() {
            switch (this.senderItem.font) {
                case Enum.FONT.MINCHO.css_class_name:
                    return Enum.FONT.MINCHO.id;
                case Enum.FONT.KAISHO.css_class_name:
                    return Enum.FONT.KAISHO.id;
                case Enum.FONT.GOTHIC.css_class_name:
                    return Enum.FONT.GOTHIC.id;
            }
            return Enum.FONT.GOTHIC.id;
        },
    },
    methods: {
        isSelect(senderType) {
            this.isSenderChange = senderType;
        },

        toggleActive(menu_name) {
            if (window.matchMedia('(min-width: 640px)').matches) {
                this.editStatus.activeSideMenu = this.editStatus.activeSideMenu === menu_name ? 'none' : menu_name;

            } else {
                this.editStatus.activeSideMenu = false;
                const asyncSlide = async () => {
                    await new Promise((resolve) => {
                        setTimeout(() => {
                            this.editStatus.activeSideMenu = this.editStatus.activeSideMenu === menu_name ? 'none' : menu_name;
                            resolve(this.editStatus.activeSideMenu);
                        }, 300);
                    });
                };
                asyncSlide();
            }
        },
    },
}
</script>

<style scoped>

</style>
