<!--
    MEMO: 購入にすすむ ボタンを押した際に非会員だった場合のログインモーダル
-->
<template>
    <div v-if="showModal"
         class="fixed w-1/2 sm:w-1/5 h-full top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 z-3 p-edit__temporarySaveComplete js-temporarySaveAnimation"></div>

    <!-- 非会員時のモーダル表示 -->
    <div class="c-modal__temporarySave text-center" :class="{'is-active': showModal }">
        <div class="block py-8 sm:py-12">
            <div class="text-base sm:text-lg font-bold">
                <p>{{ nonMemberMessage }}には<br>PIARY会員登録が必要です。</p>
            </div>
            <div class="pt-8">
                <a href="#"
                   @click.prevent.stop="save"
                   target="_self"
                   class="flex justify-center items-center gap-x-4 w-5/6 sm:w-2/3 ml-auto mr-auto py-3 rounded-full text-base sm:text-lg font-bold text-white bg-orange">
                    <span>PIARY会員登録はこちらから</span>
                </a>
            </div>
            <div class="pt-8">
                <button @click="close"
                        type="button"
                        class="text-lg font-bold text-blue02">
                    <span>閉じる</span>
                </button>
            </div>
        </div>
    </div>
    <div class="c-modal__overlay" :class="{ 'is-modal-active': showModal }"></div>
</template>

<script>
import {inject} from "vue";
import SaveDesignImageFuinctions from "../../../functions/save_design_image";
import {useRoute} from "vue-router";

const {SaveAsPicture} = SaveDesignImageFuinctions();
export default {
    name: "NonMemberModal",
    emits: ['cancel'],
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        nonMemberMessage: {
            type: String,
            default: '',
        }
    },
    setup() {
        // MEMO: 編集ページ以外使用しないのでURLをチェックする
        const route = useRoute();
        if (route.name === "editpage") {
            const makingItem = inject('makingItemInfo')
            return {
                makingItem,
            }
        }
    },
    methods: {
        async save() {
            // console.log(this.makingItem.code)
            // console.log(process.env.MIX_EC_LOGOUT_URL
            //     + '?redirect_url=' + process.env.MIX_APP_URL
            //     + '/edit/' + this.makingItem.design_code
            //     + '/' + this.makingItem.code);
            // return;
            // MEMO: 印刷画像と編集情報の保存を実行
            const ret = await SaveAsPicture(this.makingItem);
            if (ret.result) {
                // 保存に成功したらECへリダイレクトする
                window.location = process.env.MIX_EC_LOGOUT_URL
                    + '?redirect_url=' + process.env.MIX_APP_URL
                    + '/edit/' + this.makingItem.design_code
                    + '/' + this.makingItem.code;
            } else {
                this.$emit('cancel', ret.message);
            }
        },
        close() {
            this.$emit('cancel');
        }
    }
}
</script>

<style>

</style>