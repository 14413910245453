<template>
    <div class="relative">
        <div class="absolute -top-12 sm:top-6 right-4 sm:right-8 sm:flex sm:flex-col sm:items-center">
            <!-- <button type="button"
                    @click="previewButton()"
                    class="shadow-default rounded-full">
                <img src="/images/icon/edit/preview_eye_icon.svg" alt="プレビュー" class="ml-auto mr-auto">
            </button> -->
            <DesignResetButton v-if="windowWidth >= 640" @showYesNoModal="toggleYesNoModal" ref="designResetButton"></DesignResetButton>
            <!-- 拡大・縮小のスライドバー -->
            <ScaleSlidebar v-if="windowWidth >= 640"></ScaleSlidebar>

            <YesNoModal v-if="isModalActive"
                        :is-modal-active="isModalActive"
                        :message="message"
                        @close="resetDesign"
            />
        </div>

    </div>

    <!--    <div v-if="showPreview">-->
    <!--        <div class="c-modal__designPreview"-->
    <!--            :class="{'is-active': isPreviewActive}">-->
    <!--            <div class="c-modal__designPreview__container"> -->
    <!--                <img src="/images/icon/edit/previewImage.svg" class="mx-auto">-->
    <!--                <div class="c-modal__designPreview&#45;&#45;cancel"-->
    <!--                    :class="[windowWidth >= 640 ? 'is-pc' : 'is-sp']">-->
    <!--                    <button type="button"-->
    <!--                            @click="closePreviewButton()">-->
    <!--                        <img src="/images/icon/edit/preview_cancel_icon.svg">-->
    <!--                    </button>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
</template>

<script>
import ScaleSlidebar from "./ScaleSlidebar";
import {useRoute} from "vue-router";
import {inject} from "vue";
import YesNoModal from "./YesNoModal.vue";
import DesignResetButton from "./DesignResetButton.vue";

export default {
    name: 'Preview',

    components: {
        DesignResetButton,
        YesNoModal,
        ScaleSlidebar,
    },
    setup() {
        const route = useRoute();
        // MEMO: 編集ページの場合のみinjectする（Edit.vueでしかprovideされないため）
        if (route.name === "editpage") {
            const makingItem = inject('makingItemInfo')
            const editStatus = inject('editStatus')
            return {
                makingItem,
                editStatus
            }
        }
    },
    created() {
        this.windowWidth = window.innerWidth;
    },
    data() {
        return {
            showPreview: false,
            isPreviewActive: false,
            isModalActive: false,
            message: '作成した内容がすべてリセットされます。\nよろしいですか？',
            windowWidth: 0,
        }
    },

    methods: {
        previewButton() {
            this.showPreview = true;

            setTimeout(() => {
                this.isPreviewActive = true;
            }, 100)
        },

        closePreviewButton() {
            setTimeout(() => {
                this.showPreview = false;
            }, 300);

            this.isPreviewActive = false;
        },
        /**
         * 作り直す確認モーダルの開閉
         * */
        toggleYesNoModal(flg = false) {
            this.isModalActive = flg;
        },
        /**
         * 作り直すの実行
         * */
        resetDesign(flg) {
            // MEMO: YesNoModalでNoの場合
            if(!flg) {
                // MEMO: YesNoModalを閉じる
                this.toggleYesNoModal(false);
                return;
            }

            // MEMO: DesignResetButton.vueのresetDesignを呼び出す
            this.$refs.designResetButton.resetDesign();
        },
    },


}
</script>

<style>

</style>