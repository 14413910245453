<template>
    <div class="hidden sm:block p-edit__scalebar js-overLapping z-2">
        <div class="p-edit__scalebar__slider">
            <div class="p-edit__scalebar__sliderValue">
                <!-- <p>{{ Math.round(calcHeightValue) + '%' }}</p> -->
            </div>
            <div @click="clickOnSlider" 
                class="p-edit__scalebar__hitArea">
                <div ref="container"
                    class="p-edit__scalebar__container">
                    <div class="p-edit__scalebar__filled"
                        :style="{ height: calcHeightValue + '%' }">
                    </div>
                    <div class="p-edit__scalebar__handle" id="scaleBar"
                        :style="{ bottom: calcHeightValue + '%' }"
                        @mousedown="onMouseDown"
                    >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
        
<script>

function clamp(val, min, max) {
    return Math.max(min, Math.min(max, val));
};

export default {
    data() {
        return {
            calcHeightValue: 50,
            mouseDragging: false,
            routePathOnlyForEdit: this.$route.path.match(/edit/),
        };
    },

    mounted() {
        /**
         * @param[String]
         * スクロールバーを移動させた時に、デザインの倍率を可変させる
         */
        function changeScaleEvent(heightValue, routePath) {
            /**
             * 鳥居：編集画面からUページ遷移した時、
             * clientWidthやsetAttributeなどが原因でコンソールエラーが出てしまったため、
             * URLの判定式を追加しました。
             */
            const locationPath       = location.pathname;
            const splitLocationPath  = locationPath.split('/');
            const selectionSplitPath = '/' + splitLocationPath[1] + '/';
            const selectionRoutePath = '/' + routePath[0] + '/';

            if (selectionSplitPath === selectionRoutePath) {
                const minPercent               = 5;
                const designItemArea           = document.querySelector('#cardEditArea');
                const designItemAreaChildWidth = designItemArea.firstChild.clientWidth; //svgデザインの横幅を取得
                
                /**
                 * width：transformで倍率を変化させる時に、
                 * 親要素の横幅をSVGデザインのサイズに合わせる必要がありました。
                 */
                let setClassTransformAndWidth = '';
                if (heightValue > minPercent) {
                    setClassTransformAndWidth = designItemArea.setAttribute('style',`transform: scale(${heightValue / 50});`);
                }
                return setClassTransformAndWidth;
            }
        };

        (() => {
            document.addEventListener("mousemove", (e) => {
                if (!this.mouseDragging) return;

                // document.querySelector('.js-headerOverlap').classList.add('z-3');
                // document.querySelector('.js-footerLayer').classList.add('z-3');

                this.calcUpdate(e);
                changeScaleEvent(this.calcHeightValue, this.routePathOnlyForEdit);
            });
            
            document.addEventListener("mouseup", (e) => {
                this.mouseDragging = false;

                // document.querySelector('.js-headerOverlap').classList.remove('z-3');
                // document.querySelector('.js-footerLayer').classList.remove('z-3');
                /**
                 * スクロールバーを一番初めにクリックした時に、
                 * スクロールのを移動量を取得出来ていないため、高さの数値が取得してから実行処理。
                 */
               
                setTimeout(() => {
                    changeScaleEvent(this.calcHeightValue, this.routePathOnlyForEdit);
                });
            });
        })();
    },

    methods: {
        onMouseDown() {
            this.mouseDragging = true;
        },
        
        /**
         * 三角形の表示領域を取得
         */
        getRect() {
            const container = this.$refs.container;
            return container.getBoundingClientRect();
        },

        calcUpdate(e) {
            const rect = this.getRect();
            this.calcHeightValue = 100 - ((clamp(e.clientY, rect.top, rect.bottom) - rect.top) / rect.height) * 100;
        },

        clickOnSlider(e) {
            this.calcUpdate(e);
        },
    },
};
</script>
        
<style>

</style>