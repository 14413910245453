import {useCookies} from "vue3-cookies";
import config from "../const/const";

const {cookies} = useCookies();

export default function () {

    /**
     * JWTのアクセストークンをデコードする
     * */
    const decodeJwt = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
    };

    /**
     * トークンの情報を返します
     * */
    const getTokenInfo = () => {
        const cookieData = cookies.get(process.env.MIX_PIARY_SSO_COOKIE_KEY)
        if (cookieData.access_token === undefined) {
            return null;
        }

        return decodeJwt(cookieData.access_token);
    }

    /**
     * トークンが有効化確認します
     * */
    const isTokenActive = () => {
        if (process.env.MIX_USE_SSO === '0') {
            return true;
        }

        // MEMO: トークンが無い場合は無効なためリターン
        const tokenInfo = getTokenInfo();
        if (!tokenInfo || !tokenInfo.exp) {
            return false;
        }

        // タイムスタンプを取得（ミリ秒）
        let d = new Date().getTime();

        // タイムスタンプを変換（秒）
        d = Math.floor(d / 1000);
        return d < tokenInfo.exp;
    };

    /**
     * 会員の情報を取得します
     * 失敗した場合、ECのログアウト画面へリダイレクトします。
     * */
    const getCustomer = async () => {

        // SSO認証が未使用の場合
        if (process.env.MIX_USE_SSO === '0') {
            // MEMO: 自分のIDに書き換えて使用してください
            return {
                id: 1,
                email: 'test@test.com',
                name01: 'ピアリー',
                name02: '太郎',
            }
        }

        // MEMO: cookieがない場合、ゲストの為、空の配列を返します
        if (!cookies.isKey(process.env.MIX_PIARY_SSO_COOKIE_KEY)) {
            return {};
        }
        if (isTokenActive()) {
            const customer_info = await me();
            if (!customer_info) {
                return false;
            }

            // MEMO: トークンが有効な場合、代理ログイン判定情報を追加する
            const tokenInfo = getTokenInfo();
            customer_info.is_proxy_user = tokenInfo.is_proxy;
            return customer_info;
        }

        // トークンが有効期限切れな場合、リフレッシュを実行
        const res = await refresh();
        if (!res) {
            return false;
        }

        const customer_info = await me();
        if (!customer_info) {
            return false;
        }

        // MEMO: トークンが有効な場合、代理ログイン判定情報を追加する
        const tokenInfo = getTokenInfo();
        customer_info.is_proxy_user = tokenInfo.is_proxy;
        return customer_info;
    };

    /**
     * 自分自身の情報を取得します
     * */
    const me = async () => {
        if (!isTokenActive()) {
            return false;
        }
        try {
            const response = await axios.get(config.API_BASE_URL + '/auth/me');
            if (!response.data.result) {
                return false;
            }
            return response.data.data;
        } catch (e) {
            return false;
        }
    };

    /**
     * トークンをリフレッシュします
     * */
    const refresh = async () => {
        if (!cookies.isKey(process.env.MIX_PIARY_SSO_COOKIE_KEY)) {
            return false;
        }
        try {
            const response = await axios
                .get(config.API_BASE_URL + '/auth/refresh');

            if (!response.data.result) {
                return false;
            }

            // MEMO: 帰ってきたトークン情報を有効期限はブラウザを閉じるまでのため、'0'(文字列)を指定
            cookies.remove(process.env.MIX_PIARY_SSO_COOKIE_KEY);
            cookies.set(process.env.MIX_PIARY_SSO_COOKIE_KEY, response.data.data, 0, '/', process.env.MIX_PIARY_SSO_COOKIE_DOMAIN);
        } catch (e) {
            return false;
        }

        return true;

    }

    return {
        isTokenActive, me, refresh, getCustomer,
    }
};
