/**
 * 変換リストに応じて文字列を変換します
 * */
export default function () {
    const replace_list = new Map();
    replace_list.set('0', '〇');
    replace_list.set('1', '一');
    replace_list.set('2', '二');
    replace_list.set('3', '三');
    replace_list.set('4', '四');
    replace_list.set('5', '五');
    replace_list.set('6', '六');
    replace_list.set('7', '七');
    replace_list.set('8', '八');
    replace_list.set('9', '九');
    replace_list.set('０', '〇');
    replace_list.set('１', '一');
    replace_list.set('２', '二');
    replace_list.set('３', '三');
    replace_list.set('４', '四');
    replace_list.set('５', '五');
    replace_list.set('６', '六');
    replace_list.set('７', '七');
    replace_list.set('８', '八');
    replace_list.set('９', '九');
    replace_list.set('-', '－');
    replace_list.set('‐', '－');
    replace_list.set('‑', '－');
    replace_list.set('‒', '－');
    replace_list.set('–', '－');
    replace_list.set('—', '－');
    replace_list.set('―', '－');
    replace_list.set('⁃', '－');
    replace_list.set('⁻', '－');
    replace_list.set('₋', '－');
    replace_list.set('−', '－');
    replace_list.set('─', '－');
    replace_list.set('━', '－');
    replace_list.set('﹣', '－');
    replace_list.set('－', '－');
    replace_list.set('ｰ', '－');
    replace_list.set('|', '｜');
    replace_list.set('a', 'ａ');
    replace_list.set('b', 'ｂ');
    replace_list.set('c', 'ｃ');
    replace_list.set('d', 'ｄ');
    replace_list.set('e', 'ｅ');
    replace_list.set('f', 'ｆ');
    replace_list.set('g', 'ｇ');
    replace_list.set('h', 'ｈ');
    replace_list.set('i', 'ｉ');
    replace_list.set('j', 'ｊ');
    replace_list.set('k', 'ｋ');
    replace_list.set('l', 'ｌ');
    replace_list.set('m', 'ｍ');
    replace_list.set('n', 'ｎ');
    replace_list.set('o', 'ｏ');
    replace_list.set('p', 'ｐ');
    replace_list.set('q', 'ｑ');
    replace_list.set('r', 'ｒ');
    replace_list.set('s', 'ｓ');
    replace_list.set('t', 'ｔ');
    replace_list.set('u', 'ｕ');
    replace_list.set('v', 'ｖ');
    replace_list.set('w', 'ｗ');
    replace_list.set('x', 'ｘ');
    replace_list.set('y', 'ｙ');
    replace_list.set('z', 'ｚ');
    replace_list.set('A', 'Ａ');
    replace_list.set('B', 'Ｂ');
    replace_list.set('C', 'Ｃ');
    replace_list.set('D', 'Ｄ');
    replace_list.set('E', 'Ｅ');
    replace_list.set('F', 'Ｆ');
    replace_list.set('G', 'Ｇ');
    replace_list.set('H', 'Ｈ');
    replace_list.set('I', 'Ｉ');
    replace_list.set('J', 'Ｊ');
    replace_list.set('K', 'Ｋ');
    replace_list.set('L', 'Ｌ');
    replace_list.set('M', 'Ｍ');
    replace_list.set('N', 'Ｎ');
    replace_list.set('O', 'Ｏ');
    replace_list.set('P', 'Ｐ');
    replace_list.set('Q', 'Ｑ');
    replace_list.set('R', 'Ｒ');
    replace_list.set('S', 'Ｓ');
    replace_list.set('T', 'Ｔ');
    replace_list.set('U', 'Ｕ');
    replace_list.set('V', 'Ｖ');
    replace_list.set('W', 'Ｗ');
    replace_list.set('X', 'Ｘ');
    replace_list.set('Y', 'Ｙ');
    replace_list.set('Z', 'Ｚ');
    replace_list.set('ｱ', 'ア');
    replace_list.set('ｲ', 'イ');
    replace_list.set('ｳ', 'ウ');
    replace_list.set('ｴ', 'エ');
    replace_list.set('ｵ', 'オ');
    replace_list.set('ｶ', 'カ');
    replace_list.set('ｷ', 'キ');
    replace_list.set('ｸ', 'ク');
    replace_list.set('ｹ', 'ケ');
    replace_list.set('ｺ', 'コ');
    replace_list.set('ｻ', 'サ');
    replace_list.set('ｼ', 'シ');
    replace_list.set('ｽ', 'ス');
    replace_list.set('ｾ', 'セ');
    replace_list.set('ｿ', 'ソ');
    replace_list.set('ﾀ', 'タ');
    replace_list.set('ﾁ', 'チ');
    replace_list.set('ﾂ', 'ツ');
    replace_list.set('ﾃ', 'テ');
    replace_list.set('ﾄ', 'ト');
    replace_list.set('ﾅ', 'ナ');
    replace_list.set('ﾆ', 'ニ');
    replace_list.set('ﾇ', 'ヌ');
    replace_list.set('ﾈ', 'ネ');
    replace_list.set('ﾉ', 'ノ');
    replace_list.set('ﾊ', 'ハ');
    replace_list.set('ﾋ', 'ヒ');
    replace_list.set('ﾌ', 'フ');
    replace_list.set('ﾍ', 'ヘ');
    replace_list.set('ﾎ', 'ホ');
    replace_list.set('ﾏ', 'マ');
    replace_list.set('ﾐ', 'ミ');
    replace_list.set('ﾑ', 'ム');
    replace_list.set('ﾒ', 'メ');
    replace_list.set('ﾓ', 'モ');
    replace_list.set('ﾔ', 'ヤ');
    replace_list.set('ﾕ', 'ユ');
    replace_list.set('ﾖ', 'ヨ');
    replace_list.set('ﾗ', 'ラ');
    replace_list.set('ﾘ', 'リ');
    replace_list.set('ﾙ', 'ル');
    replace_list.set('ﾚ', 'レ');
    replace_list.set('ﾛ', 'ロ');
    replace_list.set('ﾜ', 'ワ');
    replace_list.set('ｦ', 'ヲ');
    replace_list.set('ﾝ', 'ン');
    replace_list.set('ｧ', 'ァ');
    replace_list.set('ｨ', 'ィ');
    replace_list.set('ｩ', 'ゥ');
    replace_list.set('ｪ', 'ェ');
    replace_list.set('ｫ', 'ォ');
    replace_list.set('ｬ', 'ャ');
    replace_list.set('ｭ', 'ュ');
    replace_list.set('ｮ', 'ョ');
    replace_list.set('(', '（');
    replace_list.set(')', '）');
    replace_list.set('[', '［');
    replace_list.set(']', '］');
    replace_list.set('{', '｛');
    replace_list.set('}', '｝');
    replace_list.set('<', '＜');
    replace_list.set('>', '＞');
    replace_list.set('!', '！');
    replace_list.set('#', '＃');
    replace_list.set('$', '＄');
    replace_list.set('%', '％');
    replace_list.set('&', '＆');
    replace_list.set('*', '＊');
    replace_list.set(',', '，');
    // replace_list.set('-', '－');
    replace_list.set('.', '．');
    replace_list.set('/', '／');
    replace_list.set(':', '‥');
    replace_list.set('：', '‥');
    replace_list.set(';', '；');
    replace_list.set('?', '？');
    replace_list.set('@', '＠');
    replace_list.set('^', '＾');
    replace_list.set('_', '＿');
    replace_list.set('`', '‘');
    replace_list.set('~', '～');
    replace_list.set('･', '・');
    replace_list.set('\\', '＼');
    replace_list.set('"', '”');
    // replace_list.set('', '’');
    replace_list.set('+', '＋');
    replace_list.set('=', '＝');

    /**
     * 指定された文字列がreplace_listのkeyに一致する場合、指定された文字列をvalueに置換して返します
     * @param {string} text 変換対象の文字列
     * */
    const replaceText = (text) => {
        if (!text) {
            return;
        }

        const arrText = text.split('');
        arrText.forEach((item, index) => {
            if (!replace_list.has(item)) {
                return;
            }
            const replace_text = replace_list.get(item);
            arrText[index] = replace_text;
        });
        return arrText.join('')

    };
    return {
        replaceText
    }
};