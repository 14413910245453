<template>
    <image :href="this.image_url"
           :transform="this.transform"
           class="fixed_object"
    />
</template>

<script>
export default {
    name: "FixedObject",
    props: [
        'image_url',
        'transform',
    ],
}
</script>

<style scoped>
.fixed_object {
    pointer-events: none;
}
</style>