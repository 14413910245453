<!--
    MEMO: 絞り込み検索用 セレクトボックス
-->
<template>
    <div class="c-input__selectRightArrow is-editAddress flex items-center">
        <span class="mr-2">絞り込み</span>
        <select v-model="selected" class="c-input__selectArea w-24 py-0.5 px-1 rounded focus:outline-none focus:border-orange focus:ring-orange focus:ring-1 cursor-pointer">
            <option v-for="option in options" :value="option.id" :key="option.id" class="change">
                {{ option.name }}
            </option>
        </select>
    </div>
</template>

<script>
import config from '../const/const'

export default {
    name: "SearchSelectBox",
    data() {
        return {
            selected: '',   // MEMO: selected属性用のプロパティ。デフォルトは未選択
            options: []
        }
    },
    created() {
        this.getList();
    },
    methods: {
        /**
         * MEMO: 選択肢の取得
         * */
        getList() {
            let options = this.options;
            axios.get(config.API_BASE_URL + '/search_select_box').then(function (response) {
                for (let i = 0; i < response.data.length; i++) {
                    options.push(response.data[i]);
                }
            }).catch(function (error) {
                console.log('failure');
            })
        }
    }


}
</script>

<style scoped>

</style>