export default function () {
    /**
     * ユニークなIDを返します
     *
     * @param {number} myStrong
     */
    const generateUniqueStr = (myStrong = 10000000000000000000000000) => {
        return new Date().getTime().toString(16) + Math.floor(myStrong * Math.random()).toString(16)
    }

    return {
        generateUniqueStr
    }
};
