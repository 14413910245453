import config, * as Enum from "../const/const";
import axios from "axios";
import RegisterFunctions from "./register";
import {DesignSenderValidationRule} from "../validation_rules/design_sender_rule";
import _ from "lodash";

const {
    filterActiveJointNames,
    resetFormDataForGuestType,
    convertToSenderJointNameParts
} = RegisterFunctions();
export default function () {

    /**
     * デザイン面の画像を生成し、保存する
     * */
    const SaveAsPicture = async (makingItem) => {
        const save_sender_result = await saveSender(makingItem);
        if (!save_sender_result) {
            return {
                result: false,
                message: '差出人の入力内容にエラーがあります。ご確認お願いいたします。'
            };
        }

        return await axios.post(config.API_BASE_URL + '/making_item/store', {
            //更新レコードのキー
            nenga_id: makingItem.nenga_id,      // 代理ログインの場合、年賀IDを使用して保存する
            customer_id: makingItem.customer_id,
            design_code: makingItem.design_code,
            code: makingItem.code,
            //更新内容
            selected_layout_id: makingItem.selectedLayout.id,
            selected_title_id: makingItem.selectedTitle.id,
            selected_background_id: makingItem.selectedBackground.id,

            // MEMO: 配置ルールがない場合はNULL
            selected_sender_display_rule: Object.keys(makingItem.selectedSenderOrderingRule).length ?
                makingItem.selectedSenderOrderingRule.id :
                null,
            deployed_images: deployedImages2Save(makingItem),
            deployed_stamps: makingItem.deployedStamps,
            deployed_texts: makingItem.userTexts,

            // MEMO: 配置ルールがない ＝ 差出人のテキストエリアが編集エリアにない為、差出人をNULLにする
            sender_id: Object.keys(makingItem.selectedSenderOrderingRule).length ? makingItem.senderItem.sender_id : null,
            sender: {
                font: makingItem.senderItem.font,
                transform: makingItem.senderItem.transform,
                color: makingItem.senderItem.color,
                anchor: makingItem.senderItem.anchor,
                writingMode: makingItem.senderItem.writingMode,
                x: makingItem.senderItem.x,
                y: makingItem.senderItem.y,
            },
            material_pattern_code: makingItem.material_pattern_code,
        }).then((res) => {
            return {
                result: true,
                message: null,
                data: res.data.data
            };
        }).catch((err) => {
            return {
                result: false,
                message: '年賀状の保存に失敗しました。'
            };
        });
    };

    /**
     *
     * */
    const deployedImages2Save = (makingItem) => {
        return makingItem.deployedImages.map((img) => {
            return {
                clipPath: img.clipPath,
                image_file: img.image_file,
                inverseImageTransform: img.inverseImageTransform,
                transform: img.transform,
                image_width: img.width,
                image_height: img.height,
                type: "userImage",
            }
        })
    }

    /**
     * バリデーションが必要か判定します
     * */
    const needsSenderValidation = (sender_post_data) => {
        if (!sender_post_data.address
            && !sender_post_data.building_name
            && !sender_post_data.corporate_name1
            && !sender_post_data.corporate_name2
            && !sender_post_data.department_name1
            && !sender_post_data.department_name2
            && !sender_post_data.mei
            && !sender_post_data.sei
            && !sender_post_data.old_name_or_age
            && !sender_post_data.position_name
            && !sender_post_data.post_no
            && !sender_post_data.pref_name
            && !sender_post_data.tel
            && (!sender_post_data.joint_names || !sender_post_data.joint_names.length)) {

            return false;
        }
        return true;
    }

    /**
     * 差出人の保存
     * */
    const saveSender = async (makingItem) => {

        // 差出人の保存
        const sender_post_data = createSenderRegisterData(makingItem.senderItem)

        // POSTする連名情報に会員IDをセットする
        sender_post_data.customer_id = makingItem.customer_id
        for (const joint_name of sender_post_data.joint_names) {
            joint_name.customer_id = makingItem.customer_id
        }

        // empty = {} の場合は配置ルールがないため 保存しない
        if (Object.keys(makingItem.selectedSenderOrderingRule).length === 0) {
            return true;
        }

        // MEMO: バリデーションが必要な場合は実行
        // 送信データがすべて空の場合はバリデーションを実行しない
        if (!needsSenderValidation(sender_post_data)) {
            // 何も入ってないので、バリデーションと保存を実行しないでTrueを返す
            return true;
        }

        const validation_result = await DesignSenderValidationRule.validate(sender_post_data)
            .then((result) => true)
            .catch((error) => {
                return false;
            });

        if (!validation_result) {
            return false;
        }

        return await axios.post(config.API_BASE_URL + '/sender_list/store', sender_post_data)
            .then((response) => {
                if (response.data.result) {
                    const saved_sender_item = response.data.data[0]
                    makingItem.senderItem.sender_id = saved_sender_item.id
                    return true;
                }
                return false;
            })
            .catch((error) => {
                console.log('failure: save sender')
                console.log(error)
                return false;
            });
    };

    /**
     * 差出人登録用のデータを生成します
     * */
    const createSenderRegisterData = (data) => {
        // MEMO: 保存後もリロードするまでは個人↔法人を切り替えても入力された値を保持するため、元の値は変更しないようにクローンする
        const post_data = _.cloneDeep(data);

        // MEMO: 個人用/法人用で不要な項目の値を削除する
        resetFormDataForGuestType(post_data, 'sender')

        // MEMO: 有効な連名だけPOSTデータにする
        post_data.joint_names = filterActiveJointNames(post_data.joint_names);

        // MEMO: 有効な連名を差出人連名パーツを保存する形に変換します
        //  法人の場合の連名の旧姓・年齢は上の処理で元のデータが消えてるので、ここでは配列に含まれないため何もしない
        post_data.sender_joint_name_parts = convertToSenderJointNameParts(post_data.joint_names);

        // MEMO: isDefaultSenderにて比較する際に、nullと空文字の比較になるため置き換える
        for (const joint_name of post_data.joint_names) {
            joint_name.sei = joint_name.sei ?? '';
            joint_name.mei = joint_name.mei ?? '';
            joint_name.old_name_or_age = joint_name.old_name_or_age ?? '';
            joint_name.position_name = joint_name.position_name ?? '';
        }

        return {
            id: post_data.sender_id ?? '',
            guest_type: post_data.guest_type,
            corporate_name1: post_data.corporate_name1 ?? '',
            corporate_name2: post_data.corporate_name2 ?? '',
            department_name1: post_data.department_name1 ?? '',
            department_name2: post_data.department_name2 ?? '',
            sei: post_data.sei ?? '',
            mei: post_data.mei ?? '',
            old_name_or_age: post_data.old_name_or_age ?? '',
            position_name: post_data.position_name ?? '',
            sender_name_parts: post_data.sender_name_parts,
            sender_joint_name_parts: post_data.sender_joint_name_parts,
            joint_names: post_data.joint_names,
            post_no: post_data.post_no,
            pref_name: post_data.pref_name,
            address: post_data.address ?? '',
            building_name: post_data.building_name ?? '',
            tel: post_data.tel,
            is_design: 1,       // MEMO: デザイン側で登録した差出人はON(1)にする
        }
    }

    return {
        SaveAsPicture,
        createSenderRegisterData,
    }
}