<template>
    <div
        class="fixed w-full h-full left-1/2 top-1/2 z-100 flex justify-center items-center -translate-y-1/2 -translate-x-1/2">
        <div class="v-validateBox">
            <div class="font-bold">
                <div class="px-4">
                    <div class="leading-7">
                        <p style="white-space: pre-line;" v-for="(message, index) in messages" :key="index">{{ message }}</p>
                    </div>
                    <div v-if="confirmIsAvailable" class="text-center text-red pt-6">
                        <button @click="confirm">OK</button>
                    </div>
                    <div class="text-center text-blue02 pt-6">
                        <button @click="cancel">閉じる</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-modal__overlay" :class="{'is-modal-active': isModalActive}"></div>
    </div>
</template>

<script>
export default {
    name: "EditPageModalAlert",
    props: {
        isModalActive: Boolean,
        messages: Array,
        confirmProcedure: Function,
        cancelProcedure: Function,
    },
    methods: {
        async confirm() {
            if (this.confirmProcedure) {
                await this.confirmProcedure()
            }
            this.$emit('close')
        },
        async cancel() {
            if (this.cancelProcedure) {
                await this.cancelProcedure()
            }
            this.$emit('close')
        },
    },
    computed: {
        confirmIsAvailable() {
            return this.confirmProcedure !== null
        },
    },
}
</script>

<style scoped>
.v-validateBox {
    width: 90%;
    max-width: 32rem;
    background-color: #FFF;
    padding: 2rem 0;
    font-weight: bold;
    text-align: center;
    position: fixed;
    top: calc(50% - 150px);
    z-index: 3;
    -webkit-animation: slideDown .3s ease .3s normal backwards;
    animation: slideDown .3s ease .3s normal backwards;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>