/**
 * 共通関数
 * */
import moment from 'moment'
moment.locale("ja")

export default {
    methods: {
        /**
         * MEMO: timestampを日付文字列へフォーマット
         * */
        convertTimestampToDate: function (date) {
            return moment.unix(date).format('YYYY/MM/DD')
        },
        startParse: function(start) {
            return moment(start).format('YYYY/MM/DD')
        },
        endParse: function(end) {
            return moment(end).format('YYYY-MM-DD')
        }
    }
}