<!-- MEMO: TOPページ 作成中のアイテム -->
<template>
    <section>
        <div class="pt-16 pb-8">
            <p class="sm:text-lg text-center font-bold text-red">作成中のアイテム</p>
            <!-- MEMO: 作成中のアイテム一覧 -->
            <div class="grid grid-cols-2 gap-x-2 gap-y-6 px-4 sm:px-0 pt-6">
                <div class="flex flex-col sm:mx-0 py-4 px-4 rounded-lg bg-white" v-for="making_item in making_items"
                     :key="making_item.id">
                    <button
                            class="relative border border-gray02 p-3 hover:shadow-md transition-shadow"
                            @click="showPreview(making_item.id, making_item.product_code)"
                    >
                        <img :src="making_item.thumbnail_file" class="w-40 ml-auto mr-auto">
                        <div class="absolute bottom-2 right-3 hidden sm:block">
                            <img src="/images/icon/top/ic_top-scale.svg">
                        </div>
                    </button>

                    <ul class="mt-4 text-xs text-gray01">
                        <!-- <li>注文番号：{{ making_item.id }}</li>
                        <li>申し込み期限：{{ this.convertTimestampToDate(making_item.order_limit) }}</li> -->
                        <li v-if="making_item.order_id">注文番号 : {{ making_item.order_id }}</li>
                        <li>商品コード：<span>{{ making_item.product_code }}</span></li>
                        <li>商品名：<span>{{ making_item.product_name }}</span></li>
                        <!-- <li class="mt-4 text-red font-bold">{{ making_item.settlement_status_name }}</li> -->
                    </ul>
                    <div class="mt-auto">
                        <div class="pt-4">
                            <button
                                    class="w-full text-sm border border-gray03 font-bold py-3 hover:bg-orange hover:text-white hover:border-orange"
                                    @click="showPreview(making_item.id, making_item.product_code)">デザイン編集
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- MEMO: 詳細/編集/複製 モーダル -->
    <section>
        <transition>
            <div
                    class="sm:mx-auto fixed w-10/12 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-3 u-scrollbar__none"
                    v-if="isShowPreview">
                <div class="bg-white w-full sm:max-w-sm ml-auto mr-auto py-8 rounded-2xl">
                    <div class="px-8">
                        <img :src="modal_preview_img" class="mx-auto z-1 shadow-md">
                        <div class="mt-8 px-4">
                            <RouterLink  :to="getEditPageParam"
                                        class="block w-full rounded text-center border border-gray03 text-sm sm:text-base font-bold mt-2 py-3 hover:bg-orange hover:text-white hover:border-orange">
                                デザイン編集に進む
                            </RouterLink>
                            <!--<a href="/edit"-->
                            <!--class="block w-full rounded text-center border border-gray03 text-sm sm:text-base font-bold mt-2 py-3 hover:bg-orange hover:text-white hover:border-orange">デザイン編集に進む</a>-->
                        </div>
                    </div>
                    <div class="block text-center pt-8">
                        <button class="font-bold text-blue02" @click="showPreview()">閉じる</button>
                    </div>
                </div>
            </div>
        </transition>
    </section>

    <!--モーダル背景-->
    <div class="c-modal__whole" v-if="isShowOverlay"></div>
    <LoadingScreen :is-loading-screen="isLoadingScreen"/>
    <!--    <div class="u-top__addresseeAdd__preview__overlay js-topAddresseeAddOverlay" v-if="isShowOverlay"></div>-->
</template>

<script>
import config from "../const/const";
import CommonModule from "../functions/common_module";
import LoadingScreen from "./LoadingScreen.vue";
import axios from "axios";

export default {
    name: "MakingList",
    components: {LoadingScreen},
    props: {
        customer: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            making_items: [],
            nenga_id: '',
            modal_preview_img: '',
            edit_product_code: '',
            preview_images: [],
            isShowPreview: false,
            isShowOverlay: false,
            isLoadingScreen: false,
        }
    },
    async mounted() {
        // MEMO: 作成中リストの取得
        await this.getMakingListItems();
        CommonModule.modalNotScroll(this.isShowPreview);
    },
    methods: {
        /**
         * MEMO: プレビューの表示
         * */
        async showPreview(nenga_id = null, product_code = null) {
            // MEMO: 値を反転させて設定
            this.isShowPreview = !this.isShowPreview;
            this.isShowOverlay = !this.isShowOverlay;
            this.edit_product_code = product_code;
            this.nenga_id = nenga_id;   // 選択された年賀状のIDをセットする

            // スクロールバーの制御
            CommonModule.modalNotScroll(this.isShowPreview);
            this.modal_preview_img = this.preview_images[nenga_id];
        },
        /**
         * プレビュー画像の取得
         * */
        async getPreviewImage(customer_id, nenga_id) {
            return await axios.get(config.API_BASE_URL + '/making_item/get_preview_image/' + customer_id + '/' + nenga_id + '/0')
                .then((response) => response.data.data)
                .catch(() => {
                    console.log('failure');
                });
        },
        /**
         * MEMO: 作成中のアイテム一覧の取得
         * */
        async getMakingListItems() {
            this.isLoadingScreen = true;
            const params = {
                customer_id: this.customer.id,
            };

            this.making_items.splice(0);

            const result = await axios.post(config.API_BASE_URL + '/making_list/search', params)
                .then((response) => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.making_items.push(response.data[i]);
                    }
                    return true;
                })
                .catch(function () {
                    console.log('failure');
                    return false;
                });

            if (!result) {
                this.isLoadingScreen = false;
            }

            // MEMO: 画像のBase64データの取得
            // for await を使用してすべての取得が終わるまで待つ
            for await (const making_item of this.making_items) {
                this.preview_images[making_item.id] = await this.getPreviewImage(this.customer.id, making_item.id);
            }
            this.isLoadingScreen = false;
        },
    },
    computed: {
        /**
         * 編集ページへのリンク
         * MEMO: 代理ログインの場合はパラメーターに年賀IDを付与する
         * */
        getEditPageParam() {
            if (this.customer.is_proxy_user) {
                return {
                    name: 'editpage',
                    params: {designCode: this.edit_product_code, nengaId: this.nenga_id}
                }
            }
            return {
                name: 'editpage',
                params: {designCode: this.edit_product_code}
            }
        }
    },
}
</script>

<style scoped>
.v-leave-active,
.v-enter-active {
    transition: opacity 0.3s;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
