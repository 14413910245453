<template>
    <div v-if="isLoadingScreen" :class="loadingModalBackground">
        <div class="animate-spin h-10 w-10 border-4 border-rose02 rounded-full"
             style="border-top-color: transparent">
        </div>
        <!-- ゲスト連携時のみ表示するUI -->
        <div v-if="otherServiceImport" class="p-address__serviceImport">
            <div class="text-red">
                <p>ゲスト情報を読み込んでおります。<br>完了次第、画面が自動で切り替わります。<br>そのままお待ちください。</p>
            </div>
        </div>
        <!-- ゲスト連携、登録時に表示するUI -->
        <div v-if="otherServiceRegister" class="p-address__serviceImport">
            <div class="text-red">
                <p>ただいま取り込み中です。<br>※件数が多い場合、取り込みに<br>お時間がかかる場合がございます。</p>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "LoadingScreen",
    props: {
        isLoadingScreen: {
            type: Boolean,
            required: true,
            default: false,
        },

        otherServiceImport: {
            type: Boolean,
            default: false,
        },

        otherServiceRegister: {
            type: Boolean,
            default: false,
        },

        initLoadingDesign: {
            type: Boolean,
        },
    },

    computed: {
        loadingModalBackground() {
            return this.initLoadingDesign ? ['c-modal__loading', 'is-editArea'] : ['c-modal__loading'];
        }
    }
}
</script>

<style scoped>

</style>