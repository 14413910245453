<!--
    MEMO: 差出人プレビュー
-->
<template>
    <div class="sm:max-w-2xl sm:ml-auto sm:mr-auto c-modal__preview">
        <div class="sm:w-80 sm:ml-auto sm:mr-auto bg-white mx-6 py-8 rounded-20">
            <!-- MEMO: プレビュー用のコンポーネントを読み込む -->
            <PreviewSvgTemplate
                class="ml-auto mr-auto z-10"
                :is-show-back-ground="true"
                :svg-width="sender_canvas_setting.width" :svg-height="sender_canvas_setting.height"
                :preview-image-width="sender_canvas_setting.width"
                :preview-image-height="sender_canvas_setting.height"
                :svg-image-string="svgImageString"
            />
            <div class="block text-center pt-6">
                <a href="#" class="font-bold text-blue02" @click="close()">閉じる</a>
            </div>
        </div>
    </div>
</template>

<script>
import SenderPrintImageFunctions from "../../functions/sender_print_image";
import PreviewSvgTemplate from "../AddressList/PreviewSvgTemplate";

const {
    canvasSize,
    canvasSizeForPrint,
} = SenderPrintImageFunctions();

export default {
    name: "SenderPreview",
    components: {PreviewSvgTemplate},
    props: {
        isPrint: {
            type: Boolean,
            required: false,
            default: false
        },
        svgImageString: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            sender_canvas_setting: this.isPrint ? canvasSizeForPrint : canvasSize, // constをtemplateで参照するために必要
        }
    },
    emits: ['close'], // このオプションが必要
    methods: {
        /**
         * MEMO: キャンセルボタン
         * */
        close() {
            this.$emit('close');       // 呼び出し元の@cancelを実行
        },
    }
}
</script>

<style scoped>

</style>