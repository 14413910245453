<template>
    <div>
        <div :class="overlayRectStyle" @click="toView()"></div>
        <div :class="setFrameImage" class="text-red font-bold">
            <p>画像を配置する箇所を選択してください</p>
        </div>
        <MoveableManipulateBox
            v-if="editing"
            ref="target"
            class="target"
            target-dom-id="#target"
            @setObjectPosition="setObjectPosition"></MoveableManipulateBox>
    </div>
</template>

<script>
import MoveableManipulateBox from "./MoveableManipulateBox.vue";
import {EditState} from "../../const/const";
import {inject} from "vue";
import decisionClip from "../../functions/user_image_decision_clipping"

export default {
    setup() {
        const editStatus = inject('editStatus');
        return {
            editStatus,
        };
    },
    components: {
        MoveableManipulateBox,
    },

    methods: {
        toView() {
            decisionClip(this.editStatus.imageToDeploy, this.editStatus.state, EditState.EDIT_USER_IMAGE, this.editStatus.flameToClip)
            this.editStatus.neutralize();
        },

        //画像、テキスト、スタンプ等の配置を確定するボタン
        setObjectPosition() {
            this.toView();
        },
    },
    data() {
        return {
            stateData: this.editStatus.state,
        }
    },
    computed: {
        editing() {
            const state_is_editing = this.editStatus.state === EditState.EDIT_OBJECT
                || this.editStatus.state === EditState.EDIT_USER_IMAGE;
            const target_is_exist = this.editStatus.currentTarget !== '';
            return state_is_editing && target_is_exist;
        },

        /**
         * targetPattern: userImage, stamp, userTextなどが返る
         */
        overlayRectStyle() {
            if (this.editStatus.state === EditState.SELECT_FLAME || this.editStatus.state === EditState.EDIT_USER_IMAGE) {
                return ['c-modal__editDesign', 'is-userImage']
            }
        },

        setFrameImage() {
            if (this.editStatus.state === EditState.SELECT_FLAME) {
                return ['c-modal__frame__attention', 'is-active'];
            } else {
                return ['c-modal__frame__attention'];
            }
        },
    },
}
</script>

<style>

</style>