<template>
    <div class="context_menu_area moveable rCS1rb3a7w moveable-control moveable-direction moveable-ne moveable-scalable"
        :style="setTransform">
        <div >
            <button @click="decision"
                class="absolute top-0 right-0 translate-x-2/4 -translate-y-2/4 rounded-2xl z-5 cursor-pointer c-button__objectDecision">
            </button>
        </div>
    </div>
</template>

<script>
import {inject} from "vue";
export default {
    name: 'setObjectPosition',

    components: {

    },

    setup() {
        const makingItem = inject('makingItemInfo');
        const editStatus = inject('editStatus');

        return {
            editStatus,
            makingItem
        };
    },

    methods: {
        decision() {
            this.$emit('setObjectPosition')
        }
    },

    computed: {
        setTransform() {
            return {
                transform: 'translate(0px, 0px)',
            }
        },
    }
}
</script>

<style>
.c-button__objectDecision {
    display: inline-block;
    position: relative;
}
.c-button__objectDecision::before {
    content: '';
    position: absolute;
    top: -30px;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border-radius: 50px;
    left: -12px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 30%);
}
.c-button__objectDecision::after {
    content: '';
    display: block;
    position: absolute;
    top: -25px;
    left: -8px;
    width: 12px;
    height: 8px;
    border-left: 3px solid #FF7700;
    border-bottom: 3px solid #FF7700;
    transform: rotate(-45deg);
}
</style>